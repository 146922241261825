import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import cloneDeep from "lodash.clonedeep";

import {useRecoilValue} from 'recoil';

import {useApolloClient} from '@apollo/client';

import { 
  fetchOneDomainInstance,
  OperationTargets
} from '../../api/CommonCrudOperations'

import {AccountType} from '../../Enums'

import LTRDomainObjectInfo from '../shared/domains/LTRDomainObjectInfo';

import ncrypter from '../utilties/ncrypter';
import PasscodeStorage, {pcodeNotFound} from '../../api/PasscodeStorage';

import {encryptionSettings} from '../settings/SettingsState';

// Our labels in the Info Table
const labels = {
  type: "TYPE",
  institution: "INSTITUTION",
  contact: "CONTACT",
  accountNumber: "ACCOUNT NUMBER",
  cardNumber: "CARD NUMBER",
  billpayAccount: "BILLPAY ACCOUNT",
  notes: "NOTES"
}

export const prepareTableRowInfo = (account) => {
  // prepare table data for show
  let messageAction = '';
  let messageDoc = '';
  if (account.actionItems) {
    messageAction = account.actionItems.length === 0 ? "This account has NO action items" :
      "This account has " + account.actionItems.length + " action items";
  }
  if (account.attachments) {
    messageDoc = account.attachments.length === 0 ? "This account has NO attached files":
    "This account has " + account.attachments.length + " attached files"
  }

  const tableInfo = {
    name: account.name,
    actionItemMessage: messageAction,
    documentsMessage: messageDoc,
    fieldData : [
      {
        field: labels.type,
        value: account.type
      },
      {
        field: labels.institution,
        value: account.insitituion
      },
    ]
  }
  const contact = {
    field: labels.contact,
    value: account.contact !== null ? account.contact.name : ''
  }
  tableInfo.fieldData.push(contact);
  
  if (account.type === AccountType.Credit) {
    const fieldData = {
      field: labels.cardNumber,
      value: account.accountNumber
    }
    tableInfo.fieldData.push(fieldData);
  } else {
    const fieldData = {
      field: labels.accountNumber,
      value: account.accountNumber
    }
    tableInfo.fieldData.push(fieldData);
  }
  if (account.type === AccountType.Credit) {
    const fieldData = {
      field: labels.billpayAccount,
      value: account.billpayAccount ? "Bills ARE paid on this credit card" : "Bills are NOT paid on this credit card"
    }
    tableInfo.fieldData.push(fieldData);
  } else {
    const fieldData = {
      field: labels.billpayAccount,
      value: account.billpayAccount ? "Bills ARE paid from this account" : "Bills are NOT paid from this account"
    }
    tableInfo.fieldData.push(fieldData);
  }
  const notes = {
    field: labels.notes,
    value: account.usage
  }
  tableInfo.fieldData.push(notes);

  return tableInfo;  
}

const useAccountFetch = (client, settings, domainId, updateFn) => {
  const [account, setAccount] = useState(null);
  
  useEffect(() => {
    const fetchAccount = () => { 
      fetchOneDomainInstance(client, OperationTargets.Accounts, domainId)
      .then(result => { 
        const account = result.item;
        let editAccount = cloneDeep(account);

        if (account.accountNumber && account.accountNumber !== '') {
          // default 
          editAccount.accountNumber = "########################";

          // but if we are we encrypting data we need to decrypt this value
          if (settings.encryptedData) {
            // see if we have a passcode
            const pcode = PasscodeStorage.getPasscode();
            if (pcode !== pcodeNotFound) {
              const tempNumber = ncrypter.decryptData(pcode, account.accountNumber)
              editAccount.accountNumber = tempNumber;       
            } 
          }
        }
        setAccount(editAccount);
        updateFn(editAccount);
      })
      .catch(error => {
        console.log(error) 
      });
    }

    if (account === null && domainId !== null) {
      fetchAccount()
    }  
  }, [client, updateFn]);
};

const AccountInfo = (props) => {
  const client = useApolloClient();

  const [showInfoDialogOpen, setShowInfoDialogOpen] = useState(false);
  const [tableInfo, setTableInfo] = useState(null);
  const [account, setAccount] = useState(null);

  const settings = useRecoilValue(encryptionSettings);

  const handleShowInfoClose = () => {
    setShowInfoDialogOpen(false);
    // notify the client
    props.handleClose();
  }

  useAccountFetch(client, settings, props.domainId, (fetchedData) => {
    if (fetchedData !== null) {
      setAccount(fetchedData);
      const info = prepareTableRowInfo(fetchedData);
      setTableInfo(info);
      setShowInfoDialogOpen(true);
    }
  });

  if (tableInfo === null) {
    return null;
  }

  return (
    <React.Fragment>  
      {showInfoDialogOpen ? (
        <LTRDomainObjectInfo
          open = {props.open}
          title = {account.name}
          tableData = {tableInfo}
          handleClose = {handleShowInfoClose}
        />): null
      }
    </React.Fragment>
  )
}

AccountInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  domainId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default (AccountInfo);

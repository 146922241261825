import * as yup from 'yup';

// const phoneRegExp = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

export const validationSchema = () => {
  const schema = yup.object({
    name: yup.string("Enter a name").required("Name is required"),
    emails : yup.array().of(yup.object({emailAddress: yup.string().email('Enter a valid email address')})),
    //email: yup.string("Enter your email").email("Enter a valid email"),
    //phones: yup.array().of(yup.object({phoneNumber: yup.string("Enter a phone number")matches(phoneRegExp, 'Phone number is not valid')})),
    //phones: yup.array().of(yup.object({phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid')})),
    //phone: yup.string().matches(phoneRegExp, 'Phone number is not valid')
  })
  return schema
}

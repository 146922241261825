/* eslint-disable react/prop-types */

import React, { useState } from "react";

import { signOut } from "firebase/auth";
import { firebaseAuth } from "../index";

export const AuthContext = React.createContext();

export const AuthProvider = ({
  children,
  ...props
}) => {

  /*
  TEMPORARY = just a test to see if this does keep up to date. If it works,
  creae an AuthContext and use it for isAuthenticated AND unsubscribe

  NOT DOING UNSCRIBE YET - this should be part of logout process
  */
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authUser, setAuthUser] = useState(null);

  const unsubscribe = props.auth.onAuthStateChanged((user) => {
    //console.log('AUTH state changed');
    if (user) {
      //console.log('AUTH state change: LOGIN of: ' + user.uid);
      setIsAuthenticated(true);
      setAuthUser(user);
    } else {
      //console.log('AUTH state change: LOGOUT');
      setIsAuthenticated(false);
      setAuthUser(null);
    }
  });

  const getCurrentUser = () => {
    const user = firebaseAuth.currentUser;
    return user;
  }

  const logout = async () => {
    // unsubscribe();
    await signOut(firebaseAuth)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authUser,
        getCurrentUser,    
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

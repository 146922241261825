import {atom} from 'recoil'

export const actionItemList = atom (
  {
    key: 'action-item-list',
    default: []
  }
)




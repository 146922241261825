import React, { useState, useEffect, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { gql, useQuery } from "@apollo/client";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

// each domain object can contribute to this view
import IncompleteAccountView from "./IncompleteAccountView";
import IncompleteBillersView from "./IncompleteBillersView";
import IncompleteContactView from "./IncompleteContactView";
import IncompleteInsuranceView from "./IncompleteInsuranceView";
import IncompleteLiabilityView from "./IncompleteLiabilityView";
import IncompleteSubscriptionsView from "./IncompleteSubscriptionsView";

// queries we use
import {
  getIncompleteAccountsCount,
  getIncompleteBillersCount,
  getIncompleteInsuranceCount,
  getIncompleteContactsCount,
  getIncompleteSubscriptionsCount,
  getIncompleteLiabilitiesCount,
} from "../../graphql/queries";

import { FamilyContext } from "../../context/FamilyContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  titleText: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    fontStyle: "italic",
  },
  margin: {
    margin: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

const GET_INCOMPLETE_ALL = gql(
  `query listAllIncomplete ($userRealm: String) {
  ` +
    getIncompleteAccountsCount +
    getIncompleteBillersCount +
    getIncompleteInsuranceCount +
    getIncompleteContactsCount +
    getIncompleteSubscriptionsCount +
    getIncompleteLiabilitiesCount +
    `
}
`
);

// function Wrapper({ children }) {
//   const count = Children.count(children);
//   const countArray = Children.toArray(children).length;
//   return (
//     <div>
// {children}
//     </div>

//     </section>
//   );
// }

const IncompleteDataListView = (props) => {
  const classes = useStyles();
  const familyContext = useContext(FamilyContext);

  const [summaryCount, setSummaryCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_INCOMPLETE_ALL, {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  useEffect(() => {
    const onCompleted = (data) => {
      if (data) {
        const count = 
          data.accounts_count.aggregate.count +
          data.billers_count.aggregate.count +
          data.contacts_count.aggregate.count +
          data.insurance_count.aggregate.count +
          data.liabilities_count.aggregate.count +
          data.subscriptions_count.aggregate.count;

        setSummaryCount(count);
      }
    };
    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  const handleRefetch = () => {
    // simply call refetch
    refetch();
  };


  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  let countMessage = "";
  let moreMessage =
    " These items require more information from you to make them useful.";

  if (summaryCount === 0) {
    countMessage = "congratulations, you have nothing left to work on!";
    moreMessage = "";
  } else {
    countMessage = "you have " + summaryCount + " items to work on.";
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <Container className={classes.paper} maxWidth="xl">
          <Typography variant="h6" className={classes.titleText}>
            {"Incomplete data: " + countMessage}
          </Typography>
          <Typography gutterBottom className={classes.subtitle}>
            {moreMessage}
          </Typography>
          <Divider className={classes.divider} />
          {data.accounts_count.aggregate.count ? (
            <IncompleteAccountView refetchFunc={handleRefetch} />
          ) : null}
           {data.billers_count.aggregate.count ? (
            <IncompleteBillersView refetchFunc={handleRefetch} />
          ) : null}
          {data.contacts_count.aggregate.count ? (
            <IncompleteContactView refetchFunc={handleRefetch} />
          ) : null}
          {data.insurance_count.aggregate.count ? (
            <IncompleteInsuranceView refetchFunc={handleRefetch} />
          ) : null}
          {data.liabilities_count.aggregate.count ? (
            <IncompleteLiabilityView refetchFunc={handleRefetch} />
          ) : null}
          {data.subscriptions_count.aggregate.count ? (
            <IncompleteSubscriptionsView refetchFunc={handleRefetch} />
          ) : null}
        </Container>
      </Paper>
    </div>
  );
};

export default IncompleteDataListView;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  bodyMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const ContactsSnip = (props) => {
  const classes = useStyles();

  return (
     <div className={classes.root}>
      <Typography variant="body2" gutterBottom color="initial">
        'Contacts' represent the people you deal with day to day.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        That includes family, friends, neighbors, doctors, dentists, financial advisors as well as work colleagues and business contacts.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        You can designate some contacts as 'Phone Tree' contacts. These contacts can help you reach out to 
        specific groups of people. For example, you can designate 'Jane' as a contact for Family - she can then be asked 
        to reach out to "Mom's cousins" on your behalf.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        To save time, you can import contacts from any application that supports VCard Export (such as Apple Contacts or Google Contacts). 
        First you export the VCard contacts to your computer from your contacts application, then import them from the Contacts page.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        Note that this is NOT your complete contact list; limit your list to those people that need to be contacted 
        should something happen to you.
      </Typography>
     </div>
  );
}

export default ContactsSnip;
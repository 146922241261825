import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";

import {useApolloClient} from '@apollo/client';

// TODO: this is wrong!
import CustomToolbar from '../../actionitems/CustomToolbar';
import CustomToolbarSelect from '../../actionitems/CustomToolbarSelect';

import { 
  domainTypeToOperationTarget,
  deleteMultipleDomainInstances
} from '../../../api/CommonCrudOperations';

import {useFamilyContext} from '../../../context/FamilyContext';

import {sortListByName} from '../../utilties/SortFunctions';

const LTRCommonDataTable = (props) => {
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [visibleList, setVisibleList] = useState([]);

  useEffect(() => {
    // just one reason we're here: if  change we need to adjust
    if (props.dataItems) {
      const sortedList = sortListByName(props.dataItems);
      setVisibleList(sortedList);
    }
  }, [props]);

  //onRowClick = (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => void
  const handleRowClick = (rowData, rowMeta) => {
   console.log('Rowdata: ', rowData);
   console.log('RowMeta dataindex: ', rowMeta.dataIndex)
   console.log('RowMeta rowindex: ', rowMeta.rowIndex)
  }

  const onRowsDelete = (rowsDeleted) => {
    const target = domainTypeToOperationTarget(domain);

    // payload is an array of items to delete
    const payload = rowsDeleted;
    
    deleteMultipleDomainInstances(client, familyContext.familyRealm.id, target, payload)
    .then(result => {    
      console.log(result)
      if (props.handleMultiDeleteNotification) {
       props.handleMultiDeleteNotification(rowsDeleted);
      }     
      return true;
    }).catch(error => { 
       console.log(error);
       return false;
    });
  }

  const {domain, domainColumns} = props;

  const noMatchMsg = "No " + domain.toLowerCase() + " found";

  // NOTE: the first column MUST be the id - so we can find it and use 
  // it for operations
  const fixedColumns = [
    {
      name: "id",
      options: {
        display: 'excluded',
        download: false,
        print: false,
        filter: false
      }
    },
  ]
  const columns = fixedColumns.concat(domainColumns);
  const downloadFilename = props.domain.toLowerCase-'download.csv';
  
  const options = {
    filterType: "multiselect",
    responsive: "vertical",
    fixedHeader: true,
    downloadOptions: { filename: downloadFilename, separator: ','},
    onRowClick: (rowData, rowState) => {
      handleRowClick(rowData, rowState);
    },
    customToolbar: () => {
      return (
        <CustomToolbar />
      );
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect 
        selectedRows={selectedRows} 
        displayData={displayData} 
        setSelectedRows={setSelectedRows}
        onRowsDelete={onRowsDelete} 
        handleEdit={props.handleEdit} />
    ),
    textLabels: {
      body: {
        noMatch: noMatchMsg,
      },
    }
  };

  if (props === null || props.dataItems === null) {
    return null;
  }
  
  return (
    <MUIDataTable
      title={domain}
      data={visibleList}
      columns={columns}
      options={options}
    />
  );
}

LTRCommonDataTable.propTypes = {
  domain: PropTypes.string.isRequired, 
  domainColumns: PropTypes.array.isRequired,
  dataItems: PropTypes.array.isRequired,
  handleMultiDeleteNotification: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired
}

export default (LTRCommonDataTable);

const fieldPropertyMapping = {
  TITLE: "title",
  TEL: "telephone",
  FN: "displayName",
  N: "name",
  EMAIL: "email",
  CATEGORIES: "categories",
  ADR: "address",
  URL: "url",
  NOTE: "notes",
  ORG: "organization",
  BDAY: "birthday",
  PHOTO: "photo",
  VERSION: "version",
  PRODID: "prodid"
};

const lookupField = (context, fieldName) => {
  let propertyName = fieldPropertyMapping[fieldName];

  if (!propertyName && fieldName !== "BEGIN" && fieldName !== "END") { 
    context.info("define property name for " + fieldName);
    propertyName = fieldName;
  }

  return propertyName;
}

const removeWeirdItemPrefix = (context, line) => {

  // sometimes lines are prefixed by "item" keyword like "item1.ADR;type=WORK:....."
  const retVal = line.substring(0, 4) === "item" ? line.match(/item\d\.(.*)/)[1] : line;
  
  // for info 
  if (line.substring(0, 4) === "item") {
    context.info('Item line: ' + line);
  }
  return retVal;
}

const singleLine = (context, fieldValue, fieldName) => {
  // convert escaped new lines to real new lines.
  const value = fieldValue.replace("\\n", "\n");

  // append value if previously specified
  if (context.currentCard[fieldName]) {
    context.currentCard[fieldName] += "\n" + value;
  } else {
    context.currentCard[fieldName] = value;
  }
}

const typedLine = (context, fieldValue, fieldName, typeInfo, valueFormatter) => {
  var isDefault = false;
 
  // strip type info and find out is that preferred value
  if (typeInfo === undefined) {
    context.info("Typed field with no type info: " + fieldName);
    context.info("With value: " + fieldValue)
    typeInfo = {};
  } else {
    typeInfo = typeInfo.filter(function (type) {
      isDefault = isDefault || type.name === "PREF";
      return type.name !== "PREF";
    });

    // return ALL types so the client can decide which to use
    if (fieldName !== 'telephone' && fieldName !== 'email') {
      // reduce to one value
      typeInfo = typeInfo.reduce(function (p, c) {
        p[c.name] = c.value;
        return p;
      }, {}); 
    } 
  } 
  context.currentCard[fieldName] = context.currentCard[fieldName] || [];
  
  context.currentCard[fieldName].push({
    isDefault: isDefault,
    valueInfo: typeInfo,
    value: valueFormatter ? valueFormatter(fieldValue) : fieldValue,
  });
}

const commaSeparatedLine = (context, fieldValue, fieldName) => {
  context.currentCard[fieldName] = fieldValue.split(",");
}

const dateLine = (context, fieldValue, fieldName) => {
  // if value is in "19531015T231000Z" format strip time field and use date value.
  fieldValue = fieldValue.length === 16 ? fieldValue.substr(0, 8) : fieldValue;

  var dateValue;

  if (fieldValue.length === 8) {
    // "19960415" format ?
    dateValue = new Date(
      fieldValue.substr(0, 4),
      fieldValue.substr(4, 2),
      fieldValue.substr(6, 2)
    );
  } else {
    // last chance to try as date.
    dateValue = new Date(fieldValue);
  }

  if (!dateValue || isNaN(dateValue.getDate())) {
    dateValue = null;
    context.error("invalid date format " + fieldValue);
  }

  context.currentCard[fieldName] = dateValue && dateValue.toJSON(); // always return the ISO date format
}

const structured = (fields) => {
  return function (context, fieldValue, fieldName) {
    var values = fieldValue.split(";");

    context.currentCard[fieldName] = fields.reduce(function (p, c, i) {
      p[c] = values[i] || "";
      return p;
    }, {});
  };
}

// Unused, untested - could have phone numbers route here if we want to
// manipulate them a bit
// const phoneLine = (context, fieldValue, fieldName, typeInfo) => {
//   typedLine(context, fieldValue, fieldName, typeInfo, function (value) {
//  
//     // Remove any alphabetic characters from the phone number
//     const phoneNumber = value.replace (/[A-Z, a-z]/g, "" );
//     return {
//       phoneNumber
//     }
//   })
// }

const addressLine = (context, fieldValue, fieldName, typeInfo) => {
  typedLine(context, fieldValue, fieldName, typeInfo, function (value) {
    var names = value.split(";");

    return {
      // ADR field sequence
      postOfficeBox: names[0],
      number: names[1],
      street: names[2] || "",
      city: names[3] || "",
      region: names[4] || "",
      postalCode: names[5] || "",
      country: names[6] || "",
    };
  });
}

const notesLine = (context, fieldValue, fieldName, typeInfo) => {
  let value = fieldValue.replace(/\\n/, "");
  value = value.replace(/\\n/g, ", ");
  value = value.replace(/\\/g, "")
  
  // append value if previously specified
  if (context.currentCard[fieldName]) {
    context.currentCard[fieldName] += "\n" + value;
  } else {
    context.currentCard[fieldName] = value;
  }
}

const noop = () => {}

// Photo can be a url OR a binary blob - we don't care, we are not handling photo
const variantLine = (context, fieldValue, fieldName, typeInfo) => {
  context.info(fieldName +  " - not handling")
}

const endCard = (context) => {
  // store card in context and create a new card.
  context.cards.push(context.currentCard);
  context.currentCard = {};
}

const fieldParsers = {
  BEGIN: noop,
  VERSION: noop,
  PRODID: noop,
  N: structured(["surname", "name", "additionalName", "prefix", "suffix"]),
  TITLE: singleLine,
  TEL: typedLine,
  EMAIL: typedLine,
  ADR: addressLine,
  NOTE: notesLine,
  NICKNAME: commaSeparatedLine,
  BDAY: dateLine,
  URL: singleLine,
  CATEGORIES: commaSeparatedLine,
  END: endCard,
  FN: singleLine,
  ORG: singleLine,
  UID: singleLine,
  PHOTO: variantLine,
};

const  feedData = (context) => {
  for (var i = 0; i < context.data.length; i++) {
    var line = removeWeirdItemPrefix(context, context.data[i]);

    let pairs = line.split(":"),
      fieldName = pairs[0],
      fieldTypeInfo,
      fieldValue = pairs.slice(1).join(":");

    // is additional type info provided ?
    if (fieldName.indexOf(";") >= 0 && line.indexOf(";") < line.indexOf(":")) {
      var typeInfo = fieldName.split(";");
      fieldName = typeInfo[0];
      fieldTypeInfo = typeInfo.slice(1).map(function (type) {
        var info = type.split("=");
        return {
          name: info[0].toLowerCase(),
          value: info[1].replace(/"(.*)"/, "$1"),
        };
      });
    }

    // ensure fieldType is in upper case
    fieldName = fieldName.toUpperCase();

    const fieldHandler = fieldParsers[fieldName];

    if (fieldHandler) {
      fieldHandler(
        context,
        fieldValue,
        lookupField(context, fieldName),
        fieldTypeInfo
      );
    } else if (fieldName.substring(0, 2) !== "X-") {
      // ignore X- prefixed extension fields.

      // but be silent if the fieldName is really long - likely part of a binary blob for a photo
      if (fieldName.length < 20) {
        context.info("unknown field " + fieldName + " with value " + fieldValue);
      }
    }
  }
}

export const  parse = (data) => {
  const  lines = data
    // replace escaped new lines
    .replace(/\n\s{1}/g, "")
    // split if a character is directly after a newline
    .split(/\r\n(?=\S)|\r(?=\S)|\n(?=\S)/);

  const context = {
    info: function (desc) {
      console.info(desc);
    },
    error: function (err) {
      console.error(err);
    },
    data: lines,
    currentCard: {},
    cards: [],
  };

  // how many contacts?
  const vLines = lines.filter(line => (line.includes('VERSION')));
  console.log('Number contacts: ' + vLines.length)

  feedData(context);

  return context.cards;
}

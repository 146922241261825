import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {useApolloClient} from '@apollo/client';

import { 
  fetchOneDomainInstance,
  OperationTargets
} from '../../api/CommonCrudOperations'

import LTRDomainObjectInfo from '../shared/domains/LTRDomainObjectInfo';

// Our labels in the Info Table
const labels = {
  type: "TYPE",
  description: "DESCRIPTION",
  recommendation: "RECOMMENDATION",
  notes: "NOTES"
}

export const prepareTableRowInfo = (item) => {
  // prepare table data for show
  let messageAction = '';
  let messageDoc = '';
  console.log(item)
  if (item.actionItems) {
    messageAction = item.actionItems.length === 0 ? "This item has NO action items" :
    "This item has " + item.actionItems.length + " action items";
  }
  if (item.attachments) {
    messageDoc = item.attachments.length === 0 ? "This item has NO attached files":
    "This item has " + item.attachments.length + " attached files"
  }

  const tableInfo = {
    name: item.name,
    actionItemMessage: messageAction,
    documentsMessage: messageDoc,
    fieldData : [
      {
        field: labels.type,
        value: item.type
      },
      {
        field: labels.description,
        value: item.description
      },
      {
        field: labels.recommendation,
        value: item.recommendation
      },
      {
        field: labels.notes,
        value: item.notes
      },
    ]
  }

  return tableInfo;  
}

const useOtherItemsFetch = (client, domainId, updateFn) => {
  const [otherItems, setOtherItems] = useState(null);
  
  useEffect(() => {
    const fetchOtherItems = () => { 
      fetchOneDomainInstance(client, OperationTargets.OtherItems, domainId)
      .then(result => { 
        const otherItems = result.item;
        setOtherItems(otherItems);
        updateFn(otherItems);
      })
      .catch(error => {
        console.log(error) 
      });
    }

    if (otherItems === null && domainId !== null) {
      fetchOtherItems()
    }  
  }, [client, updateFn]);
};

const OtherItemsInfo = (props) => {
  const client = useApolloClient();

  const [showInfoDialogOpen, setShowInfoDialogOpen] = useState(false);
  const [tableInfo, setTableInfo] = useState(null);
  const [otherItems, setOtherItems] = useState(null);

  const handleShowInfoClose = () => {
    setShowInfoDialogOpen(false);
    // notify the client
    props.handleClose();
  }

  useOtherItemsFetch(client, props.domainId, (fetchedData) => {
    if (fetchedData !== null) {
      setOtherItems(fetchedData);
      const info = prepareTableRowInfo(fetchedData);
      setTableInfo(info);
      setShowInfoDialogOpen(true);
    }
  });

  if (tableInfo === null) {
    return null;
  }

  return (
    <React.Fragment>  
      {showInfoDialogOpen ? (
        <LTRDomainObjectInfo
          open = {props.open}
          title = {otherItems.name}
          tableData = {tableInfo}
          handleClose = {handleShowInfoClose}
        />): null
      }
    </React.Fragment>
  )
}

OtherItemsInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  domainId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default (OtherItemsInfo);

import {useState} from 'react'

export const useSnackbar = () => {

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const openSnackbar = (message) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  }

  const closeSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  }
  
  return {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage
  }
}

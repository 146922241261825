import React, {useState, useEffect} from 'react';

import {makeStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import {useInterviewContext, CheckListAnswerIds} from './InterviewContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  answersSavedMsg: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold'
  },
}));

const GovCheckList = (props) => {
  const classes = useStyles();
  const interviewContext = useInterviewContext();
  
  const [haveSocialSecurity, setHaveSocialSecurity] =  useState(false);
  const [spouseHaveSocialSecurity, setSpouseHaveSocialSecurity] =  useState(false);
  const [haveMedicare, setHaveMedicare] =  useState(false);
  const [spouseHaveMedicare, setSpouseHaveMedicare] =  useState(false);
  
  useEffect(() => {
    
    const answers = interviewContext.answers;
    
      // we have some answers - see if we need to adjust our defaults
    answers.forEach ((answer) => {
      if (answer.id === CheckListAnswerIds.ss1) {
        setHaveSocialSecurity(answer.value);
      }
      if (answer.id === CheckListAnswerIds.ss2) {
        setSpouseHaveSocialSecurity(answer.value);
      }
      if (answer.id === CheckListAnswerIds.mc1) {
        setHaveMedicare(answer.value);
      }
      if (answer.id === CheckListAnswerIds.mc2) {
        setSpouseHaveMedicare(answer.value);
      }
    })
  });

  const handleSetSocialSecurity = (event) => {
    setHaveSocialSecurity(event.target.checked);

    const answer = {
      id: CheckListAnswerIds.ss1,
      value: event.target.checked
    }
    interviewContext.updateAnswer(answer);
  }

  const handleSetSpouseSecialSecurity = (event) => {
    setSpouseHaveSocialSecurity(event.target.checked);

    const answer = {
      id: CheckListAnswerIds.ss2,
      value: event.target.checked
    }
    interviewContext.updateAnswer(answer);
  }

  const handleSetMedicare = (event) => {
    setHaveMedicare(event.target.checked);

    const answer = {
      id: CheckListAnswerIds.mc1,
      value: event.target.checked
    }
    interviewContext.updateAnswer(answer);
  }

  const handleSetSpouseMedicare = (event) => {
    setSpouseHaveMedicare(event.target.checked)

    const answer = {
      id: CheckListAnswerIds.mc2,
      value: event.target.checked
    }
    interviewContext.updateAnswer(answer);
  }

  const socialSecurityLabel = "I receive Social Security benefits";
  const medicareLabel = "I receive Medicare benefits";
  const socialSecuritySpouseLabel = "My spouse/partner receives Social Security benefits";
  const medicareSpouseLabel = "My spouse/partner receives Medicare benefits";
  
  return (
    <React.Fragment>
      <>
        <Typography align="left" variant="h6">
          Answer these questions from the perspective of the Plan Owner(s).
        </Typography>
        <Typography align="left" variant="h6" gutterBottom>
          Then press Next.
        </Typography>     
        <form>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormControlLabel 
              control={
                <Checkbox
                  checked={haveSocialSecurity}
                  onChange={handleSetSocialSecurity}
                  name="socialsecurity1"
                  color="primary"
                />
              }
              label={socialSecurityLabel}
            />
          </FormControl>
          {interviewContext.planOwners.length > 1 ? (
            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel 
                control={
                  <Checkbox
                    checked={spouseHaveSocialSecurity}
                    onChange={handleSetSpouseSecialSecurity}
                    name="socialsecurity2"
                    color="primary"
                  />
                }
                label={socialSecuritySpouseLabel}
              />
            </FormControl>
            ) : null
          }
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={haveMedicare}
                  onChange={handleSetMedicare}
                  name="medicare1"
                  color="primary"
                />
              }
              label={medicareLabel}
            />
          </FormControl>
            {interviewContext.planOwners.length > 1 ? (
              <FormControl component="fieldset" className={classes.formControl}>
                <FormControlLabel 
                  control={
                    <Checkbox
                      checked={spouseHaveMedicare}
                      onChange={handleSetSpouseMedicare}
                      name="medicare2"
                      color="primary"
                    />
                  }
                  label={medicareSpouseLabel}
                />
              </FormControl>
              ) : null
          }
          
        </form>
      </>
    </React.Fragment>
  );
}

export default (GovCheckList);
import React from 'react';

import Typography from '@material-ui/core/Typography';

import {APPNAME} from '../../Enums';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
     
        {APPNAME} Website
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
export default Copyright
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from "../shared/TabPanel";

import ActionItemTable from '../actionitems/ActionItemTable';
import { ActionItemType, ActionItemGroupType } from '../../Enums';

import ActionItemStatus from '../overview/ActionItemStatus';
import ActionItemGroups from '../overview/ActionItemGroups';
import Billers from '../billers/Billers'
import PeopleToContact from '../contacts/PeopleToContact';
import Letter from '../overview/Letter';

import UserTableView from './UserTableView';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  tabPanel: {
    marginTop: theme.spacing(1)
  }
}));

const HelperView =(props) => { 
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tasksPanel =  ( 
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Incomplete action items</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ActionItemStatus /> 
        </AccordionDetails>
      </Accordion>
      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Medical action items</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ActionItemGroups groupTypeToFilter={ActionItemGroupType.Medical}/> 
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Housing/Home related action items</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ActionItemGroups groupTypeToFilter={ActionItemGroupType.Home}/> 
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography className={classes.heading}>Bills we pay</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Billers />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography className={classes.heading}>People to contact</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PeopleToContact />
        </AccordionDetails>
      </Accordion>
    </>
  )
  
  return (
    <>
    <AppBar position="static">
      <Tabs value={value} onChange={handleChange} centered aria-label="simple tabs example">
        <Tab label="Getting Started"  />
        <Tab label="Action Items"  />
        <Tab label="Tasks by Group"  />
        <Tab label="Your Plan Team"  />
      </Tabs>
    </AppBar>
    <TabPanel className={classes.tabPanel} value={value} index={0}>
      <Letter />
    </TabPanel>
    <TabPanel className={classes.tabPanel} value={value} index={1}>
      <ActionItemTable 
          itemType = {ActionItemType.All}
      />    
    </TabPanel>
    <TabPanel className={classes.tabPanel}  value={value} index={2}>
      {tasksPanel}
    </TabPanel>
    <TabPanel className={classes.tabPanel} value={value} index={3}>
      <UserTableView/>
    </TabPanel>
    </>
  )
}

export default (HelperView);
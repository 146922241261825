import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Draggable from "react-draggable";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import NoteContentContainer from "./NoteContentContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  flex: {
    flex: 1,
  },

  paper: {},

  button: {
    margin: theme.spacing(1),
  },

  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  modal: {
    overflow: "visible",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaperComponent = (props) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

export default function NoteViewer(props) {
  const classes = useStyles();

  const { open, handleClose } = props;

  const dialogTitle = "View note: " + props.note.name;

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"lg"}
      PaperComponent={PaperComponent}
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="form-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <NoteContentContainer children={props.note.content} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}

NoteViewer.propTypes = {
  open: PropTypes.bool.isRequired,
  note: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

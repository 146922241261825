import React, { useContext } from "react";
import PropTypes from "prop-types";

import moment from "moment";

import { useApolloClient } from "@apollo/client";
import { addOneActionItem } from "../../api/ActionItemApi";

import {
  domainTypeToOperationTarget,
  OperationTargets,
} from "../../api/CommonCrudOperations";

import {
  StatusType,
  PriorityType,
  NonChoice,
  ActionItemType,
  DomainType,
} from "../../Enums";

import { FamilyContext } from "../../context/FamilyContext";

import AddEditActionItemFormCore from "./AddEditActionItemFormCore";

const AddActionItemForm = (props) => {
  const client = useApolloClient();
  const familyContext = useContext(FamilyContext);

  const domainTypeToActionItemType = (domainType) => {
    let retVal;
    switch (domainType) {
      case DomainType.Accounts: {
        retVal = ActionItemType.Account;
        break;
      }
      case DomainType.Contacts: {
        retVal = ActionItemType.Contacts;
        break;
      }
      case DomainType.Assets: {
        retVal = ActionItemType.Assets;
        break;
      }
      case DomainType.Liabilities: {
        retVal = ActionItemType.Liabilities;
        break;
      }
      case DomainType.Insurance: {
        retVal = ActionItemType.Insurance;
        break;
      }
      case DomainType.Subscriptions: {
        retVal = ActionItemType.Subscriptions;
        break;
      }
      case DomainType.Services: {
        retVal = ActionItemType.Services;
        break;
      }
      case DomainType.OtherItems: {
        retVal = ActionItemType.OtherItems;
        break;
      }
      case DomainType.Documents: {
        retVal = ActionItemType.Documents;
        break;
      }
      default: 
        retVal = ActionItemType.General;
        console.log('Unexpected domain type')
    }
    return retVal;
  }

  const save = (values) => {
    //alert(JSON.stringify(values, null, 2));

    // Mutation
    let actionItemDetails;
    if (props.domainType) {
      actionItemDetails = {
        userRealm: familyContext.familyRealm.id,
        name: values.name,
        owner: values.owner ? values.owner : NonChoice,
        priority: values.priority ? values.priority : PriorityType.Medium,
        dueDate: values.dueDate
          ? moment(values.dueDate).format("L")
          : NonChoice,
        status: values.status ? values.status : StatusType.Open,
        notes: values.notes ? values.notes : "",
        domainId: props.domainId ? props.domainId : "",
        domainName: props.itemName ? props.itemName : "",
        type: props.domainType ? domainTypeToActionItemType(props.domainType) : ActionItemType.General,
      };
    } else {
      // this is a general action item
      actionItemDetails = {
        userRealm: familyContext.familyRealm.id,
        name: values.name,
        owner: values.owner ? values.owner : NonChoice,
        priority: values.priority ? values.priority : PriorityType.Medium,
        dueDate: values.dueDate
          ? moment(values.dueDate).format("L")
          : NonChoice,
        status: values.status ? values.status : StatusType.Open,
        notes: values.notes ? values.notes : "",
        type: ActionItemType.General,
      };
    }

    const target = props.domainType
      ? domainTypeToOperationTarget(props.domainType)
      : OperationTargets.General;
    addOneActionItem(
      client,
      familyContext.familyRealm.id,
      target,
      actionItemDetails
    )
      .then((result) => {
        console.log(result);
        const actionItem = result.insert_ActionItems_one;

        props.handleAddNotification(actionItem);
      })
      .catch((error) => {
        console.log(error);
      });

    // hide the modal
    props.handleClose();
  };

  const title = props.domainType
    ? props.itemName
      ? props.domainType + ": add an action item for " + props.itemName
      : props.domainType + ": add an action item"
    : "Add an action item";

  // pass in an empty object for editing
  return (
    <div>
      {props.open ? (
        <AddEditActionItemFormCore
          open={props.open}
          title={title}
          actionItem={{
            name: "",
            owner: "",
            priority: PriorityType.Medium,
            dueDate: null,
            status: StatusType.Open,
            notes: "",
          }}
          save={save}
          handleClose={props.handleClose}
        />
      ) : null}
    </div>
  );
};

AddActionItemForm.defaultTypes = {
  domainId: null,
  domainType: null,
  itemType: null,
};

AddActionItemForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleAddNotification: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  domainId: PropTypes.string,
  domainType: PropTypes.string,
  itemName: PropTypes.string,
};

export default AddActionItemForm;

import {ContactType} from '../../Enums';

import { mapContactsFromVCard } from './DataMapperVCard';

export const ContactsImportSource = {
  LTR: 'LTR',
  Google: 'Google',
  Outlook: 'Outlook',
  VCard: 'VCard'
}

export const identifyContactsImportSource = (importResults) => {
  // default to our own format
  let retVal = ContactsImportSource.LTR;

  // sample from a single row
  const row = importResults.data[0];

  // test google - field that is specific to google contacts
  if (row.phone_1___value !== undefined) {
    retVal = ContactsImportSource.Google
  } 

  // other tests
  const source = importResults.meta.source ? importResults.meta.source : null;
  if (source) {
    if (source === ContactsImportSource.VCard) {
      retVal = ContactsImportSource.VCard;
    }
  } 
  console.log(retVal)
  return retVal;
}

const mapContactsFromLTR = (rows) => {
  const newRows = rows.map ((row) => {
    let newRow = {};
    if (row.fullName !== undefined) {
      newRow.fullName = row.name;
    }
    if (row.type !== undefined) {
      newRow.type = row.type;
    }
    if (row.company !== undefined) {
      newRow.company = row.company;
    }
    if (row.phone1 !== undefined) {
      newRow.phone1 = row.phone1;
    }
    if (row.phone1Type !== undefined) {
      newRow.phone1Type = row.phone1Type;
    }
    if (row.phone2 !== undefined) {
      newRow.phone2 = row.phone2;
    }
    if (row.phone2Type !== undefined) {
      newRow.phone2Type = row.phone2Type;
    } 
    if (row.phone3 !== undefined) {
      newRow.phone3 = row.phone3;
    }
    if (row.phone3Type !== undefined) {
      newRow.phone3Type = row.phone3Type;
    } 
    if (row.email1 !== undefined) {
      newRow.email1 = row.email1;
    }
    if (row.email2 !== undefined) {
      newRow.email2 = row.email2;
    }
    if (row.email3 !== undefined) {
      newRow.email3 = row.email3;
    }
    if (row.notes !== undefined) {
      newRow.notes = row.notes;
    }
    return newRow;
  })
  return newRows; 
}

const collectPhoneNumbers = (dataRow) => {
  const phones = []

  // if there are multiple phone numbers of a certain type, i.e. 'Mobile' then
  // they will be separated by ' ::: '
  let tempValue;
  if (dataRow.phone_1___value) {
    // note: the values may be numeric, not string so convert first
    const testString = dataRow.phone_1___value.toString();
    tempValue = testString.split(" ::: ");
    tempValue.map((num) => {
      let newValue = {
        phoneNumber: num,
        phoneType: dataRow.phone_1___type
      }
      phones.push(newValue)
    })
  }

  if (dataRow.phone_2___value) {
    const testString = dataRow.phone_2___value.toString();
    tempValue = testString.split(" ::: ");
    tempValue.map((num) => {
      let newValue = {
        phoneNumber: num,
        phoneType: dataRow.phone_2___type
      }
      phones.push(newValue)
    })
  }
 
  if (dataRow.phone_3___value) {
    const testString = dataRow.phone_3___value.toString();
    tempValue = testString.split(" ::: ");
    tempValue.map((num) => {
      let newValue = {
        phoneNumber: num,
        phoneType: dataRow.phone_3___type
      }
      phones.push(newValue)
    })
  }
  return phones;
}

const collectEmails = (dataRow) => {
  const emails = []

  // if there are multiple email addresses of a certain type, i.e. 'Mobile' then
  // they will be separated by ' ::: '
  let tempValue;
  if (dataRow.e_mail_1___value) {
    tempValue = dataRow.e_mail_1___value.split(" ::: ");
    tempValue.map((num) => {
      let newValue = {
        emailAddress: num,
        emailType: null
      }
      emails.push(newValue)
    })
  }

  if (dataRow.e_mail_2___value) {
    tempValue = dataRow.e_mail_2___value.split(" ::: ");
    tempValue.map((num) => {
      let newValue = {
        emailAddress: num,
        emailType: dataRow.e_mail_2___type
      }
      emails.push(newValue)
    })
  }

  if (dataRow.e_mail_3___value) {
    tempValue = dataRow.e_mail_3___value.split(" ::: ");
    tempValue.map((num) => {
      let newValue = {
        emailAddress: num,
        emailType: dataRow.e_mail_2___type
      }
      emails.push(newValue)
    })
  }

  return emails;
}

const identifyType = (row) => {
  const label = row.group_membership;

  const regExpFriend = /friend/i;
  const regExpFamily = /family/i;
  const regExpMedical = /doctor/i;
  const regExpBusiness = /business/i;
  const regExpCoworkers = /coworker/i;
  const regExpNeighbors = /neighbor/i;

  // check friend
  let result = label.match(regExpFriend);
  if (result !== null) {
    return ContactType.Friend;
  }

  result = label.match(regExpFamily);
  if (result !== null) {
    return ContactType.Family;
  }
  
  result = label.match(regExpMedical);
  if (result !== null) {
    return ContactType.Medical;
  }

  result = label.match(regExpBusiness);
  if (result !== null) {
    return ContactType.Business;
  }

  result = label.match(regExpCoworkers);
  if (result !== null) {
    return ContactType.CoWorker;
  }

  result = label.match(regExpNeighbors);
  if (result !== null) {
    return ContactType.Neighbor;
  }

  return null;
}

const mapContactsFromGoogle = (rows) => {  
  const newRows = rows.map ((row) => {
    let newRow = {};
    // types sometimes having a leading * - we want to remove it so we'll 
    // use this regex pattern
    const pattern = /[a-z]+/ig
    if (row.name) {
      newRow.fullName = row.name;
    }

    newRow.type = identifyType(row);

    const phones = collectPhoneNumbers(row);
    phones.forEach( (phone, index) => {
      if (index === 0) {
        newRow.phone1 = phone.phoneNumber;
        if (phone.phoneType) {
          const test = phone.phoneType.match(pattern);
          if (test !== null) {
            newRow.phone1Type = test.toString();
          } else {
            newRow.phone1Type = null;
          }
        }
      } else if (index === 1) {
        newRow.phone2 = phone.phoneNumber;
        if (phone.phoneType) {
          const test = phone.phoneType.match(pattern);
          if (test !== null) {
            newRow.phone2Type = test.toString();
          } else {
            newRow.phone2Type = null;
          }
        }
      } else if (index === 2) {
        newRow.phone3 = phone.phoneNumber;
        if (phone.phoneType) {
          const test = phone.phoneType.match(pattern);
          if (test !== null) {
            newRow.phone3Type = test.toString();
          } else {
            newRow.phone3Type = null;
          }
        }
      }
    })

    const emails = collectEmails(row)
    emails.forEach( (email, index) => {
      if (index === 0) {
        newRow.email1 = email.emailAddress;
        if (email.emailType) {
          const test = email.emailType.match(pattern);
          if (test !== null) {
            newRow.email1Type = test.toString();
          } else {
            newRow.email1Type = null;
          }
        }
      } else if (index === 1) {
        newRow.email2 = email.emailAddress;
        if (email.emailType) {
          const test = email.emailType.match(pattern);
          if (test !== null) {
            newRow.email2Type = test.toString();
          } else {
            newRow.email2Type = null;
          }
        }
      } else if (index === 2) {
        newRow.email3 = email.emailAddress;
        if (email.emailType) {
          const test = email.emailType.match(pattern);
          if (test !== null) {
            newRow.email3Type = test.toString();
          } else {
            newRow.email3Type = null;
          }
        }
      }
    })

    // address
    if (row.address_1___type) {
      const test = row.address_1___type.match(pattern);
      if (test !== null) {
        newRow.address1Type = test.toString();
      } else {
        newRow.address1Type = null;
      }
    } 
    if (row.address_1___street) {
      newRow.address1Street = row.address_1___street;
    } 
    if (row.address_1___city) {
      newRow.address1City = row.address_1___city;
    } 
    if (row.address_1___region) {
      newRow.address1State = row.address_1___region;
    } 
    if (row.address_1___country) {
      newRow.address1Country = row.address_1___country;
    } 
    if (row.address_1___po_box) {
      newRow.address1PoBox = row.address_1___po_box;
    } 
    if (row.address_1___postal_code) {
      newRow.address1PostalCode = row.address_1___postal_code;
    } 
    if (row.notes) {
      newRow.notes = row.notes;
    }
    console.log(newRow)
    return newRow;
  })
  return newRows; 
}

export const getContactsRows = (source, fetchedData) => {  
  let rows = [];
  switch (source) {
    case ContactsImportSource.LTR:
      rows = mapContactsFromLTR(fetchedData);
      break;
    case ContactsImportSource.Google:
      rows = mapContactsFromGoogle(fetchedData);
      break;
    case ContactsImportSource.VCard:
      rows = mapContactsFromVCard(fetchedData);
      break;
    default: 
      console.log('Unknown import source')
  }
     
  console.log(rows);
  return rows;
}
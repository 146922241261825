import React, { useContext } from "react";
import PropTypes from 'prop-types';

import { makeStyles } from "@material-ui/core/styles";

import cloneDeep from "lodash.clonedeep";

import { useHistory } from "react-router-dom";

import RealmInfoStorage from "../../api/RealmInfoStorage";

import LTRSnackbar from '../utilties/LTRSnackbar';
import { useSnackbar } from '../utilties/snackbarUtil';

import { UserContext } from "../../context/UserContext";
import { FamilyContext } from "../../context/FamilyContext";

import ChoosePlanDialog from "./forms/ChoosePlanDialog";

import LetterRoutes from "../../Routes";

const useStyles = makeStyles((theme) => ({
  typography: {
    margin: theme.spacing(2),
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  logoutButton: {
    margin: theme.spacing(1),
  },
}));

const PlanSwitcher = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const familyContext = useContext(FamilyContext);

  const {openSnackbar, closeSnackbar, snackbarOpen, snackbarMessage}  = useSnackbar();

    const handleChoosePlanSave = (plan) => {
      // have to direct back to landing page OR set up user/family here then redirect to landing
    
      console.log(plan);

      // save this new plan to storage so we can return to it on the next login
      RealmInfoStorage.setLastPlan(plan);

      familyContext.setFamilyFromRealm(plan.realmId)
      .then((_) => {
        console.log('Successfully set up a family');
        // notify the user and close the popover
        openSnackbar("Successfully switched to '" + plan.name + "'");
        props.handleClose();

        // notify the family context that we are logged in to a specific realm
        familyContext.onLogin(plan.realmId);

        // notify the user context that this user has a new realm,
        // there may be a simpler way but let's just 'login' again
        let tempUser = cloneDeep(userContext.user);
        tempUser.realm = plan.realmId;
        tempUser.isOwner = plan.isOwner;
        userContext.onLogin(tempUser);

        // go the overview page?
        history.push(LetterRoutes.OverviewRoute);
      })
      .catch((_) => {
        console.log('Failed to set up the family when switching plans');
      });
    };

    return (
      <div className={classes.root}>
        <LTRSnackbar 
          open = {snackbarOpen}
          message = {snackbarMessage}
          handleClose = {closeSnackbar}
        />
        
        <ChoosePlanDialog
          open={props.open}
          realms={props.userRealms}
          handleCancel={props.handleClose}
          handleSave={handleChoosePlanSave}
        /> 
    </div>
  )
}

PlanSwitcher.propTypes = {
  open: PropTypes.bool.isRequired,
  userRealms: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default PlanSwitcher;
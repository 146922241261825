import { createTheme } from '@material-ui/core/styles';

import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';

// A theme with custom primary and secondary color.
// It's optional.

// main: blue
// light: #6ec6ff
// dark: #0069c0

// secondary: indigo
// light: #757de8
// dark: #002984


// just changing the palette
const theme = createTheme({
  palette: {
    primary: blue,
    secondary: indigo,
  },
});

export { theme as LetterTheme };
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  actionItemText: {
    margin: theme.spacing(1)
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const LTRDomainObjectInfo = (props) => {
  const classes = useStyles();

  const {tableData} = props;

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'sm'}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      >
      <DialogTitle align="center" id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{tableData.nameLabel ? tableData.nameLabel : 'NAME'}</StyledTableCell>
                <StyledTableCell>{tableData.name}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.fieldData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.field.toUpperCase()}</TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>  
        <DialogContentText className={classes.actionItemText} align="center">
          {tableData.actionItemMessage}
        </DialogContentText>  
        <DialogContentText className={classes.actionItemText} align="center">
          {tableData.documentsMessage}
        </DialogContentText>  
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>   
  );
}

LTRDomainObjectInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  tableData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default (LTRDomainObjectInfo);
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useQuery, gql } from "@apollo/client";
import { listContacts } from "../../../graphql/queries";

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { useFamilyContext } from '../../../context/FamilyContext';

import {sortListByName} from '../../utilties/SortFunctions';

export const ContactSelect = (props) => {
  const familyContext = useFamilyContext();

  const [contacts, setContacts] = useState(null);
  const [contactValue, setContactValue] = useState('');
  
  const { loading, error, data } = useQuery(gql(listContacts), {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  useEffect(() => {
    const onCompleted = (data) => {
      const sortedList = sortListByName(data.item);
      setContacts(sortedList);
    };

    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (props.existingContact !== undefined) {
      setContactValue(constructContactValue(props.existingContact));
    } else {
      setContactValue('')
    }
  }, [props.existingContact]);

  const constructContactValue = (contact) => {
    return contact ? contact.id + '::' + contact.name : ''
  }
 
  const createContactSelectItems = () => {
    let items = []; 
    if (contacts === null) {
      return null;
    }
    
    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i]; 
      const value = constructContactValue(contact);         
      items.push(<MenuItem key={contact.id} value={value}>{contact.name}</MenuItem>);   
    }  
    return items;
  }  

  return (
    <>
      <InputLabel id="contacts-select-label">Person to Contact</InputLabel>
      <Select
        labelId="contacts-select-label"
        id="contacts-select"
        value={contactValue}
        onChange = {ev => {
          // update our UI
          setContactValue(ev.target.value);
          // call the client - construct an object that the client can use
          const localArray = ev.target.value.split("::");
          const selectedContact = {
            id: localArray[0],
            name: localArray[1]
          }
          props.onChange(selectedContact)
        }}
      >
         {
          createContactSelectItems()
        }
      </Select>
    </>
  )
}

ContactSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  existingContact: PropTypes.object
}

export default ContactSelect;

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";

import cloneDeep from "lodash.clonedeep";

// wrappers for material ui components for formik
import { TextField, Select } from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { FormDialogBase } from "../../shared/formhelpers/FormDialogBase";
import PaymentAndBilling from "../../shared/formhelpers/PaymentAndBilling";

import {
  LiabilityType,
  LiabilityTypes,
  NonChoice,
  DomainType,
} from "../../../Enums";

import { validationSchema } from "./LiabilityFormMetaData";
import { useApolloClient, useQuery, gql } from "@apollo/client";
import { useLiabilityAdd, useLiabilityUpdate } from "../../../api/APIHooks";

import { OperationTargets } from "../../../api/CommonCrudOperations";

import { getLiability } from "../../../graphql/queries";
import { updateOneBiller } from "../../../api/BillerApi";
import { addMultipleActionItems } from "../../../api/ActionItemApi";

import { useFamilyContext } from "../../../context/FamilyContext";

import { constructCorrespondingActionItems } from "../LiabilityHelper";

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const AddEditLiabilityForm = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [open, setOpen] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [liability, setLiability] = useState({
    name: "",
    type: NonChoice,
    institution: "",
    accountNumber: "",
    howPaid: NonChoice,
    billType: NonChoice,
    billingFrequency: NonChoice,
    usage: "",
    accountId: NonChoice,
    LiabilityActionItems: [],
    LiabilityAttachments: [],
  });

  const [skipQuery, setSkipQuery] = useState(true);

  let { loading, error, data } = useQuery(gql(getLiability), {
    variables: { id: props.selected ? props.selected.id : "" },
    skip: skipQuery,
  });

  const [addOneLiability] = useLiabilityAdd(familyContext.familyRealm.id, (data) => {
    console.log(data);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data);
      // }
    }
  );

  const [updateOneLiability] = useLiabilityUpdate(familyContext.familyRealm.id, (data) => {
      console.log(data);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data);
      // }
    }
  );

  useEffect(() => {
    if (!skipQuery) {
      const onCompleted = (data) => {
        const liability = data.item;
        const editLiability = cloneDeep(liability);

        // flatten the Liability Biller fields
        if (liability.billers && liability.billers.length > 0) {
          const biller = liability.billers[0];
          editLiability.billingFrequency = biller.billingFrequency
            ? biller.billingFrequency
            : "";
          editLiability.howPaid = biller.howPaid ? biller.howPaid : "";
          editLiability.billType = biller.billType ? biller.billType : "";
          editLiability.billerId = biller.id;
          editLiability.accountId = biller.accountId
            ? biller.accountId
            : NonChoice;
        }
        setLiability(editLiability);
      };
      const onError = (error) => {
        return <div>{error}</div>;
      };

      if (onCompleted || onError) {
        if (onCompleted && !loading) {
          onCompleted(data);
          setSkipQuery(true);
        } else if (onError && !loading && error) {
          onError(error);
          setSkipQuery(true);
        }
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    const { selected } = props;

    // if we're editing and we haven't yet fetched the item
    if (selected && skipQuery) {
      setSkipQuery(false);
    }

    return () => {
      setSkipQuery(true);
    };
  }, [props.selected]);

  const save = (values) => {
    // alert(JSON.stringify(values, null, 2));

    // Mutation - add one
    const detailsMain = {
      userRealm: familyContext.familyRealm.id,
      name: values.name,
      type: values.type ? values.type : "",
      institution: values.institution ? values.institution : "",
      accountNumber: values.accountNumber ? values.accountNumber : "",
      usage: values.usage ? values.usage : "",
    };

    // use the insitution name, if we have one, as the payee
    // use the name itself as the description
    const detailsBiller = {
      payee: values.institution ? values.institution : values.name,
      howPaid: values.howPaid ? values.howPaid : "",
      billType: values.billType ? values.billType : "",
      billingFrequency: values.billingFrequency ? values.billingFrequency : "",
      domainType: DomainType.Liabilities,
      description: values.name,
      accountId: values.accountId !== NonChoice ? values.accountId : null,
    };

    // props.open means we're here for editing - otherwise we're adding
    props.open
      ? updateOne(detailsMain, detailsBiller)
      : addOne(detailsMain, detailsBiller);
  };

  const addOne = (detailsMain, detailsBiller) => {
    // prepare the biller detail - just add it to the payload
    detailsMain.LiabilityBiller = {
      data: [{ ...detailsBiller }],
    };

    // add any action items
    const actionItems = constructCorrespondingActionItems(detailsMain);
    if (actionItems.length > 0) {
      detailsMain.LiabilityActionItems = {
        data: [...actionItems],
      };
    }

    addOneLiability({ variables: { object: detailsMain } })
    .then((result) => {
      props.handleAdd(result.data.item)
    })
    .catch((error) => {
      console.error(error);
    });

    handleClose();
  };

  const updateOne = (detailsMain, detailsBiller) => {
    // add action items IF we don't have any already
    if (liability.actionItems.length === 0) {
      const actionItems = constructCorrespondingActionItems(detailsMain);
      if (actionItems.length > 0) {
        const items = actionItems.map((item) => {
          item.userRealm = familyContext.familyRealm.id;
          item.domainId = liability.id;
          return item;
        });

        addMultipleActionItems(
          client,
          familyContext.familyRealm.id,
          OperationTargets.Liabilities,
          items
        )
          .then((result) => {
            console.log("Added %s action items", actionItems.length);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    // update the biller
    updateOneBiller(client, liability.billerId, detailsBiller)
      .then((resultBiller) => {
        console.log(resultBiller);
      })
      .catch((error) => {
        console.log(error);
      });

    updateOneLiability({
      variables: { id: liability.id, changes: detailsMain },
    })
      .then((result) => {
        props.handleEdit(result.data.item);
      })
      .catch((error) => {
        console.error(error);
      });

    // hide the modal
    handleClose();
  };

  // Attach this to your <Formik>
  const formRef = useRef();

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleResetClick = () => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // normal close and cancel
  const handleClose = () => {
    // if we were opened by the client, let them close us, otherwise we close
    props.handleClose ? props.handleClose() : setOpen(false);
  };

  const isLoanType = () => {
    let retVal = true;
    if (liability) {
      switch (liability.type) {
        case LiabilityType.StudentLoan:
        case LiabilityType.PersonalLoan:
        case LiabilityType.AutoLoan:
        case LiabilityType.OtherLoan:
          retVal = true;
          break;
        default:
          retVal = false;
      }
    }
    return retVal;
  };
  const body = (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={liability}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        // actually save the form
        save(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="name"
              label="Name"
              autoFocus
              className={classes.textField}
              margin="normal"
              helperText={
                touched.name
                  ? errors.name
                  : "Name this liability (i.e. My Visa Card or Our Mortgage)"
              }
              error={touched.name && Boolean(errors.name)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="liabilitytype-type">Liability Type</InputLabel>
            <Field
              component={Select}
              name="type"
              inputProps={{
                id: "liabilitytype-type",
              }}
            >
              {LiabilityTypes.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText>What type of liability is this?</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="institution"
              label="Company"
              className={classes.textField}
              margin="normal"
              helperText="Provide the name of the company that money is paid to"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="accountNumber"
              label={isLoanType() ? "Loan Number" : "Account Number"}
              helperText="Provide the loan or account number for this liability"
            />
          </FormControl>
          <PaymentAndBilling
            nameReceipt="billType"
            namePayment="howPaid"
            nameBilling="billingFrequency"
            nameBillingAccount="accountId"
          />
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="usage"
              label="Notes"
              multiline
              maxRows={4}
              className={classes.textField}
              margin="normal"
              helperText="Add any notes/hints/special instructions to help with this liability."
            />
          </FormControl>
        </Form>
      )}
    </Formik>
  );

  const title = props.open ? "Edit a liability" : "Add a liability";

  return (
    <FormDialogBase
      open={props.open ? props.open : open}
      title={title}
      body={body}
      submitEnabled={submitEnabled}
      handleAddClickOpen={handleClickOpen}
      handleClose={handleClose}
      handleReset={handleResetClick}
      handleSubmit={handleSubmitClick}
    />
  );
};

// client can open if they choose to OR we will open
AddEditLiabilityForm.defaultProps = {
  selected: null,
  open: false,
  handleClose: null,
};

AddEditLiabilityForm.propTypes = {
  selected: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAdd: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func,
};

export default AddEditLiabilityForm;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import LTRCommonLayout from "../shared/domains/LTRCommonLayout";
import LTRCommonNameList, {
  RefreshReason,
} from "../shared/domains/LTRCommonNameList";
import LTRCommonDataTable from "../shared/domains/LTRCommonDataTable";
import LTRDomainObjectInfo from "../shared/domains/LTRDomainObjectInfo";
import LTRSnackbar, {
  buildAddMessage,
  buildEditMessage,
  buildDeleteMessage,
} from "../utilties/LTRSnackbar";

import { useQuery, gql } from "@apollo/client";
import { listOtherItems } from "../../graphql/queries";
import { useOtherItemsDelete } from "../../api/APIHooks";

import { DomainType, DomainTypeSingular } from "../../Enums";

import AddEditOtherItemsForm from "./forms/AddEditOtherItemsForm";
import OtherItemsActionPanel from "./forms/OtherItemsActionPanel";

import ImportDialog from "../import/ImportDialog";

import { sortListByName } from "../utilties/SortFunctions";

import { prepareTableRowInfo } from "./OtherItemsInfo";

import { useFamilyContext } from "../../context/FamilyContext";

import { useSnackbar } from "../utilties/snackbarUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
}));

const OtherItems = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [otherItems, setOtherItems] = useState(null);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [infoTableItem, setInfoTableItem] = useState(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const [visibleList, setVisibleList] = useState([]);

  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  const { loading, error, data, refetch } = useQuery(gql(listOtherItems), {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  const processReceivedData = (data) => {
    setOtherItems(data);
  };

  const [deleteOneOtherItem] = useOtherItemsDelete(
    familyContext.familyRealm.id,
    (data) => {
      processReceivedData(data);
    }
  );

  useEffect(() => {
    const onCompleted = (data) => {
      processReceivedData(data.item);
    };
    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    // just one reason we're here: if items change we need to adjust
    if (otherItems) {
      const sortedList = sortListByName(otherItems);
      setVisibleList(sortedList);
    }
  }, [otherItems]);

  const handleSearch = (searchTerm) => {
    if (otherItems.length === 0) {
      return [];
    }

    let displayList;
    if (searchTerm === null) {
      displayList = [...otherItems];
    } else {
      displayList = otherItems.filter((item) => {
        const testName = item.name ? item.name.toLowerCase() : "";
        const testType = item.type ? item.type.toLowerCase() : "";
        const testDescription = item.description
          ? item.description.toLowerCase()
          : "";
        const testRecommendation = item.recommendation
          ? item.recommendation.toLowerCase()
          : "";
        const testNotes = item.notes ? item.notes.toLowerCase() : "";
        const againstSearchTerms = searchTerm ? searchTerm.toLowerCase() : "";

        // test the relevant fields
        return (
          testName.includes(againstSearchTerms) ||
          testType.includes(againstSearchTerms) ||
          testDescription.includes(againstSearchTerms) ||
          testRecommendation.includes(againstSearchTerms) ||
          testNotes.includes(againstSearchTerms)
        );
      });
    }
    setVisibleList(displayList);
  };

  const handleAddNotification = (item) => {
    if (item) {
      openSnackbar(buildAddMessage(item.name));
    }
  };

  const handleEditNotification = (item) => {
    if (item) {
      openSnackbar(buildEditMessage(item.name));
    }
  };

  const handleEditOpen = (selectedItem) => {
    setSelectedItem(selectedItem);
    setEditFormOpen(true);
  };

  const handleEditClose = () => {
    setSelectedItem(null);
    setEditFormOpen(false);
  };

  const handleDelete = (selected) => {
    console.log(selected);
    deleteOneOtherItem({ variables: { id: selected.id } })
      .then((result) => {
        openSnackbar(buildDeleteMessage(result.data.item.name));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMultiDeleteNotification = (selected) => {
    const aList = otherItems.filter((item) => {
      return selected.includes(item.id) ? false : true;
    });

    let userMessage = "";
    const numItemsDeleted = selected.length;
    if (numItemsDeleted > 1) {
      userMessage = numItemsDeleted + " items deleted successfully";
      openSnackbar(userMessage);
    } else {
      userMessage = "Item deleted successfully";
      openSnackbar(userMessage);
    }
    setOtherItems(aList);
  };

  const handleGlobalActions = (action) => {
    console.log("other items handle import OPEN");
    setImportDialogOpen(true);
  };

  const handleImportClose = () => {
    console.log("other items handle import CLOSE");
    setImportDialogOpen(false);
  };

  const handleImportSave = (data) => {
    //const target = OperationTargets.OtherItems;

    // // Mutation - add as many as we've imported
    // // payload is an array of contacts to insert
    // const payload = data.map ( (contact) => {
    //   const details = {
    //     userRealm: familyContext.familyRealm.id,
    //     name: contact.fullName,
    //     email: contact.email1 ? contact.email1: '',
    //     company: contact.company ? contact.company: '',
    //     phone: contact.phone1 ? contact.phone1 : '',
    //     notes: contact.notes ? contact.notes: '',
    //     type: contact.type ? contact.type: ''
    //   }
    //   return details;
    // })

    // console.log(data);
    // addMultipleDomainInstances(client, familyContext.familyRealm.id, OperationTargets.Contacts, payload)
    // .then(data => {
    //   const contacts = data.insert_Contacts.returning;
    //   const newList = [...otherItems, ...contacts]
    //   const message = contacts.length.toString() + ' contacts added successfully'
    //   openSnackbar(message)
    //   setOtherItems(newList);

    //   console.log(contacts);
    // })
    // .catch(error => { console.log(error) });

    setImportDialogOpen(false);
  };

  const handleEditFromId = (selectedId) => {
    const aList = otherItems.filter((item) => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!

    // call the handleEdit open handler
    handleEditOpen(aList[0]);
  };

  const handleRefresh = (selected, refreshReason) => {
    console.log("Selected:");
    console.log(selected);

    switch (refreshReason) {
      case RefreshReason.UploadAttachment:
        console.log("Refresh - upload");
        refetch();
        break;
      case RefreshReason.DeleteAttachment:
        console.log("Refresh - delete");

        const list = otherItems.filter((i) => i.id !== selected.id);
        selected.attachments = [];
        list.push(selected);
        processReceivedData(list);
        break;
      default:
        console.log("NOT A VALID PATH");
        break;
    }
  };

  const handleShowInfo = (selectedId) => {
    const aList = otherItems.filter((item) => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!
    const item = aList[0];
    const tableInfo = prepareTableRowInfo(item);

    setInfoTableItem(tableInfo);
    setSelectedItem(aList[0]);
    setShowInfoDialog(true);
  };

  const handleShowInfoClose = () => {
    setSelectedItem(false);
    setShowInfoDialog(false);
  };

  const checkForAttachments = (selected) => {
    return selected.attachments.length > 0;
  };

  const handleViewChange = (oldView, newView) => {
    // whenever we get a view change, we need to cancel all filtering, searching and restore the display list
    handleSearch(null);
  };

  // are we ready?
  if (otherItems === null) {
    return <div>Loading ...</div>;
  }

  return (
    <React.Fragment>
      <LTRCommonLayout
        domain={DomainType.OtherItems}
        domainSingular={DomainTypeSingular.OtherItems}
        handleViewChange={handleViewChange}
        commonNameList={
          <div className={classes.root}>
            <LTRCommonNameList
              list={visibleList}
              domain={DomainType.OtherItems}
              domainSingular={DomainTypeSingular.OtherItems}
              hasAttachments={checkForAttachments}
              addComponent={
                <AddEditOtherItemsForm
                  handleAdd={handleAddNotification}
                  handleEdit={handleEditNotification}
                  handleReceiveData={processReceivedData}
                />
              }
              handleDelete={handleDelete}
              handleEdit={handleEditOpen}
              handleInfo={handleShowInfo}
              handleSearch={handleSearch}
              handleRefresh={handleRefresh}
            />
          </div>
        }
        commonNameTable={
          <LTRCommonDataTable
            domain={DomainType.OtherItems}
            domainColumns={[
              { name: "name", label: "Name" },
              { name: "type", label: "Type" },
              { name: "description", label: "Description" },
              { name: "recommendation", label: "Recommendation" },
              { name: "notes", label: "Notes" },
            ]}
            dataItems={visibleList}
            handleMultiDeleteNotification={handleMultiDeleteNotification}
            handleEdit={handleEditFromId}
          />
        }
        actionPanel={
          <OtherItemsActionPanel
            handleAddNotification={handleAddNotification}
            handleReceiveData={processReceivedData}
          />
        }
      />
      {editFormOpen ? (
        <AddEditOtherItemsForm
          open={editFormOpen}
          selected={selectedItem}
          handleAdd={handleAddNotification}
          handleEdit={handleEditNotification}
          handleClose={handleEditClose}
          handleReceiveData={processReceivedData}
        />
      ) : null}
      {importDialogOpen ? (
        <ImportDialog
          open={importDialogOpen}
          domain={DomainType.OtherItems}
          handleClose={handleImportClose}
          handleSave={handleImportSave}
        />
      ) : null}
      {showInfoDialog ? (
        <LTRDomainObjectInfo
          open={showInfoDialog}
          title={selectedItem.name}
          tableData={infoTableItem}
          handleClose={handleShowInfoClose}
        />
      ) : null}
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    </React.Fragment>
  );
};

OtherItems.propTypes = {};

export default OtherItems;

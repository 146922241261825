const CryptoJS = require("crypto-js");
 
// // Encrypt
// var ciphertext = CryptoJS.AES.encrypt('my message', 'secret key 123').toString();
 
// // Decrypt
// var bytes  = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
// var originalText = bytes.toString(CryptoJS.enc.Utf8);
 
// console.log(originalText); // 'my message'

class ncrypter {

  static encryptData(key, data) {
   
    var encryptedData = CryptoJS.AES.encrypt(data, key).toString();
    return encryptedData;
  }

  static decryptData(key, encryptedData) {
    var bytes  = CryptoJS.AES.decrypt(encryptedData, key);
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }  
}

export default ncrypter;
 
 
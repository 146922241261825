/* eslint-disable react/prop-types */

import React, { useState, useContext } from "react";

import { useApolloClient } from '@apollo/client';
import { fetchOneRealm } from '../api/UsersApi';
import LoginState from "../auth/LoginState";

export const FamilyContext = React.createContext();
export const useFamilyContext= () => useContext(FamilyContext);

export const NULL_FAMILY_NAME = "null";
export const NULL_FAMILY_ID = "null";

export const FamilyProvider = ({
  children,
  ...props
}) => {

  const client = useApolloClient();

  const [members, setFamilyMembers] = useState([]);
  const [familyRealm, setFamilyRealm] = useState(
    {
      name: NULL_FAMILY_NAME,
      id: NULL_FAMILY_ID
    } );

  const onLogin = (realmId) => {
    // need to persist this for long lived sessions
    LoginState.setRealmData(realmId);
  }

  const onLogout = () => {
    setFamilyRealm ({name: NULL_FAMILY_NAME, id: NULL_FAMILY_ID})
  }

  const addFamilyMember = (member) => {
    const mems = [member, ...members];
    setFamilyMembers(mems);
  }

  const addNewFamily = (newRealm, familyMembers) => {
    const mems = [...familyMembers];
    setFamilyMembers(mems);
    setFamilyRealm(newRealm);
  }

  const familyName = () => {
    return familyRealm.name;
  }

  const setFamilyFromRealm = (realmId) => {  
    return new Promise((resolve, reject) => {
      fetchOneRealm(client, realmId)
      .then (result => {
        // result is Users (and array of users)
        // first, dig out the users
        const members = [];
        let planName = '';
        result.Users.map ((user) => {
          const userToAdd = {...user};
         
          // // need to find THIS realm to find out the user role
          const thisRealm = user.UsersRealms.filter(item => {
            return item.realms.id === realmId;
          })
          
          userToAdd.isOwner = thisRealm[0].isOwner;
          planName = thisRealm[0].realms.name;
          
          members.push(userToAdd);
          return members;
        })

        const realm = {
          name: planName,
          id: realmId
        }
  
        setFamilyMembers(members);
        setFamilyRealm(realm);
         
        resolve(true);
      })
      .catch (error => {
        reject(error);
      })   
    })      
  }

  return (
    <FamilyContext.Provider
      value={{
        familyRealm,
        members,
        familyName, 
        onLogout,
        onLogin,
        addFamilyMember,
        addNewFamily,
        setFamilyRealm,
        setFamilyMembers,
        setFamilyFromRealm,
      }}
    >
      {children}
    </FamilyContext.Provider>
  );
};

import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ListItemText from "@material-ui/core/ListItemText";

import Typography from "@material-ui/core/Typography";

import LTRSnackbar, {
  buildEditMessage,
} from "../utilties/LTRSnackbar";
import { useSnackbar } from "../utilties/snackbarUtil";

import { getIncompleteBillers } from "../../graphql/queries";

import { FamilyContext } from "../../context/FamilyContext";

import { gql, useQuery, useMutation } from "@apollo/client";

import QuickAssignBillerAccount from "../billers/QuickAssignBillerAccount";
import { DomainType } from "../../Enums";

const GET_BILLERS = gql(
  `query listBillers ($userRealm: String) {
  ` +
    getIncompleteBillers +
    `
}
`
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: "bold",
    marginRight: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const IncompleteBillersView = (props) => {
  const classes = useStyles();
  const familyContext = useContext(FamilyContext);

  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState({});
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_BILLERS, {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  useEffect(() => {
    const onCompleted = (data) => {
      setItems(data.Biller);
    };
    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  if (error) return `Error! ${error.message}`;

  const handleEdit = (item) => {
    setSelected(item);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditNotification = (item) => {
    setEditDialogOpen(false);

    // refetch our incomplete items
    refetch()
      .then((_) => {
        // notify the user and refetch the counts
        openSnackbar(buildEditMessage(item.payee));
        props.refetchFunc();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // simply return if we have no data
  if (items.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography
        variant="subtitle1"
        gutterBottom
        className={classes.titleText}
      >
        {"Items without billers"}
      </Typography>

      <List>
        {items.map((bill) => (
          <ListItem
            key={bill.id}
            role={undefined}
            alignItems="flex-start"
            divider
            className={classes.listItem}
            onClick={() => setSelected(bill)}
          >
            <ListItemText
              disableTypography
              primary={
                <React.Fragment>
                  <Typography
                    className = {classes.primaryText}
                    // component="div"
                    variant="body1"
                    color="textPrimary"
                  >
                    {bill.payee}
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    // component="div"
                    variant="body2"
                    // color="textPrimary"
                  >
                    {bill.description}
                  </Typography>
                  {" To fix: edit the item and specify the 'billpay account'"}
                </React.Fragment>
                
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title={"Edit"}>
                <IconButton
                  aria-label={"Edit"}
                  onClick={() => {
                    handleEdit(bill);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      {editDialogOpen && selected ? (
        <QuickAssignBillerAccount
          biller={selected}
          open={editDialogOpen}
          handleClose={handleEditClose}
          handleSave={handleEditNotification}
        />
      ) : null}
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    </div>
  );
};

IncompleteBillersView.propTypes = {
  refetchFunc: PropTypes.func.isRequired,
};

export default IncompleteBillersView;

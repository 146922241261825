import {AssetType, ActionItemType, ActionItemGroupType, PriorityType } from '../../Enums';
import {NonChoice } from '../../Enums';

const prepareHighPriorityItems = (editItem, asset) => {
  let priority = PriorityType.High;
  let groupType = ActionItemGroupType.None;

  // Asset types: Vehicle, Housing, Building(rental), Pension, Annuity, Cash, Other
  // NOTE: we don't prepare action items for all types
  
  // if the asset is insured, add a note to remind the user to contact the insurer
  let note = '';
  if (editItem.insuredId !== null) {
    note = "Contact " + editItem.insurance.name + " and notify them of your plans."
  }

  let name = null;
  if (asset.type === AssetType.Vehicle || asset.type === AssetType.Building || asset.type === AssetType.Housing) {
    name = "Figure out whether to keep OR sell the asset '" + asset.name + "'.";
  }
  if (asset.type === AssetType.Pension || asset.type === AssetType.Annuity) {
    name = "Contact the agency/company that manages '" + asset.name + "' to determine next steps.";
  }
  if (asset.type === AssetType.Cash) {
    name = "Identify where the cash is held and transfer the funds to an appropriate account.";
  }
  // are we dealing with an unsupported type?
  if (name === null) {
    return null;
  }

  // for grouping purposes
  if (asset.type === AssetType.Housing) {
    groupType = ActionItemGroupType.Home;
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: asset.id,
    domainName: asset.name,
    type: ActionItemType.Assets,
    group: groupType,
    notes: note
  };
  return actionItemDetails;
}

const prepareAdditionalItems = (asset) => {
  let priority = PriorityType.Medium;
  let groupType = ActionItemGroupType.None;

  // Asset types: Vehicle, Housing, Building(rental), Pension, Annuity, Cash, Digital Other
  // NOTE: we don't prepare action items for all types
  
  let name = null;
  if (asset.type === AssetType.Digital) {
    name = "Figure out what to do '" + asset.name + "'. Download it? Delete it? Delete an account? Transfer ownership?";
  }

  // are we dealing with an unsupported type?
  if (name === null) {
    return null;
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: asset.id,
    domainName: asset.name,
    type: ActionItemType.Assets,
    group: groupType,
    notes: ''
  };
  return actionItemDetails;
}

export const constructCorrespondingActionItems = (editItem, asset) => {
  const actionItems = [];
  const actionItemDetails = prepareHighPriorityItems(editItem, asset);
  if (actionItemDetails) {
    actionItems.push(actionItemDetails);
  }
  const otherActionItemDetails = prepareAdditionalItems(asset);
  if (otherActionItemDetails) {
    actionItems.push(otherActionItemDetails);
  }
  return actionItems;
}

import {constructDoubleQuotedString} from '../pages/utilties/StringFunctions';

import {ActionItem, ContactEmail, ContactPhone, ContactAddress} from './fragments';
import {Account, Asset, Contact, Insurance, Liability, OtherItem, Service, Subscription, Document} from './fragments';
import {User, UsersRealms, Biller, Settings, Attachment, Note} from './fragments';
import {LinkedContact, LinkedInsurance} from './fragments';

export const listAllActionItems = `query AllActionItems($userRealm: String) {
  ActionItems (where: {userRealm: {_eq: $userRealm}}) 
  {` + ActionItem + `}
}`

export const listAccountActionItems = `query AccountActionItems($userRealm: String) {
  ActionItems(where: {_and: {userRealm: {_eq: $userRealm}, type: {_eq: "Account"}}}) 
  {` + ActionItem + `}
}`

export const listContactActionItems = `query ContactActionItems($userRealm: String) {
  ActionItems(where: {_and: {userRealm: {_eq: $userRealm}, type: {_eq: "Contacts"}}}) 
  {` + ActionItem + `}
}`

export const listAssetActionItems = `query AssetActionItems($userRealm: String) {
  ActionItems(where: {_and: {userRealm: {_eq: $userRealm}, type: {_eq: "Assets"}}}) 
  {` + ActionItem + `}
}`

export const listLiabilityActionItems = `query LiabilityActionItems($userRealm: String) {
  ActionItems(where: {_and: {userRealm: {_eq: $userRealm}, type: {_eq: "Liabilities"}}}) 
  {` + ActionItem + `}
}`

export const listInsuranceActionItems = `query InsuranceActionItems($userRealm: String) {
  ActionItems(where: {_and: {userRealm: {_eq: $userRealm}, type: {_eq: "Insurance"}}}) 
  {` + ActionItem + `}
}`

export const listSubscriptionActionItems = `query SubscriptionsActionItems($userRealm: String) {
  ActionItems(where: {_and: {userRealm: {_eq: $userRealm}, type: {_eq: "Subscriptions"}}}) 
  {` + ActionItem + `}
}`

export const listServiceActionItems = `query ServicesActionItems($userRealm: String) {
  ActionItems(where: {_and: {userRealm: {_eq: $userRealm}, type: {_eq: "Services"}}}) 
  {` + ActionItem + `}
}`

export const listOtherItemsActionItems = `query OtherItemsActionItems($userRealm: String) {
  ActionItems(where: {_and: {userRealm: {_eq: $userRealm}, type: {_eq: "Other Items"}}}) 
  {` + ActionItem + `}
}`

export const listDocumentsActionItems = `query DocumentsActionItems($userRealm: String) {
  ActionItems(where: {_and: {userRealm: {_eq: $userRealm}, type: {_eq: "Documents"}}}) 
  {` + ActionItem + `}
}`

export const listGeneralActionItems = `query GeneralActionItems($userRealm: String) {
  ActionItems(where: {_and: {userRealm: {_eq: $userRealm}, type: {_eq: "General"}}}) 
  {` + ActionItem + `}
}`

// this query is designed to fetch items where owner === user or (owner === user1)
// ActionItems(where: {_and:  {owner: {_in: ["Linda","Andrew"]},  userRealm: {_eq: $userRealm}})
export const listActionItemsForUserGroupOwners = (userRealm, owners) => {
  let whereClause = 'where: {_and: {owner: {_in: [' 
  var i;
  for (i = 0; i < owners.length; i++) {
    whereClause += constructDoubleQuotedString(owners[i]);
    if (i+1 < owners.length) {
      whereClause += ',';
    }
  }
  whereClause += ']}},  userRealm: {_eq: ' + constructDoubleQuotedString(userRealm) + '}}';
  
  return (`query OwnerActionItems  {` +
    `ActionItems  (` + whereClause +`)  
    {` + ActionItem + `}
  }`)
}

export const listAllAttachments = `query AllAttachments($userRealm: String) {
  Attachments (where: {userRealm: {_eq: $userRealm}}) 
  {` + Attachment + `}
}`

export const getActionItem = `query ActionItems_by_pk ($id: uuid!) {
  ActionItems_by_pk(id: $id) {` + ActionItem + `}
}
`;

export const getAccount = `query Account_by_pk ($id: uuid!) {
  item: Account_by_pk(id: $id) {
    ` + Account + `
    contact: Contact {` + LinkedContact + ` }
    actionItems: AccountActionItems {` + ActionItem + `}
    attachments: AccountAttachments {` + Attachment + `}
  }
}
`;

export const getAsset = `query Asset_by_pk ($id: uuid!) {
  item: Asset_by_pk(id: $id) {
    ` + Asset + `
    insurance: Insurance {` + LinkedInsurance + `}
    actionItems: AssetActionItems {` + ActionItem + `}
    attachments: AssetAttachments {` + Attachment + `}
  }
}
`;

export const getLiability = `query Liability_by_pk ($id: uuid!) {
  item: Liability_by_pk(id: $id) {
    ` + Liability + `
    billers: LiabilityBiller {` + Biller + `}
    actionItems: LiabilityActionItems {` + ActionItem + `}
    attachments: LiabilityAttachments {` + Attachment + `}
  }
}
`;

export const getContact = `query Contacts_by_pk ($id: uuid!) {
  item: Contacts_by_pk(id: $id) {
    ` + Contact + `
    ContactPhone {` + ContactPhone + `}
    ContactEmail {` + ContactEmail + `}
    ContactAddress {` + ContactAddress + `}
    actionItems: ContactActionItems {` + ActionItem + `}
    attachments: ContactAttachments {` + Attachment + `}
  }
}
`;

export const getInsurance = `query Insurance_by_pk ($id: uuid!) {
  item: Insurance_by_pk(id: $id) {
    ` + Insurance + `
    billers: InsuranceBiller {` + Biller + `}
    actionItems: InsuranceActionItems {` + ActionItem + `}
    attachments: InsuranceAttachments {` + Attachment + `}
  }
}
`;

export const getSubscription = `query Subscriptions_by_pk ($id: uuid!) {
  item: Subscriptions_by_pk(id: $id) {
    ` + Subscription + `
    billers: SubscriptionBiller {` + Biller + `}
    actionItems: SubscriptionsActionItems {` + ActionItem + `}
    attachments: SubscriptionsAttachments {` + Attachment + `}
  }
}
`;

export const getService = `query Services_by_pk ($id: uuid!) {
  item: Services_by_pk(id: $id) {
    ` + Service + `
    billers: ServiceBiller {` + Biller + `}
    contact: Contact {` + LinkedContact + ` }
    actionItems: ServicesActionItems {` + ActionItem + `}
    attachments: ServicesAttachments {` + Attachment + `}
  }
}
`;

export const getOtherItem = `query OtherItems_by_pk ($id: uuid!) {
  item: OtherItems_by_pk(id: $id) {
    ` + OtherItem + `
    actionItems: OtherItemsActionItems {` + ActionItem + `}
    attachments: OtherItemsAttachments {` + Attachment + `}
  }
}
`;

export const getDocument = `query Documents_by_pk ($id: uuid!) {
  item: Documents_by_pk(id: $id) {
    ` + Document + `
    actionItems: DocumentsActionItems {` + ActionItem + `}
    attachments: DocumentsAttachments {` + Attachment + `}
    note: DocumentsNotes { ` + Note + `}
  }
}
`;

export const getSettings = `query Settings ($userRealm: String!) {
  Settings (
    where: {userRealm: {_eq: $userRealm}}
  ) {
      ` + Settings + `
  }
}
`;

export const getUserByEmail = `query Users ($email: String!) {
  Users (
    where: {email: {_eq: $email}}
  ) {
      ` + User + `
      UsersRealms { ` + UsersRealms + `}
  }
}
`;

export const getUserByAuthId = `query Users ($authId: String!) {
  Users (
    where: {authId: {_eq: $authId}}
  ) {
      ` + User + `
      UsersRealms { ` + UsersRealms + `}
  }
}
`;

export const getUserByAuthIdOrEmail = `query Users ($authId: String!, $email: String!) {
  Users (where: 
    {
      _or: [{
         authId: { _eq: $authId }},
         {email: { _eq: $email }}
      ]
    }
  )  {
      ` + User + `
      UsersRealms { ` + UsersRealms + `}
  }
}
`;

export const getUser = `query Users_by_pk ($id: uuid!) {
  Users_by_pk(id: $id) {
    ` + User + `
    UsersRealms { ` + UsersRealms + `}
  }
}
`;

export const getUserByUserName = `query Users ($loginName: String!) {
  Users (
    where: {loginName: {_eq: $loginName}}
  ) {
      ` + User + `
  }
}
`;

export const getRealmsForUser = `query getRealmsForUser ($userId: uuid!) {
  UsersRealms (where: {userId: {_eq: $userId}}) {
    ` + UsersRealms + `
  }
}
`;

export const listAccounts = `query listAccounts ($userRealm: String) {
  item: Account (
    where: {userRealm: {_eq: $userRealm}}
  ){
    ` + Account + `
    contact: Contact {` + LinkedContact + ` }
    actionItems: AccountActionItems {` + ActionItem + `}
    attachments: AccountAttachments {` + Attachment + `}
  }
}
`;

// {billpayAccount: {_eq: true}}
export const listBillpayAccounts = `query listBillpayAccounts ($userRealm: String) {
  Account (
    where: {_and: {userRealm: {_eq: $userRealm}, billpayAccount: {_eq: true}}}
  ){
    ` + Account + `
  }
}
`;

export const listContacts = `query listContacts ($userRealm: String) {
  item: Contacts (
    where: {userRealm: {_eq: $userRealm}}
  ){
    ` + Contact + `
    ContactPhone {` + ContactPhone + `}
    ContactEmail {` + ContactEmail + `}
    ContactAddress {` + ContactAddress + `}
    actionItems: ContactActionItems {` + ActionItem + `}
    attachments: ContactAttachments {` + Attachment + `}
  }
}
`;

export const listInsurance = `query listInsurance ($userRealm: String) {
  item: Insurance (
    where: {userRealm: {_eq: $userRealm}}
  ){
    ` + Insurance + `
    contact: Contact {` + LinkedContact + ` }
    billers: InsuranceBiller {` + Biller + `}
    actionItems: InsuranceActionItems {` + ActionItem + `}
    attachments: InsuranceAttachments {` + Attachment + `}
  }
}
`;

export const listAssets = `query listAsset ($userRealm: String) {
  item: Asset (
    where: {userRealm: {_eq: $userRealm}}
  ){
    ` + Asset + `
    insurance: Insurance {` + LinkedInsurance + `}
    actionItems: AssetActionItems {` + ActionItem + `}
    attachments: AssetAttachments {` + Attachment + `}
  }
}
`;

export const listLiabilities = `query listLiability ($userRealm: String) {
  item: Liability (
    where: {userRealm: {_eq: $userRealm}}
  ){
    ` + Liability + `
    billers: LiabilityBiller {` + Biller + `}
    actionItems: LiabilityActionItems {` + ActionItem + `}
    attachments: LiabilityAttachments {` + Attachment + `}
  }
}
`;

export const listSubscriptions = `query listSubscriptions ($userRealm: String) {
  item: Subscriptions (
    where: {userRealm: {_eq: $userRealm}}
  ){
    ` + Subscription + `
    billers: SubscriptionBiller {` + Biller + `}
    actionItems: SubscriptionsActionItems {` + ActionItem + `}
    attachments: SubscriptionsAttachments {` + Attachment + `}
  }
}
`;

export const listServices = `query listServices ($userRealm: String) {
  item: Services (
    where: {userRealm: {_eq: $userRealm}}
  ){
    ` + Service + `
    billers: ServiceBiller {` + Biller + `}
    contact: Contact {` + LinkedContact + ` }
    actionItems: ServicesActionItems {` + ActionItem + `}
    attachments: ServicesAttachments {` + Attachment + `}
  }
}
`;

export const listOtherItems = `query listOtherItems ($userRealm: String) {
  item: OtherItems (
    where: {userRealm: {_eq: $userRealm}}
  ){
    ` + OtherItem + `
    actionItems: OtherItemsActionItems {` + ActionItem + `}
    attachments: OtherItemsAttachments {` + Attachment + `}
  }
}
`;

export const listDocuments = `query listDocuments ($userRealm: String) {
  item: Documents (
    where: {userRealm: {_eq: $userRealm}}
  ){
    ` + Document + `
    actionItems: DocumentsActionItems {` + ActionItem + `}
    attachments: DocumentsAttachments {` + Attachment + `}
    note: DocumentsNotes { ` + Note + `}
  }
}
`;

export const listNotes = `query listNotess ($userRealm: String) {
  Notes (
    where: {userRealm: {_eq: $userRealm}}
  ){
    ` + Note + `
  }
}
`;

export const listUsers = `query listUsers ($userRealm: uuid) {
  Users (where: {UsersRealms: {realms: {id: {_eq: $userRealm}}}}) {
    ` + User + `
    UsersRealms  {
      isOwner
      realms {
        id
        name
      }
    }
  }
}`

export const listBillers = `query listBillers ($userRealm: String) {
  Biller (
    where: {userRealm: {_eq: $userRealm}}
  ){
    ` + Biller + `
    BillerAccount {` + Account + ` }
  }
}
`;

// Incomplete query sections - used to help us find incomplete accounts, contacts, etc
// Note: these are exported fragments, not named queries

export const getIncompleteAccounts = `
  Account (
    where: {_and: {userRealm: {_eq: $userRealm}, institution: {_eq: ""}}}
  ){
    ` + Account + `
  }
`;

export const getIncompleteAccountsCount = `
  accounts_count: Account_aggregate (
    where: {_and: {userRealm: {_eq: $userRealm}, institution: {_eq: ""}}}
  ){
    aggregate {
      count
    }
  }
`;

export const getIncompleteBillers = `
  Biller (
    where: {_and: {userRealm: {_eq: $userRealm}, accountId: {_is_null: true}}}
  ){
    ` + Biller + `
  }
`;

export const getIncompleteBillersCount = `
  billers_count: Biller_aggregate (
    where: {_and: {userRealm: {_eq: $userRealm}, accountId: {_is_null: true}}}
  ){
    aggregate {
      count
    }
  }
`;

export const getIncompleteInsurance = `
  Insurance (
    where: {
      userRealm: {_eq: $userRealm}, 
      _or: [ {company: { _eq: ""}}, {policyNumber: {_eq: ""}}]
    }
  ){
    ` + Insurance + `
  }
`;

export const getIncompleteInsuranceCount = `
  insurance_count: Insurance_aggregate (
    where: {
      userRealm: {_eq: $userRealm}, 
      _or: [ {company: { _eq: ""}}, {policyNumber: {_eq: ""}}]
    }
  ){
    aggregate {
      count
    }
  }
`;

export const getIncompleteContacts = `
  Contacts (
    where: {
      _and: { 
        userRealm: {_eq: $userRealm},
        _not: { 
          _or:  [
            {ContactPhone: {}},
            {ContactEmail: {}}
          ] 
        }
      }
    }
  ){
    ` + Contact + `
  }
`;

export const getIncompleteContactsCount = `
  contacts_count: Contacts_aggregate (
    where: {
      _and: { 
        userRealm: {_eq: $userRealm},
        _not: { 
          _or:  [
            {ContactPhone: {}},
            {ContactEmail: {}}
          ] 
        }
      }
    }
  ){
    aggregate {
      count
    }
  }
`;

export const getIncompleteLiabilities = `
  Liability (
    where: {_and: {userRealm: {_eq: $userRealm}, institution: {_eq: ""}}}
  ){
    ` + Liability + `
  }
`;

export const getIncompleteLiabilitiesCount = `
  liabilities_count: Liability_aggregate (
    where: {_and: {userRealm: {_eq: $userRealm}, institution: {_eq: ""}}}
  ){
    aggregate {
      count
    }
  }
`;

export const getIncompleteSubscriptions = `
  Subscriptions (
    where: {_and: {userRealm: {_eq: $userRealm}, company: {_eq: ""}}}
  ){
    ` + Subscription + `
  }
`;

export const getIncompleteSubscriptionsCount = `
  subscriptions_count: Subscriptions_aggregate (
    where: {_and: {userRealm: {_eq: $userRealm}, company: {_eq: ""}}}
  ){
    aggregate {
      count
    }
  }
`;

// THIS IS ALL OLD

export const getFamily = `query GetFamily($id: ID!) {
  getFamily(id: $id) {
    id
    realm
    members {
      items {
        id
        userRealm
        firstName
        lastName
        email
        phone
      }
      nextToken
    }
  }
}
`;
export const listFamilys = `query ListFamilys(
  $filter: ModelFamilyFilterInput
  $limit: Int
  $nextToken: String
) {
  listFamilys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      realm
      members {
        items {
          id
          userRealm
          firstName
          lastName
          email
          phone
        }
        nextToken
      }
    }
    nextToken
  }
}
`;


import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import LTRCommonActionPanel from '../../shared/domains/LTRCommonActionPanel'

import {InsuranceType} from '../../../Enums';

import QuickAddInsurance from './QuickAddInsurance';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  }
}));

const InsuranceActionPanel = (props) => {
  const classes = useStyles();

  const [insuranceType, setInsuranceType] = useState(InsuranceType.Other);
  const [quickAddOpen, setQuickAddOpen] = useState(false);

  const handleAddInsurance = (type) => {
    setInsuranceType(type);
    setQuickAddOpen(true);
  }

  const handleCloseQuickAddOpen = () => {
    setQuickAddOpen(false);
  }

  const handleSave  = (asset) => {
    props.handleAddNotification(asset);
  }

  const handleReceiveData = (data) => {
    if (props.handleReceiveData) {
      props.handleReceiveData(data);
    }
  }

  return (
    <div className={classes.root}>
      <LTRCommonActionPanel
        actionComponents = {
          <ButtonGroup
            orientation="vertical"
            size = "small"
            color="primary"
            variant="text"
            aria-label="vertical outlined primary button group"
            >
            <Button className={classes.button} onClick={() => handleAddInsurance(InsuranceType.HomeOwners)} color="primary">
              Add home owners insurance
            </Button>
            <Button className={classes.button} onClick={() => handleAddInsurance(InsuranceType.Auto)}   color="primary">
              Add auto insurance
            </Button>
            <Button className={classes.button} onClick={() => handleAddInsurance(InsuranceType.Medical)}   color="primary">
              Add medical insurance
            </Button>
            <Button className={classes.button} onClick={() => handleAddInsurance(InsuranceType.Dental)}   color="primary">
              Add dental insurance
            </Button>
            <Button className={classes.button} onClick={() => handleAddInsurance(InsuranceType.Life)}   color="primary">
              Add life insurance
            </Button>
            <Button className={classes.button} onClick={() => handleAddInsurance(InsuranceType.LongTerm)}   color="primary">
              Add long term care insurance
            </Button>
            <Button className={classes.button} onClick={() => handleAddInsurance(InsuranceType.Other)}   color="primary">
              Add other insurance
            </Button>
          </ButtonGroup>
        }
        />
        {quickAddOpen ? (
           <QuickAddInsurance
              open = {quickAddOpen}
              insuranceType = {insuranceType}
              handleClose = {handleCloseQuickAddOpen}
              handleSave = {handleSave}
              handleReceiveData = {handleReceiveData}
           />) 
          : null
        }
    
    </div>
  )
}

InsuranceActionPanel.propTypes = {
  handleAddNotification: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired,
}

export default (InsuranceActionPanel)

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import TabPanel from "../shared/TabPanel";
import UserTableView from './UserTableView';
import ActionItemListView from '../actionitems/ActionItemListView';
import IncompleteDataListView from '../domaindata/IncompleteDataListView';
import Billers from '../billers/Billers'
import PeopleToContact from '../contacts/PeopleToContact';
import Letter from '../overview/Letter';

// TESTING ONLY
//import NewUserInfo from '../overview/tutorial/NewUserIntro';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  tabPanel: {
    marginTop: theme.spacing(1)
  }
}));

const OwnerView =(props) => { 
  const classes = useStyles();
  
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const tasksPanel =  ( 
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography className={classes.heading}>Bills we pay</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Billers />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography className={classes.heading}>People to contact</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PeopleToContact />
        </AccordionDetails>
      </Accordion>
    </>
  )

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} centered aria-label="simple tabs example">
          <Tab label="Getting Started"  />
          <Tab label="Action Items"  />
          <Tab label="Tasks by Group"  />
          <Tab label="Your Plan Team"  />
        </Tabs>
      </AppBar>
      <TabPanel className={classes.tabPanel} value={value} index={0}>
        <Letter />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={1}>
        <ActionItemListView
          title = {"view"}
          filter = { {filter: "filter"}}
        />
        <IncompleteDataListView />
      </TabPanel>
      <TabPanel className={classes.tabPanel}  value={value} index={2}>
        {tasksPanel}
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={3}>
        <UserTableView/>
      </TabPanel>
    </div>
  );
}

export default (OwnerView);
import { gql } from '@apollo/client';

import { DomainType } from '../Enums';

// file operations
import {
  getAccount, 
  getContact, 
  getAsset, 
  getLiability, 
  getInsurance, 
  getSubscription,
  getService,
  getOtherItem,
  getDocument,
} from '../graphql/queries';

import {
  listAccounts,
  listContacts,
  listAssets,
  listLiabilities,
  listInsurance,
  listSubscriptions,
  listServices,
  listOtherItems,
  listDocuments
} from '../graphql/queries';

 import {
  deleteAccounts, 
  deleteContacts, 
  deleteAssets, 
  deleteLiabilities, 
  deleteInsurances, 
  deleteSubscriptions,
  deleteServices,
  deleteOtherItems,
  deleteDocuments
} from '../graphql/mutations';

import {
  insertAccounts, 
  insertContacts, 
  insertAssets, 
  insertLiabilities, 
  insertInsurances, 
  insertSubscriptions,
  insertServices,
  insertOtherItems,
  insertDocuments
} from '../graphql/mutations';


// Targets are used to help select the correct graphql mutations, etc. for
// domain operations such as add, edit, and delete
export const OperationTargets = {
  Accounts: 'Account',
  Contacts: 'Contacts',
  Assets: 'Assets',
  Liabilities: 'Liabilities',
  Insurance: 'Insurance',
  Subscriptions: 'Subscriptions',
  Services: 'Services',
  OtherItems: 'OtherItems',
  Documents: 'Documents',
  General: 'General'  // for action items that are NOT attached to a domain
}

export const domainTypeToOperationTarget = (domainType) => {
  let retVal;
  switch (domainType) {
    case DomainType.Accounts: {
      retVal = OperationTargets.Accounts;
      break;
    }
    case DomainType.Contacts: {
      retVal = OperationTargets.Contacts;
      break;
    }
    case DomainType.Assets: {
      retVal = OperationTargets.Assets;
      break;
    }
    case DomainType.Liabilities: {
      retVal = OperationTargets.Liabilities;
      break;
    }
    case DomainType.Insurance: {
      retVal = OperationTargets.Insurance;
      break;
    }
    case DomainType.Subscriptions: {
      retVal = OperationTargets.Subscriptions;
      break;
    }
    case DomainType.Services: {
      retVal = OperationTargets.Services;
      break;
    }
    case DomainType.OtherItems: {
      retVal = OperationTargets.OtherItems;
      break;
    }
    case DomainType.Documents: {
      retVal = OperationTargets.Documents;
      break;
    }
    default: 
      console.log('Unexpected domain type')
  }
  return retVal;
}

export async function fetchOneDomainInstance(client, target, id) { 
  let promise = new Promise((resolve, reject) => {

    let targetFetch;
    let ourFetchPolicy = 'network-only';
    switch (target) {
      case OperationTargets.Accounts:
        targetFetch = gql(getAccount);
        break;
      case OperationTargets.Contacts:
        targetFetch = gql(getContact);
        break;
      case OperationTargets.Assets:
        targetFetch = gql(getAsset);
        break;
      case OperationTargets.Liabilities:
        targetFetch = gql(getLiability);
        break;
      case OperationTargets.Insurance:
        targetFetch = gql(getInsurance);
        break;
      case OperationTargets.Subscriptions:
        targetFetch = gql(getSubscription);
        break;
      case OperationTargets.Services:
        targetFetch = gql(getService);
        break;
      case OperationTargets.OtherItems:
        targetFetch = gql(getOtherItem);
        break;
      case OperationTargets.Documents:
        targetFetch = gql(getDocument);
        break;
      default:
        console.log('Unknown operation')
    }

    client.query( {
      query: targetFetch, 
      fetchPolicy: ourFetchPolicy,
      variables: {id: id}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function addMultipleDomainInstances(client, userRealm, target, payload) { 
  let promise = new Promise((resolve, reject) => {

    let targetMutation;
    let refetchQuery;
    switch (target) {
      case OperationTargets.Accounts:
        targetMutation = gql(insertAccounts);
        refetchQuery = gql(listAccounts);
        break;
      case OperationTargets.Contacts:
        targetMutation = gql(insertContacts);
        refetchQuery = gql(listContacts);
        break;
      case OperationTargets.Assets:
        targetMutation = gql(insertAssets);
        refetchQuery = gql(listAssets);
        break;
      case OperationTargets.Liabilities:
        targetMutation = gql(insertLiabilities);
        refetchQuery = gql(listLiabilities);
        break;
      case OperationTargets.Insurance:
        targetMutation = gql(insertInsurances);
        refetchQuery = gql(listInsurance);
        break;   
      case OperationTargets.Subscriptions:
        targetMutation = gql(insertSubscriptions);
        refetchQuery = gql(listSubscriptions);
        break;
      case OperationTargets.Services:
        targetMutation = gql(insertServices);
        refetchQuery = gql(listServices);
        break;             
      case OperationTargets.OtherItems:
        targetMutation = gql(insertOtherItems);
        refetchQuery = gql(listOtherItems);
        break;  
      case OperationTargets.Documents:
        targetMutation = gql(insertDocuments);
        refetchQuery = gql(listDocuments);
        break;           
      default:
        console.log('Unknown operation')
    }

    client.mutate( {
      mutation: targetMutation,
      refetchQueries: [{
        query: refetchQuery,
        variables: { userRealm: userRealm},
      }],   
      variables: {objects: payload}
    })
    .then(result => {
    
     // TODO: update the cach
      // return to the client
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

const arrayContainsId = ((arr, id) => {
  //for (i, )
  const list = arr.map(item => {
    return item.id.indexOf(id) === -1;
  })
  console.log(list)
  return list;
})

const mergeMultipleDeleteData = (target, existingData, result) => {
  let updateData;
  let mergedData;
  let list;

  switch (target) {
    case OperationTargets.Accounts:
      updateData = result.data.delete_Account;

      // remove the edited item from the list
      console.log(updateData.returning)
      list = existingData.Account.filter(i => arrayContainsId(updateData.returning, i.id))
      mergedData = {Account: [...list]};
      break;
    case OperationTargets.Contacts:
      updateData = result.data.delete_Contacts;
      // remove the edited item from the list
      list = existingData.Contacts.filter(i => i.id !== updateData.id)
      mergedData = {Contacts: [...list]};
      break;
    case OperationTargets.Assets:
      updateData = result.data.delete_Asset;
      // remove the edited item from the list
      list = existingData.Asset.filter(i => i.id !== updateData.id)
      mergedData = {Asset: [...list]};
      break;
    case OperationTargets.Liabilities:
      updateData = result.data.delete_Liability;
      // remove the edited item from the list
      list = existingData.Liability.filter(i => i.id !== updateData.id)
      mergedData = {Liability: [...list]};    
      break;
    case OperationTargets.Insurance:
      updateData = result.data.delete_Insurance;
      // remove the edited item from the list
      list = existingData.Insurance.filter(i => i.id !== updateData.id)
      mergedData = {Insurance: [...list]};   
      break; 
    case OperationTargets.Subscriptions:
      updateData = result.data.delete_Subscriptions;
      // remove the edited item from the list
      list = existingData.Subscriptions.filter(i => i.id !== updateData.id)
      mergedData = {Subscriptions: [...list]};
      break;
    case OperationTargets.Services:
      updateData = result.data.delete_Services;
      // remove the edited item from the list
      list = existingData.Services.filter(i => i.id !== updateData.id)
      mergedData = {Services: [...list]}; 
      break;
    case OperationTargets.OtherItems:
      updateData = result.data.item;
      // remove the edited item from the list
      list = existingData.item.filter(i => i.id !== updateData.id)
      mergedData = {OtherItems: [...list]};  
      break;  
    case OperationTargets.Documents:
      updateData = result.data.delete_Documents;
      // remove the edited item from the list
      list = existingData.Documents.filter(i => i.id !== updateData.id)
      mergedData = {Documents: [...list]};
      break;                
    default:
      console.log('Unknown operation')
  }
  return mergedData;
}

export async function deleteMultipleDomainInstances(client, userRealm, target, payload) { 
  let promise = new Promise((resolve, reject) => {

    let targetMutation;
    let refetchQuery;
    switch (target) {
      case OperationTargets.Accounts:
        targetMutation = gql(deleteAccounts);
        refetchQuery = gql(listAccounts);
        break;
      case OperationTargets.Contacts:
        targetMutation = gql(deleteContacts);
        refetchQuery = gql(listContacts);
        break;
      case OperationTargets.Assets:
        targetMutation = gql(deleteAssets);
        refetchQuery = gql(listAssets);
        break;
      case OperationTargets.Liabilities:
        targetMutation = gql(deleteLiabilities);
        refetchQuery = gql(listLiabilities);
        break;
      case OperationTargets.Insurance:
        targetMutation = gql(deleteInsurances);
        refetchQuery = gql(listInsurance);
        break;   
      case OperationTargets.Subscriptions:
        targetMutation = gql(deleteSubscriptions);
        refetchQuery = gql(listSubscriptions);
        break;
      case OperationTargets.Services:
        targetMutation = gql(deleteServices);
        refetchQuery = gql(listServices);
        break;             
      case OperationTargets.OtherItems:
        targetMutation = gql(deleteOtherItems);
        refetchQuery = gql(listOtherItems);
        break;
      case OperationTargets.Documents:
        targetMutation = gql(deleteDocuments);
        refetchQuery = gql(listDocuments);
        break;              
      default:
        console.log('Unknown operation')
    }

    client.mutate( {
      mutation: targetMutation, 
      variables: {rows: payload}
    })
    .then(result => { 
      // update the cache
      const data = client.readQuery({
        query: refetchQuery,
        variables: { userRealm: userRealm },
      });
      
      const newData = mergeMultipleDeleteData(target, data, result);
      console.log(newData);

      // client.writeQuery({
      //   query: refetchQuery, 
      //   variables: { userRealm: userRealm },
      //   data: newData
      // })
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};



import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  bodyMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const SubscriptionsSnip = (props) => {
  const classes = useStyles();

  return (
     <div className={classes.root}>
      <Typography variant="body2" gutterBottom color="initial">
        'Subscriptions' are much like 'services'. They require signup, usually recurring payments and are usually provided by a company.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        This includes video and software services as well as magazine and newpaper subscriptions.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        Identify services that you've signed up for that use a subscription model for pricing and billing.
      </Typography>
     </div>
  );
}

export default SubscriptionsSnip;
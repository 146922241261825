import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  bodyMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const InsuranceSnip = (props) => {
  const classes = useStyles();

  return (
     <div className={classes.root}>
      <Typography variant="body2" gutterBottom color="initial">
        'Insurance' consists of policies that have been written to protect you..
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        All manner of insurance should be captured here, such as life, medical, dental, vision, or long term care. 
        This could also include auto insurance and pet insurance. It should also include insurance on personal property (such as a phone.)
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        Any type of policy you have, for which you pay regular premiums for, is detailed here. 
        Note, however, that it isn't necessary to include short term insurance policies (such as travel insurance).
      </Typography>
     </div>
  );
}

export default InsuranceSnip;
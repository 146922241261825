import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import {ActionItemType, NonChoice} from '../../Enums';

import {useRecoilValue} from 'recoil';
import { 
  actionItemList
} from '../actionitems/ActionItemState';

import AssignOwnersDialog from '../actionitems/AssignOwnersDialog';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  tableHead: {
    fontWeight: 'bold',
    margin: theme.spacing(1)
  },
  actionItemTitle: {
    width: '100%'
  }
}));

const ActionItemStatus = (props) => {
  const classes = useStyles();

  const actionItems = useRecoilValue(actionItemList);

  const [assignOwnersDialogOpen, setAssignOwnerDialogOpen] = useState(false);
  const [actionItemType, setActionItemType] = useState(ActionItemType.All);
  const [actionItemCounts, setActionItemCounts] = useState({});

  useEffect(() => {
    if (actionItems.length !== 0) {  
      const noHandlers = actionItems.filter(item => {
        return item.owner === NonChoice;
      });

      const counts = {
        actionItemCount: actionItems.length,
        noHandlerCountAccount : noHandlers.filter((item) => item.type.indexOf(ActionItemType.Account) === 0).length,
        noHandlerCountAsset : noHandlers.filter((item) => item.type.indexOf(ActionItemType.Assets) === 0).length,
        noHandlerCountContact : noHandlers.filter((item) => item.type.indexOf(ActionItemType.Contacts) === 0).length,
        noHandlerCountInsurance : noHandlers.filter((item) => item.type.indexOf(ActionItemType.Insurance) === 0).length,
        noHandlerCountLiability : noHandlers.filter((item) => item.type.indexOf(ActionItemType.Liability) === 0).length,
        noHandlerCountOtherItems : noHandlers.filter((item) => item.type.indexOf(ActionItemType.OtherItems) === 0).length,        
        noHandlerCountSubscriptions : noHandlers.filter((item) => item.type.indexOf(ActionItemType.Subscriptions) === 0).length,
        noHandlerCountDocuments : noHandlers.filter((item) => item.type.indexOf(ActionItemType.Documents) === 0).length,
        noHandlerCountGeneral : noHandlers.filter((item) => item.type.indexOf(ActionItemType.General) === 0).length,
        noDueDatesCount : actionItems.filter((item) => item.dueDate.indexOf(NonChoice) === 0).length
      }
      setActionItemCounts(counts);
    }
  }, [actionItems]);

  let noDueDatesMsg = 'All items have due dates';
  if (actionItemCounts.noDueDatesCount) {
    noDueDatesMsg = actionItemCounts.noDueDatesCount + " items with no due dates"
  }

  let noOwnersMsg = 'All items have assigned owners';

  let noOwnersMsgAccount, 
      noOwnersMsgContact, 
      noOwnersMsgAsset, 
      noOwnersMsgLiability, 
      noOwnersMsgInsurance, 
      noOwnersMsgOtherItems,
      noOwnersMsgServices,
      noOwnersMsgSubscriptions,
      noOwnersMsgDocuments,
      noOwnersMsgGeneral;
  
  // Each domain has its own count and message
  const commonPhrase = " items with no assigned handler";
  if (actionItemCounts.noHandlerCountAccount && actionItemCounts.noHandlerCountAccount > 0) {
    noOwnersMsgAccount = "Accounts: " + actionItemCounts.noHandlerCountAccount + commonPhrase;
  } else {
    noOwnersMsgAccount = "Accounts: " + noOwnersMsg;
  }
  if (actionItemCounts.noHandlerCountAsset && actionItemCounts.noHandlerCountAsset > 0) {
    noOwnersMsgAsset = "Assets: " + actionItemCounts.noHandlerCountAsset + commonPhrase;
  } else {
    noOwnersMsgAsset = "Assets: " + noOwnersMsg;
  }
  if (actionItemCounts.noHandlerCountContact && actionItemCounts.noHandlerCountContact > 0) {
    noOwnersMsgContact = "Contacts: " + actionItemCounts.noHandlerCountContact + commonPhrase;
  } else {
    noOwnersMsgContact = "Contacts: " + noOwnersMsg;
  }
  if (actionItemCounts.noHandlerCountInsurance && actionItemCounts.noHandlerCountInsurance > 0) {
    noOwnersMsgInsurance = "Insurance: " + actionItemCounts.noHandlerCountInsurance + commonPhrase;
  } else {
    noOwnersMsgInsurance = "Insurance: " + noOwnersMsg;
  }
  if (actionItemCounts.noHandlerCountLiability && actionItemCounts.noHandlerCountLiability > 0) {
    noOwnersMsgLiability = "Liabilities: " + actionItemCounts.noHandlerCountLiability + commonPhrase;
  } else {
    noOwnersMsgLiability = "Liabilities: " + noOwnersMsg;
  }
  if (actionItemCounts.noHandlerCountOtherItems && actionItemCounts.noHandlerCountOtherItems > 0) {
    noOwnersMsgOtherItems = "Other Items: " + actionItemCounts.noHandlerCountOtherItems + commonPhrase;
  } else {
    noOwnersMsgOtherItems = "Other Items: " + noOwnersMsg;
  }
  if (actionItemCounts.noHandlerCountServices && actionItemCounts.noHandlerCountServices > 0) {
    noOwnersMsgServices = "Services: " + actionItemCounts.noHandlerCountServices + commonPhrase;
  } else {
    noOwnersMsgServices = "Services: " + noOwnersMsg;
  }
  if (actionItemCounts.noHandlerCountSubscriptions && actionItemCounts.noHandlerCountSubscriptions > 0) {
    noOwnersMsgSubscriptions = "Subscriptions: " + actionItemCounts.noHandlerCountSubscriptions + commonPhrase;
  } else {
    noOwnersMsgSubscriptions = "Subscriptions: " + noOwnersMsg;
  } 
  if (actionItemCounts.noHandlerCountDocuments && actionItemCounts.noHandlerCountDocuments > 0) {
    noOwnersMsgDocuments = "Documents: " + actionItemCounts.noHandlerCountDocuments + commonPhrase;
  } else {
    noOwnersMsgDocuments = "Documents: " + noOwnersMsg;
  } 
  if (actionItemCounts.noHandlerCountGeneral && actionItemCounts.noHandlerCountGeneral > 0) {
    noOwnersMsgGeneral = "General: " + actionItemCounts.noHandlerCountGeneral + commonPhrase;
  } else {
    noOwnersMsgGeneral = "General: " + noOwnersMsg;
  } 
  
  const handleAssignOwnersDialogOpen = (type) => {
    setActionItemType(type)
    setAssignOwnerDialogOpen(true);
  }
  
  const handleAssignOwnersDialogClose = () => {
    setAssignOwnerDialogOpen(false);
  }

  return (
    <div className={classes.root}>
      
      { actionItemCounts.actionItemCount === undefined || actionItemCounts.actionItemCount === 0 ? (
        <Typography align="center">
          No action items found
        </Typography>
      ): (
      <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={6}
      >
        <Grid item>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="no owner table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography className={classes.tableHead} align= "left" variant="subtitle1" color="initial">Items without handlers</Typography>        
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actionItemCounts.noHandlerCountAccount > 0 &&
                  <TableRow key={'no-owners-account'}>
                    <TableCell>{noOwnersMsgAccount}</TableCell>       
                    <TableCell>
                      <Button onClick={() => handleAssignOwnersDialogOpen(ActionItemType.Account)}>Fix</Button>
                    </TableCell>  
                  </TableRow>
                }
                {actionItemCounts.noHandlerCountAsset > 0 &&
                  <TableRow key={'no-owners-asset'}>
                    <TableCell>{noOwnersMsgAsset}</TableCell>   
                    <TableCell>
                      <Button onClick={() => handleAssignOwnersDialogOpen(ActionItemType.Asset)}>Fix</Button>
                    </TableCell> 
                  </TableRow>
                }
                {actionItemCounts.noHandlerCountContact > 0 &&
                  <TableRow key={'no-owners-contact'}>
                    <TableCell>{noOwnersMsgContact}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleAssignOwnersDialogOpen(ActionItemType.Contacts)}>Fix</Button>
                    </TableCell>                    
                  </TableRow>
                }
                {actionItemCounts.noHandlerCountInsurance > 0 &&
                  <TableRow key={'no-owners-insurance'}>
                    <TableCell>{noOwnersMsgInsurance}</TableCell>
                    
                    <TableCell>
                      <Button onClick={() => handleAssignOwnersDialogOpen(ActionItemType.Insurance)}>Fix</Button>
                    </TableCell>
                    
                  </TableRow>
                }
                {actionItemCounts.noHandlerCountLiability > 0 &&
                  <TableRow key={'no-owners-liability'}>
                    <TableCell>{noOwnersMsgLiability}</TableCell>
                    
                    <TableCell>
                      <Button onClick={() => handleAssignOwnersDialogOpen(ActionItemType.Liabilities)}>Fix</Button>
                    </TableCell>
                    
                  </TableRow>
                }
                {actionItemCounts.noHandlerCountOtherItems > 0 &&
                  <TableRow key={'no-owners-otheritems'}>
                    <TableCell>{noOwnersMsgOtherItems}</TableCell>
                    
                    <TableCell>
                      <Button onClick={() => handleAssignOwnersDialogOpen(ActionItemType.OtherItems)}>Fix</Button>
                    </TableCell>
                    
                  </TableRow>
                }
                {actionItemCounts.noHandlerCountServices > 0 &&
                  <TableRow key={'no-owners-services'}>
                    <TableCell>{noOwnersMsgServices}</TableCell>
                    
                    <TableCell>
                      <Button onClick={() => handleAssignOwnersDialogOpen(ActionItemType.Services)}>Fix</Button>
                    </TableCell>
                    
                  </TableRow>
                }
                {actionItemCounts.noHandlerCountSubscriptions > 0 &&
                  <TableRow key={'no-owners-subscriptions'}>
                    <TableCell>{noOwnersMsgSubscriptions}</TableCell>
                    
                    <TableCell>
                      <Button onClick={() => handleAssignOwnersDialogOpen(ActionItemType.Subscriptions)}>Fix</Button>
                    </TableCell>
                    
                  </TableRow>
                }
                {actionItemCounts.noHandlerCountDocuments > 0 &&
                  <TableRow key={'no-owners-documents'}>
                    <TableCell>{noOwnersMsgDocuments}</TableCell>
                    
                    <TableCell>
                      <Button onClick={() => handleAssignOwnersDialogOpen(ActionItemType.Documents)}>Fix</Button>
                    </TableCell>
                    
                  </TableRow>
                }
                {actionItemCounts.noHandlerCountGeneral > 0 &&
                  <TableRow key={'no-owners-general'}>
                    <TableCell>{noOwnersMsgGeneral}</TableCell>
                    
                    <TableCell>
                      <Button onClick={() => handleAssignOwnersDialogOpen(ActionItemType.General)}>Fix</Button>
                    </TableCell>
                    
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="due date table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography className={classes.tableHead} align= "left" variant="subtitle1" color="initial">Items without due dates</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={'no-dates'}>  
                  <TableCell>{noDueDatesMsg}</TableCell>        
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid> 
      </Grid>
      </>
      )}
      {assignOwnersDialogOpen ? (
        <AssignOwnersDialog 
          open = {assignOwnersDialogOpen}
          actionItemType = {actionItemType}
          handleClose = {handleAssignOwnersDialogClose}
        />
      ) : null}
    </div>
  );
}



export default ActionItemStatus;
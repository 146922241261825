import React from 'react';

import { InterviewProvider } from './InterviewContext';

import InterviewController from './InterviewController';

const Interview = (props) => {

  return (
    <InterviewProvider>
        <InterviewController />
    </InterviewProvider>
  );
}

export default (Interview);
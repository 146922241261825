import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

// content
import Markdown from "../utilties/Markdown";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const GettingStarted = (props) => {
  const classes = useStyles();

  const [content, setContent] = useState("");
  const [keyConceptsContent, setKeyConceptsContent] = useState("");

  const getStoredContent = (receiveFn1, receiveFn2) => {
    // get 'getting started'
    fetch(process.env.PUBLIC_URL + "/helpcontent/gettingstarted.md")
      .then((response) => response.text())
      .then((text) => {
        // get 2
        receiveFn1(text);
        // get 'keyconcepts'
        fetch(process.env.PUBLIC_URL + "/helpcontent/keyconcepts.md")
          .then((response) => response.text())
          .then((text) => {
            receiveFn2(text);
          });
      });
  };

  const receiveGettingStartedContentFn = (text) => {
    setContent(text);
  };

  const receiveKeyConceptsContentFn = (text) => {
    setKeyConceptsContent(text);
  };

  useEffect(() => {
    if (content === "" || keyConceptsContent === "") {
      getStoredContent(
        receiveGettingStartedContentFn,
        receiveKeyConceptsContentFn
      );
    }
  }, [content, keyConceptsContent]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        {props.needFullPage && (
          <Typography variant="h5" align="center" gutterBottom>
            Getting Started
          </Typography>
        )}

        <Markdown key={1}>{content}</Markdown>

        <Markdown key={2}>{keyConceptsContent}</Markdown>
      </div>
    </React.Fragment>
  );
};

GettingStarted.defaultProps = {
  needFullPage: true,
};

GettingStarted.propTypes = {
  needFullPage: PropTypes.bool,
};

export default GettingStarted;

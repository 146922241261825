import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import cloneDeep from "lodash.clonedeep";

import { Formik, Form, Field } from "formik";

// wrappers for material ui components for formik
import { TextField, CheckboxWithLabel } from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";

import { FormDialogBase } from "../../shared/formhelpers/FormDialogBase";

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },
  "& .MuiTextField-root": {
    margin: theme.spacing(1),
    width: 200,
  },

  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const UserAddEditFormCore = (props) => {
  const classes = useStyles();
 
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    // set up what we need
    if (props.user) {
      let ourValues = cloneDeep(props.user);
      ourValues.plansToLogin = true;
      setData(ourValues);
    }
  }, [props.user]);

  // Attach this to your <Formik>
  const formRef = useRef();

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleResetClick = () => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
  };

  const handleClickOpen = () => {
    console.log("unexpected");
  };

  const handleSave = (values) => {
    // just pass this along
    props.handleSave(values);   
  }

  const handleValidate = (values) => {
    const errors = {};

    if (values.plansToLogin) {
      if (!values.email) {
        errors.email = "Email is required to login or receive email";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
    }

    if (values.firstName.length === 0) {
      errors.firstName = "First name is required";
    }
    if (values.lastName.length === 0) {
      errors.lastName = "Last name is required";
    }

    return errors;
  };

  const body = (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={data}
      validate={handleValidate}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        // actually save the form
        handleSave(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="firstName"
              type="text"
              label="First name"
              autoFocus
              className={classes.textField}
              margin="normal"
              helperText={
                touched.firstName
                  ? errors.firstName
                  : "Provide the first name of this user"
              }
              error={touched.firstName && Boolean(errors.firstName)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="lastName"
              type="text"
              label="Last name"
              className={classes.textField}
              margin="normal"
              helperText={
                touched.lastName
                  ? errors.lastName
                  : "Provide the last name of this user"
              }
              error={touched.lastName && Boolean(errors.lastName)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="email"
              type="email"
              label="Email"
              className={classes.textField}
              margin="normal"
              helperText={
                touched.email
                  ? errors.email
                  : "Provide the email address of this user - optional if user has NO plans to login or receive email"
              }
              error={touched.email && Boolean(errors.email)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="plansToLogin"
              indeterminate={false}
              Label={{
                label: "This user plans to login and work on this plan",
              }}
            />
          </FormControl>
          {props.allowOwnerControl ? (
            <FormControl className={classes.formControl}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="isOwner"
                indeterminate={false}
                Label={{ label: "This user is a plan owner" }}
              />
            </FormControl>
          ) : null}
        </Form>
      )}
    </Formik>
  );

  return (
    <FormDialogBase
      open={props.open}
      title={props.title}
      body={body}
      submitEnabled={submitEnabled}
      handleAddClickOpen={handleClickOpen}
      handleClose={props.handleClose}
      handleReset={handleResetClick}
      handleSubmit={handleSubmitClick}
    />
  );
};

UserAddEditFormCore.defaultProps = {
  allowOwnerControl: false,
};

UserAddEditFormCore.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  allowOwnerControl: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default UserAddEditFormCore;

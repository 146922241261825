import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  bodyMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const AccountSnip = (props) => {
  const classes = useStyles();

  return (
     <div className={classes.root}>
      <Typography variant="body2" gutterBottom color="initial">
        'Accounts' represent the real-world financial accounts you have.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        That includes bank and savings accounts, credit card accounts, investment accounts and 401K and other retirement accounts.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        It also includes special accounts, like TreasuryDirect accounts that hold US savings bonds.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        When you add an account, include enough information for others to contact the account institution to transfer funds and/or close the account.
      </Typography>
     </div>
  );
}

export default AccountSnip;
import React from "react";

import { makeStyles } from '@material-ui/core/styles';

import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell"
import MuiTablePagination from "@material-ui/core/TablePagination";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  footerStyle: {
    display:'flex', 
    justifyContent: 'center',
    padding: '12px 24px 12px 24px'
  }
}));

const CustomerUserTableFooter =(props) => {

  const classes = useStyles();

  // const handleRowChange = event => {
  //   props.changeRowsPerPage(event.target.value);
  // };

  // const handlePageChange = (_, page) => {
  //   props.changePage(page);
  // };

  const { count, textLabels, rowsPerPage, page } = props;

  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.footerStyle} colSpan={1000}>
          <Typography variant="body1" gutterBottom>
            Number of users in your plan: {count}
          </Typography>
         
          {/* <MuiTablePagination
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={textLabels.rowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${textLabels.displayRows} ${count}`}
            backIconButtonProps={{
              'aria-label': textLabels.previous,
            }}
            nextIconButtonProps={{
              'aria-label': textLabels.next,
            }}
            rowsPerPageOptions={[10,20,100]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowChange}
          /> */}
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

export default CustomerUserTableFooter;
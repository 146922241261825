 const LetterRoutes = {
  LandingRoute: "/",
  LandingWithPurposeRoute: "/landing/interview",
  OverviewRoute: "/overview",
  AccountsRoute: "/accounts",
  AssetsRoute: "/assets",
  ContactsRoute: "/contacts",
  DocumentsRoute: "/documents",
  LiabilitiesRoute: "/liabilities",
  InsuranceRoute: "/insurance",
  SubscriptionsRoute: "/subscriptions",
  OtherItemsRoute: "/otheritems",
  ServicesRoute: "/services",
  SettingsRoute: "/settings",
  IssuesRoute: "/issues",
  HelpRoute: "/help",
  GettingStartedRoute: "/gettingstarted",
  PrivacyTermsRoute: "/privacyandterms",
  SignupRoute: "/signup",
  LoginRoute: "/login",
  LogoutRoute: "/logout",
  InterviewRoute: "/interview",
  GlobalActionItemsRoute: "/globalaction"
}
export default LetterRoutes;
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Slide from "@material-ui/core/Slide";

import { useFamilyContext } from "../../../context/FamilyContext";

import {isFileTypeVideo} from "../../utilties/MimeTypeHelper";

import { signedUrlEndpoint } from "../../../api/AttachmentApi";

import ViewWrapper from "./ViewWrapper";

const SizeWarning = "Be patient! This file will take a loooonng time to download."

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  appBar: {
    position: "static",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iconButton: {
    fill: "white",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

async function getAttachmentUrl(userRealm, fileName) {
  let promise = new Promise((resolve, reject) => {
    axios
      .get(signedUrlEndpoint, {
        params: {
          realm: userRealm,
          originalName: fileName,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;
}

const ContentViewer = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [attachment, setAttachment] = useState(null);
  const [signedUrl, setSignedUrl] = useState(null);
  const [sizeWarningNeeded, setSizeWarningNeeded] = useState(false);

  useEffect(() => {
    if (props.item) {
      const attachmentData = props.item.attachments[0];
      if (attachmentData) {
        console.log(
          "Setting attachment with mimetype: %s",
          attachmentData.mimeType
        );
        console.log(attachmentData);
        setAttachment(attachmentData);

        // figure out if this will take a long time to download
        if (isFileTypeVideo(attachmentData.mimeType)) {
          setSizeWarningNeeded(true);
        }

        // need to get a signed url for use by the content viewers
        getAttachmentUrl(
          familyContext.familyRealm.id,
          attachmentData.originalName
        )
          .then((response) => {
            setSignedUrl(response.results.signedUrl);
            console.log(response);
          })
          .catch((error) => {
            console.log(error.toJSON());
          });
      }
    }
  }, [props]);

  if (attachment === null) {
    return null;
  }

  /*
   id
  userRealm
  uri
  type
  domainId
  name
  mimeType
  originalName
  */

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            View Attachment
          </Typography>
          <Tooltip title={"Download file"}>
            <IconButton
              aria-label="download file"
              color="inherit" 
              href={signedUrl}
            >
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12}>
            {sizeWarningNeeded ? (
              <Typography variant="h1" color="initial" gutterBottom>
                  {SizeWarning}
              </Typography> 
            ): null}
          </Grid>
          <Grid item xs={12}>
            {attachment && attachment.description ? (
              <Grid item xs={12}>
                <Typography variant="body2" color="initial" gutterBottom>
                  {attachment.description}
                </Typography> 
              </Grid>
            ): null}
          </Grid>
          {signedUrl !== null ? (
            <Grid item xs={12}>
              <ViewWrapper attachment={attachment} contentUrl={signedUrl} />
            </Grid>
           ) : null}
        </Grid>    
      </DialogContent>
    </Dialog>
  );
};

ContentViewer.propTypes = {
  open: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ContentViewer;

import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Editor (props) {
 
  const [html, setHtml] = useState('');
  const reactQuillRef = useRef();
  
  const handleChange = (content, delta, source, editor) => {
    // don't trigger updates if the source of changes is not the user
    if (source !== 'user') {
      return;
    }
    props.onChange(content, delta, editor);
  	setHtml(content);
  }
  
  const modules = {
    toolbar: { 
      container: 
        [
          [{size: []}],
          [{'font': [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{'list': 'ordered'}, {'list': 'bullet'}, 
          {'indent': '-1'}, {'indent': '+1'}],
          ['clean']
        ],
      },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    history: {
      delay: 2000,
      maxStack: 500
    }
  }   
  
  return (
    
    <ReactQuill 
      ref={reactQuillRef}
      onChange={handleChange}
      defaultValue={props.initialContent}
      modules={modules}
      formats={Editor.formats}
      bounds={'.app'}
      placeholder={props.placeholder}
    />     
  )
}


Editor.formats = [
  'size', 'font', 
  'bold', 'italic', 'underline', 'strike', 
  'list', 'bullet', 'indent',
]

/* 
 * PropType validation
 */
Editor.propTypes = {
  placeholder: PropTypes.string,
  initialContent: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}



import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

import {
  identifyContactsImportSource,
  getContactsRows,
} from "./DataMapper";

import { useRecoilState } from "recoil";
import { rowsChosen } from "./ImportState";

import DataTable from "../shared/datatable/DataTable";

const headCells = [
  { id: "fullname", numeric: false, disablePadding: false, label: "Name" },
  { id: "type", numeric: false, disablePadding: false, label: "Type" },
  // { id: "company", numeric: false, disablePadding: false, label: "Company" },
  { id: "phone", numeric: false, disablePadding: false, label: "Phones" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "address", numeric: false, disablePadding: false, label: "Address" },
  { id: "notes", numeric: false, disablePadding: false, label: "Notes" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontStyle: "italic"
  }
}));

const ContactsChooser = (props) => {
  const classes = useStyles();

  const [displayTable, setDisplayTable] = useRecoilState(rowsChosen);
  const [importFields, setImportFields] = useState([]);

  useEffect(() => {
    // this is the data we'll be using
    if (props.data) {
      const fetchedData = props.data.data;
    
      // now, need to shape this data to fit our table
      let newRows = [];
      const source = identifyContactsImportSource(props.data);
      newRows = getContactsRows(source, fetchedData);

      // augment each row with an id
      newRows = newRows.map((row, index) => {
        row.id = (index + 1).toString();
        return row;
      });
      setDisplayTable(newRows);

      setImportFields(props.data.meta.fields);
      console.log("Meta (in chooser");
      console.log(props.data.meta.fields);
    }
  }, [props.data]);

  const handleDelete = (ids) => {
    console.log("Delete");
    const newTable = displayTable.filter((row) => {
      return ids.indexOf(row.id) === -1;
    });
    setDisplayTable(newTable);
  };

  const renderColumns = (row) => {

    const phoneItem = (phone) => {
      let item = phone.value;
      if (phone.type !== undefined && phone.type !== "") {
        item = item.concat(" (" + phone.type + ")")
      }
      return item;  
    }

    const emailItem = (email) => {
      let item = email.value;
      if (email.type !== undefined && email.type !== "") {
        item = item.concat(" (" + email.type + ")")
      }
      return item;
    }

    return (
      <>
        <TableCell>{row.fullName}</TableCell>
        <TableCell>{row.type}</TableCell>
        {/* <TableCell>{row.company}</TableCell> */}
        <TableCell>
          {row.phones.map ((phone, index) => (
            <Typography key = {index} variant="body2">{phoneItem(phone)}</Typography>
          ))}
        </TableCell>
        <TableCell>
          {row.emails.map ((email, index) => (
            <Typography key = {index} variant="body2">{emailItem(email)}</Typography>
          ))}
         </TableCell>
         <TableCell>
          {row.addresses.map ((address, index) => (
            <Typography key = {index} variant="body2">{address.street + ", " + address.city + ", " + address.state}</Typography>
          ))}
         </TableCell>
        <TableCell>
          <Typography variant="body2">{row.notes}</Typography>
        </TableCell>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant = {"body1"} className = {classes.message}>
        Select, then delete, any contacts that you do NOT wish to import.
      </Typography>
      <DataTable
        rows={displayTable}
        size = "small"
        headerCells={headCells}
        renderColumns={renderColumns}
        orderBy={"name"}
        handleDelete={handleDelete}
      />
    </div>
  );
};

ContactsChooser.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactsChooser;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import LTRCommonLayout from "../shared/domains/LTRCommonLayout";
import LTRCommonNameList, {
  RefreshReason,
} from "../shared/domains/LTRCommonNameList";
import LTRCommonDataTable from "../shared/domains/LTRCommonDataTable";
import LTRDomainObjectInfo from "../shared/domains/LTRDomainObjectInfo";
import LTRSnackbar, {
  buildAddMessage,
  buildEditMessage,
  buildDeleteMessage,
} from "../utilties/LTRSnackbar";

import { useQuery, gql } from "@apollo/client";
import { listDocuments } from "../../graphql/queries";
import { useDocumentDelete } from "../../api/APIHooks";

import { DomainType, DomainTypeSingular, DocumentType } from "../../Enums";

import AddEditDocumentsForm from "./forms/AddEditDocumentForm";
import DocumentActionPanel from "./forms/DocumentActionPanel";

import EditNoteDialog from "./notes/EditNoteDialog";
import NoteViewer from "./notes/NoteViewer";

import { sortListByName } from "../utilties/SortFunctions";

import { prepareTableRowInfo } from "./DocumentInfo";

import { useFamilyContext } from "../../context/FamilyContext";

import { useSnackbar } from "../utilties/snackbarUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
}));

const Documents = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [documents, setDocuments] = useState(null);

  const [editFormOpen, setEditFormOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [infoTableItem, setInfoTableItem] = useState(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [noteViewerOpen, setNoteViewerOpen] = useState(false);

  const [visibleList, setVisibleList] = useState([]);

  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  const { loading, error, data, refetch } = useQuery(gql(listDocuments), {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  const processReceivedData = (data) => {
    setDocuments(data);
  };

  const [deleteOneDocument] = useDocumentDelete(familyContext.familyRealm.id, (data) => {
      console.log(data);
    }
  );

  useEffect(() => {
    const onCompleted = (data) => {
      processReceivedData(data.item);
    };
    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    // just one reason we're here: if items change we need to adjust
    if (documents) {
      const sortedList = sortListByName(documents);
      setVisibleList(sortedList);
    }
  }, [documents]);

  const handleSearch = (searchTerm) => {
    if (documents.length === 0) {
      return [];
    }

    let displayList;
    if (searchTerm === null) {
      displayList = [...documents];
    } else {
      displayList = documents.filter((item) => {
        const testName = item.name ? item.name.toLowerCase() : "";
        const testType = item.type ? item.type.toLowerCase() : "";
        const testDescription = item.description
          ? item.description.toLowerCase()
          : "";
        const testLocation = item.location ? item.location.toLowerCase() : "";
        const testNotes = item.notes ? item.notes.toLowerCase() : "";
        const againstSearchTerms = searchTerm ? searchTerm.toLowerCase() : "";

        // test the relevant fields
        return (
          testName.includes(againstSearchTerms) ||
          testType.includes(againstSearchTerms) ||
          testDescription.includes(againstSearchTerms) ||
          testLocation.includes(againstSearchTerms) ||
          testNotes.includes(againstSearchTerms)
        );
      });
    }
    setVisibleList(displayList);
  };

  const handleAddNotification = (item) => {
    if (item) {
      openSnackbar(buildAddMessage(item.name));
    }
  };

  const handleEditNotification = (item) => {
    if (item) {
      openSnackbar(buildEditMessage(item.name));
    }
  };

  // when a note is edited, we have to update the document
  const handleNoteEditNotification = (item) => {
    handleEditNotification(item);
  };

  const handleEditOpen = (selectedItem) => {
    setSelectedItem(selectedItem);
    setEditFormOpen(true);
  };

  const handleEditClose = () => {
    setSelectedItem(null);
    setEditFormOpen(false);
  };

  const handleDelete = (selected) => {
    deleteOneDocument({ variables: { id: selected.id } })
      .then((result) => {
        openSnackbar(buildDeleteMessage(result.data.item.name));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMultiDeleteNotification = (selected) => {
    const aList = documents.filter((item) => {
      return selected.includes(item.id) ? false : true;
    });

    let userMessage = "";
    const numItemsDeleted = selected.length;
    if (numItemsDeleted > 1) {
      userMessage = numItemsDeleted + " items deleted successfully";
      openSnackbar(userMessage);
    } else {
      userMessage = "Item deleted successfully";
      openSnackbar(userMessage);
    }
    setDocuments(aList);
  };

  const handleEditFromId = (selectedId) => {
    const aList = documents.filter((item) => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!

    // call the handleEdit open handler
    handleEditOpen(aList[0]);
  };

  const handleRefresh = (selected, refreshReason) => {
    console.log("Selected:");
    console.log(selected);

    switch (refreshReason) {
      case RefreshReason.UploadAttachment:
        console.log("Refresh - upload");
        refetch();
        break;
      case RefreshReason.DeleteAttachment:
        console.log("Refresh - delete");

        const list = documents.filter((i) => i.id !== selected.id);
        selected.attachments = [];
        list.push(selected);
        processReceivedData(list);
        break;
      default:
        console.log("NOT A VALID PATH");
        break;
    }
  };

  const handleShowInfo = (selectedId) => {
    const aList = documents.filter((item) => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!
    const item = aList[0];
    const tableInfo = prepareTableRowInfo(item);

    setInfoTableItem(tableInfo);
    setSelectedItem(aList[0]);
    setShowInfoDialog(true);
  };

  const handleShowInfoClose = () => {
    setSelectedItem(null);
    setShowInfoDialog(false);
  };

  const checkForNotes = (selected) => {
    return selected.note.length > 0;
  };

  const checkForAttachments = (selected) => {
    return selected.attachments.length > 0;
  };

  const handleViewChange = (oldView, newView) => {
    // whenever we get a view change, we need to cancel all filtering, searching and restore the display list
    handleSearch(null);
  };

  const handleViewNote = (selected) => {
    setSelectedItem(selected);
    setNoteViewerOpen(true);
  };

  const handleViewNoteClose = () => {
    setNoteViewerOpen(false);
  };

  const myExtensions = (selected) => {
    let content = null;
    if (selected.type === DocumentType.Note) {
      content = (
        <MenuItem key={"View"} onClick={() => handleViewNote(selected)}>
          <ListItemIcon className={classes.icon}>
            <OpenInNewIcon />
          </ListItemIcon>
          <ListItemText primary={"View"} />
        </MenuItem>
      );
    }

    return content;
  };

  // are we ready?
  if (documents === null) {
    return <div>Loading ...</div>;
  }

  return (
    <React.Fragment>
      <LTRCommonLayout
        domain={DomainType.Documents}
        domainSingular={DomainTypeSingular.Documents}
        handleViewChange={handleViewChange}
        commonNameList={
          <div className={classes.root}>
            <LTRCommonNameList
              list={visibleList}
              domain={DomainType.Documents}
              domainSingular={DomainTypeSingular.Documents}
              hasAttachments={checkForAttachments}
              hasNotes={checkForNotes}
              addComponent={
                <AddEditDocumentsForm
                  handleAdd={handleAddNotification}
                  handleEdit={handleEditNotification}
                  handleReceiveData={processReceivedData}
                />
              }
              menuExtensions={myExtensions}
              handleDelete={handleDelete}
              handleEdit={handleEditOpen}
              handleInfo={handleShowInfo}
              handleSearch={handleSearch}
              handleRefresh={handleRefresh}
            />
          </div>
        }
        commonNameTable={
          <LTRCommonDataTable
            domain={DomainType.Documents}
            domainColumns={[
              { name: "name", label: "Name" },
              { name: "type", label: "Type" },
              { name: "description", label: "Description" },
              { name: "digital", label: "Digital" },
              { name: "location", label: "Location" },
              { name: "notes", label: "Notes" },
            ]}
            dataItems={visibleList}
            handleMultiDeleteNotification={handleMultiDeleteNotification}
            handleEdit={handleEditFromId}
          />
        }
        actionPanel={
          <DocumentActionPanel
            handleAddNotification={handleAddNotification}
            handleReceiveData={processReceivedData}
          />
        }
      />
      {editFormOpen &&
      selectedItem &&
      selectedItem.type !== DocumentType.Note ? (
        <AddEditDocumentsForm
          open={editFormOpen}
          selected={selectedItem}
          handleAdd={handleAddNotification}
          handleEdit={handleEditNotification}
          handleClose={handleEditClose}
          handleReceiveData={processReceivedData}
        />
      ) : null}
      {editFormOpen &&
      selectedItem &&
      selectedItem.type === DocumentType.Note ? (
        <EditNoteDialog
          open={editFormOpen}
          selected={selectedItem}
          handleClose={handleEditClose}
          handleSave={handleNoteEditNotification}
          handleReceiveData={processReceivedData}
        />
      ) : null}
      {showInfoDialog ? (
        <LTRDomainObjectInfo
          open={showInfoDialog}
          title={selectedItem.name}
          tableData={infoTableItem}
          handleClose={handleShowInfoClose}
        />
      ) : null}
      {noteViewerOpen ? (
        <NoteViewer
          open={noteViewerOpen}
          note={selectedItem.note[0]}
          handleClose={handleViewNoteClose}
        />
      ) : null}
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    </React.Fragment>
  );
};

Documents.propTypes = {};

export default Documents;

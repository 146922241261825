import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, useLocation, useHistory } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { currentUserState } from "../../context/UserState";

//import { useAuthHelper } from "../../auth/AuthHook";
//import { useFetchAppUser } from "../../api/UserHooks";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import RealmInfoStorage, { LastPlanNotFound } from "../../api/RealmInfoStorage";
import Copyright from "./Copyright";

import Loading from "../shared/Loading";

import { APPNAME } from "../../Enums";

import LetterRoutes from "../../Routes";

import { UserContext, USERFIELD_NOT_SET } from "../../context/UserContext";
import { FamilyContext } from "../../context/FamilyContext";
import { AuthContext } from "../../context/AuthContext";

import LandingNoRealms from './LandingNoRealms';
import LandingWithRealms from './LandingWithRealms';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  title: {
    flexGrow: 1,
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100vh",
    overflow: "auto",
  },

  buttonContainer: {
    width: "100%",
    textAlign: "center",
    display: "block",
  },

  media: {
    width: "40%",
    height: "40%",
  },

  button: {
    marginTop: theme.spacing(1.5),
  },

  restrictions: {
    marginTop: theme.spacing(2),
  },
}));

const Landing = (props) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const familyContext = useContext(FamilyContext);
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
 
  
  // NOTE: testing an alternate approach

  // this
  const appUser = useRecoilValue(currentUserState);
 
  // or this
  // const { loading, appUser } = useFetchAppUser();

  // this will cause the load to happen
  //const { loading } = useFetchAppUser();

  let subState = location.state !== undefined ? location.state.sub : ""
  
  const setupUser = (userToLogin, realm) => {
    // notify the context that we are ready to log in
    let user = {
      id: userToLogin.id,
      authId: userToLogin.authId,
      loginName: userToLogin.loginName,
      firstName: userToLogin.firstName,
      lastName: userToLogin.lastName,
      email: userToLogin.email,
      isOwner: realm !== USERFIELD_NOT_SET ? realm.isOwner : false,
      userRealm: realm !== USERFIELD_NOT_SET ? realm.realmId : realm,
    };

    // notify the user context that we have a login
    userContext.onLogin(user);

    // now, the family context
    if (realm !== USERFIELD_NOT_SET) {
      familyContext
        .setFamilyFromRealm(user.userRealm)
        .then((_) => {
          //console.log('Successfully set up a family');
          // notify the family context that we are logged in to a specific realm
          familyContext.onLogin(user.userRealm);
        })
        .catch((_) => {
          //console.log('Failed to set up the family');
        });
    }
  };

  useEffect(() => {
    if (appUser.id === undefined) {
      return;
    }

    if (authContext.isAuthenticated) { 
      let realm = USERFIELD_NOT_SET;
      if (appUser.id !== USERFIELD_NOT_SET ) {
        if (appUser.realms.length <= 1) {
          realm = appUser.realms.length
            ? appUser.realms[0]
            : USERFIELD_NOT_SET;
          setupUser(appUser, realm);
        } else {
          // more than two realms - find the last realm the user was active in and use it
          // otherwise, just pick the first one
          let realmToUse = appUser.realms[0];
          const lastRealm = RealmInfoStorage.getLastPlan();
          if (lastRealm !== LastPlanNotFound ) {
            realmToUse = lastRealm;
          } else {
            // save the plan we're using to storage
            RealmInfoStorage.setLastPlan(realmToUse);
          }
          setupUser(appUser, realmToUse)
        }
      }
    }
  }, [appUser, authContext.isAuthenticated]);

  const handleLogin = () => {
    history.push(LetterRoutes.LoginRoute);
  };

  // if we are authenticated but we don't have an authUser then we are still loading
  if ( authContext.isAuthenticated && authContext.authUser === null ) { 
    return <Loading />
  }

  // if we are authenicated and our user is not set up then we are still loading
  if (authContext.isAuthenticated && appUser.id === undefined ) { 
    // try to establish the app user by email
    userContext.establishUserByAuthEmail(authContext.authUser.email);
    return <Loading />
  }
 
  // NOTE: if we are authenticated - congratulate - first, find out if this user has a realm defined
  // if not, only choice is to create one: if they have one then offer to resume the plan OR create a new one.
  return (
    <div className={classes.root}>  
      {authContext.isAuthenticated && appUser.id !== USERFIELD_NOT_SET && appUser.realms.length > 0 && (
        <LandingWithRealms 
          authenticated = {authContext.isAuthenticated}
          appUser = {appUser}
        />
        
      )}
      {authContext.isAuthenticated && appUser.id !== USERFIELD_NOT_SET && appUser.realms.length === 0 && (
        <LandingNoRealms 
          authenticated = {authContext.isAuthenticated}
          appUser = {appUser}
        />
        
      )}
      {authContext.isAuthenticated && subState === 'interview' && (
        <Redirect
          to={{
            pathname: LetterRoutes.InterviewRoute,
            state: { from: props.location },
          }}
        />
      )}
      {!authContext.isAuthenticated && (
        <>
          <Typography variant="h4" align="center" gutterBottom>
            Welcome to {APPNAME}
          </Typography>
          <Typography variant="h5" align="center" gutterBottom>
            A place to create, organize and manage estate plans
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              onClick={handleLogin}
              color="primary"
              className={classes.button}
            >
              Get Started
            </Button>
          </div>
        </>
      )}

      {/* Footer */}
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <br />
          <br />
          <br />
          <Copyright />
        </Container>
      </footer>
      {/* End footer */}
    </div>
  );
};

Landing.propTypes = {
  location: PropTypes.object,
};

export default Landing;

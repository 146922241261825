import React, {useState, useEffect} from 'react';

import {makeStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import {useInterviewContext, CheckListAnswerIds} from './InterviewContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  answersSavedMsg: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold'
  },
}));

const LifeDeathCheckList = (props) => {
  const classes = useStyles();
  const interviewContext = useInterviewContext();
  
  const [haveMadeArrangements, setHaveMadeArrangements] =  useState(false);
  const [spouseHaveMadeArrangements, setSpouseHaveMadeArrangements] =  useState(false);
  
  useEffect(() => {
    
    const answers = interviewContext.answers;
    
      // we have some answers - see if we need to adjust our defaults
    const list = answers.map (answer => {
      if (answer.id === CheckListAnswerIds.el1) {
        setHaveMadeArrangements(answer.value);
      }
      if (answer.id === CheckListAnswerIds.el2) {
        setSpouseHaveMadeArrangements(answer.value);
      }
    })
  });

  const handleSetHaveMadeArrangements = (event) => {
    setHaveMadeArrangements(event.target.checked);

    const answer = {
      id: CheckListAnswerIds.el1,
      value: event.target.checked
    }
    interviewContext.updateAnswer(answer);
  }

  const handleSetSpouseHaveMadeArrangements = (event) => {
    setSpouseHaveMadeArrangements(event.target.checked);

    const answer = {
      id: CheckListAnswerIds.el2,
      value: event.target.checked
    }
    interviewContext.updateAnswer(answer);
  }

  const haveMadeArrangementsLabel = "I have made end of life arrangements";
  const endofLifeWishesLabel = "Describe what your final wishes are";
  
  return (
    <React.Fragment>
      <>
        <Typography align="left" variant="h6">
          Answer these questions from the perspective of the Plan Owner(s).
        </Typography>
        <Typography align="left" variant="h6" gutterBottom>
          Then press Next.
        </Typography>    
        <form>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormControlLabel 
              control={
                <Checkbox
                  checked={haveMadeArrangements}
                  onChange={handleSetHaveMadeArrangements}
                  name="arrangements1"
                  color="primary"
                />
              }
              label={haveMadeArrangementsLabel}
            />
          </FormControl>
          {interviewContext.planOwners.length > 1 ? (
            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel 
                control={
                  <Checkbox
                    checked={spouseHaveMadeArrangements}
                    onChange={handleSetSpouseHaveMadeArrangements}
                    name="arrangements2"
                    color="primary"
                  />
                }
                label={spouseHaveMadeArrangements}
              />
            </FormControl>
            ) : null
          }        
        </form>
      </>
    </React.Fragment>
  );
}

export default (LifeDeathCheckList);
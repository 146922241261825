import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const LTRCommonActionPanel = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={6}
      >
        <Grid item>
          <Typography variant={"h6"} gutterBottom={true}>
            Quick Action Panel
          </Typography>
        </Grid>
        <Grid item>
          {props.actionComponents}
        </Grid>
      </Grid>
    </div>
  )
}

LTRCommonActionPanel.propTypes = {
  actionComponents: PropTypes.object
}

export default (LTRCommonActionPanel)
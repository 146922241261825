import React, {useState} from 'react';
import {makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import GettingStarted from './GettingStarted';

import {APPNAME} from '../../Enums';

// content
import Markdown from '../utilties/Markdown';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Help = (props) => {
  const classes = useStyles();

  const [thingsToKnowContent, setThingsToKnowContent] = useState('');
  const [thingsToTryContent, setThingsToTryContent] = useState('');
  const [issues, setIssues] = useState('')
  const [faqContent, setFaqContent] = useState('');
  const [expanded, setExpanded] = useState(false);
  
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // content => thingstoknow.md
  const getThingsToKnowContent = (receiveFn) => {
    fetch(process.env.PUBLIC_URL + '/helpcontent/thingstoknow.md')
    .then(response => response.text())
    .then(text => {
      receiveFn(text);
    });
  }

  const receiveThingsToKnowContentFn = (content) => {
    setThingsToKnowContent(content)
  }

  // thingtotrycontent = things to try
  const getThingsToTryContent = (receiveFn) => {
    fetch(process.env.PUBLIC_URL + '/helpcontent/thingstotry.md')
    .then(response => response.text())
    .then(text => {
      receiveFn(text);
    });
  }

  const receiveThingsToTryContentFn = (content) => {
    setThingsToTryContent(content)
  }

  const getFaqContent = (receiveFn) => {
    fetch(process.env.PUBLIC_URL + '/helpcontent/faq.md')
    .then(response => response.text())
    .then(text => {
      receiveFn(text);
    });
  }

  const receiveFaqContentFn = (content) => {
    setFaqContent(content)
  }

  const getIssues = (receiveFn) => {
    fetch(process.env.PUBLIC_URL + '/helpcontent/issues.md')
    .then(response => response.text())
    .then(text => {
      receiveFn(text);
    });
  }

  const receiveIssues = (content) => {
    setIssues(content);
  }
  
  // fetch our content
  getThingsToKnowContent(receiveThingsToKnowContentFn);
  getFaqContent(receiveFaqContentFn);
  getThingsToTryContent(receiveThingsToTryContentFn);
  getIssues(receiveIssues);

  const title = APPNAME + ' Help';
  
  return(
    <React.Fragment>

      <div className={classes.root}>
        <div className = {classes.title}>
          <Typography variant ='h5' align='center'>{title}</Typography>
          <Typography variant = 'subtitle1' align='center'>Version: 1.0</Typography>
        </div>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>Getting Started</Typography>
            <Typography className={classes.secondaryHeading} gutterBottom>
              {"A guide to pages and operations in " + APPNAME }
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GettingStarted needFullPage = {false} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>Things to Try</Typography>
            <Typography className={classes.secondaryHeading} gutterBottom>
              Some common operations to get started with
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Markdown key={thingsToTryContent.substring(0, 40)}>
              {thingsToTryContent}
            </Markdown>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className={classes.heading}>FAQ</Typography>
            <Typography className={classes.secondaryHeading} gutterBottom>
              Frequenty asked questions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Markdown key={faqContent.substring(0, 40)}>
              {faqContent}
            </Markdown>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>How Things Work</Typography>
            <Typography className={classes.secondaryHeading} gutterBottom>
              {"Things to know about " + APPNAME + " and how it works"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Markdown key={thingsToKnowContent.substring(0, 40)}>
              {thingsToKnowContent}
            </Markdown>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Typography className={classes.heading}>Limitations and Known Problems</Typography>
            <Typography className={classes.secondaryHeading} gutterBottom>
              Things that need improvement
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Markdown key={issues.substring(0, 40)}>
              {issues}
            </Markdown>
          </AccordionDetails>
        </Accordion>
      </div> 
    </React.Fragment>
  )
}

export default (Help)
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  bodyMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const DocumentsSnip = (props) => {
  const classes = useStyles();

  return (
     <div className={classes.root}>
      <Typography variant="body2" gutterBottom color="initial">
        'Documents' represent important notes and documents that provide information about you and/or your estate.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        This might include a will/trust or a birth certificate. It could also be documentation of your 'power of attorney',
        or 'health care directive'. It also could include a deed to an asset (house or car).
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
         You can also add simple notes that provide clarity about your wishes ('make sure Teresa does NOT get my jewelry')
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        These are either descriptions of documents ('the pink slip for the Camry is in the closet') or actual electronic copies of documents
        that you have uploaded. 
      </Typography>
     </div>
  );
}

export default DocumentsSnip;
import React from 'react';
import PropTypes from 'prop-types';

import InfoModal from '../shared/modals/InfoModal';


const GeneralInfo = (props) => {
  
  const handleShowInfoClose = () => {
    
    // notify the client
    props.handleClose();
  }

  
  return (
    <React.Fragment>  
      {props.open ? (
        <InfoModal 
          title = "A title"
          open = {props.open}
          handleClose = {handleShowInfoClose}
          body = {
            'No info to provide'
          }
        />
        ): null
      }
    </React.Fragment>
  )
}

GeneralInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default (GeneralInfo);

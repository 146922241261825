export const sortListByName = (names) => {
  // copy the list for the sort
  const newList = [...names];
  const sortedList = newList.sort( (a, b) => 
  {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });

  return sortedList;
}

export const sortListByPayee = (names) => {
  // copy the list for the sort
  const newList = [...names];
  const sortedList = newList.sort( (a, b) => 
  {
    var nameA = a.payee.toUpperCase(); // ignore upper and lowercase
    var nameB = b.payee.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });

  return sortedList;
}
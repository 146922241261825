import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {useApolloClient} from '@apollo/client';

import { 
  fetchOneDomainInstance,
  OperationTargets
} from '../../api/CommonCrudOperations'

import LTRDomainObjectInfo from '../shared/domains/LTRDomainObjectInfo';

// Our labels in the Info Table
const labels = {
  type: "TYPE",
  company: "COMPANY",
  contact: "CONTACT",
  policyNumber: "POLICY NUMBER",
  billType: "TYPE OF BILL",
  billingFrequency: "BILL FREQUENCY",
  howPaid: "HOW PAID",
  notes: "NOTES"
}

export const prepareTableRowInfo = (policy) => {
  // prepare table data for show
  let messageAction = '';
  let messageDoc = '';
  if (policy.actionItems) {
    messageAction = policy.actionItems.length === 0 ? "This policy has NO action items" :
    "This policy has " + policy.actionItems.length + " action items";
  }
  if (policy.attachments) {
    messageDoc = policy.attachments.length === 0 ? "This policy has NO attached files":
    "This policy has " + policy.attachments.length + " attached files"
  }
  const tableInfo = {
    name: policy.name,
    actionItemMessage: messageAction,
    documentsMessage: messageDoc,
    fieldData : [
      {
        field: labels.type,
        value: policy.type
      },
      {
        field: labels.company,
        value: policy.company
      },
      {
        field: labels.contact,
        value: policy.contact !== null ? policy.contact.name : ''
      },
      {
        field: labels.policyNumber,
        value: policy.policyNumber
      }
    ]
  }

  let billerData;
  if (policy.billers && policy.billers.length > 0) {
    const biller = policy.billers[0];   
    billerData = [
      {
        field: labels.billType,
        value: biller.billType
      },
      {
        field: labels.howPaid,
        value: biller.howPaid
      },
      {
        field: labels.billingFrequency,
        value: biller.billingFrequency
      },  
    ] 
  } else {
    billerData = billerData = [
      {
        field: labels.billType,
        value: ''
      },
      {
        field: labels.howPaid,
        value: ''
      },
      
      {
        field: labels.billingFrequency,
        value: ''
      },  
    ]
  }
  // add the biller data
  tableInfo.fieldData = tableInfo.fieldData.concat(billerData);
  
  // finally, notes
  const notesData = {
    field: labels.notes,
    value: policy.usage
  } 
  tableInfo.fieldData.push(notesData);

  return tableInfo;  
}

const useInsuranceFetch = (client, domainId, updateFn) => {
  const [insurance, setInsurance] = useState(null);
  
  useEffect(() => {
    const fetchInsurance = () => { 
      fetchOneDomainInstance(client, OperationTargets.Insurance, domainId)
      .then(result => { 
        const insurance = result.item;
        setInsurance(insurance);
        updateFn(insurance);
      })
      .catch(error => {
        console.log(error) 
      });
    }

    if (insurance === null && domainId !== null) {
      fetchInsurance()
    }  
  }, [client, updateFn]);
};

const InsuranceInfo = (props) => {
  const client = useApolloClient();

  const [showInfoDialogOpen, setShowInfoDialogOpen] = useState(false);
  const [tableInfo, setTableInfo] = useState(null);
  const [policy, setPolicy] = useState(null);

  const handleShowInfoClose = () => {
    setShowInfoDialogOpen(false);
    // notify the client
    props.handleClose();
  }

  useInsuranceFetch(client, props.domainId, (fetchedData) => {
    if (fetchedData !== null) {
      setPolicy(fetchedData);
      const info = prepareTableRowInfo(fetchedData);
      setTableInfo(info);
      setShowInfoDialogOpen(true);
    }
  });

  if (tableInfo === null) {
    return null;
  }

  return (
    <React.Fragment>  
      {showInfoDialogOpen ? (
        <LTRDomainObjectInfo
          open = {props.open}
          title = {policy.name}
          tableData = {tableInfo}
          handleClose = {handleShowInfoClose}
        />): null
      }
    </React.Fragment>
  )
}

InsuranceInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  domainId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default (InsuranceInfo);

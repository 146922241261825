import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';

import LTRDomainSearch from './LTRDomainSearch';

const styles = theme => ({
  left: {
    flex: "1 1 55%",
  },
  actions: {
    flex: "0 0 45%",
    textAlign: "right",
  },
  icon: {
    "&:hover": {
      color: "#307BB0",
    },
  },
  iconActive: {
    color: "#307BB0",
  },
  searchIcon: {
    display: "inline-flex",
    marginTop: "10px",
    marginRight: "8px",
  },
  [theme.breakpoints.down("sm")]: {
    titleRoot: {},
    titleText: {
      fontSize: "16px",
    },
    spacer: {
      display: "none",
    },
    left: {
      // flex: "1 1 40%",
      padding: "8px 0px",
    },
    actions: {
      // flex: "1 1 60%",
      textAlign: "right",
    },
  },
  [theme.breakpoints.down("xs")]: {
    root: {
      display: "block",
    },
    left: {
      padding: "8px 0px 0px 0px",
    },
    titleText: {
      textAlign: "center",
    },
    actions: {
      textAlign: "center",
    },
  },
});

class LTRDomainToolbar extends Component {

  state = {
    iconActive: null,
    showSearch: false,
  }

  setActiveIcon = iconName => {
    this.setState(() => ({
      showSearch: iconName === "search" ? this.handleShowSearch() : false,
    }));
  };

  handleShowSearch = () => {
    return true;
  };

  getActiveIcon = (styles, iconName) => {
    return this.state.iconActive !== iconName ? styles.icon : styles.iconActive;
  };

  hideSearch = () => {
    this.props.searchTextUpdate(null);

    this.setState(() => ({
      iconActive: null,
      showSearch: false,
    }));

    this.searchButton.focus();
  };

  render() {
    const { classes, title, options, searchTextUpdate} = this.props;
    const { showSearch } = this.state; 
 
    return (
      <Toolbar className={classes.root} role={"toolbar"} aria-label={"Domain Toolbar"}>
        <div className={classes.left}>
          {showSearch === true ? (
 
            <LTRDomainSearch onSearch={searchTextUpdate} onHide={this.hideSearch} />
          ) : (
            <>
              <div className={classes.titleRoot} aria-hidden={"true"}>
                <Typography variant="h6" className={classes.titleText}>
                  {title}
                </Typography>
              </div>  
            </>
          )}
        </div>
        <div className={classes.actions}>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              ref={el => (this.searchButton = el)}
              classes={{ root: this.getActiveIcon(classes, "search") }}
              onClick={this.setActiveIcon.bind(null, "search")}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
          {options.customToolbar ? options.customToolbar() : false}
        </div>
      </Toolbar>
    )
  }
}

LTRDomainToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  searchTextUpdate: PropTypes.func.isRequired,
  options: PropTypes.object
};

export default withStyles(styles)(LTRDomainToolbar)
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";

// wrappers for material ui components for formik
import { TextField, Select, CheckboxWithLabel } from "formik-material-ui";

import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import { FormDialogBase } from "../../shared/formhelpers/FormDialogBase";

import { validationSchema } from "./DocumentFormMetaData";

import { useQuery, gql } from "@apollo/client";
import { useDocumentAdd, useDocumentUpdate } from '../../../api/APIHooks';

import { getDocument } from "../../../graphql/queries";

import { DocumentTypes, DocumentType, NonChoice } from "../../../Enums";

import { useFamilyContext } from "../../../context/FamilyContext";

//import {addCorrespondingActionItem} from '../DocumentHelper';

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const AddEditDocumentForm = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [open, setOpen] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [document, setDocument] = useState({
    name: "",
    type: NonChoice,
    description: "",
    digital: false,
    location: "",
    notes: "",
    DocumentsActionItems: [],
    DocumentsAttachments: [],
  });

  const [skipQuery, setSkipQuery] = useState(true);

  let { loading, error, data } = useQuery(gql(getDocument), {
    variables: { id: props.selected ? props.selected.id : "" },
    skip: skipQuery,
  });

  const [addOneDocument] = useDocumentAdd(familyContext.familyRealm.id, (data) => {
    console.log(data)
    // if (props.handleReceiveData) {
    //   props.handleReceiveData(data)
    // } 
  });
  
  const [updateOneDocument] = useDocumentUpdate(familyContext.familyRealm.id, (data) => {
    console.log(data)
    // if (props.handleReceiveData) {
    //   props.handleReceiveData(data)
    // }   
  });

  useEffect(() => {
    if (!skipQuery) {
      const onCompleted = (data) => {
        setDocument(data.item);
      };
      const onError = (error) => {
        return <div>{error}</div>;
      };

      if (onCompleted || onError) {
        if (onCompleted && !loading) {
          onCompleted(data);
          setSkipQuery(true);
        } else if (onError && !loading && error) {
          onError(error);
          setSkipQuery(true);
        }
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    const { selected } = props;

    // if we're editing and we haven't yet fetched the item
    if (selected && skipQuery) {
      setSkipQuery(false);
    }

    return () => {
      setSkipQuery(true);
    };
  }, [props.selected]);

  const save = (values) => {
    // alert(JSON.stringify(values, null, 2));
    // return;

    // Mutation - add one
    const details = {
      userRealm: familyContext.familyRealm.id,
      name: values.name,
      type: values.type,
      description: values.description ? values.description : "",
      digital: values.digital ? true : false,
      location: values.location ? values.location : "",
      notes: values.notes ? values.notes : "",
    };

    // props.open means we're here for editing - otherwise we're adding
    props.open ? updateOne(details) : addOne(details);
  };

  const addOne = (details) => {
    addOneDocument({variables: {object: details}})
    .then((result) => {
      props.handleAdd(result.data.item);
    })
    .catch((error) => {
      console.error(error);
    })
    
    // hide the modal
    handleClose();
  };

  const updateOne = (details) => {
    updateOneDocument({variables: {id: document.id, changes: details}})
    .then((result) => {
      props.handleEdit(result.data.item);
    })
    .catch((error) => {
      console.error(error);
    })
    
    // hide the modal
    handleClose();
  };

  // Attach this to your <Formik>
  const formRef = useRef();

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleResetClick = () => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // normal close and cancel
  const handleClose = () => {
    // if we were opened by the client, let them close us, otherwise we close
    props.handleClose ? props.handleClose() : setOpen(false);
  };

  const body = (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={document}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        // actually save the form
        save(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="name"
              type="text"
              label="Name"
              autoFocus
              helperText={
                touched.name ? errors.name : "Provide a name for this document"
              }
              error={touched.name && Boolean(errors.name)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="documenttype-type">Document Type</InputLabel>
            <Field
              component={Select}
              name="type"
              inputProps={{
                id: "documenttype-type",
              }}
            >
              {DocumentTypes.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText>What type of document is this?</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="description"
              type="text"
              label="Description"
              helperText="Describe the document (i.e. 'Family Trust Document')"
            />
          </FormControl>
          {values.type !== DocumentType.Note ? (
            <FormControl className={classes.formControl}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="digital"
                Label={{ label: "This is a digital document" }}
              />
            </FormControl>
          ) : null}
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="location"
              type="text"
              label="Location"
              helperText="Describe where this document can be found (i.e. 'Bottom shelf, den closet' or 'On the family computer')"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="notes"
              type="text"
              label="Notes"
              multiline
              maxRows="3"
              helperText="Add any additional notes about this document (i.e. 'See a lawyer to understand it.')"
            />
          </FormControl>
        </Form>
      )}
    </Formik>
  );

  const title = props.open ? "Edit an document" : "Add an document";

  return (
    <div>
      <FormDialogBase
        open={props.open ? props.open : open}
        title={title}
        body={body}
        submitEnabled={submitEnabled}
        handleAddClickOpen={handleClickOpen}
        handleClose={handleClose}
        handleReset={handleResetClick}
        handleSubmit={handleSubmitClick}
      />
    </div>
  );
};

// client can open if they choose to OR we will open
AddEditDocumentForm.defaultProps = {
  selected: null,
  open: false,
  handleClose: null,
};

AddEditDocumentForm.propTypes = {
  selected: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAdd: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired
};

export default AddEditDocumentForm;

import React from 'react';

import {
  ThemeProvider,
} from '@material-ui/core/styles';

import { LetterTheme } from './LetterTheme';

import CssBaseline from '@material-ui/core/CssBaseline';

function withRoot(Component) {
  function WithRoot(props) {
    // JssProvider allows customizing the JSS styling solution.
    return (
     
        <ThemeProvider theme={LetterTheme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...props} />
        </ThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;


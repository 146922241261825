import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import LTRCommonLayout from "../shared/domains/LTRCommonLayout";
import LTRCommonNameList, {
  RefreshReason,
} from "../shared/domains/LTRCommonNameList";
import LTRCommonDataTable from "../shared/domains/LTRCommonDataTable";
import LTRDomainObjectInfo from "../shared/domains/LTRDomainObjectInfo";
import LTRSnackbar, {
  buildAddMessage,
  buildEditMessage,
  buildDeleteMessage,
} from "../utilties/LTRSnackbar";

import { DomainType, DomainTypeSingular } from "../../Enums";

import { useQuery, gql } from "@apollo/client";
import { listAssets } from "../../graphql/queries";
import { useAssetDelete } from "../../api/APIHooks";

import { useFamilyContext } from "../../context/FamilyContext";

import AddEditAssetForm from "./forms/AddEditAssetForm";
import AssetsActionPanel from "./forms/AssetsActionPanel";

import { sortListByName } from "../utilties/SortFunctions";

import { prepareTableRowInfo } from "./AssetInfo";

import { useSnackbar } from "../utilties/snackbarUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const Assets = () => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [assets, setAssets] = useState(null);

  const [editFormOpen, setEditFormOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [infoTableItem, setInfoTableItem] = useState(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const [visibleList, setVisibleList] = useState([]);

  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  const { loading, error, data, refetch } = useQuery(gql(listAssets), {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  const processReceivedData = (data) => {
    setAssets(data);
  };

  const [deleteOneAsset] = useAssetDelete(familyContext.familyRealm.id, (data) => {
      console.log(data);
    }
  );

  useEffect(() => {
    const onCompleted = (data) => {
      processReceivedData(data.item);
    };
    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    // just one reason we're here: if assets change we need to adjust
    if (assets) {
      const sortedList = sortListByName(assets);
      setVisibleList(sortedList);
    }
  }, [assets]);

  const handleSearch = (searchTerm) => {
    if (assets.length === 0) {
      return [];
    }

    let displayList;
    if (searchTerm === null) {
      displayList = [...assets];
    } else {
      displayList = assets.filter((item) => {
        const testName = item.name ? item.name.toLowerCase() : "";
        const testType = item.type ? item.type.toLowerCase() : "";
        const testDescription = item.description
          ? item.description.toLowerCase()
          : "";
        const testNotes = item.notes ? item.notes.toLowerCase() : "";
        const againstSearchTerms = searchTerm ? searchTerm.toLowerCase() : "";

        // test the relevant fields
        return (
          testName.includes(againstSearchTerms) ||
          testType.includes(againstSearchTerms) ||
          testDescription.includes(againstSearchTerms) ||
          testNotes.includes(againstSearchTerms)
        );
      });
    }
    return setVisibleList(displayList);
  };

  const handleAddNotification = (item) => {
    if (item) {
      openSnackbar(buildAddMessage(item.name));
    }
  };

  const handleEditNotification = (item) => {
    if (item) {
      openSnackbar(buildEditMessage(item.name));
    }
  };

  const handleEditOpen = (selectedItem) => {
    setSelectedItem(selectedItem);
    setEditFormOpen(true);
  };

  const handleEditClose = () => {
    setSelectedItem(null);
    setEditFormOpen(false);
  };

  const handleDelete = (selected) => {
    console.log(selected);
    deleteOneAsset({ variables: { id: selected.id } })
      .then((result) => {
        openSnackbar(buildDeleteMessage(result.data.item.name));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMultiDeleteNotification = (selected) => {
    let userMessage = "";
    const numItemsDeleted = selected.length;
    if (numItemsDeleted > 1) {
      userMessage = numItemsDeleted + " assets deleted successfully";
      openSnackbar(userMessage);
    } else {
      userMessage = "Asset deleted successfully";
      openSnackbar(userMessage);
    }
    // just call the refetch function
    refetch();
  };

  const handleEditFromId = (selectedId) => {
    const aList = assets.filter((item) => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!

    // call the handleEdit open handler
    handleEditOpen(aList[0]);
  };

  const handleRefresh = (selected, refreshReason) => {
    console.log("Selected:");
    console.log(selected);

    switch (refreshReason) {
      case RefreshReason.UploadAttachment:
        console.log("Refresh - upload");
        refetch();
        break;
      case RefreshReason.DeleteAttachment:
        console.log("Refresh - delete");

        const list = assets.filter((i) => i.id !== selected.id);
        selected.attachments = [];
        list.push(selected);
        processReceivedData(list);
        break;
      default:
        console.log("NOT A VALID PATH");
        break;
    }
  };

  const handleShowInfo = (selectedId) => {
    const aList = assets.filter((item) => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!

    const asset = aList[0];
    const tableInfo = prepareTableRowInfo(asset);

    setInfoTableItem(tableInfo);
    setSelectedItem(aList[0]);
    setShowInfoDialog(true);
  };

  const handleShowInfoClose = () => {
    setSelectedItem(false);
    setShowInfoDialog(false);
  };

  const checkForAttachments = (selected) => {
    return selected.attachments.length > 0;
  };

  const handleViewChange = (oldView, newView) => {
    // whenever we get a view change, we need to cancel all filtering, searching and restore the display list
    handleSearch(null);
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <React.Fragment>
      <LTRCommonLayout
        domain={DomainType.Assets}
        domainSingular={DomainTypeSingular.Assets}
        handleViewChange={handleViewChange}
        commonNameList={
          <div className={classes.root}>
            <LTRCommonNameList
              list={visibleList}
              domain={DomainType.Assets}
              domainSingular={DomainTypeSingular.Assets}
              hasAttachments={checkForAttachments}
              addComponent={
                <AddEditAssetForm
                  handleAdd={handleAddNotification}
                  handleEdit={handleEditNotification}
                  handleReceiveData={processReceivedData}
                />
              }
              handleDelete={handleDelete}
              handleEdit={handleEditOpen}
              handleInfo={handleShowInfo}
              handleSearch={handleSearch}
              handleRefresh={handleRefresh}
            />
          </div>
        }
        commonNameTable={
          <LTRCommonDataTable
            domain={DomainType.Assets}
            domainColumns={[
              { name: "name", label: "Name" },
              { name: "type", label: "Type" },
              { name: "description", label: "Description" },
              { name: "notes", label: "Notes" },
            ]}
            dataItems={visibleList}
            handleMultiDeleteNotification={handleMultiDeleteNotification}
            handleEdit={handleEditFromId}
          />
        }
        actionPanel={
          <AssetsActionPanel 
            handleAddNotification={handleAddNotification} 
            handleReceiveData={processReceivedData}
            />
        }
      />
      {editFormOpen ? (
        <AddEditAssetForm
          open={editFormOpen}
          selected={selectedItem}
          handleAdd={handleAddNotification}
          handleEdit={handleEditNotification}
          handleClose={handleEditClose}
          handleReceiveData={processReceivedData}
        />
      ) : null}
      {showInfoDialog ? (
        <LTRDomainObjectInfo
          open={showInfoDialog}
          title={"Info: " + selectedItem.name}
          tableData={infoTableItem}
          handleClose={handleShowInfoClose}
        />
      ) : null}
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    </React.Fragment>
  );
};

export default Assets;

import React, { useState, useEffect, useContext }  from 'react';

import Typography from '@material-ui/core/Typography';

import { UserContext } from "../../context/UserContext";
import { FamilyContext } from "../../context/FamilyContext";

// content
import Markdown from '../utilties/Markdown';

const Letter = () => {
  const userContext = useContext(UserContext);
  const familyContext = useContext(FamilyContext);

  const [letterContent, setLetterContent] = useState("");
  const [addressee, setAddressee] = useState("")
  const [sender, setSender] = useState("")

  useEffect(() => {
    let isOwner = userContext.user.isOwner; 
    getLetterContent(receiveLetterContentFn, isOwner);
  }, [userContext]);

  useEffect(() => {
    let recipient = ""
    let senders = "";
    const user = userContext.user;
    const members = familyContext.members; 

    // addressee is easy: it is always the logged in user
    setAddressee(user.firstName);

    if (user.isOwner) {
      console.log("user", user)
     
      const list = members.filter ((member) => {
        return member.id != user.id && member.isOwner
      })

      // 1st case: there is only one owner then sender and receiver are
      // the same, make it "Dear Self"
      if (list.length === 0) {
        setSender("Self")
      } else if (list.length === 1) {   // two owners, common
        setSender(list[0].firstName)
      } else { // more than two owners, uncommon
        list.forEach((element, index) => {
          if (index === 0) {
            recipient += element.firstName
          }
          if (index === list.length-1)  {
            // last entry
            recipient +=  " and " + element.firstName
          } else if (index > 0 && index <  list.length) {
            recipient += ", " + element.firstName
          }  
        })
        setSender(recipient)
      }
    } else {
      const listOwners = members.filter ((member) => {
        return member.isOwner
      })

      if (listOwners.length === 1) {   // one owners, uncommon
        setSender(listOwners[0].firstName)
      } else if (listOwners.length === 2) { //  two owners, common
        setSender(listOwners[0].firstName + " and " + listOwners[1].firstName)
      } else {
        listOwners.forEach((element, index) => {
          if (index === 0) {
            senders += element.firstName
          }
          if (index === listOwners.length-1)  {
            // last entry
            senders +=  " and " + element.firstName
          } else if (index > 0 && index <  listOwners.length) {
            senders += ", " + element.firstName
          } 
        })
        setSender(senders)
      }
    }
    
  }, [userContext, familyContext]);


  const getLetterContent = (receiveFn, isOwner) => {
    let md = '/lettercontent/letterhelper.md'
    if (isOwner) {
      md = '/lettercontent/letterowner.md'
    }
    fetch(process.env.PUBLIC_URL + md)
    .then(response => response.text())
    .then(text => {
      receiveFn(text);
    });
  }

  const receiveLetterContentFn = (content) => {
    setLetterContent(content)
  }

  return (
    <React.Fragment>  
      <Typography gutterBottom>
        Dear {addressee},
      </Typography>
      <Markdown key={letterContent.substring(0, 40)}>
        {letterContent}
      </Markdown>
      <Typography>
        Best of luck -  {sender}
      </Typography>
    </React.Fragment>
  )
}

export default (Letter);

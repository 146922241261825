import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },  
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'
  }
}));


const AudioViewer = (props) => {
  const classes = useStyles();


   /*
   id
  userRealm
  uri
  type
  domainId
  name
  mimeType
  originalName
  */

  if (props.attachment === null) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Container fixed maxWidth="md">
        <audio controls>
          <source src={props.url} type={props.attachment.mimeType} />  
        </audio>
      </Container>
    </div>
  );
}

AudioViewer.propTypes = {
  attachment: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
};

export default AudioViewer;


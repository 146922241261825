import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Draggable from "react-draggable";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";

import { useApolloClient, useQuery, gql } from "@apollo/client";
import { useDocumentUpdate } from "../../../api/APIHooks";
import { updateOneNote } from "../../../api/DocumentApi";

import { useFamilyContext } from "../../../context/FamilyContext";
import { getDocument } from "../../../graphql/queries";

// our editor component
import Editor from "./Editor";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  flex: {
    flex: 1,
  },

  paper: {},

  button: {
    margin: theme.spacing(1),
  },

  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  modal: {
    overflow: "visible",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaperComponent = (props) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

const initialValues = {
  title: "",
  description: "",
};

const ACTIONS = {
  TITLE: "title",
  DESCRIPTION: "description",
};

const noteReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.TITLE:
      return {
        ...state,
        title: action.value,
      };
    case ACTIONS.DESCRIPTION:
      return {
        ...state,
        description: action.value,
      };
    default:
      return state;
  }
};

export default function EditNoteDialog(props) {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [state, dispatch] = useReducer(noteReducer, initialValues);

  const [note, setNote] = useState(null);
  // const [content, setContent] = useState('');
  const [editor, setEditor] = useState(null);

  const [skipQuery, setSkipQuery] = useState(true);

  let { loading, error, data } = useQuery(gql(getDocument), {
    variables: { id: props.selected ? props.selected.id : "" },
    skip: skipQuery,
  });

  const [updateOneDocument] = useDocumentUpdate(
    familyContext.familyRealm.id,
    (data) => {
      console.log(data);
      if (props.handleReceiveData) {
        props.handleReceiveData(data);
      }
    }
  );

  useEffect(() => {
    if (!skipQuery) {
      const onCompleted = (data) => {
        const document = data.item;
        // set our edit fields
        const note = document.note[0];
        setNote(note);
        console.log(note);

        dispatch({ type: ACTIONS.TITLE, value: note.name });
        dispatch({ type: ACTIONS.DESCRIPTION, value: note.description });
      };
      const onError = (error) => {
        return <div>{error}</div>;
      };

      if (onCompleted || onError) {
        if (onCompleted && !loading) {
          onCompleted(data);
          setSkipQuery(true);
        } else if (onError && !loading && error) {
          onError(error);
          setSkipQuery(true);
        }
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    const { selected } = props;

    // if we're editing and we haven't yet fetched the item
    if (selected && skipQuery) {
      setSkipQuery(false);
    }

    return () => {
      setSkipQuery(true);
    };
  }, [props.selected]);

  const handleEditorChanges = (content, delta, editor) => {
    setEditor(editor);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // get some stuff from the editor
    if (editor !== null) {
      const html = editor.getHTML();

      let output = {
        content: html,
      };

      handleSave(output);
    } else {
      props.handleClose();
    }
  };

  const handleSave = (output) => {
    // Save the  note
    const detailsNote = {
      content: output.content,
      name: state.title,
      description: state.description,
    };

    const detailsDoc = {
      name: state.title,
      description: state.description,
    };

    updateOneNote(client, familyContext.familyRealm.id, note.id, detailsNote)
      .then((result) => {
        console.log(result);

        // update the document
        updateOneDocument({
          variables: { id: note.domainId, changes: detailsDoc },
        })
          .then((result) => {
            props.handleSave(result.data.item);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        props.handleClose();
      });
  };

  // short circuit if we don't have a note to edit
  if (note === null) {
    return null;
  }

  const { open, handleClose } = props;

  const dialogTitle = "Edit a Note";

  let placeHolderText = "Edit a note ...";

  const body = (
    <div>
      <Editor
        placeholder={placeHolderText}
        onChange={handleEditorChanges}
        initialContent={note.content}
      />
      <form className={classes.container}>
        <FormControl className={classes.formControl}>
          <TextField
            id="title"
            label="Title"
            required
            autoFocus
            className={classes.textField}
            value={state.title}
            onChange={(event) => {
              dispatch({ type: ACTIONS.TITLE, value: event.target.value });
            }}
            margin="normal"
            helperText="Provide a descriptive title for this note"
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="description"
            label="Description"
            className={classes.textField}
            value={state.description}
            onChange={(event) => {
              dispatch({
                type: ACTIONS.DESCRIPTION,
                value: event.target.value,
              });
            }}
            margin="normal"
            helperText="Describe the note (i.e. 'How to find our family photos')"
          />
        </FormControl>
      </form>
    </div>
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"lg"}
      PaperComponent={PaperComponent}
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="form-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={state.title === ""}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditNoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  selected: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired,
};

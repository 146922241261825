import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import UserCreate from "../../user/forms/UserCreate";
import UserEdit from "../../user/forms/UserEdit";

import LTRSnackbar, {
  buildAddMessage,
  buildEditMessage,
  buildDeleteMessage,
} from "../../utilties/LTRSnackbar";

import { deleteUser, removeUserFromRealm } from "../../../graphql/mutations";
import { gql, useMutation } from "@apollo/client";

import ConfirmModal from "../../shared/modals/ConfirmModal";

import { useInterviewContext } from "./InterviewContext";
import {
  useUserContext,
} from "../../../context/UserContext";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    topMargin: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

const PlanOwners = (props) => {
  const classes = useStyles();
  const interviewContext = useInterviewContext();
  const userContext = useUserContext();

  const [userCreateOpen, setUserCreateOpen] = useState(false);
  const [userEditOpen, setUserEditOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [removeUser] = useMutation(gql(deleteUser));
  const [removeFromRealm] = useMutation(gql(removeUserFromRealm));

  const owners = interviewContext.planOwners;
  console.log(owners.length);

  const openSnackbar = (message) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const openUserCreateDialog = () => {
    setUserCreateOpen(true);
  };

  const handleClose = () => {
    setUserCreateOpen(false);
  };

  const handleSave = (user) => {
    handleClose();

    // add to the interview context
    interviewContext.addOwner(user);
    openSnackbar(buildAddMessage(user.firstName + " " + user.lastName));
  };

  const handleDelete = (user) => {
    setSelected(user);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteUser = () => {
    // two stage delete - delete the assoication, then delete the realm user
    removeFromRealm({ variables: { userId: selected.id, realmId: realmId } })
      .then((result) => {
        // now delete the user
        removeUser({ variables: { id: selected.id } })
          .then((anotherResult) => {
            interviewContext.removeOwner(selected);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    setDeleteConfirmOpen(false);
    openSnackbar(
      buildDeleteMessage(selected.firstName + " " + selected.lastName)
    );
  };

  const handleEdit = (user) => {
    setSelected(user);
    setUserEditOpen(true);
  };

  const handleEditUserSave = (user) => {
    setUserEditOpen(false);
    interviewContext.replaceOwner(user);
    openSnackbar(buildEditMessage(user.firstName + " " + user.lastName));
  };

  // just grab the id from the realm - we don't need the name here
  const realmId = interviewContext.realm.id;
  const loggedInUser = userContext.user;

  console.log(loggedInUser);

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Plan Owners
      </Typography>

      <Typography variant="body1" gutterBottom>
        Add plan owners.
      </Typography>

      <List>
        {owners &&
          owners.map((user) => (
            <ListItem
              key={user.id}
              role={undefined}
              alignItems="flex-start"
              divider
              className={classes.listItem}
              onClick={() => setSelected(user)}
            >
              <ListItemText
                primary={user.firstName + " " + user.lastName}
                secondary={user.email}
              />
              {user.id !== loggedInUser.id ? (
                <ListItemSecondaryAction>
                  <Tooltip title={"Delete"}>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => handleDelete(user)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Edit"}>
                    <IconButton
                      aria-label="Edit"
                      onClick={() => handleEdit(user)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          ))}
        {interviewContext.proxyOwner !== null ? (
          <ListItem
            key={interviewContext.proxyOwner.id}
            role={undefined}
            alignItems="flex-start"
            className={classes.listItem}
          >
            <ListItemText
              primary={
                interviewContext.proxyOwner.firstName +
                " " +
                interviewContext.proxyOwner.lastName +
                "(proxy user)"
              }
              secondary={interviewContext.proxyOwner.email}
            />
          </ListItem>
        ) : null}
      </List>

      <Button
        variant="contained"
        className={classes.button}
        color="secondary"
        onClick={openUserCreateDialog}
      >
        Add Plan Owner
      </Button>

      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
      {userCreateOpen ? (
        <UserCreate
          open={userCreateOpen}
          realm={realmId}
          isOwner={true}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      ) : null}

      {userEditOpen && selected !== null ? (
        <UserEdit
          open={userEditOpen}
          selectedId={selected.id}
          isOwner={selected.isOwner}
          allowOwnerControl={false}
          handleClose={() => {
            setUserEditOpen(false);
          }}
          handleEdit={handleEditUserSave}
        />
      ) : null}

      <ConfirmModal
        title={"Confirm user deletion"}
        open={deleteConfirmOpen}
        handleConfirm={handleDeleteUser}
        handleClose={() => {
          setDeleteConfirmOpen(false);
        }}
        body={"Are you sure you want to delete this user?"}
      />
    </React.Fragment>
  );
};

export default PlanOwners;

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Formik, Form, Field } from "formik";

// wrappers for material ui components for formik
import { TextField, Select } from "formik-material-ui";
import { DatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { FormDialogBase } from "../shared/formhelpers/FormDialogBase";

import { validationSchema } from "./ActionItemMetaData";
import { StatusTypes, PriorityTypes, NonChoice } from "../../Enums";
import { useFamilyContext } from "../../context/FamilyContext";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const AddEditActionItemFormCore = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [open, setOpen] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  // Attach this to your <Formik>
  const formRef = useRef();

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleResetClick = () => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
  };

  const createOwnerSelectItems = () => {
    let items = [];
    const { members } = familyContext;

    // want to be able to assign an action item to 'unassigned'
    const owners = [NonChoice, ...members];
    for (let i = 0; i < owners.length; i++) {
      var value = null;
      // special case!
      if (i === 0) {
        value = owners[i];
      } else {
        value = owners[i].firstName;
      }
      items.push(
        <MenuItem key={i} value={value}>
          {value}
        </MenuItem>
      );
    }
    return items;
  };

  const body = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={props.actionItem}
        validationSchema={validationSchema()}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);

          // actually save the form
          props.save(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          isSubmitting,
          isValid,
          /* and other goodies */
        }) => (
          <Form>
            <FormControl className={classes.formControl}>
              <Field
                component={TextField}
                name="name"
                label="Name"
                helperText="Describe the action required to close this item"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="priority-type">Priority</InputLabel>
              <Field
                component={Select}
                name="priority"
                inputProps={{
                  id: "priority-type",
                }}
              >
                {PriorityTypes.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText>
                What is the priority of this action item?
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="status-type">Status</InputLabel>
              <Field
                component={Select}
                name="status"
                inputProps={{
                  id: "status-type",
                }}
              >
                {StatusTypes.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText>
                What is the status of this action item?
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="ownerselect-type">Assigned to</InputLabel>
              <Field
                component={Select}
                name="owner"
                inputProps={{
                  id: "ownerselect-type",
                }}
              >
                {createOwnerSelectItems()}
              </Field>
              <FormHelperText>Who will handle this action item?</FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Field component={DatePicker} label="Date due" name="dueDate" />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Field
                component={TextField}
                name="notes"
                label="Notes"
                multiline
                maxRows={4}
                className={classes.textField}
                margin="normal"
                helperText="Add any notes/hints/special instructions to help manage this action item"
              />
            </FormControl>
          </Form>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );

  return (
    <FormDialogBase
      open={props.open ? props.open : open}
      title={props.title}
      body={body}
      submitEnabled={submitEnabled}
      handleAddClickOpen={handleClickOpen}
      handleClose={props.handleClose}
      handleReset={handleResetClick}
      handleSubmit={handleSubmitClick}
    />
  );
};

AddEditActionItemFormCore.defaultTypes = {
  domainType: null,
  itemName: null,
};

AddEditActionItemFormCore.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  actionItem: PropTypes.object.isRequired,
  domainType: PropTypes.string,
  itemName: PropTypes.string,
  save: PropTypes.func.isRequired,
};

export default AddEditActionItemFormCore;

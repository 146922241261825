import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useQuery, gql } from "@apollo/client";
import { listInsurance } from "../../../graphql/queries";

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { useFamilyContext } from '../../../context/FamilyContext';

import {sortListByName} from '../../utilties/SortFunctions';

export const InsurerSelect = (props) => {
  const familyContext = useFamilyContext();

  const [insurers, setInsurers] = useState(null);
  const [insuranceValue, setInsuranceValue] = useState('');
  
  const { loading, error, data } = useQuery(gql(listInsurance), {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  useEffect(() => {
    const onCompleted = (data) => {
      const sortedList = sortListByName(data.item);
      setInsurers(sortedList);
    };

    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (props.existingInsurer !== undefined) {
      setInsuranceValue(constructInsuranceValue(props.existingInsurer));
    } else {
      setInsuranceValue('')
    }
  }, [props.existingInsurer]);

  const constructInsuranceValue = (insurer) => {
    return insurer ? insurer.id + '::' + insurer.name : ''
  }
 
  const createInsuranceSelectItems = () => {
    let items = []; 
    if (insurers === null) {
      return null;
    }
    
    for (let i = 0; i < insurers.length; i++) {
      const insurer = insurers[i]; 
      const value = constructInsuranceValue(insurer);         
      items.push(<MenuItem key={insurer.id} value={value}>{insurer.name}</MenuItem>);   
    }  
    return items;
  }  

  return (
    <>
      <InputLabel id="insurances-select-label">Insured by</InputLabel>
      <Select
        labelId="insurances-select-label"
        id="insurances-select"
        value={insuranceValue}
        onChange = {ev => {
          // update our UI
          setInsuranceValue(ev.target.value);
          // call the client - construct an object that the client can use
          const localArray = ev.target.value.split("::");
          const selectedInsurer = {
            id: localArray[0],
            name: localArray[1]
          }
          props.onChange(selectedInsurer)
        }}
      >
         {
          createInsuranceSelectItems()
        }
      </Select>
    </>
  )
}

InsurerSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  existingInsurer: PropTypes.object
}

export default InsurerSelect;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  bodyMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const OtherItemsSnip = (props) => {
  const classes = useStyles();

  return (
     <div className={classes.root}>
      <Typography variant="body2" gutterBottom color="initial">
        'Household valuables' is a catch-all for the 'smallish' personal propery items that you own. 
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        This includes furniture, artwork, appliances, electronics, collections, family photographs and other 'stuff' that you want to mention.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        Identify all personal property that is important to you in some way; this is property that you likely do
        NOT want to be thrown out without some thought and discussion.
      </Typography>
     </div>
  );
}

export default OtherItemsSnip;
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import LTRCommonActionPanel from '../../shared/domains/LTRCommonActionPanel'

import QuickAddService from './QuickAddService';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import {ServiceType} from '../../../Enums';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  }
}));

const ServiceActionPanel = (props) => {
  const classes = useStyles();

  const [serviceType, setServiceType] = useState(ServiceType.Other)
  const [quickAddOpen, setQuickAddOpen] = useState(false);

  const handleAddService = (type) => {
    setServiceType(type)
    setQuickAddOpen(true);
  }
  
  const handleCloseQuickAddOpen = () => {
    setQuickAddOpen(false);
  }

  const handleSave  = (contact) => {
    props.handleAddNotification(contact);
  }

  const handleReceiveData = (data) => {
    if (props.handleReceiveData) {
      props.handleReceiveData(data);
    }
  }

  return (
    <div className={classes.root}>
      <LTRCommonActionPanel
        actionComponents = {
          <ButtonGroup
            orientation="vertical"
            size = "small"
            color="primary"
            variant="text"
            aria-label="vertical outlined primary button group"
            >
            <Button className={classes.button} onClick={() => handleAddService(ServiceType.Utility)} color="primary">
              Add a utility service
            </Button>
            <Button className={classes.button} onClick={() => handleAddService(ServiceType.Telecommunications)} color="primary">
              Add a telecommunications service
            </Button>  
            <Button className={classes.button} onClick={() => handleAddService(ServiceType.Housekeeping)} color="primary">
              Add a housekeeping service
            </Button>
            <Button className={classes.button} onClick={() => handleAddService(ServiceType.Gardening)}   color="primary">
              Add a gardening service
            </Button>
            <Button className={classes.button} onClick={() => handleAddService(ServiceType.PestControl)}   color="primary">
              Add a pest control service 
            </Button>
            <Button className={classes.button} onClick={() => handleAddService(ServiceType.Membership)}   color="primary">
              Add a membership 
            </Button>
            <Button className={classes.button} onClick={() => handleAddService(ServiceType.Other)}   color="primary">
              Add any type of service
            </Button>
          </ButtonGroup>
        }
        />
        {quickAddOpen ? (
           <QuickAddService 
              open = {quickAddOpen}
              serviceType = {serviceType}
              handleClose = {handleCloseQuickAddOpen}
              handleSave = {handleSave}
              handleReceiveData = {handleReceiveData}
           />) 
          : null
        }
    
    </div>
  )
}

ServiceActionPanel.propTypes = {
  handleAddNotification: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired,
}

export default (ServiceActionPanel)

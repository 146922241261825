import React, { useEffect, useState, Suspense } from 'react';
import {ErrorBoundary} from 'react-error-boundary'

import {Auth0Provider} from "@auth0/auth0-react";
import Auth0Config from "./auth/Auth0Config";

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import withRoot from './withRoot';

import IsolatedContainer from './pages/shared/IsolatedContainer';
import LoadingMessagePage from './pages/shared/LoadingMessagePage';

import './App.css';

// our routes
import LetterRoutes from './Routes';

// dashboard
import Dashboard from './pages/dashboard/Dashboard';
import Landing from './pages/home/Landing';
import Overview from './pages/overview/Overview';
import Accounts from './pages/accounts/Accounts';
import Contacts from './pages/contacts/Contacts';
import Documents from './pages/documents/Documents';
import Assets from './pages/assets/Assets';
import Liabilities from './pages/liabilities/Liabilities';
import Insurance from './pages/insurance/Insurance';
import OtherItems from './pages/otheritems/OtherItems';
import Subscriptions from './pages/subscriptions/Subscriptions';
import Services from './pages/services/Services';
import Settings from './pages/settings/Settings';
import GettingStarted from './pages/help/GettingStarted';
import Help from './pages/help/Help';
import Login from './pages/user/Login';
import Signup from './pages/user/Signup';
import GlobalActionItemView from './pages/actionitems/GlobalActionItemView';
import PageNotFound from './pages/PageNotFound';

import Interview from './pages/overview/interview/Interview';

import ProtectedRoute from "./ProtectedRoute";

const privatePathRoutes = [
  { path: LetterRoutes.OverviewRoute, component: Overview},
  { path: LetterRoutes.AccountsRoute, component: Accounts},
  { path: LetterRoutes.AssetsRoute, component: Assets},
  { path: LetterRoutes.ContactsRoute, component: Contacts},
  { path: LetterRoutes.DocumentsRoute, component: Documents},
  { path: LetterRoutes.LiabilitiesRoute, component: Liabilities},
  { path: LetterRoutes.InsuranceRoute,component: Insurance},
  { path: LetterRoutes.SubscriptionsRoute,component: Subscriptions},
  { path: LetterRoutes.OtherItemsRoute, component: OtherItems},
  { path: LetterRoutes.ServicesRoute, component: Services},
  { path: LetterRoutes.SettingsRoute, component:Settings},
  { path: LetterRoutes.HelpRoute, component:Help},
  { path: LetterRoutes.GettingStartedRoute, component: GettingStarted},
  { path: LetterRoutes.InterviewRoute, component:Interview},
  { path: LetterRoutes.GlobalActionItemsRoute, component: GlobalActionItemView},
];

// public routes, not protected by login, should work for all users
// after they've logged in
const publicPathRoutes = [
  { path: '/', component: Landing},
  { path: LetterRoutes.LandingRoute, component: Landing},
  { path: LetterRoutes.LoginRoute, component: Login},
  { path: LetterRoutes.SignupRoute, component: Signup}
  // { path: LetterRoutes.PrivacyTermsRoute, component: PrivacyAndTerms}
];

// route that we will be redirected to - probably never reached, but just in case
const AUTH_CALLBACK_LOGIN = "/";
const AUTH_CALLBACK_LOGOUT = "/";

function ErrorFallback({error, componentStack, resetErrorBoundary}) {
  return (
    <IsolatedContainer>
      <p><strong>Oops! An error occured!</strong></p>
      <p>Here’s what we know…</p>
      <p><strong>Error:</strong> {error.toString()}</p>
      <p><strong>Stacktrace:</strong> {componentStack}</p>
    </IsolatedContainer>
  )
}
 
const App = (props) =>  {
  
  const [privateRoutes, setPrivateRoutes] = useState(null);
  const [publicRoutes, setPublicRoutes] = useState(null);

  useEffect(() => {
    // set up the routes
    if (privateRoutes) { // || publicRoutes) {
      return;
    }
    setUpPrivateRoutes();
    setUpPublicRoutes();
    
  }, [privateRoutes]);
  
  const setUpPrivateRoutes = (userRole)  => {
    // Note: if we want to differentiate between routes, do it here
    // at this time all routes are private to all users; they
    // become available after log in
    
    // return the role specific array + routes available to all
    setPrivateRoutes(privatePathRoutes)
  }

  const setUpPublicRoutes = () => {
   
    //  let routes = [];
    //  routes = routes.concat(publicPathRoutes);
 
    //  // special cases - any developer only routes
    //  if (process.env.NODE_ENV !== 'production') {
    //    routes.push( {path:"/sandbox", component:Sandbox});
    //  }
 
     // special case - admin user
     // if (role === RoleTypes.RoleAdmin) {
     //   routes.push ( {path:"/tools", component:Toolmain});
     // }
    
     setPublicRoutes(publicPathRoutes);
  }

  const onRedirectCallback = (appState) => {
      if (appState.targetRoute) {
        console.log('Return to: ' + appState.targetRoute)
      }
  };
   
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense
          fallback={
          <LoadingMessagePage>Loading Application</LoadingMessagePage>
          }
        > 
          <Router>
            <Auth0Provider 
              domain={Auth0Config.domain}
              clientId={Auth0Config.clientId}
              redirectUri={Auth0Config.loginRedirectUri}
              useRefreshTokens={true}
              onRedirectCallback={onRedirectCallback}
              >
              <Dashboard>
                <Switch>     
                  {privateRoutes && privateRoutes.map((route, i) => <ProtectedRoute key={i} exact {...route} />)}
                  {publicRoutes && publicRoutes.map((route, i) => <Route key={i} exact {...route} />)}
                  <Route path = {AUTH_CALLBACK_LOGIN} component={() => <Landing/>}/>    
                  <ProtectedRoute path = {AUTH_CALLBACK_LOGOUT} component={() => <Landing/>}/> 
                  <Route path = {LetterRoutes.LandingWithPurposeRoute} exact component={() => <Landing sub="inverview"/>}/>         
                  <Route component={PageNotFound}/>       
                </Switch>
              </Dashboard>
            </Auth0Provider>
          </Router> 
        </Suspense>
    </ErrorBoundary>
  )
}

export default withRoot(App);
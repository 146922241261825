import * as yup from 'yup';

export const validationSchema = () => {
  const schema = yup.object({
    name: yup.string("Enter a name").required("Name is required"),
  })
  return schema
}

export const initialValues = () => {
  //const dueDate = moment().format('LL');
  // const dueDate = new Date();
  return (
    { 
      name : '',
      owner : '',
      priority : 'Medium',
      dueDate : null,
      status : 'Open',
      notes: ''
    }
  )
}

import {InsuranceType, ActionItemType, ActionItemGroupType, PriorityType } from '../../Enums';
import {NonChoice } from '../../Enums';

const prepareDetailsForContactItems = (editItem, policy) => {
  let priority = PriorityType.High;
  let groupType = ActionItemGroupType.None;
  
  let name;
  let notes = "Pay the outstanding premiums, then cancel this policy.";
  if (policy.company && policy.company !== '') {
    if (editItem.contact !== null) {
      name = "Call " + editItem.contact.name + " at " + policy.company + " about the " +  policy.type.toLowerCase() + " insurance policy '" + policy.name + "' and cancel the policy." ;
    } else {
      name = "Call " + policy.company + " about the " +  policy.type.toLowerCase() + " insurance policy '" + policy.name + "' and cancel the policy." ;
    }  
    if (policy.type === InsuranceType.Life) {
      notes = "Pay the outstanding premiums for '" + policy.company + "'  while you figure out who the beneficiaries of the policy are.";
    } 
  } else {
    if (editItem.contact !== null) {
      name = "Call " + editItem.contact.name + " about the " +  policy.type.toLowerCase() + " insurance policy '" + policy.name + "' and cancel the policy.";
    } else {
      name = "Call the company about the " +  policy.type.toLowerCase() + " insurance policy '" + policy.name + "' and cancel the policy.";
    }
    if (policy.type === InsuranceType.Life) {
      notes = "Pay the outstanding premiums while you figure out who the beneficiaries of the policy are.";
    }
  }
  if (policy.type === InsuranceType.Auto || policy.type === InsuranceType.HomeOwners) {
    notes = "Pay the outstanding premiums while you decide what to do with the asset this " + policy.type.toLowerCase() + " policy insures, i.e. if you keep the house, then first transfer ownership of the house, then cancel the homeowners policy.";
  }

  // is this insurance policy part of a group?
  if (policy.type === InsuranceType.Medical || policy.type === InsuranceType.Dental) {
    groupType = ActionItemGroupType.Medical;
  }
  if (policy.type === InsuranceType.HomeOwners) {
    groupType = ActionItemGroupType.Home;
  }
  
  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: policy.id,
    domainName: policy.name,
    type: ActionItemType.Insurance,
    group: groupType,
    notes: notes
  };
  return actionItemDetails;
}

// const prepareDetailsForCancellationItems = (policy) => {
//   let priority = PriorityType.Medium;
//   let groupType = ActionItemGroupType.None;
  
//   let notes;
//   let name = "Cancel the " + policy.type.toLowerCase() + " insurance policy for '" + policy.name + "'";
//   if (policy.notes && policy.notes !== '') {
//     notes = policy.notes;
//   } else {
//     if (policy.company && policy.company !== '') {
//       notes = "Cancel the " +  policy.type.toLowerCase() + " insurance policy at '" + policy.company + "'.";
//     } else {
//       notes = "Cancel this " + policy.type.toLowerCase() + " insurance policy."
//     }
//   }

//   // is this insurance policy part of a group?
//   if (policy.type === InsuranceType.Medical || policy.type === InsuranceType.Dental) {
//     groupType = ActionItemGroupType.Medical;
//   }
//   if (policy.type === InsuranceType.HomeOwners) {
//     groupType = ActionItemGroupType.Home;
//   }

//   const actionItemDetails = {
//     name: name,
//     dueDate: NonChoice,
//     priority: priority,
//     status: 'Open',
//     domainId: policy.id,
//     domainName: policy.name,
//     type: ActionItemType.Insurance,
//     group: groupType,
//     notes: notes
//   };
//   return actionItemDetails;
// }

export const constructCorrespondingActionItems = (editItem, policy) => {
  const actionItems = [];

  const contactItemDetails = prepareDetailsForContactItems(editItem, policy);
  if (contactItemDetails) {
    actionItems.push(contactItemDetails);
  }
  // const cancellationDetails = prepareDetailsForCancellationItems(policy);
  // if (cancellationDetails) {
  //   actionItems.push(cancellationDetails);
  // }
  
  return actionItems;
}
/* eslint-disable react/prop-types */

import React, { useState, useContext } from "react";

import { useApolloClient } from "@apollo/client";

import {
  DocumentType,
  GovernmentBenefitType,
  DomainType,
} from "../../../Enums";
import { useFamilyContext } from "../../../context/FamilyContext";

import {
  NO_WILL_OR_TRUST,
  WILL_ONLY,
  TRUST_ONLY,
  TRUST_AND_WILL,
} from "./DocCheckList";
import {
  addDocuments,
  addEOLItems,
  addGovernmentBenefits,
  addDeathCerticateItem,
  addAnythingElseItems
} from "./DatabaseHelper";

export const InterviewContext = React.createContext();
export const useInterviewContext = () => useContext(InterviewContext);

// These ID's must be unique to identify checklist answers
export const CheckListAnswerIds = {
  tw: "TW", // trust and will
  hd1: "HD1", // health directive (owner)
  hd2: "HD2", // health directive (spouse/partner)
  pa1: "PA1", // power of attorney (owneer)
  pa2: "PA2", // power of attorney (spouse/partner)
  ss1: "SS1", // social security (owner)
  ss2: "SS2", // social security (spouse/partner)
  mc1: "MC1", // medicare (owner)
  mc2: "MC2", // medicare (spouse/partner)
  el1: "EL1", // end of life(owner)
  el2: "EL2", // end of life (spouse/partner)
};

export const InterviewProvider = ({ children, ...props }) => {
  const familyContext = useFamilyContext();
  const client = useApolloClient();

  const [planOwners, setPlanOwners] = useState([]);
  const [proxyOwner, setProxyOwner] = useState(null);
  const [planWorkers, setPlanWorkers] = useState([]);
  const [answers, setAnswers] = useState([
    // doc checklist
    {
      id: CheckListAnswerIds.hd1,
      value: false,
    },
    {
      id: CheckListAnswerIds.hd2,
      value: false,
    },
    {
      id: CheckListAnswerIds.tw,
      value: NO_WILL_OR_TRUST,
    },
    {
      id: CheckListAnswerIds.pa1,
      value: false,
    },
    {
      id: CheckListAnswerIds.pa2,
      value: false,
    },
    // gov checklist
    {
      id: CheckListAnswerIds.ss1,
      value: false,
    },
    {
      id: CheckListAnswerIds.ss2,
      value: false,
    },
    {
      id: CheckListAnswerIds.mc1,
      value: false,
    },
    {
      id: CheckListAnswerIds.mc2,
      value: false,
    },
    {
      id: CheckListAnswerIds.el1,
      value: false,
    },
    {
      id: CheckListAnswerIds.el2,
      value: false,
    },
  ]);
  const [realm, setRealm] = useState({});

  const addOwner = (owner) => {
    // testing to see if this owner exists
    const newList = planOwners.filter((item) => {
      return item.id === owner.id;
    });
    if (newList.length === 0) {
      const owners = [owner, ...planOwners];
      setPlanOwners(owners);
    }
  };

  const addProxyOwner = (owner) => {
    setProxyOwner(owner);
  }

  const removeOwner = (owner) => {
    const newList = planOwners.filter((item) => {
      return item.id !== owner.id;
    });
    setPlanOwners(newList);
  };

  const replaceOwner = (owner) => {
    const filteredList = planOwners.filter((item) => {
      return item.id !== owner.id;
    });
    const newList = [filteredList, ...owner];
    setPlanOwners(newList);
  };

  const addWorker = (worker) => {
    // testing to see if this worker exists
    const newList = planWorkers.filter((item) => {
      return item.id === worker.id;
    });
    if (newList.length === 0) {
      const workers = [worker, ...planWorkers];
      setPlanWorkers(workers);
    }
  };

  const removeWorker = (worker) => {
    const newList = planWorkers.filter((item) => {
      return item.id !== worker.id;
    });
    setPlanWorkers(newList);
  };

  const replaceWorker = (worker) => {
    const filteredList = planWorkers.filter((item) => {
      return item.id !== worker.id;
    });
    const newList = [filteredList, ...worker];
    setPlanWorkers(newList);
  };

  const addRealm = (newRealm) => {
    // plan consists of name and id
    setRealm(newRealm);
  };

  const updateAnswer = (oneAnswer) => {
    // see if we have answer already, if we do just replace it
    const a = answers.filter((answer) => answer.id !== oneAnswer.id);
    const list = [...a, oneAnswer];
    setAnswers(list);
  };

  const prepareForDocumentSave = () => {
    // TODO: personalize the HD and the PA name values - one for each owner, i.e. "Noah's Power of Attorney"

    const documents = [];
    const actions = [];

    const planOwnerName = planOwners[0].firstName;
    const planOwnerSpouseName =
      planOwners.length > 1 ? planOwners[1].firstName : null;
    answers.map((answer) => {
      let document = {};
      let action = {};
      if (answer.id === CheckListAnswerIds.tw) {
        if (answer.value !== NO_WILL_OR_TRUST) {
          if (answer.value === TRUST_ONLY) {
            document.name = "Trust";
            document.description = "Trust document";
          } else if (answer.value === WILL_ONLY) {
            document.name = "Will";
            document.description = "Will document";
          } else if (answer.value === TRUST_AND_WILL) {
            document.name = "Trust and Will";
            document.description = "Trust and Will documents";
          }
          document.type = DocumentType.WillOrTrust;
          document.trustOrWill = answer.value;
          documents.push(document);
        } else {
          action.type = DocumentType.WillOrTrust;
          action.trustOrWill = answer.value;
          actions.push(action);
        }
      }
      if (answer.id === CheckListAnswerIds.pa1) {
        if (answer.value) {
          document.type = DocumentType.PowerOfAttorney;
          document.name = "Power of Attorney - " + planOwnerName;
          document.description = "Power of Attorney for " + planOwnerName;
          document.havePowerOfAttorney = answer.value;
          document.user = planOwnerName;
          documents.push(document);
        } else {
          action.type = DocumentType.PowerOfAttorney;
          action.havePowerOfAttorney = answer.value;
          action.user = planOwnerName;
          actions.push(action);
        }
      }
      if (
        planOwnerSpouseName !== null &&
        answer.id === CheckListAnswerIds.pa2
      ) {
        if (answer.value) {
          document.type = DocumentType.PowerOfAttorney;
          document.name = "Power of Attorney - " + planOwnerSpouseName;
          document.description = "Power of Attorney for " + planOwnerSpouseName;
          document.havePowerOfAttorney = answer.value;
          document.user = planOwnerSpouseName;
          documents.push(document);
        } else {
          action.type = DocumentType.PowerOfAttorney;
          action.havePowerOfAttorney = answer.value;
          action.user = planOwnerSpouseName;
          actions.push(action);
        }
      }
      if (answer.id === CheckListAnswerIds.hd1) {
        if (answer.value) {
          document.type = DocumentType.HealthDirective;
          document.name = "Advanced Health Directive - " + planOwnerName;
          document.description =
            "Advanced Health Directive for " + planOwnerName;
          document.haveAdvancedHealthDirective = answer.value;
          document.user = planOwnerName;
          documents.push(document);
        } else {
          action.type = DocumentType.HealthDirective;
          action.havePowerOfAttorney = answer.value;
          action.user = planOwnerName;
          actions.push(action);
        }
      }
      if (
        planOwnerSpouseName !== null &&
        answer.id === CheckListAnswerIds.hd2
      ) {
        if (answer.value) {
          document.type = DocumentType.HealthDirective;
          document.name = "Advanced Health Directive - " + planOwnerSpouseName;
          document.description =
            "Advanced Health Directive for " + planOwnerSpouseName;
          document.user = planOwnerSpouseName;
          document.haveAdvancedHealthDirective = answer.value;
          documents.push(document);
        } else {
          action.type = DocumentType.HealthDirective;
          action.haveAdvancedHealthDirective = answer.value;
          action.user = planOwnerSpouseName;
          actions.push(action);
        }
      }
      return true;
    });
    return { documents, actions };
  };

  const prepareForEndOfLifeDocumentSave = () => { 
    const documents = [];
   
    const planOwnerName = planOwners[0].firstName;
    const planOwnerSpouseName = planOwners.length > 1 ? planOwners[1].firstName : null;
    
    let documentOwner = {
      name: 'End of Life - ' + planOwnerName,
      description: "Details of end of life arrangements/wishes",
      type: DocumentType.EndOfLife,
      user: planOwnerName
    };
    documents.push(documentOwner);

    if (planOwnerSpouseName !== null) {
      let documentSpouse = {
        name: 'End of Life - ' + planOwnerSpouseName,
        description: "Details of end of life arrangements/wishes",
        type: DocumentType.EndOfLife,
        user: planOwnerSpouseName
      };
      documents.push(documentSpouse)
    }
    return documents;
  };

  const prepareForBenefitsSave = () => {
    const benefits = [];
    answers.map((answer) => {
      let benefit = {};

      const planOwnerName = planOwners[0].firstName;
      const planOwnerSpouseName =
        planOwners.length > 1 ? planOwners[1].firstName : null;
      if (answer.id === CheckListAnswerIds.ss1) {
        benefit.name = planOwnerName + "'s Social Security benefit";
        benefit.description = "Social Security";
        benefit.domainType = DomainType.Assets;
        benefit.type = GovernmentBenefitType.SocialSecurity;
        benefit.user = planOwnerName;
        benefit.value = answer.value;
        benefits.push(benefit);
      }
      if (planOwners.length > 1 && answer.id === CheckListAnswerIds.ss2) {
        benefit.name = planOwnerSpouseName + "'s Social Security benefit";
        benefit.description = "Social Security";
        benefit.domainType = DomainType.Assets;
        benefit.type = GovernmentBenefitType.SocialSecurity;
        benefit.user = planOwnerSpouseName;
        benefit.value = answer.value;
        benefits.push(benefit);
      }
      if (answer.id === CheckListAnswerIds.mc1) {
        benefit.name = planOwnerName + "'s Medicare benefit";
        benefit.description = "Medicare";
        benefit.domainType = DomainType.Insurance;
        benefit.type = GovernmentBenefitType.Medicare;
        benefit.user = planOwnerName;
        benefit.value = answer.value;
        benefits.push(benefit);
      }
      if (planOwners.length > 1 && answer.id === CheckListAnswerIds.mc2) {
        benefit.name = planOwnerSpouseName + "'s Medicare benefit";
        benefit.description = "Medicare";
        benefit.domainType = DomainType.Insurance;
        benefit.type = GovernmentBenefitType.Medicare;
        benefit.user = planOwnerSpouseName;
        benefit.value = answer.value;
        benefits.push(benefit);
      }
      return true;
    });
    return benefits;
  };

  const commitPlanNow = () => {
    return new Promise((resolve, reject) => {
      const newFamily = [...planOwners, ...planWorkers];
      familyContext.addNewFamily(realm, newFamily);

      const { documents, actions } = prepareForDocumentSave();
      addDocuments(client, realm.id, documents, actions);

      const benefits = prepareForBenefitsSave();
      addGovernmentBenefits(client, realm.id, benefits);

      const eol = prepareForEndOfLifeDocumentSave();
      addEOLItems(client, eol, realm.id);

      // death certificate
      addDeathCerticateItem(client, realm.id);

      // 'open me first'

      // anything else
      addAnythingElseItems (client, planOwners, realm.id);

      resolve(realm);
    });
  };

  return (
    <InterviewContext.Provider
      value={{
        planOwners,
        planWorkers,
        proxyOwner,
        realm,
        answers,
        commitPlanNow,
        addOwner: addOwner,
        addProxyOwner: addProxyOwner,
        replaceOwner: replaceOwner,
        removeOwner: removeOwner,
        addWorker: addWorker,
        replaceWorker: replaceWorker,
        removeWorker: removeWorker,
        addRealm: addRealm,
        updateAnswer: updateAnswer,
      }}
    >
      {children}
    </InterviewContext.Provider>
  );
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import LTRCommonActionPanel from "../../shared/domains/LTRCommonActionPanel";

import { DocumentType } from "../../../Enums";

import QuickAddDocument from "./QuickAddDocument";
import AddNoteDialog from "../notes/AddNoteDialog";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const DocumentActionPanel = (props) => {
  const classes = useStyles();

  const [documentType, setDocumentType] = useState(DocumentType.Other);
  const [quickAddOpen, setQuickAddOpen] = useState(false);
  const [addNoteDialogOpen, setAddNoteDialogOpen] = useState(false);

  const handleAddDocument = (type) => {
    setDocumentType(type);
    setQuickAddOpen(true);
  };

  // notes
  const handleAddNote = () => {
    setAddNoteDialogOpen(true);
  };

  const handleCloseAddNoteDialog = () => {
    setAddNoteDialogOpen(false);
  };

  const handleCloseQuickAddOpen = () => {
    setQuickAddOpen(false);
  };

  const handleSave = (doc) => {
    props.handleAddNotification(doc);
  };

  const processReceivedData = (data) => {
    props.handleReceiveData(data);
  };

  return (
    <div className={classes.root}>
      <LTRCommonActionPanel
        actionComponents={
          <ButtonGroup
            orientation="vertical"
            size="small"
            color="primary"
            variant="text"
            aria-label="vertical outlined primary button group"
          >
            <Button
              className={classes.button}
              onClick={() => handleAddDocument(DocumentType.WillOrTrust)}
              color="primary"
            >
              Add a will or trust document
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleAddDocument(DocumentType.HealthDirective)}
              color="primary"
            >
              Add a Health Directive document
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleAddDocument(DocumentType.PowerOfAttorney)}
              color="primary"
            >
              Add a Power of Attorney document
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleAddDocument(DocumentType.Government)}
              color="primary"
            >
              Add a government document
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleAddDocument(DocumentType.Identity)}
              color="primary"
            >
              Add an identity document
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleAddDocument(DocumentType.Tax)}
              color="primary"
            >
              Add a tax document
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleAddDocument(DocumentType.Other)}
              color="primary"
            >
              Add some other document
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleAddNote()}
              color="primary"
            >
              Add a note
            </Button>
          </ButtonGroup>
        }
      />
      {quickAddOpen ? (
        <QuickAddDocument
          open={quickAddOpen}
          documentType={documentType}
          handleClose={handleCloseQuickAddOpen}
          handleSave={handleSave}
          handleReceiveData={processReceivedData}
        />
      ) : null}
      {addNoteDialogOpen ? (
        <AddNoteDialog
          open={addNoteDialogOpen}
          handleClose={handleCloseAddNoteDialog}
          handleSave={handleSave}
          handleReceiveData={processReceivedData}
        />
      ) : null}
    </div>
  );
};

DocumentActionPanel.propTypes = {
  handleAddNotification: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired,
};

export default DocumentActionPanel;

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";

import { useRecoilValue } from "recoil";

import { useApolloClient, useQuery, gql } from "@apollo/client";
import { useAccountAdd, useAccountUpdate } from "../../../api/APIHooks";

// wrappers for material ui components for formik
import { TextField, Select, CheckboxWithLabel } from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { FormDialogBase } from "../../shared/formhelpers/FormDialogBase";

import { validationSchema } from "./AccountFormMetaData";

import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";

import { useFamilyContext } from "../../../context/FamilyContext";
import { encryptionSettings } from "../../settings/SettingsState";

import { OperationTargets } from "../../../api/CommonCrudOperations";

import { addMultipleActionItems } from "../../../api/ActionItemApi";

import { getAccount } from "../../../graphql/queries";

import { getAccountTypeEnumList, AccountType, NonChoice } from "../../../Enums";

import ncrypter from "../../utilties/ncrypter";

import SecureDataDialog from "../../shared/security/SecureDataDialog";
import PasscodeEntry from "../../shared/security/PasscodeEntry";
import PasscodeStorage, { pcodeNotFound } from "../../../api/PasscodeStorage";

import { constructCorrespondingActionItems } from "../AccountHelper";

import ContactSelect from "../../shared/formhelpers/ContactSelect";

// TESTING
//import {CreditCardTextField} from '../../shared/formhelpers/CreditCardNumber';

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const AddEditAccountForm = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [open, setOpen] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [accountNumberEncrypted, setAccountNumberEncrypted] = useState(false);
  const [secureDataDialogOpen, setSecureDataDialogOpen] = useState(false);
  const [showEncryptedField, setShowEncryptedField] = useState(false);
  const [showEnterPasscodeDialog, setShowEnterPasscodeDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [account, setAccount] = useState({
    name: "",
    type: NonChoice,
    institution: "",
    contactId: null,
    contact: null,
    accountNumber: "",
    taxable: false,
    usage: "",
    billpayAccount: false,
  });
  const billpayCredit = "I pay bills on this card";
  const billpayBank = "I pay bills from this account";

  const settings = useRecoilValue(encryptionSettings);

  const [skipQuery, setSkipQuery] = useState(true);

  let { loading, error, data } = useQuery(gql(getAccount), {
    variables: { id: props.selected ? props.selected.id : "" },
    skip: skipQuery,
  });

  const [addOneAccount] = useAccountAdd(familyContext.familyRealm.id, (data) => {
      //console.log(data);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data);
      // }
    }
  );

  const [updateOneAccount] = useAccountUpdate(familyContext.familyRealm.id, (data) => {
      //console.log(data);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data);
      // }
    }
  );

  useEffect(() => {
    if (!skipQuery) {
      const onCompleted = (data) => {
        const account = data.item;

        // first, see if we even have an account or credit card number
        if (settings.encryptedData) {
          let encryptedField = true;
          if (account.accountNumber === "") {
            encryptedField = false;
          }

          setAccountNumberEncrypted(encryptedField);
        } else {
          // since we are not encrypted, open the visibility
          setShowEncryptedField(true);
        }
        setAccount(account);
      };
      const onError = (error) => {
        return <div>{error}</div>;
      };

      if (onCompleted || onError) {
        if (onCompleted && !loading) {
          onCompleted(data);
          setSkipQuery(true);
        } else if (onError && !loading && error) {
          onError(error);
          setSkipQuery(true);
        }
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    const { selected } = props;

    // if we're editing and we haven't yet fetched the item
    if (selected && skipQuery) {
      setSkipQuery(false);
    }

    return () => {
      setSkipQuery(true);
    };
  }, [props.selected]);

  const save = (values) => {
    // 1) do we have a field to protect
    if (values.accountNumber && values.accountNumber !== "") {
      // 2) are we encrypting data? This means some other account data has been previously encrypted,
      // we may just need to collect the passphrase
      if (settings.encryptedData) {
        // have we a passphrase to use
        const pcode = PasscodeStorage.getPasscode();
        if (pcode === pcodeNotFound) {
          // 3)just collect it
          setShowEnterPasscodeDialog(true);
        } else {
          // 5) just save
          fullSave(values);
        }
      } else {
        // 4) we have an account value but we aren't encrypting anything yet
        setSecureDataDialogOpen(true);
      }
    } else {
      // no account numbers
      fullSave(values);
    }
  };

  const saveSecurely = (values) => {
    fullSave(values);
  };

  const fullSave = (values) => {
    // Test before save
    // alert(JSON.stringify(values, null, 2));
    // return

    // encrypt the sensitive data with the passcode, if we have one
    const pcode = PasscodeStorage.getPasscode();
    if (pcode !== pcodeNotFound) {
      if (values.accountNumber && values.accountNumber !== "") {
        values.accountNumber = ncrypter.encryptData(
          pcode,
          values.accountNumber
        );
      }
    }

    // Mutation - either add or update
    const details = {
      userRealm: familyContext.familyRealm.id,
      name: values.name,
      type: values.type ? values.type : "",
      institution: values.institution ? values.institution : "",
      accountNumber: values.accountNumber ? values.accountNumber : "",
      usage: values.usage ? values.usage : "",
      taxable: values.taxable ? values.taxable : false,
      billpayAccount: values.billpayAccount ? values.billpayAccount : false,
    };

    // if we have a contact, add it here
    if (values.contact) {
      details.contactId = values.contact.id
    }

    // props.open means we're here for editing - otherwise we're adding
    props.open ? updateOne(values, details) : addOne(values, details);
  };

  const addOne = (values, details) => {
    const actionItems = constructCorrespondingActionItems(values, details);
    if (actionItems.length > 0) {
      details.AccountActionItems = {
        data: [...actionItems],
      };
    }

    addOneAccount({variables: {object: details}})
    .then((result) => {
      props.handleAdd(result.data.item);
    })
    .catch((error) => {
      console.error(error);
    })
    // hide the modal
    handleClose();

    setIsSaving(false);
  };

  const updateOne = (values, details) => {
    if (account.actionItems.length === 0) {
      const actionItems = constructCorrespondingActionItems(values, details);
      if (actionItems.length > 0) {
        const items = actionItems.map((item) => {
          item.userRealm = familyContext.familyRealm.id;
          item.domainId = account.id;
          return item;
        });

        addMultipleActionItems(
          client,
          familyContext.familyRealm.id,
          OperationTargets.Accounts,
          items
        )
          .then((result) => {
            //console.log(result);
            //console.log("Added %s action items", actionItems.length);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    updateOneAccount({ variables: { id: account.id, changes: details } })
      .then((result) => {
        props.handleEdit(result.data.item);
      })
      .catch((error) => {
        console.error(error);
      });

    setIsSaving(false);

    // hide the modal
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // normal close and cancel
  const handleClose = () => {
    // if we were opened by the client, let them close us, otherwise we close
    props.handleClose ? props.handleClose() : setOpen(false);
  };

  const createAccountTypeSelectItems = () => {
    let items = [];

    const accountTypes = getAccountTypeEnumList();
    for (let i = 0; i < accountTypes.length; i++) {
      var value = accountTypes[i];
      items.push(
        <MenuItem key={i} value={value}>
          {value}
        </MenuItem>
      );
    }

    return items;
  };

  const handleClickShowPassword = () => {
    if (accountNumberEncrypted) {
      const pcode = PasscodeStorage.getPasscode();
      if (pcode === pcodeNotFound) {
        setShowEnterPasscodeDialog(true);
      } else {
        if (account.accountNumber && account.accountNumber !== "") {
          const tempNumber = ncrypter.decryptData(pcode, account.accountNumber);
          if (formRef.current) {
            formRef.current.setFieldValue("accountNumber", tempNumber);
          }
          // no longer encrypted
          setAccountNumberEncrypted(false);
          const existing = showEncryptedField;
          setShowEncryptedField(!existing);
        }
      }
    } else {
      const existing = showEncryptedField;
      setShowEncryptedField(!existing);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleShowEnterPasscodeDialogSave = () => {
    // a valid passcode was provided, use it to change the values

    const pcode = PasscodeStorage.getPasscode();
    if (pcode !== pcodeNotFound) {
      if (account.accountNumber && account.accountNumber !== "") {
        const tempNumber = ncrypter.decryptData(pcode, account.accountNumber);
        if (formRef.current) {
          formRef.current.setFieldValue("accountNumber", tempNumber);
        }
      }
      // no longer encrypted
      setAccountNumberEncrypted(false);

      // we have a passcode, allow the field to be shown
      setShowEncryptedField(true);
    }

    setShowEnterPasscodeDialog(false);
  };

  const handleSecureDataDialogClose = () => {
    setSecureDataDialogOpen(false);
  };

  const handleSecureDataDialogSave = () => {
    // dismiss the dialog(s) - don't need to check which is open
    setSecureDataDialogOpen(false);
    setShowEnterPasscodeDialog(false);

    if (formRef.current) {
      saveSecurely(formRef.current.values);
    }
  };

  const handleSecureDataSaveAnyway = () => {
    // dismiss the dialog
    setSecureDataDialogOpen(false);

    // save = but we are NOT protecting with a password
    if (formRef.current) {
      fullSave(formRef.current.values);
    }
  };

  // Attach this to your <Formik>
  const formRef = useRef();

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleResetClick = () => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
  };

  // functions for credit card/account number fields
  const renderFieldsAsProtected = (values) => {
    if (values.type === AccountType.Credit) {
      return (
        <FormControl className={classes.formControl}>
          <Field
            component={TextField}
            name="accountNumber"
            label="Credit Card Number"
            type={showEncryptedField ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showEncryptedField ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText="Provide the card number for this account"
          />
        </FormControl>
      );
    } else {
      return (
        <FormControl className={classes.formControl}>
          <Field
            component={TextField}
            name="accountNumber"
            label="Account Number"
            type={showEncryptedField ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showEncryptedField ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText="Provide the account number for this account"
          />
        </FormControl>
      );
    }
  };

  const renderFieldsAsNonProtected = (values) => {
    if (values.type === AccountType.Credit) {
      return (
        <FormControl className={classes.formControl}>
          <Field
            component={TextField}
            name="cardNumber"
            label="Credit Card Number"
            helperText="Provide the card number (whole or partial) for this account"
          />
        </FormControl>
      );
    } else {
      return (
        <FormControl className={classes.formControl}>
          <Field
            component={TextField}
            name="accountNumber"
            label="Account Number"
            helperText="Provide the account number (whole or partial) for this account"
          />
        </FormControl>
      );
    }
  };

  const body = (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={account}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        // need to set a saving flag
        setIsSaving(true);

        // actually save the form
        save(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="name"
              label="Name"
              autoFocus
              error={touched.name && Boolean(errors.name)}
              helperText={
                touched.name
                  ? errors.name
                  : "Provide a descriptive name for this account"
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="type-select">Account Type</InputLabel>
            <Field
              component={Select}
              name="type"
              type="text"
              label="Account Type"
              inputProps={{
                id: "type-select",
              }}
            >
              {createAccountTypeSelectItems()}
            </Field>
            <FormHelperText>Identify the type of the account</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="institution"
              label="Institution"
              helperText="Provide the name of the institution"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <ContactSelect
              existingContact={account.contactId === null ? undefined : account.contact} 
              onChange={(contact) => {
                setFieldValue('contact', contact ? contact: null);
              }}
            />  
            <FormHelperText>Identify a contact person for this institution</FormHelperText>
          </FormControl>
          {/* start account number and credit card fields  */}
          {settings && settings.encryptedData === true
            ? renderFieldsAsProtected(values)
            : renderFieldsAsNonProtected(values)}
          {/* end account number and credit card fields  */}
          <FormControl className={classes.formControl}>
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="billpayAccount"
              indeterminate={false}
              Label={{
                label:
                  values.type === AccountType.Credit
                    ? billpayCredit
                    : billpayBank,
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="usage"
              label="Notes"
              multiline
              rows="4"
              helperText="Provide any helpful information you have about this account and describe how it is used."
            />
          </FormControl>
        </Form>
      )}
    </Formik>
  );

  const title = props.open ? "Edit an account" : "Add an account";

  return (
    <div>
      <FormDialogBase
        open={props.open ? props.open : open}
        title={title}
        body={body}
        submitEnabled={submitEnabled}
        handleAddClickOpen={handleClickOpen}
        handleClose={handleClose}
        handleReset={handleResetClick}
        handleSubmit={handleSubmitClick}
      />
      {secureDataDialogOpen ? (
        <SecureDataDialog
          open={secureDataDialogOpen}
          dataName={"account or credit card number"}
          handleClose={handleSecureDataDialogClose}
          handleDoNotProtect={handleSecureDataSaveAnyway}
          handleSave={handleSecureDataDialogSave}
        />
      ) : null}
      {showEnterPasscodeDialog ? (
        <PasscodeEntry
          open={showEnterPasscodeDialog}
          current={settings.encryptedBase}
          handleClose={() => setShowEnterPasscodeDialog(false)}
          handleSave={
            isSaving
              ? handleSecureDataDialogSave
              : handleShowEnterPasscodeDialogSave
          }
        />
      ) : null}
    </div>
  );
};

// client can open if they choose to OR we will open
AddEditAccountForm.defaultProps = {
  selected: null,
  open: false,
  handleClose: null,
  handleReceiveData: null,
};

AddEditAccountForm.propTypes = {
  selected: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAdd: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func,
};

export default AddEditAccountForm;

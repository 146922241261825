import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {parse} from "./vCardParser";
import {ContactsImportSource} from './DataMapper';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  title: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
}));

const fileEncoding = 'UTF-8';

const VCardReader = (props) => {
  const classes = useStyles();

  const handleChangeFile = (e) => {
    let reader = new FileReader()
    const files = e.target.files;
    if (files.length > 0) {
      reader.onload = (event ) => {
        const contactData = parse(reader.result);
        const returnData = {
          data: contactData,
          meta: {
            source: ContactsImportSource.VCard
          },
          errors: ""
        }
        props.onFileLoaded(returnData, files[0])
      }
      reader.readAsText(files[0], fileEncoding)
    }
  }

  const inputProps = {
    accept: '.vcf',
  };
  
  return (
    <div className = {classes.root}>
      <Typography className = {classes.title}>{props.title}</Typography>
      <TextField
        id="vcf file selection"
        type="file"
        inputProps={inputProps}
        onChange={e => handleChangeFile(e)}
      />
    </div>
  );
}

VCardReader.propTypes = {
  title: PropTypes.string.isRequired,
  onFileLoaded: PropTypes.func.isRequired,
};

export default VCardReader

import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";

import { useApolloClient, gql } from "@apollo/client";

import { listActionItemsForUserGroupOwners } from "../../graphql/queries";
import { deleteOneActionItem } from "../../api/ActionItemApi";

import { StatusType } from "../../Enums";
import LTRSnackbar, {
  buildEditMessage,
  buildDeleteMessage,
} from "../utilties/LTRSnackbar";

import ConfirmModal from "../shared/modals/ConfirmModal";

import EditActionItemForm from "./EditActionItemForm";

import { FamilyContext } from "../../context/FamilyContext";

import { useSnackbar } from "../utilties/snackbarUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(1),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    fontStyle: "italic",
  },
  nothingFoundMessage: {
    marginTop: theme.spacing(2),
  },
}));

const AddActionItemListView = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useContext(FamilyContext);

  const [actionItems, setActionItems] = useState(null);
  const [displayItems, setDisplayItems] = useState([]);
  const [ownerNamesString, setOwnerNamesString] = useState("");
  const [ownerNames, setOwnerNames] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const [selected, setSelected] = useState({});
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  useEffect(() => {
    let isMounted = true;

    const fetchItems = () => {
      const q = listActionItemsForUserGroupOwners(
        familyContext.familyRealm.id,
        ownerNames
      );
      client
        .query({
          query: gql(q),
          fetchPolicy: "network-only",
        })
        .then((result) => {
          const items = result.data.ActionItems;
          if (isMounted) {
            setActionItems(items);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (ownerNames && ownerNames.length) {
      fetchItems();
    }

    return () => {
      isMounted = false;
    };
  });

  useEffect(() => {
    if (actionItems) {
      if (actionItems.length && !showAll) {
        const newItems = actionItems.filter((item) => {
          return item.status !== StatusType.Closed;
        });
        setDisplayItems(newItems);
      } else {
        setDisplayItems(actionItems);
      }
    }
  }, [actionItems]);

  useEffect(() => {
    // identify owners and extract their first names for the query
    const localNames = [];
    familyContext.members.map((member) => {
      if (member.isOwner) {
        localNames.push(member.firstName);
      }
    });

    // set up our owner names - we need this for the query
    setOwnerNames(localNames);

    var i;
    let temp = "";
    for (i = 0; i < localNames.length; i++) {
      temp += localNames[i];
      if (i + 1 < localNames.length) {
        temp += " and ";
      }
    }

    // set up the owner name string fo the UI
    setOwnerNamesString(temp);
  }, [familyContext.members]);

  const handleEdit = (item) => {
    setSelected(item);
    setEditDialogOpen(true);
  };

  const handleDelete = (item) => {
    setSelected(item);
    setDeleteDialogOpen(true);
  };

  const handleShowAll = (ev) => {
    const fullList = ev.target.checked;
    setShowAll(fullList);

    if (fullList) {
      setDisplayItems(actionItems);
    } else {
      const items = actionItems.filter((item) => {
        return item.status !== StatusType.Closed;
      });
      setDisplayItems(items);
    }
  };

  const handleEditActionItemClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditActionItemNotification = (actionItem) => {
    handleEditActionItemClose();

    const existingRows = actionItems.filter((item) => {
      return item.id.indexOf(actionItem.id) !== 0;
    });

    const newRows = [...existingRows, actionItem];
    setActionItems(newRows);
    openSnackbar(buildEditMessage(actionItem.name));
  };

  const handleDeleteActionItemClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteActionItem = () => {
    handleDeleteActionItemClose();

    deleteOneActionItem(client, selected.id)
      .then((result) => {
        const aList = actionItems.filter((item) => {
          return item.id !== selected.id;
        });

        openSnackbar(buildDeleteMessage(selected.name));
        setActionItems(aList);
      })
      .catch((error) => {
        console.log("Error deleting action item: " + error);
        openSnackbar("Oh no! The action item was NOT deleted");
      });
    return true;
  };

  const RenderNothingFound = () => {
    const message = "Congratulations! No open Action Items found.";
    return (
      // style={{ backgroundColor: '#cfe8fc', margin: '80px', height: '100vh' }}
      <Container maxWidth="sm">
        <Typography
          className={classes.nothingFoundMessage}
          align="center"
          variant="h6"
          color="initial"
        >
          {message}
        </Typography>
      </Container>
    );
  };

  const switchLabel =
    "Show ALL (Open and Completed) action items assigned to " +
    ownerNamesString +
    ".";

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <Container className={classes.paper} maxWidth="xl">
          <Typography variant="h6" gutterBottom className={classes.title}>
            {"Your open Action Items"}
          </Typography>
          <Typography gutterBottom className={classes.subtitle}>
            {"These are items that you need to work on."}
          </Typography>
          <Divider />
          <FormControlLabel
            control={
              <Switch
                checked={showAll}
                onChange={handleShowAll}
                name="showAll"
                color="primary"
              />
            }
            label={switchLabel}
          />
          <Divider />
          {displayItems && displayItems.length > 0 ? (
            <>
              <List>
                {displayItems.map((item) => (
                  <ListItem
                    key={item.id}
                    role={undefined}
                    alignItems="flex-start"
                    divider
                    className={classes.listItem}
                    onClick={() => setSelected(item)}
                  >
                    <ListItemText
                      primary={item.name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {"Handler: " +
                              item.owner +
                              ", status: " +
                              item.status}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title={"Edit"}>
                        <IconButton
                          aria-label={"Edit"}
                          onClick={() => {
                            handleEdit(item);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Delete"}>
                        <IconButton
                          aria-label={"Delete"}
                          onClick={() => {
                            handleDelete(item);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            <RenderNothingFound classes={{ nothingFoundMessage: "8px" }} />
          )}
        </Container>
        <LTRSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          handleClose={closeSnackbar}
        />

        <ConfirmModal
          title={"Confirm action item deletion"}
          open={deleteDialogOpen}
          handleConfirm={handleDeleteActionItem}
          handleClose={handleDeleteActionItemClose}
          body={"Are you sure you want to delete this action item?"}
        />

        {editDialogOpen && selected !== {} ? (
          <EditActionItemForm
            open={editDialogOpen}
            selectedId={selected.id}
            handleClose={handleEditActionItemClose}
            handleEdit={handleEditActionItemNotification}
          />
        ) : null}
      </Paper>
    </div>
  );
};

AddActionItemListView.propTypes = {
  filter: PropTypes.object.isRequired,
};

export default AddActionItemListView;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Apollo-related stuff

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from, split } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

import { RecoilRoot } from 'recoil';

import { initializeApp } from 'firebase/app';
import { initializeAuth, indexedDBLocalPersistence } from 'firebase/auth';

import { UserProvider } from './context/UserContext';
import { FamilyProvider } from './context/FamilyContext';
import { AuthProvider } from './context/AuthContext';

// Firebase Authentication
export const firebaseApp = initializeApp({
  apiKey: "AIzaSyDef9kCPIAH3T2ncnpLOv_iHHsdWMb6WFc",
  authDomain: "letter-from-mom-and-dad.firebaseapp.com",
  projectId: "letter-from-mom-and-dad",
  storageBucket: "letter-from-mom-and-dad.appspot.com",
  messagingSenderId: "307218628795",
  appId: "1:307218628795:web:f2dc7474574550d250299e"
});

export const firebaseAuth = initializeAuth(firebaseApp, { persistence: indexedDBLocalPersistence} );

// All graphql traffic gets funneled through our server
const graphQlEndpoint = `/api/graphql`;

// Links
const httpLink = new HttpLink({
  uri: graphQlEndpoint
});

const retryLink = new RetryLink({
  delay: {
    initial: 2000,
    max: 2000
  }
});

export const client = new ApolloClient({
  // Provide required constructor fields
  cache: new InMemoryCache(),
  link: from ([retryLink, httpLink]),

  // Provide some optional constructor fields
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
  resolvers: {},
});

const WithProviders = () => (
  <ApolloProvider client={client}>
    <RecoilRoot>
      <AuthProvider auth={firebaseAuth}>
        <UserProvider client={client}>
          <FamilyProvider>
            <App />
          </FamilyProvider>
        </UserProvider>
      </AuthProvider>
    </RecoilRoot>
  </ApolloProvider>
);

// set up cache persistence
persistCache({
  cache: client.cache,
  storage: new LocalStorageWrapper(window.localStorage)
}).then( () => {
  ReactDOM.render(<WithProviders />, document.getElementById('root'));
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

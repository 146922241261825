import { atom } from "recoil";

import { USERFIELD_NOT_SET } from "./UserContext";

export const appUserState = atom({
  key: "active-app-user",
  default: {
    id: USERFIELD_NOT_SET,
    authId: USERFIELD_NOT_SET,
    loginName: USERFIELD_NOT_SET,
    firstName: USERFIELD_NOT_SET,
    lastName: USERFIELD_NOT_SET,
    email: USERFIELD_NOT_SET,
    isOwner: USERFIELD_NOT_SET,
    userRealm: USERFIELD_NOT_SET,
    emailVerified: false,
    realms: []
  },
});

export const currentUserState = atom({
  key: 'current-user-state',
  default: {
    id: USERFIELD_NOT_SET,
    authId: USERFIELD_NOT_SET,
    loginName: USERFIELD_NOT_SET,
    firstName: USERFIELD_NOT_SET,
    lastName: USERFIELD_NOT_SET,
    email: USERFIELD_NOT_SET,
    isOwner: USERFIELD_NOT_SET,
    userRealm: USERFIELD_NOT_SET,
    emailVerified: false,
    realms: []
  }, 
});



export const authUserState = atom({
  key: "active-auth-user",
  default: {},
});

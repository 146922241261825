import {gql} from '@apollo/client';

import {
  updateEmail,
  insertEmails,
  deleteEmails,
} from '../graphql/mutations';


export async function deleteMultipleEmails(client, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(deleteEmails), 
      variables: {rows: payload}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function insertMultipleEmails(client, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(insertEmails), 
      variables: {objects: payload}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
}

export async function updateOneEmail(client, id, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(updateEmail), 
      variables: {id: id, changes: payload}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
}
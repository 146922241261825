export const MimeTypes = {
  DocPDF:  'application/pdf',
  DocMSWord:'application/msword',
  Image: 'image/jpeg',
  ImageTiff: 'image/tiff',
  ImagePng: 'image/png',
  ImageWebp: 'image/webp',
  VideoMP4: 'video/mp4',
  VideoMOV:'video/quicktime',
  VideoWebm: 'video/webm',
  AudioMP4A:'audio/x-m4a',
  AudioMP4: 'audio/mp4',
  AudioMP3: 'audio/mpeg',
}

export const isFileTypeImage = (mimeType) => {
  let retVal = false;
  if ( (mimeType === MimeTypes.Image) 
          || (mimeType === MimeTypes.ImagePng) 
          || (mimeType === MimeTypes.ImageTiff)
          || (mimeType === MimeTypes.ImageWebp)) {
    retVal = true;
  }
  return retVal;
}

export const isFileTypeVideo = (mimeType) => {
  let retVal = false;
  if ( (mimeType === MimeTypes.VideoMP4) 
    || (mimeType === MimeTypes.VideoMOV)
    || (mimeType === MimeTypes.VideoWebm) ) {
    retVal = true;
  }
  return retVal;
}

export const isFileTypeAudio = (mimeType) => {
  let retVal = false;
  if ( (mimeType === MimeTypes.AudioMP3) 
        || (mimeType === MimeTypes.AudioMP4A)
        || mimeType === MimeTypes.AudioMP4) {
    retVal = true;
  }
  return retVal;
}

export const isFileTypeDoc = (mimeType) => {
  let retVal = false;
  if (mimeType === MimeTypes.DocPDF) {
    retVal = true;
  }
  return retVal;
}
import React from "react";
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
  iconButton: {
    marginRight: "24px",
    top: "50%",
    display: "inline-block",
    position: "relative",
  },
  icon: {
    color: "#000",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
}));

const CustomToolbarSelect = (props) => {
  const classes = useStyles();

  const handleClickInverseSelection = () => {
    const nextSelectedRows = props.displayData.reduce((nextSelectedRows, _, index) => {
      if (!props.selectedRows.data.find(selectedRow => selectedRow.index === index)) {
        nextSelectedRows.push(index);
      }

      return nextSelectedRows;
    }, []);

    props.setSelectedRows(nextSelectedRows);
  };

  const handleClickDeselectAll = () => {
    props.setSelectedRows([]);
  };

  const handleClickEdit = () => {
    const { selectedRows, displayData} = props;

    // can only select 1 item for editing
    if (selectedRows.data.length > 1) {
      console.log('Only 1 can be edited at a time')
      return;
    }

    const indexToId = 0;
    let selectedId = 0;
    
    selectedRows.data.map(item => {
      const row = displayData[item.index];
      selectedId = row.data[indexToId];
      return item;
    });
    props.handleEdit(selectedId);
  };

  const handleClickDeleteRows = () => {
    // NOTE: the ID is set up as the first column - if it moves this
    // code will fail!
    const indexToId = 0;
    const itemsToDelete = [];
    const { selectedRows, displayData} = props;

    // client may ask us to limit deletes to one row at a time
    if (selectedRows.data.length > 1 && !props.allowMultipleDelete) {
      console.log('Only 1 can be deleted at a time')
      return;
    }

    selectedRows.data.map(item => {
      const row = displayData[item.index];
      const id = row.data[indexToId];
      itemsToDelete.push(id);
      return item;
    });

    // call the handler
    const retVal = props.onRowsDelete(itemsToDelete);

    // and deselect the rows
    handleClickDeselectAll();
    return retVal;
  };

  const { selectedRows } = props;
  const multipleSelection = (selectedRows.data.length > 1);
  const editTooltip = multipleSelection ? 'You can only edit one item at a time' : 'Edit';
  const deleteTooltip = multipleSelection && props.allowMultipleDelete ? 'Delete' : 'You can delete one item at a time';

  return (
    <div className={"custom-toolbar-select"}>
      <Tooltip title={"Deselect ALL"}>
        <IconButton className={classes.iconButton} onClick={handleClickDeselectAll}>
          <IndeterminateCheckBoxIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Inverse selection"}>
        <IconButton className={classes.iconButton} onClick={handleClickInverseSelection}>
          <CompareArrowsIcon className={[classes.icon, classes.inverseIcon].join(" ")} />
        </IconButton>
      </Tooltip>
      <Tooltip title={editTooltip}>
        <span>
          <IconButton className={classes.iconButton}  disabled={multipleSelection} onClick={handleClickEdit}>
            <EditIcon className={classes.icon} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={deleteTooltip}>
        <IconButton className={classes.iconButton} onClick={handleClickDeleteRows} aria-label={"Delete"}>
          <DeleteIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </div>
    );
}

CustomToolbarSelect.defaultProps = {
  allowMultipleDelete: true
}

CustomToolbarSelect.propTypes = {
  displayData: PropTypes.array.isRequired,
  selectedRows: PropTypes.object.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  onRowsDelete: PropTypes.func.isRequired,
  allowMultipleDelete: PropTypes.bool,
  handleEdit: PropTypes.func.isRequired,
};

export default (CustomToolbarSelect);

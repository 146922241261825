import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Slide from '@material-ui/core/Slide';

import {useFamilyContext} from '../../../context/FamilyContext';

import PasscodeStorage from '../../../api/PasscodeStorage';

import {gql, useApolloClient} from '@apollo/client';
import {updateSettings} from '../../../graphql/mutations';

import ncrypter from '../../utilties/ncrypter';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  boldText: {
    fontWeight: 'bold'
  },
  error: {
    color: 'RED',
    width: '100%'
  }
}));

const MIN_PASSCODE_LENGTH = 8;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const SecureDataDialog = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [passcode, setPasscode] = useState(null);
  const [hint, setHint] = useState('');
  const [savePasscodeForSession, setSavePasscodeForSession] = useState(true);
  const [error, setError] = useState(null);

  const handleProtect= (ev) => {
    // save the settings
    const payload = {
      userRealm: familyContext.familyRealm.id,
      encryptedData: true,
      encryptionHint: hint,
      encryptedBase: ncrypter.encryptData(passcode, familyContext.familyRealm.id)
    }
    client.mutate( {
      mutation: gql(updateSettings),
      variables: {userRealm:familyContext.familyRealm.id, changes: payload}
    })
    .then(result => { 
      // store the passcode so we don't need to keep fetching
      if (savePasscodeForSession) {
        PasscodeStorage.setPasscode(passcode);
      }
      props.handleSave();
    })
    .catch(error => {
      console.log(error) 
      props.handleClose();
    });
  }

  const handleReEnterPasscode = (ev) => {
    if (passcode.toLowerCase().indexOf(ev.target.value.toLowerCase()) !== 0) {
      setError("Passwords do not match, try again")
    } else {
      setError(null);
    }
  }

  const {open, handleClose, handleDoNotCorrect} = props;

  const passwordHelperTxt = "Enter a passphrase (min. " + MIN_PASSCODE_LENGTH + " characters)"; 
  
  const bodyText1 = "The '" + props.dataName + "' field should be protected by a passphrase.";
  const bodyText2 = "Enter a passphrase to protect this field OR click 'Do Not Protect' to leave it unprotected";
  const bodyText3 = "Important Note: ";
  const bodyText4 = "DO not forget this passphrase! You will need to enter it periodically to access this data."
  return (
    <div classes = {classes.root}>
      <Dialog
        TransitionComponent={Transition}
        maxWidth = {'lg'}
        open={open}
        onClose={handleClose}
        aria-labelledby="protect-data-dialog-title">
        <DialogTitle>Protect your data</DialogTitle>
        <DialogContent>
          <DialogContentText>  
              {bodyText1}  
          </DialogContentText>  
          <DialogContentText>
            {bodyText2}
          </DialogContentText> 
            <TextField 
              label="Passphrase" 
              type = "password"
              margin="normal"
              className = {classes.textField}
              onChange = {(ev) => setPasscode(ev.target.value)}
              helperText = {passwordHelperTxt}
            />
            <TextField 
              label="Re-enter Passphrase" 
              type = "password"
              margin="normal"
              className = {classes.textField}
              onChange = {handleReEnterPasscode}
              helperText = {"Re-enter the passphrase."}
            />
            {error && 
              <Typography align="center" className={classes.error}>{error}</Typography>
            }
            <TextField 
              label="Hint" 
              margin="normal"
              className = {classes.textField}
              onChange = {(ev) => setHint(ev.target.value)}
              helperText = {"Enter a hint to help you remember your passphrase" }
            />
          <br/>
          <br/>
          <FormControlLabel
            control={
              <Checkbox
                checked={savePasscodeForSession}
                onChange={(event) => setSavePasscodeForSession(event.target.checked)}
                name="savePasscode"
                color="primary"
              />
            }
            label="Remember passphrase until you quit?"
          />  
          <br/>
          <br/>
          <DialogContentText gutterBottom>
            {<strong>{bodyText3}</strong>}
            {bodyText4}
          </DialogContentText> 
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDoNotCorrect} color="primary">
            Do Not Protect
          </Button>
          <Button onClick={handleProtect} disabled={passcode === null || passcode.length < MIN_PASSCODE_LENGTH} color="primary">
            Protect
          </Button>
        </DialogActions>
      </Dialog> 
    </div> 
  );
}

SecureDataDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  dataName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDoNotProtect: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default (SecureDataDialog)
import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


import { useAssetAdd } from "../../../api/APIHooks";

import Draggable from 'react-draggable';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import {AssetType} from '../../../Enums';

import {useFamilyContext} from '../../../context/FamilyContext';

const useStyles = makeStyles(theme => ({ 
  flex: {
    flex: 1,
  },
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: 200,
  },

  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
}));

const PaperComponent = (props) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuickAddAssets = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [name, setName] = useState('');

  const [addOneAsset] = useAssetAdd(familyContext.familyRealm.id, (data) => {
    console.log(data);
    if (props.handleReceiveData) {
      props.handleReceiveData(data);
    }
  });

  const save = (dismiss) => {
    // Mutation
    const details = {
      userRealm: familyContext.familyRealm.id,
      name: name,
      // set all the other fields to blank or default values
      type: props.assetType,
      description: '',
      notes: ''
    };

    addOneAsset({ variables: { object: details } })
    .then((result) => {
      handleSave(result.data.item);
      if (dismiss) {
        handleClose();
      } else {
        setName('');
        setFocus();
      } 
    })
    .catch((error) => {
      console.error(error);
    });
  }

  const handleSaveAndStay = () => {
    save(false);
  }

  const handleSaveAndGo = () => {
    save (true);
  }

  // need a ref to re-focus the form element
  const nameRef = useRef();
  const setFocus = () => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }

  const {open, handleClose, handleSave, assetType} = props;

  const title = "Quick Add Asset: " + assetType;

  let helpText;
  switch (assetType) {
    case AssetType.Housing: 
      helpText="Provide a descriptive name for this house (i.e. 'Our house' or 'Tahoe retreat')" 
      break;
    case AssetType.Building: 
      helpText="Provide a descriptive name for this rental property (i.e. 'Phoenix rental')" 
      break;
    case AssetType.Vehicle: 
      helpText="Provide a descriptive name for this vehicle (i.e. 'Red Corolla' or 'Dad's Truck'" 
      break;
    case AssetType.Pension: 
      helpText="Provide a descriptive name for this pension" 
      break;
    case AssetType.Annuity: 
      helpText="Provide a descriptive name for this annuity" 
      break;
    case AssetType.Digital: 
      helpText="Provide a descriptive name for this digital asset (i.e. 'The family website')" 
      break;
    case AssetType.Other: 
      helpText="Provide a descriptive name for this asset (i.e. 'Mom's sewing machine' or 'Cash under mattress')" 
      break;
    default: 
      helpText="Provide a descriptive name for this asset" 
  }
  
  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="form-dialog-title"
      >
      <DialogTitle style={{ cursor: 'move' }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <form>
          <FormControl
            className = {classes.formControl}  
          >
            <TextField
              inputRef = {nameRef}
              autoFocus
              name= "name"
              label = "Asset name"
              value = {name}
              onChange = { (ev) => setName(ev.target.value)}
              helperText={helpText}
            />
          </FormControl>
          <FormControl
            className = {classes.formControl}
          >
            <TextField
              disabled
              name = "assetType"
              label = "Asset type"
              value = {assetType}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveAndStay} disabled={name === ''} color="primary">
          Save and continue
        </Button>
        <Button onClick={handleSaveAndGo} disabled={name === ''} color="primary">
          Save and finish
        </Button>
      </DialogActions>
    </Dialog>   
  )
}

QuickAddAssets.propTypes = {
  open: PropTypes.bool.isRequired,
  assetType: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired,
}

export default (QuickAddAssets)
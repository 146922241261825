import * as yup from 'yup';

const phoneRegExp = /((\(\d{3}\)?)|(\d{3}))([\s-./]?)(\d{3})([\s-./]?)(\d{4})/

export const validationSchema = () => {
  const schema = yup.object({
    name: yup.string("Enter a name").required("Name is required"),
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid')
  })
  return schema
}


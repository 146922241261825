
import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";

import { firebaseAuth } from "../../index";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";

import { useAuthHelper } from "../../auth/AuthHook";
//import Auth0Config from "../../auth/Auth0Config";

//import { useFetchAuthUser } from "../../api/UserHooks";

import { useRecoilValue } from "recoil";
import { currentUserState } from "../../context/UserState";

import { UserContext } from "../../context/UserContext";
import { AuthContext } from "../../context/AuthContext";

import PlanSwitcher from "./PlanSwitcher";

import ChangePasswordDialog from "./forms/ChangePasswordDialog";
import ChangeUserNameDialog from "./forms/ChangeUserNameDialog";
import ChangeEmailDialog from "./forms/ChangeEmailDialog";

import LetterRoutes from "../../Routes";

const useStyles = makeStyles((theme) => ({
  typography: {
    margin: theme.spacing(2),
  },
  avatar: {
    margin: 10,
    color: theme.palette.text.main,
    backgroundColor: theme.palette.primary.main,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  logoutButton: {
    margin: theme.spacing(1),
  },
}));

const ProfileCard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const authContext = useContext(AuthContext);
  
  const authUser  = authContext.authUser;

  //const { appUser } = useFetchAppUser();
  const appUser = useRecoilValue(currentUserState);

  const { isAuthenticated, logoutUser } = useAuthHelper();

  const [anchorEl, setAnchorEl] = useState(null);
  const [userFullName, setUserFullName] = useState("");
  const [initials, setInitials] = useState("");

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(
    false
  );
  const [changeUsernameDialogOpen, setChangeUsernameDialogOpen] = useState(
    false
  );
  const [changeEmailDialogOpen, setChangeEmailDialogOpen] = useState(false);
  const [choosePlanDialogOpen, setChoosePlanDialogOpen] = useState(false);
  const [userRealms, setUserRealms] = useState([]);

  useEffect(() => {
    
    // set up what we need
    if (isAuthenticated()) {
      if (authUser) {
        if (authUser.displayName) {
          setUserFullName(authUser.displayName);
          // need initials for the avatar
          const nameParts = authUser.displayName.split(" ");
          if (nameParts.length > 1) {
            setInitials(nameParts[0].charAt(0) + nameParts[1].charAt(0))
          } else {
            setInitials(nameParts[0].charAt(0))
          }
        }
      }
      if (appUser) {
        setUserRealms(appUser.realms)
      }
    }
  }, [isAuthenticated, authUser, appUser]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logoutUser();
  };

  const handleChangePassword = () => {
    setChangePasswordDialogOpen(true);
  };

  const handleChangeEmail = () => {
    setChangeEmailDialogOpen(true);
  };

  const handleChangeUsername = () => {
    setChangeUsernameDialogOpen(true);
  };

  const handleEmailChanged = (newAuthUser) => {
    console.log ("Update user context with: " + newAuthUser.email)
    setChangeEmailDialogOpen(false);
  }

  const handleUsernameChanged = async (newAuthUser) => {
    console.log ("Update user context with: " + newAuthUser.displayName)
    
    // this reload will effectively update our UI
    await firebaseAuth.currentUser.reload()
    setChangeUsernameDialogOpen(false);
  }

  const handleCreateNewPlan = () => {
    // close our popover
    handleClose();

    // should WARN
    userContext.clearRealm();

    const path = LetterRoutes.LandingRoute;
    history.push(path, { sub: 'interview' });
  }
  
  const handlePlanSwitch = () => {
    // close our popover
    handleClose();
    
    setChoosePlanDialogOpen(true);
  }

  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      {isAuthenticated && authUser && (
        <div>
          <Tooltip title={"User profile"}>
            <IconButton
              aria-owns={open ? "account-popper" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Tooltip>
          <Popover
            id="account-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {authUser && (
                <Grid>
                  <Avatar className={classes.avatar} alt={userFullName}>{initials}</Avatar>
                </Grid>
              )}
              <Grid>
                <Typography align="center" className={classes.typography}>
                  {userFullName}
                </Typography>
              </Grid>
              <Grid>
                <ButtonGroup
                  orientation="vertical"
                  color="primary"
                  aria-label="vertical contained primary button group"
                  variant="text"
                >
                  {userRealms.length > 1 ? (
                    <Button
                      size="small"
                      className={classes.logoutButton}
                      onClick={handlePlanSwitch}
                      >
                      Switch Plans
                    </Button>
                  ) : null}  
                  <Button
                    size="small"
                    className={classes.logoutButton}
                    onClick={handleChangeEmail}
                  >
                    Change Email
                  </Button>   
                  {/* {authUser.username ? (
                    <Button
                      size="small"
                      className={classes.logoutButton}
                      onClick={handleChangeUsername}
                    >
                      Change Username
                    </Button>
                  ) : null}   */}
                 
                    <Button
                      size="small"
                      className={classes.logoutButton}
                      onClick={handleChangeUsername}
                    >
                      Change Username
                    </Button>
                 
                  <Button
                    size="small"
                    className={classes.logoutButton}
                    onClick={handleChangePassword}
                  >
                    Change Password
                  </Button>
                  <Button
                    size="small"
                    className={classes.logoutButton}
                    onClick = {handleCreateNewPlan}
                  >
                    Create new plan
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid>
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.logoutButton}
                  onClick={handleLogout}
                  color="inherit"
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          </Popover>
        </div>
      )}
      {authUser && (
        <ChangePasswordDialog
          open={changePasswordDialogOpen}
          authUser={authUser}
          handleClose={() => setChangePasswordDialogOpen(false)}
        />
      )}
      {authUser && (
        <ChangeUserNameDialog
          open={changeUsernameDialogOpen}
          authUser={authUser}
          handleClose={() => setChangeUsernameDialogOpen(false)}
          handleSave={handleUsernameChanged}
        />
      )}
      {authUser && (
        <ChangeEmailDialog
          open={changeEmailDialogOpen}
          authUser={authUser}
          handleClose={() => setChangeEmailDialogOpen(false)}
          handleSave = {handleEmailChanged}
        />
      )}
      {authUser && (
         <PlanSwitcher 
          open={choosePlanDialogOpen}
          userRealms={userRealms}
          handleClose={() =>  setChoosePlanDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default ProfileCard;

import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import { useApolloClient, useMutation, gql } from "@apollo/client";

import { addOneUserWithRealm } from "../../../api/UsersApi";
import { addUserToRealm } from "../../../graphql/mutations";
import { fetchOneUserByEmail } from "../../../api/UsersApi";

import UserAddEditFormCore from "./UserAddEditFormCore";

import InfoModal from "../../shared/modals/InfoModal";
import UserEditIssues from "../UserEditIssues";

import { FamilyContext } from "../../../context/FamilyContext";

const UserCreate = (props) => {
  const client = useApolloClient();
  const familyContext = useContext(FamilyContext);

  const [addUserRealm] = useMutation(gql(addUserToRealm));

  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const handleError = (values, currentUser) => {
    console.log("Received INREALM_ALREADY");
    setInfoModalOpen(true);
  };

  const handleSave = (values) => {
    // check first to see if we can continue with the save
    if (values.plansToLogin) {
      console.log("Must check email for uniqueness");
      // check to see if the email is already in use
      fetchOneUserByEmail(client, values.email)
        .then((user) => {
          let realmList = [];
          let inCurrentRealm = false;

          // simplify the realms
          user.UsersRealms.forEach((topLevelRealm) => {
            const realmEntry = {
              isOwner: topLevelRealm.isOwner,
              realmId: topLevelRealm.realmId,
              name: topLevelRealm.realms.name,
            };
            if (realmEntry.realmId === familyContext.familyRealm.id) {
              inCurrentRealm = true;
            } else {
              console.log("Not in this REALM");
            }
            realmList.push(realmEntry);
          });
          if (inCurrentRealm) {
            console.log("Sending INREALM_ALREADY");
            handleError(values, user);
          } else {
            console.log("Sending ADDTO_REALM");
            handleAddToRealm(values, user);
          }
        })
        .catch((error) => {
          // OK, we're in the clear
          console.log("Email address is NOT in use");
          handleFullSave(values);
        });
    } else {
      console.log("No plans to login");
      handleFullSave(values);
    }
  };

  const handleFullSave = (values) => {
    // save the new user - and add this realm to the new user

    // Test before save
    // alert(JSON.stringify(values, null, 2));
    // return
    const saveLoginName = values.firstName + "-" + values.lastName;
    const details = {
      loginName: saveLoginName.toLowerCase(),
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };
    addOneUserWithRealm(client, props.realm, values.isOwner, details)
      .then((result) => {
        const user = result;
        props.handleSave(user);
      })
      .catch((error) => {
        console.log(error);
        props.handleClose();
      });
  };

  const handleAddToRealm = (values, user) => {
    // this is an existing user, just adding to the current realm
    const details = {
      userId: user.id,
      realmId: props.realm,
      isOwner: values.isOwner,
    };

    addUserRealm({ variables: { object: details } })
      .then((_) => {
        props.handleSave(user);
      })
      .catch((error) => {
        console.log(error);
        props.handleClose();
      });
  };

  const handleInfoModalClose = () => {
    setInfoModalOpen(false);
    props.handleClose();
  };

  const title = "Add user";

  return (
    <>
      {props.open ? (
        <UserAddEditFormCore
          open={props.open}
          title={title}
          allowOwnerControl={props.allowOwnerControl}
          user={{
            firstName: "",
            lastName: "",
            email: "",
            isOwner: props.isOwner,
          }}
          handleClose={props.handleClose}
          handleSave={handleSave}
        />
      ) : null}
      <InfoModal
        title="User already exists"
        open={infoModalOpen}
        handleClose={handleInfoModalClose}
        body={
          <UserEditIssues
            messageLead="Nothing saved - this user is ALREADY part of your plan"
            editUser={{}}
            existingUser={{}}
          />
        }
      />
    </>
  );
};

UserCreate.defaultProps = {
  isOwner: false,
  allowOwnerControl: false,
};

UserCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  realm: PropTypes.string.isRequired,
  isOwner: PropTypes.bool,
  allowOwnerControl: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default UserCreate;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";

import { listContacts } from "../../graphql/queries";
import { useQuery, gql } from "@apollo/client";

import { ContactType, NonChoice } from "../../Enums";

import SearchInput from "../utilties/SearchInput";
import LTRDomainObjectInfo from "../shared/domains/LTRDomainObjectInfo";

import { useFamilyContext } from "../../context/FamilyContext";

import { prepareTableRowInfo } from "./ContactInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  actionButtons: {
    margin: theme.spacing(1),
  },
}));

const sortListByName = (names) => {
  const sortedList = names.sort((a, b) => {
    // specifically, payee
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });

  return sortedList;
};

const PeopleToContact = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [contacts, setContacts] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showInfoDialog, setShowInfoDialog] = useState(null);
  const [infoTableItem, setInfoTableItem] = useState(null);

  const [visibleList, setVisibleList] = useState([]);

  let { loading, error, data } = useQuery(gql(listContacts), {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  useEffect(() => {
    const onCompleted = (data) => {
      setContacts(data.item);
    };
    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    // just one reason we're here: if contacts change we need to adjust
    if (contacts) {
      const filteredContacts = contacts.filter((contact) => {
        return (
          contact.type === ContactType.Friend ||
          contact.type === ContactType.Family ||
          contact.type === ContactType.CoWorker
        );
      });
      const sortedList = sortListByName(filteredContacts);
      setVisibleList(sortedList);
    }
  }, [contacts]);

  const handleShowInfo = (contact) => {
    const tableInfo = prepareTableRowInfo(contact);
    setInfoTableItem(tableInfo);

    setSelectedContact(contact);
    setShowInfoDialog(true);
  };

  const handleShowInfoClose = () => {
    setSelectedContact(null);
    setShowInfoDialog(false);
  };

  const handleSearch = (searchTerm) => {
    let displayList;

    if (searchTerm === null) {
      displayList = [...contacts];
    } else {
      displayList = contacts.filter((item) => {
        const testName = item.name ? item.name.toLowerCase() : "";
        const testType = item.type ? item.type.toLowerCase() : "";
        const againstSearchTerms = searchTerm ? searchTerm.toLowerCase() : "";

        // test the relevant fields
        return (
          testName.includes(againstSearchTerms) ||
          testType.includes(againstSearchTerms)
        );
      });
    }
    setVisibleList(displayList);
  };

  // just return if we have no contacts or can't complete the query
  if (contacts === null) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <SearchInput searchUpdate={handleSearch} />
        </Grid>
        <Grid item xs={12}>
          <List dense={true}>
            {visibleList.map((contact) => (
              <ListItem key={contact.id}>
                {contact.phoneTreeLeader === NonChoice ? (
                  <ListItemText
                    primary={contact.name}
                    secondary={contact.type}
                  />
                ) : (
                  <ListItemText
                    primary={contact.name}
                    secondary={contact.type + " and a phone tree contact"}
                  />
                )}

                <ListItemSecondaryAction className={classes.actionButtons}>
                  <IconButton
                    edge="end"
                    aria-label="info"
                    onClick={() => handleShowInfo(contact)}
                  >
                    <InfoIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>

      {showInfoDialog && selectedContact ? (
        <LTRDomainObjectInfo
          open={showInfoDialog}
          title={selectedContact.name}
          tableData={infoTableItem}
          handleClose={handleShowInfoClose}
        />
      ) : null}
    </div>
  );
};

PeopleToContact.propTypes = {};

export default PeopleToContact;

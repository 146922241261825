import React, { useState, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Typography from "@material-ui/core/Typography";

import LoginState from "../../auth/LoginState";
import LetterRoutes from "../../Routes";
import Signin from "./Signin";
import InfoModal from "../shared/modals/InfoModal";

import { useApolloClient } from "@apollo/client";

import {
  UserContext,
  UserValidityStates,
  USERFIELD_NOT_SET,
} from "../../context/UserContext";
import { useFamilyContext } from "../../context/FamilyContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
  },
}));

// Credit to https://stackoverflow.com/a/2117523
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

const Login = (props) => {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();
  const familyContext = useFamilyContext();
  const client = useApolloClient();

  const [authenticated, setAuthenticated] = useState(false);
  const [infoModalOpenUnknown, setInfoModalOpenUnknown] = useState(false);
  const [infoModalOpenNoPlan, setInfoModalOpenNoPlan] = useState(false);

  // useEffect(() => {
  //   checkAuthentication();
  // });

  // const checkAuthentication = () => {
  //   const localAuthState = LoginState.isAuthenticated();
  //   if (localAuthState !== authenticated) {
  //     setAuthenticated (localAuthState)
  //   }
  //   if (localAuthState) {
  //     const userId = LoginState.getUserId();
  //     if (userId) {
  //       // NOT: SURE ABOUT THIS
  //       // const profile = {
  //       //   // TODO: need to provide this OR have userContext look it up
  //       //   // userFullName: user.name,
  //       //   // userFirstName: user.given_name,
  //       //   // userLastName: user.family_name,
  //       // //  userImageUrl: userProfile.userImageUrl,
  //       //   userEmail: userId
  //       // }
  //       // // notify the context that we're logged in
  //       // userContext.onLogin(profile);
  //     }
  //   }
  // }

  const onSuccess = (res) => {
    console.log("Login: login result");
    if (res.status === "SUCCESS") {
      // NOTE: unless we are whitelisting this validity check
      // is NOT really necessary
      // const userStatus = userContext.validUser(res.user.email);
      // if (userStatus === UserValidityStates.Valid) {
      //   // if we did login, update the context
      const user = {
        id: res.user.id,
        authId: res.user.authId,
        fullName: res.user.firstName + " " + res.user.lastName,
        firstName: res.user.firstName,
        lastName: res.user.lastName,
        //  userImageUrl: userProfile.userImageUrl,
        email: res.user.email,
        isOwner: res.userExt.isOwner,
        userRealm: res.userExt.userRealm
          ? res.userExt.userRealm
          : USERFIELD_NOT_SET,
      };
      // notify the context
      userContext.onLogin(user);

      // delegate ALL other login side effects (family context, loginstate) to 
      // the landing page and the code it calls - note: we will probably be calling the user context multiple times
      // to login
      history.push(LetterRoutes.LandingRoute);

      //   // update the LoginState
      //   // TODO: this is not necessary - if we need the token we can get it from the logged in user
      //   const createDateTime = moment.utc();
      //   const token = uuidv4().replace("-", "");
      //   LoginState.setTokenData(token, res.user.email, createDateTime);

      //   // set the current realm id and family, then navigate away
      //   if (user.userRealm !== USERFIELD_NOT_SET) {
      //     familyContext
      //       .setFamilyFromRealm(user.userRealm)
      //       .then((result) => {
      //         history.push(LetterRoutes.LandingRoute);
      //       })
      //       .catch((error) => {
      //         console.log("Failed to set up the family");
      //       });
      //   }
    } else {
      console.log('Unknown login result, problem detected');
      // if (userStatus.validity === UserValidityStates.Unknown) {
      //   // TODO: navigate to a page to signup, then start the interview
      //   showInfoModalUnknown();
      // }
    }
  };

  const showInfoModalUnknown = () => {
    setInfoModalOpenUnknown(true);
  };

  const hideInfoModalUnknown = () => {
    setInfoModalOpenUnknown(false);
  };

  const showInfoModalNoPlan = () => {
    setInfoModalOpenNoPlan(true);
  };

  const hideInfoModalNoPlan = () => {
    setInfoModalOpenNoPlan(false);
  };

  const noAccessBody = (
    <React.Fragment>
      <Typography variant="body1" className={classes.titleText} gutterBottom>
        {"Unrecognized user login! Unknown user name or password"}
      </Typography>
      <br />
      <Typography variant="body1" className={classes.titleText} gutterBottom>
        {"Try again or contact the administrator of this program for help."}
      </Typography>
    </React.Fragment>
  );

  const noPlanBody = (
    <React.Fragment>
      <Typography variant="body1" className={classes.titleText} gutterBottom>
        {"Unrecognized user login! Unknown user name or password"}
      </Typography>
      <br />
      <Typography variant="body1" className={classes.titleText} gutterBottom>
        {"Try again or contact the administrator of this program for help."}
      </Typography>
    </React.Fragment>
  );

  return authenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <>
      <Signin handleSuccess={onSuccess} />
      <InfoModal
        classes={{
          paper: classes.dialog,
        }}
        title="Unknown user"
        open={infoModalOpenNoPlan}
        handleClose={hideInfoModalNoPlan}
        body={noPlanBody}
      />
      <InfoModal
        classes={{
          paper: classes.dialog,
        }}
        title="Unknown user"
        open={infoModalOpenUnknown}
        handleClose={hideInfoModalUnknown}
        body={noAccessBody}
      />
    </>
  );
};

export default Login;

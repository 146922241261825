import { useContext } from "react";
import { useHistory } from "react-router-dom";

//import { useAuth0 } from "@auth0/auth0-react";
//import Auth0Config from "./Auth0Config";

import cloneDeep from "lodash.clonedeep";

import { useApolloClient } from '@apollo/client';

import LetterRoutes from "../Routes";

import { UserContext, USERFIELD_NOT_SET } from "../context/UserContext";
import { FamilyContext } from "../context/FamilyContext";
import { AuthContext } from "../context/AuthContext";
import { fetchOneUser } from '../api/UsersApi';

import LoginState from "./LoginState";

export const useAuthHelper = () => {
  const client = useApolloClient();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const familyContext = useContext(FamilyContext);
  const authContext = useContext(AuthContext);

  //const {isLoading, logout, loginWithRedirect } = useAuth0();

  const isAuthenticated = () => {
    // const user = firebaseAuth.currentUser;
    // if (user) {
    //   console.log('User IS authenticated')
    // } else {
    //   console.log('User IS NOT authenticated')
    // }
    // return user === null ? false : true;
    return authContext.isAuthenticated;
  }

  const getUser = () => {
    //return firebaseAuth.currentUser;
    return authContext.authUser;
  }

  const logoutUser = async () => {
    // Let the auth context logout the user
    await authContext.logout();
    
    // application logout
    userContext.onLogout();
    familyContext.onLogout();
    LoginState.clearUserData();

    // finally
    history.push('/');
  };

  const loginUser = (path) => { 
    if (authContext.isAuthenticated) {
      console.log('Pushing another route: ' + path)
      history.push(path);
    } else {
      console.log('Pushing login route')
      history.push(LetterRoutes.LandingRoute);
    }
  };

  const fetchUserById = async (userId) => {
    let promise = new Promise((resolve, reject) => {

      fetchOneUser(client, userId)
      .then(result => {
        const user = {...result.Users_by_pk};
        resolve(user);
      })
      .catch(error => {
        reject(error);
      });
    });
  
    let result = await promise; // wait till the promise resolves (*)
    return result;  
  }
  
  const restoreEnvironment = (userId, realmId) => {
    // need to fetch the user
    if (userId) {
      fetchUserById(userId)
      .then(userToLogin => {
        let realmList = [];
        
        // we may NOT be logged in to a realm when this call is made
        let loggedInRealm = realmId !== null ? realmId: USERFIELD_NOT_SET;
        let matchingRealmEntry = {};
  
        // simplify the realms
        userToLogin.UsersRealms.forEach ((topLevelRealm) => {
          const realmEntry = {
            isOwner: topLevelRealm.isOwner,
            realmId: topLevelRealm.realmId,
            name:topLevelRealm.realms.name
          }
          // is this the logged in realm?
          if (realmEntry.realmId === realmId) {
            loggedInRealm = realmEntry.realmId;
            matchingRealmEntry = realmEntry;
          }
          realmList.push(realmEntry)
        })
  
        let tempUser = cloneDeep(userToLogin);
        tempUser.realms = [...realmList];
  
        let user = {
          id: userToLogin.id,
          authId: userToLogin.authId,
          loginName: userToLogin.loginName,
          firstName: userToLogin.firstName,
          lastName: userToLogin.lastName,
          email: userToLogin.email,
          isOwner: loggedInRealm !== USERFIELD_NOT_SET ? matchingRealmEntry.isOwner : false,
          userRealm: loggedInRealm
        };
  
        // notify the user context that we have a login
        userContext.onLogin(user);
  
        // now, the family context
        if (loggedInRealm !== USERFIELD_NOT_SET) {
          familyContext
            .setFamilyFromRealm(user.userRealm)
            .then((_) => {
              //console.log('Successfully set up a family');
              // notify the family context that we are logged in to a specific realm
              familyContext.onLogin(user.userRealm);
            })
            .catch((_) => {
              //console.log('Failed to set up the family');
            });
        }
      })
      .catch((_) => {
        console.log('Cannot fetch user')
      })
    } else {
      console.log('No userid provided - cannot fetch user');
    }
   
  };

  return { isAuthenticated, getUser, loginUser, logoutUser, restoreEnvironment };
};

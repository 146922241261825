import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
}));

const UserEditIssues = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body1" color="initial">{props.messageLead}</Typography>
      <Typography variant="body1" color="initial">{props.messageTrail}</Typography>
    </div>
  )
};

UserEditIssues.defaultProps = {
  messageTrail : ""
}

UserEditIssues.propTypes = {
  messageLead: PropTypes.string.isRequired,
  messageTrail: PropTypes.string,
  editUser: PropTypes.object.isRequired,
  existingUser: PropTypes.object.isRequired,
};

export default UserEditIssues;

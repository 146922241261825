import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import Tooltip from "@material-ui/core/Tooltip";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import AddActionItemForm from '../../actionitems/AddActionItemForm';

import ConfirmModal from '../modals/ConfirmModal';
import InfoModal from '../modals/InfoModal';

import {useApolloClient, useMutation, gql} from '@apollo/client';
import {deleteAttachment} from '../../../graphql/mutations';

import {useFamilyContext} from '../../../context/FamilyContext';

import LTRDomainToolbar from './LTRDomainToolbar';
import LTRSnackbar, {buildAddMessage, buildDeleteMessage} from '../../utilties/LTRSnackbar';

import {domainTypeToOperationTarget} from '../../../api/CommonCrudOperations'
import {deleteActionItemsByDomainOwner} from '../../../api/ActionItemApi';
import {deleteBillerByDomainOwner, domainSupportsBilling} from '../../../api/BillerApi';
import {deleteNotesByDomainOwner, domainSupportsNotes} from '../../../api/DocumentApi';
import {deleteEndpoint} from '../../../api/AttachmentApi';

import {listAllAttachments} from '../../../graphql/queries';

import UploadDialog from '../../upload/UploadDialog';
import ContentViewer from '../contentviewers/ContentViewer';

import {sortListByName} from '../../utilties/SortFunctions';

const MENU_HEIGHT = 48;

const DomainMenuItems = {
  Info: 'Info',
  Edit: 'Edit',
  Delete: 'Remove',
  AddActionItem: 'Add an action item',
  UploadAttachment: 'Attach a file',
  RemoveAttachment: 'Remove attachment',
  ViewAttachment: 'View attachment',
  Tools: 'Tools'
}

export const RefreshReason = {
  UploadAttachment: 'UploadAttachment',
  DeleteAttachment: 'DeleteAttachment'
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 2,
    
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  }, 
  inline: {
    display: 'inline',
  }
}));

const LTRCommonNameList = (props) => {

  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false); 
  const [deleteAttachmentConfirmOpen, setDeleteAttachmentConfirmOpen] = useState(false);
  const [infoConfirmOpen, setInfoConfirmOpen] = useState(false); 
  const [addActionItemOpen, setAddActionItemOpen] = useState(false); 
  const [selected, setSelected] = useState({});
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
  const [anchorEl, setAnchorEl] = useState(null);

  const [visibleList, setVisibleList] = useState([]);
 
  const [attachmentDelete] = useMutation(gql(deleteAttachment), {
    refetchQueries: [
      {
        query: gql(listAllAttachments), 
        fetchPolicy: 'network-only',
        variables: {userRealm: familyContext.familyRealm.id}
      },
    ],
    awaitRefetchQueries: true,
    update(cache, result) {
      const resultData = result.data.delete_Attachments_by_pk;
      if (!cache.evict(resultData.__typename + ':' + resultData.id )) {
        console.log('Attachment NOT removed from the cache');
      }
      if (props.handleRefresh) {
        props.handleRefresh(selected, RefreshReason.DeleteAttachment)
      }
    }
  });

  useEffect(() => {
    // just one reason we're here: if  change we need to adjust
    if (props.list) {
      const sortedList = sortListByName(props.list);
      setVisibleList(sortedList);
    }
  }, [props.list]);

  const handleClick = (item, event) => {
    setSelected(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openSnackbar = (message) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  }

  const handleSearch = searchText => {
    if (props.handleSearch) {
      props.handleSearch(searchText);
    }
  }
  
  const handleUploadSuccess = (payload) => {
    setShowUploadDialog(false);
    if (props.handleRefresh) {
      props.handleRefresh(selected, RefreshReason.UploadAttachment);
    }
    // Note: one-off message
    openSnackbar("'" + payload.name + "' was uploaded successfully.");
  }
 
  // Note: if Info goes to the context menu it cannot rely on 'item' it has to rely on 'selected'
  const handleInfoRequest = (item) => {
    setSelected(item);
    if (props.handleInfo) {
      props.handleInfo(item.id);
    } else {
      setInfoConfirmOpen(true);
    }
  }

  const handleAddActionItem = (item) => {
    openSnackbar(buildAddMessage(item.name)); 
  }
  
  // Note: if Edit goes to the context menu it cannot rely on 'item' it has to rely on 'selected'
  const handleEdit = (item) => {
    setSelected(item);
    setAnchorEl(null);
    if (props.handleEdit) {
      props.handleEdit(item);
    } else {
      console.log('Edit clicked, not handled');
    }
  }

  const handleDelete = (item) => {
    setSelected(item);
    setDeleteConfirmOpen(true);
    setAnchorEl(null);
  }

  // dialog control
  const handleCancelDelete = () => {
    setSelected('');
    setDeleteConfirmOpen(false);
  }

  const fullyDeleteAttachment = (attachment) => {
   
    const request = new Request(deleteEndpoint + '/?name=' + attachment.userRealm + '/' + attachment.originalName, 
      {
        method: 'DElETE', 
      })
    fetch(request)
    .then((response) => {
      if (response.status === 200 || response.status === 204) {
        console.log('Delete request succeeded');
        attachmentDelete({variables: { id: attachment.id }})
        .then(resp => {
          console.log(resp);
          openSnackbar(buildDeleteMessage(attachment.name))
        })
        .catch(error => {
          console.log(error)
        })   
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }
 
  const handleConfirmDelete = () => {
    const target = domainTypeToOperationTarget(props.domain);
    console.assert(selected !== null, "Selected item must be set to do a delete");
    if (selected) {
      // TEST
      if (selected.actionItems) {
        console.log('Found %s action items', selected.actionItems.length)
      }

      if (selected.attachments) {
        console.log('Found %s attachments', selected.attachments.length)
      }

      // TEST - delete sequence

      // 1 delete the domain object
      // close the dialog
      setDeleteConfirmOpen(false); 

      // start the delete process
      if (props.handleDelete) {
        props.handleDelete(selected);
      
        // now, action items
        // first delete any action items associated with this domain object, 
        deleteActionItemsByDomainOwner(client, familyContext.familyRealm.id, target, selected.id)
        .then(result => { 
          console.log(result);
        })
        .catch(error => { 
          console.log(error) 
        });

        // and billers
        if (domainSupportsBilling (props.domain)) {
          deleteBillerByDomainOwner(client, selected.id)
          .then (resultBiller => {
            console.log(resultBiller);
          })
          .catch( error => {
            console.log(error);
          })
        }
  
        // next, delete any notes associated with this domain object - if this domain object supports notes
        if (domainSupportsNotes (props.domain)) {
          deleteNotesByDomainOwner(client, familyContext.familyRealm.id, selected.id)
          .then (resultNotes => {
            console.log(resultNotes);
          })
          .catch( error => {
            console.log(error);
          })
        }

        // and attachments
        if (selected.attachments && selected.attachments.length > 0) {
          // Note: even though this is an array there is only one attachment
          const attachment = selected.attachments[0];
          fullyDeleteAttachment(attachment); 
        }
      }
    } else {
      console.error('SERIOUS ISSUE: NO SELECTED ITEM TO DELETE')
    }
  }

  const handleAttachmentDeleteConfirmDialogOpen = (selected) => {
    setSelected(selected);
    setDeleteAttachmentConfirmOpen(true);
    setAnchorEl(null);
  }

  const handleAttachmentCancelDelete = () => {
    setSelected('');
    setDeleteAttachmentConfirmOpen(false);
  }

  const handleAttachmentConfirmDelete = () => {
  
    setDeleteAttachmentConfirmOpen(false);

    // lets get the attachment information
    if (selected.attachments && selected.attachments.length > 0) {
      const attachment = selected.attachments[0];
      fullyDeleteAttachment(attachment);
    }
  }

  const handleViewAttachment = (selected) => {
    setSelected(selected);
    setAnchorEl(null);
    setViewAttachmentDialog(true);
  }

  const handleAddActionItemOpen = (item) => {
    setSelected(item);
    setAnchorEl(null);
    setAddActionItemOpen(true);
    return (
      <AddActionItemForm 
        open = {true}
        handleAddNotification = {handleAddActionItem}
        handleClose={() => {setAddActionItemOpen(false)}}
        domainId = {item.id} 
        domainType = {props.domain}
        itemName = {item.name}/>
    )
  }

  const handleUploadDialogOpen = (selected) => {
    setSelected(selected);
    setAnchorEl(null);
    setShowUploadDialog(true);
  }
  
  const deleteBody = 'Are you sure you want to delete this ' +  props.domainSingular.toLowerCase() + '?';
  const infoBody = 'Info about the' +  props.domainSingular.toLowerCase();
  
  const options = {
    customToolbar: () => {
      return (
        <React.Fragment>
          {/* <LTRCommonAddObject 
            domain = {props.domain}
            domainSingular = {props.domainSingular}
            getObjectDetails = {getDetails}
            addParams = {props.addParams}
            handleAddedObject = {handleAddedObject} 
          /> */}
        {props.addComponent}

        </React.Fragment>
      );
    }
  };

  const RenderNothingFound = () => {
    const message = "No " + props.domain.toLowerCase() + " found."
    return (
      <Container maxWidth="sm" >
        <Typography align="center" variant="h6" color="initial">{message}</Typography>
      </Container>
    )
  }

  const timeOutContextMenu = () => {
    setAnchorEl(null);
  }

  const renderContextMenu = () => {

    // set a timeout for our context menu
    window.setTimeout(timeOutContextMenu, 5000);
    const attachments = props.hasAttachments && props.hasAttachments(selected);

    // notes are incompatible with attachments - don't show any attachment menu items
    // if the selected item has notes
    const hideAttachmentMenus = props.hasNotes && props.hasNotes(selected);
  
    return (
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={toolMenuOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            maxHeight: MENU_HEIGHT * 5,
            width: 250,
          },
        }}> 
        
        {props.menuExtensions ? (
            props.menuExtensions (selected)
          ) 
        : null}

        
        {!hideAttachmentMenus && attachments &&  
          <MenuItem key={DomainMenuItems.ViewAttachment}
            onClick = {()=> handleViewAttachment(selected)}>
            <ListItemIcon className={classes.icon}>
              <OpenInNewIcon />
            </ListItemIcon>
            <ListItemText primary={DomainMenuItems.ViewAttachment}/>
          </MenuItem> 
        }
        {!hideAttachmentMenus && attachments && 
          <MenuItem key={DomainMenuItems.RemoveAttachment}
            onClick = {()=> handleAttachmentDeleteConfirmDialogOpen(selected)}>
            <ListItemIcon className={classes.icon}>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary={DomainMenuItems.RemoveAttachment}/>
          </MenuItem> 
        }
        {!hideAttachmentMenus && !attachments &&
          <MenuItem key={DomainMenuItems.UploadAttachment}
              onClick = {()=> handleUploadDialogOpen(selected)}>
            <ListItemIcon className={classes.icon}>
              <AttachFileIcon />
            </ListItemIcon>
            <ListItemText primary={DomainMenuItems.UploadAttachment}/>
          </MenuItem> 
        }
        <Divider />    
        <MenuItem key={DomainMenuItems.AddActionItem}
                onClick = {()=> handleAddActionItemOpen(selected)}>
          <ListItemIcon className={classes.icon}>
              <AddIcon />
          </ListItemIcon>
          <ListItemText primary={DomainMenuItems.AddActionItem} />
        </MenuItem>
      </Menu>
    )
  }

  const deleteConfirmTitle= "Confirm " + props.domainSingular.toLowerCase() + " deletion";
  const infoConfirmTitle = "Info About the " + props.domainSingular;

  const toolMenuOpen = anchorEl !== null;

  return (
    <React.Fragment> 
      <div className={classes.root}>
        <LTRDomainToolbar 
          classes={{
            root: classes.root,
          }}
          title = {props.domain}
          searchTextUpdate = {handleSearch}
          options = {options}
          />
        {visibleList.length > 0 ?
          <>
          <List>
            {visibleList.map(item => (
              <ListItem
                key={item.id}
                role={undefined}
                alignItems="flex-start"
                divider
                className={classes.listItem}
                onClick={() => setSelected(item)}
              >
                <ListItemText primary={item.name} secondary={item.type}/>
                <ListItemSecondaryAction>
                  <Tooltip title={DomainMenuItems.Info}>
                    <IconButton aria-label={DomainMenuItems.Info}
                      onClick={() => {     
                        handleInfoRequest(item)}
                      }
                    >
                      <InfoIcon />                   
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={DomainMenuItems.Edit}>
                    <IconButton aria-label={DomainMenuItems.Edit}
                      onClick={()=> {       
                        handleEdit(item)}
                      }
                      >
                      <EditIcon /> 
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={DomainMenuItems.Delete}>
                    <IconButton aria-label={DomainMenuItems.Delete}
                      onClick={()=> {       
                        handleDelete(item)}
                      }
                      >
                      <DeleteIcon /> 
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={DomainMenuItems.Tools}>
                    <IconButton aria-label={DomainMenuItems.Tools}
                      onClick={(event)=> handleClick(item, event)}
                      >
                      <MoreVertIcon /> 
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>          
            ))}
          </List> 
          {toolMenuOpen ? 
            renderContextMenu() 
          : null}
          </>
        : (
          <RenderNothingFound />
          )
        }
         
        <ConfirmModal
          title={deleteConfirmTitle}
          open={deleteConfirmOpen}
          handleConfirm={handleConfirmDelete}
          handleClose={handleCancelDelete}
          body={deleteBody}
        />
        <ConfirmModal
          title={'Confirm attachment deletion'}
          open={deleteAttachmentConfirmOpen}
          handleConfirm={handleAttachmentConfirmDelete}
          handleClose={handleAttachmentCancelDelete}
          body={'Are you sure you want to delete this attachment?'}
        />
        <InfoModal
          title={infoConfirmTitle} 
          open={infoConfirmOpen}
          handleClose={() => {setInfoConfirmOpen(false)}}
          body={infoBody}
        />
 
        {addActionItemOpen ? (
          <AddActionItemForm 
            open={addActionItemOpen}
            handleAddNotification = {handleAddActionItem}
            handleClose = {() => {setAddActionItemOpen(false)}}
            domainId = {selected.id} 
            domainType = {props.domain}
            itemName = {selected.name}/> 
         ): null}
         {showUploadDialog ? (
            <UploadDialog
              open = {showUploadDialog}
              domainId = {selected.id} 
              domainType = {props.domain}
              itemName = {selected.name}
              handleCancel = {() => {setShowUploadDialog(false)}}
              handleSuccess = {handleUploadSuccess}
            />): null
          }
          {viewAttachmentDialog  && selected ? (
            <ContentViewer 
              open = {viewAttachmentDialog}
              item = {selected}
              handleClose = { () => setViewAttachmentDialog(false)}
            />
            ): null
          }
          <LTRSnackbar 
            open = {snackbarOpen}
            message = {snackbarMessage}
            handleClose = {handleSnackbarClose}
          />             
      </div>
    </React.Fragment>
  );
}

LTRCommonNameList.defaultProps = {
  menuExtensions: null,
  handleEdit: null,
  handleSearch: null,
  hasAttachments: null,
  getAttachment: null,
  hasNotes: null,
  handleRefresh: null
}

LTRCommonNameList.propTypes = {
  list: PropTypes.array.isRequired,
  domain: PropTypes.string.isRequired,
  domainSingular: PropTypes.string.isRequired,
  hasAttachments: PropTypes.func,
  getAttachment: PropTypes.func,
  hasNotes: PropTypes.func,
  addComponent: PropTypes.object.isRequired,
  menuExtensions: PropTypes.func,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func,
  handleInfo: PropTypes.func.isRequired,
  handleSearch: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default (LTRCommonNameList)

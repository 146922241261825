import {gql} from '@apollo/client';

import {DomainType} from '../Enums';

import {
  updateBiller,
  deleteBiller,
  deleteBillerByDomainId
} from '../graphql/mutations';

export function domainSupportsBilling (domainType) {
  let retVal = false;
  switch (domainType) {
    case DomainType.Insurance:
    case DomainType.Liabilities:
    case DomainType.Services:
    case DomainType.Subscriptions:
      retVal = true;
      break;
    default: 
      break;
  }
  return retVal;
}

export async function updateOneBiller(client, id, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(updateBiller),
      variables: {id: id, changes: payload}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function deleteOneBiller(client, id) { 
  let promise = new Promise((resolve, reject) => {
    client.mutate( {
      mutation: gql(deleteBiller), 
      variables: {id: id}
    })
    .then(result => { 
      console.log(result); 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  })
  

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function deleteBillerByDomainOwner(client, domainId) { 
  let promise = new Promise((resolve, reject) => {
 
    client.mutate( {
      mutation: gql(deleteBillerByDomainId), 
      variables: {domainId: domainId}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import LTRCommonLayout from '../shared/domains/LTRCommonLayout';
import LTRCommonNameList, {RefreshReason} from '../shared/domains/LTRCommonNameList';
import LTRCommonDataTable from '../shared/domains/LTRCommonDataTable';
import LTRDomainObjectInfo from '../shared/domains/LTRDomainObjectInfo';

import LTRSnackbar, {buildAddMessage, buildEditMessage, buildDeleteMessage} from '../utilties/LTRSnackbar';

import {useApolloClient, useQuery,  gql} from '@apollo/client';
import {listContacts} from '../../graphql/queries';
import {useContactDelete} from '../../api/APIHooks';

import {OperationTargets, addMultipleDomainInstances} from '../../api/CommonCrudOperations';

import {DomainType, DomainTypeSingular, ToolbarActions,  NonChoice} from '../../Enums';

import AddEditContactForm from './forms/AddEditContactForm';
import ContactActionPanel from './forms/ContactActionPanel';

import ImportDialog from '../import/ImportDialog';

import {prepareTableRowInfo} from './ContactInfo'

import {sortListByName} from '../utilties/SortFunctions';

import {useFamilyContext} from '../../context/FamilyContext';

import {useSnackbar} from '../utilties/snackbarUtil';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const Contacts = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [contacts, setContacts] = useState(null); 
  
  const [editFormOpen, setEditFormOpen] = useState(false); 
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [infoTableItem, setInfoTableItem] = useState(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const [visibleList, setVisibleList] = useState([]);

  const {openSnackbar, closeSnackbar, snackbarOpen, snackbarMessage}  = useSnackbar();

  const { loading, error, data, refetch } = useQuery(gql(listContacts), {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  const processReceivedData = (data) => {
    setContacts(data);
  }

  const [deleteOneContact] = useContactDelete(familyContext.familyRealm.id, (data) => {
    console.log(data);
    //processReceivedData(data);
  });

  useEffect(
    () => {
      const onCompleted = data => {
        processReceivedData(data.item);
      }
      const onError = error => {
        return (
          <div>{error}</div>
        )
      }
      if (onCompleted || onError) {
        if (onCompleted && !loading && !error) {
          onCompleted(data)
        } else if (onError && !loading && error) {
          onError(error)
        }
      }
    },
    [loading, data, error]
  )

  useEffect(() => {
    // just one reason we're here: if contacts change we need to adjust
    if (contacts) {
      const sortedList = sortListByName(contacts);
      setVisibleList(sortedList);
    }
  }, [contacts]);

  const handleSearch = searchTerm => {
    if (contacts.length === 0) {
      return [];
    }

    let displayList;
    if (searchTerm === null) {
      displayList = [...contacts];
    } else {
      displayList = contacts.filter(item =>{
        const testName = item.name ? item.name.toLowerCase(): '';
        const testType = item.type ? item.type.toLowerCase(): '';
        const testCompany = item.company ? item.company.toLowerCase(): '';
        const testWebsite = item.website ? item.website.toLowerCase(): '';
        const testNotes = item.notes ? item.notes.toLowerCase() : '';
        const againstSearchTerms = searchTerm ? searchTerm.toLowerCase() : "";
  
        // test the relevant fields
        return testName.includes(againstSearchTerms) ||
                testType.includes(againstSearchTerms) ||
                  testCompany.includes(againstSearchTerms) ||
                    testWebsite.includes(againstSearchTerms) ||
                    testNotes.includes(againstSearchTerms);
      })
    }
    return setVisibleList(displayList);
  };
  
  const handleAddNotification = (item) => {
    if (item) {
      openSnackbar(buildAddMessage(item.name));
    }
  }

  const handleEditNotification = (item) => {
    if (item) {
      openSnackbar(buildEditMessage(item.name));
    }
  }
  
  const handleEditOpen = (selectedItem) => {
    setSelectedItem(selectedItem);
    setEditFormOpen(true);
  }

  const handleEditClose = () => {
    setSelectedItem(null);
    setEditFormOpen(false);
  }

  const handleDelete = (selected) => {
    deleteOneContact({ variables: { id: selected.id }})
    .then(result => {
      openSnackbar(buildDeleteMessage(result.data.item.name));
    })
    .catch(error => {
      console.log(error)
    })
  }

  const handleMultiDeleteNotification = (selected) => {
    const aList = contacts.filter( item => {
      return selected.includes(item.id) ? false : true;
    });
    
    let userMessage = "";
    const numItemsDeleted = selected.length;
    if (numItemsDeleted > 1) {
      userMessage = numItemsDeleted + ' contacts deleted successfully';
      openSnackbar(userMessage);
    } else {
      userMessage = 'Contact deleted successfully';
      openSnackbar(userMessage);
    }  
    setContacts(aList); 
  }

  const handleGlobalActions = (action) => { 
    setImportDialogOpen(true);
  }

  const handleImportClose = () => {
    setImportDialogOpen(false);
  }

  const handleImportSave = (data) => {
    const target = OperationTargets.Contacts;

    // Mutation - add as many as we've imported 
    // payload is an array of contacts to insert
    console.log('Incoming contacts')
    console.log(data)

    const payload = data.map ( (contact) => {
     

      const details = {
        userRealm: familyContext.familyRealm.id,
        name: contact.fullName,
        company: contact.company ? contact.company: '',
        notes: contact.notes ? contact.notes: '',
        type: contact.type ? contact.type: '',
      }

      let phones = [];
      if (contact.phones) {
        phones = contact.phones.map ((phone) => {
          let newPhone = {};
          newPhone.phoneNumber = phone.value.toString();
          newPhone.type = '';
          if (phone.type) {
            newPhone.type = phone.type;
          }
          return newPhone;
        })
      }
        
      if (phones.length > 0) {
        details.ContactPhone = {
          data: [...phones]
        }
      }

      let emails = [];
      if (contact.emails) {
        emails = contact.emails.map ((email) => {
          let newEmail = {};
          newEmail.emailAddress = email.value;
          newEmail.type = '';
          if (email.type) {
            newEmail.type = email.type;
          }
          return newEmail;
        })
      }

      if (emails.length > 0) {
        details.ContactEmail = {
          data: [...emails]
        }
      }

      let addresses = [];
      if (contact.addresses) {
        addresses = contact.addresses.map ((address) => {
          let newAddress = {};
          newAddress.streetAddress1 = address.street;
          newAddress.poBox = address.poBox ? address.poBox : "";
          newAddress.city = address.city ? address.city: "";
          newAddress.state = address.state ? address.state: "";
          newAddress.postalCode = address.postalCode ? address.postalCode: "";
          newAddress.country = address.country ? address.country: "";
          newAddress.type = "";
          if (address.type) {
            newAddress.type = address.type;
          }
          return newAddress;
        }) 
      }

      if (addresses.length > 0) {
        details.ContactAddress = {
          data: [...addresses]
        }
      }

      return details;
    })
   
    console.log('Prepared to send')
    console.log(payload);
    addMultipleDomainInstances(client, familyContext.familyRealm.id, target, payload)
    .then(data => { 
      const newContacts = data.item.returning;
      const newList = [...contacts, ...newContacts]
      const message = newContacts.length.toString() + ' contacts added successfully'
      openSnackbar(message)
      processReceivedData(newList); 

      console.log(contacts);
    })
    .catch(error => { console.log(error) });

    setImportDialogOpen(false);
  }

  const handleEditFromId = (selectedId) => {
    const aList = contacts.filter( item => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!
    
    // call the handleEdit open handler
    handleEditOpen(aList[0]);
  } 

  const handleRefresh = (selected, refreshReason) => {
    console.log('Selected:')
    console.log(selected)

    switch (refreshReason) {
      case RefreshReason.UploadAttachment:
        console.log('Refresh - upload')
        refetch();
        break;
      case RefreshReason.DeleteAttachment: 
        console.log('Refresh - delete')

        const list = contacts.filter(i => i.id !== selected.id)
        selected.attachments = [];
        list.push(selected);
        processReceivedData(list);
        break;
      default: 
          console.log('NOT A VALID PATH');
          break;
    }  
  }

  const handleShowInfo = (selectedId) => {
    const aList = contacts.filter( item => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!

    const contact = aList[0];
    const tableInfo = prepareTableRowInfo(contact);
    setInfoTableItem(tableInfo);
  
    setSelectedItem(aList[0]);
    setShowInfoDialog(true);
  }

  const handleShowInfoClose = () => {
    setSelectedItem(null);
    setShowInfoDialog(false);
  }

  const checkForAttachments = (selected) => {
    return (selected.attachments.length > 0);
  }

  const handleViewChange = (oldView, newView) => {
    // whenever we get a view change, we need to cancel all filtering, searching and restore the display list
    handleSearch(null);
  }

  // are we ready?
  if (contacts === null) {
    return (<div>Loading ...</div>)
  }

  // we want to add an Import tool
  const toolbarActions = [ToolbarActions.Import];

  return (
    <React.Fragment>
      <LTRCommonLayout 
          domain={DomainType.Contacts}
          domainSingular = {DomainTypeSingular.Contacts}
          handleViewChange = {handleViewChange}
          commonNameList = {     
            <div className={classes.root}>    
              <LTRCommonNameList 
                list = {visibleList}
                domain={DomainType.Contacts}
                domainSingular = {DomainTypeSingular.Contacts}
                hasAttachments = {checkForAttachments}
                addComponent = {
                  <AddEditContactForm 
                    handleAdd={handleAddNotification} 
                    handleEdit={handleEditNotification}
                    handleReceiveData = {processReceivedData} 
                  />}
                handleDelete={handleDelete}
                handleEdit = {handleEditOpen}
                handleInfo = {handleShowInfo}
                handleSearch = {handleSearch}
                handleRefresh = {handleRefresh}
              />
            </div>
          }
          commonNameTable = {
            <LTRCommonDataTable 
              domain= {DomainType.Contacts} 
              domainColumns = {
                [
                  { name: "name", label:"Name"},
                  { name: "type", label:"Type"},
                  { name: "company", label:"Company"},
                  { name: "website", label:"Website"},
                  { name: "phoneTreeLeader", label: "PhoneTree Leader",
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => {
                        if (value === NonChoice) {
                          return "";
                        } else {
                          return value;
                        }
                      }
                    } 
                  },
                  { name: "ContactPhone", label: "Phones",
                    options: {
                      filter: false,
                      sort: false,
                      customBodyRenderLite: (dataIndex, rowIndex) => {
                        const item = visibleList[dataIndex];
                        let phoneString = '';
                        if (item.ContactPhone) {
                          const phones = item.ContactPhone;
                        
                          phones.map( (phone, index) => {
                            phoneString = phoneString.concat(phone.phoneNumber);
                            if (phone.type) {
                              phoneString = phoneString.concat(' (' + phone.type + ')');  
                            }
                            if (index < phones.length-1) {
                              phoneString = phoneString.concat(', ');
                            }
                            return phoneString;
                          })
                        }  
                        return phoneString;
                      }
                    }        
                  },
                  { name: "ContactEmail", label: "Email",
                    options: {
                      filter: false,
                      sort: false,
                      customBodyRenderLite: (dataIndex, rowIndex) => {
                        const item = visibleList[dataIndex];
                        let emailString = '';
                        if (item.ContactEmail) {
                          const emails = item.ContactEmail;
                         
                          emails.map( (email, index) => {
                            emailString = emailString.concat(email.emailAddress);
                            if (index < emails.length-1) {
                              emailString = emailString.concat(', ');
                            }
                            return emailString
                          })
                        } 
                        return emailString;
                      }
                    }          
                  },
                  { name: "notes", label:"Notes" },
                ]
              }
              dataItems = {visibleList}
              handleMultiDeleteNotification = {handleMultiDeleteNotification} 
              handleEdit = {handleEditFromId}       
            />
          }
          actionPanel = {
            <ContactActionPanel 
              handleAddNotification={handleAddNotification} 
              handleReceiveData={processReceivedData}
              />
          }
          toolbarActionHandler = {handleGlobalActions}
          toolbarActionsNeeded = {toolbarActions}
          />
        {editFormOpen ? (<AddEditContactForm 
          open = {editFormOpen} 
          selected = {selectedItem} 
          handleAdd={handleAddNotification} 
          handleEdit={handleEditNotification} 
          handleClose = {handleEditClose}
          handleReceiveData = {processReceivedData} 
          />)
          : null
        }
        {importDialogOpen ? (
          <ImportDialog 
            open = {importDialogOpen}
            domain = {DomainType.Contacts}
            handleClose = {handleImportClose}
            handleSave = {handleImportSave}
          />) 
          : null
        }
        {showInfoDialog ? (
          <LTRDomainObjectInfo
            open = {showInfoDialog}
            title = {selectedItem.name}
            tableData = {infoTableItem}
            handleClose = {handleShowInfoClose}
        />): null
        }
        <LTRSnackbar 
          open = {snackbarOpen}
          message = {snackbarMessage}
          handleClose = {closeSnackbar}
        />
    </React.Fragment>
  );
}


export default (Contacts);
  
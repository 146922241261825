import {ContactType} from '../../Enums';

/*
  todo: what to do about
  - company
  - contact type
  - phone type
  - email type
  - address type
  - notes

    TITLE: "title",
        TEL: "telephone",
        FN: "displayName",
        N: "name",
        EMAIL: "email",
    CATEGORIES: "categories",
        ADR: "address",
        URL: "url",
        NOTE: "notes",
        ORG: "organization",
    BDAY: "birthday",
    PHOTO: "photo",
    VERSION: "version",
    PRODID: "prodid"

  change address handling: collect array of new addresses to pass along to the save method

*/

const identifyContactType = (labels) => {
 
  const regExpFriend = /friend/i;
  const regExpFamily = /family/i;
  const regExpMedical = /doctor/i;
  const regExpBusiness = /business/i;
  const regExpCoworkers = /coworker/i;
  const regExpNeighbors = /neighbor/i;

  // check friend
  const results = labels.map( (label) => {
    let result = label.match(regExpFriend);
    if (result !== null) {
      return ContactType.Friend;
    }
  
    result = label.match(regExpFamily);
    if (result !== null) {
      return ContactType.Family;
    }
    
    result = label.match(regExpMedical);
    if (result !== null) {
      return ContactType.Medical;
    }
  
    result = label.match(regExpBusiness);
    if (result !== null) {
      return ContactType.Business;
    }
  
    result = label.match(regExpCoworkers);
    if (result !== null) {
      return ContactType.CoWorker;
    }
  
    result = label.match(regExpNeighbors);
    if (result !== null) {
      return ContactType.Neighbor;
    }
    return null;
  })
  
  return results.length ? results[0] : "";
}

const identifyPhoneType = (typeInfo) => {
 
  const regExpCell = /cell/i;
  const regExpVoice = /voice/i;
  const regExpWork = /work/i;
  const regExpHome = /home/i;
 
  if (Array.isArray(typeInfo)) {
    const results = typeInfo.map( (t) => {
      // check type in priority order
      let result = t.value.match(regExpCell);
      if (result !== null) {
        return "Cell";
      }
      result = t.value.match(regExpWork);
      if (result !== null) {
        return "Work";
      }
      result = t.value.match(regExpHome);
      if (result !== null) {
        return "Home";
      }
      result = t.value.match(regExpVoice);
      if (result !== null) {
        return "Voice";
      } 
      // as is - no matches
      if (t.value.length > 1) {
        return t.value;
      }  
    })
    return results.length ? results[0] : "";
  } else {
    return "";
  } 
}

const identifyEmailType = (typeInfo) => {
 
  const regExpInternet = /internet/i;
  const regExpWork = /work/i;
  const regExpHome = /home/i;
  const notFound = "";
 
  if (Array.isArray(typeInfo)) {
    const results = typeInfo.map( (t) => {
      // check type in priority order
      let result = t.value.match(regExpWork);
      if (result !== null) {
        return {
          value: "Work",
          priority: 1
        }
      } 
      result = t.value.match(regExpHome);
      if (result !== null) {
        return {
          value: "Home",
          priority: 2
        }
      }
      result = t.value.match(regExpInternet);
      if (result !== null) {
        return {
          value: "Personal",
          priority: 3
        }
      }
      // as is - no matches
      if (t.value.length > 1) {
        return {
          value: t.value,
          priority: 4
        }
      }
      return result;
    })

    // 0 result
    if (results.length === 0) {
      return "";
    }
  
    // 1 result? return it
    if (results.length === 1) {
      return results[0].value;
    }

    // multiple results - sort, then choose
    if (results.length > 1) {
      let retVal = results.sort( (a, b) => {
        return a.priority - b.priority;
      });
      if (retVal.length) {
        return retVal[0].value;
      } else {
        return notFound;
      }
    } 
  } else {
    return notFound;
  } 
}

export const mapContactsFromVCard = (rows) => {  
  console.log('Mapping contacts from VCard')
  console.log(rows);
 
  const newRows = rows.map ((row, index) => {
    let newRow = {};
    // just to satisfy the table unique key requirement
    newRow.id = index;
    
    // prefer the display name to the name
    if (row.displayName) {
      newRow.fullName = row.displayName;
    } else if (row.name) {
      newRow.fullName = row.name;
    }

    // contact type
    if (row.categories) {
      newRow.type = identifyContactType(row.categories);
    }

    // company
    if (row.organization) {
      newRow.company = row.organization.replace('\\', '');
    }

    // url 
    if (row.url) {
      newRow.website = row.url;
    }

    // notes
    if (row.notes) {
      newRow.notes = row.notes;
    }

    // phones
    newRow.phones = [];
    if (row.telephone !== undefined) {
      let phones = row.telephone.map ((phone) => {
        return {
          value: phone.value,
          type: identifyPhoneType(phone.valueInfo)
        }
      })
      newRow.phones = [...phones];
    }

    // emails
    newRow.emails = [];
    if (row.email !== undefined) {
      let emails = row.email.map ((email) => {
        return {
          value: email.value,
          type: identifyEmailType(email.valueInfo)
        }
      })
      newRow.emails = [...emails];
    }
    
    // address
    newRow.addresses = [];
    if (row.address !== undefined) {
      const addresses = row.address.map( (address) => {
        return {
          street: address.value.street,
          city: address.value.city,
          state: address.value.region,
          country: address.value.country,
          poBox:  address.value.postOfficeBox,
          postalCode: address.value.postalCode
        }
      });
      newRow.addresses = addresses;
    }
    if (row.notes) {
      newRow.notes = row.notes;
    }
    console.log('Adding new contact: ')
    console.log(newRow);
    return newRow;
  })
  return newRows; 
}
import {
  ActionItemType,
  GovernmentBenefitType,
  PriorityType,
  DocumentType,
  NonChoice,
} from "../../../Enums";

import { OperationTargets } from "../../../api/CommonCrudOperations";
import {
  addMultipleActionItems,
  addOneActionItem,
} from "../../../api/ActionItemApi";

import { NO_WILL_OR_TRUST } from "./DocCheckList";

const prepareDetailsForWillTrust = (document) => {
  let priority = PriorityType.Medium;

  let name;
  let notes = "";
  let owner = NonChoice;
  if (document.trustOrWill === NO_WILL_OR_TRUST) {
    // owner action item
    priority = PriorityType.Top;
    name = "Create a will or a trust!";
    notes =
      "This is critical to ensuring that your estate is passed along according to your wishes.";
    owner = document.user;
  } else {
    name = "Find the will/trust and make copies of the summary pages.";
    notes =
      "Many institutions require a summary page from a will/trust to transfer/sell assets.";
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: "Open",
    owner: owner,
    type: ActionItemType.Documents,
    notes: notes,
  };
  return actionItemDetails;
};

const prepareDetailsForAdvancedHealthDirective = (document) => {
  let priority = PriorityType.Medium;

  let name;
  let owner = NonChoice;
  if (document.haveAdvancedHealthDirective) {
    name =
      "Find the health directive for '" + document.user + "' and make copies.";
  } else {
    // owner item
    priority = PriorityType.High;
    name =
      "Create a health directive for '" +
      document.user +
      "' and make sure it can be easily found.";
    owner = document.user;
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: "Open",
    owner: owner,
    type: ActionItemType.Documents,
    notes:
      "Make sure that physicians/hospitals have a copy AND the trustee is aware of his/her responsibilities.",
  };
  return actionItemDetails;
};

const prepareDetailsForPowerOfAttorney = (document) => {
  let priority = PriorityType.Medium;

  let name;
  let owner = NonChoice;
  if (document.havePowerOfAttorney) {
    name =
      "Find the power of attorney document for '" +
      document.user +
      "' and make sure the trustee for it is aware of their responsibilities.";
  } else {
    priority = PriorityType.High;
    name =
      "Create a power of attorney document for '" +
      document.user +
      "' and make sure it can be easily found.";
    owner = document.user;
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: "Open",
    owner: owner,
    type: ActionItemType.Documents,
    notes: "",
  };
  return actionItemDetails;
};

const prepareDetailsForMedicare = (benefit) => {
  let priority = PriorityType.Medium;

  let name;
  let owner = NonChoice;
  // only create an action item if it is medicare
  if (!benefit.value) {
    name =
      "Make sure '" +
      benefit.user +
      "' signs up for Medicare when eligible. Medicare signup should ALWAYS be done, even if there is no plan to use it.";
    owner = benefit.user;
  } else {
    name = "Contact Medicare to cancel the benefit for '" + benefit.user + "'";
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: "Open",
    owner: owner,
    type: ActionItemType.Insurance,
    notes: "",
  };

  return actionItemDetails;
};

const prepareDetailsForSocialSecurity = (benefit) => {
  let priority = PriorityType.Medium;

  let name;

  // only create an action item if we already have social security
  if (benefit.value) {
    return null;
  }

  if (benefit.value) {
    name =
      "Contact the Social Security Administration to cancel the benefit for '" +
      benefit.user +
      "'";
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: "Open",
    type: ActionItemType.Insurance,
    notes: "",
  };

  return actionItemDetails;
};

export const constructDocActionItems = (document, actions) => {
  // Note: each detail preparer is assumed to only prepare 1 action item; if more action
  // items are created then this code must change

  const objects = [];
  if (document.type === DocumentType.WillOrTrust) {
    const willTrustDetails = prepareDetailsForWillTrust(document);
    if (willTrustDetails) {
      willTrustDetails.domainName = document.name;
      objects.push(willTrustDetails);
    }
  }
  if (document.type === DocumentType.HealthDirective) {
    const healthDirectiveDetails = prepareDetailsForAdvancedHealthDirective(
      document
    );
    if (healthDirectiveDetails) {
      healthDirectiveDetails.domainName = document.name;
      objects.push(healthDirectiveDetails);
    }
  }
  if (document.type === DocumentType.PowerOfAttorney) {
    const powerOfAttorneyDetails = prepareDetailsForPowerOfAttorney(document);
    if (powerOfAttorneyDetails) {
      powerOfAttorneyDetails.domainName = document.name;
      objects.push(powerOfAttorneyDetails);
    }
  }

  return objects;
};

// we have to add these as 'free floating' action items - since there are no documents associated with them
export const addDocActionItems = (client, userRealm, actions) => {
  // Note: the actions need to carry with it ALL the info needed to create action items

  // Note: each detail preparer is assumed to only prepare 1 action item; if more action
  // items are created then this code must change
  const objects = [];
  actions.map((action) => {
    switch (action.type) {
      case DocumentType.HealthDirective:
        const healthDirectiveDetails = prepareDetailsForAdvancedHealthDirective(
          action
        );
        if (healthDirectiveDetails) {
          healthDirectiveDetails.userRealm = userRealm;
          healthDirectiveDetails.type = ActionItemType.General;
          objects.push(healthDirectiveDetails);
        }
        break;
      case DocumentType.PowerOfAttorney:
        const powerOfAttorneyDetails = prepareDetailsForPowerOfAttorney(action);
        if (powerOfAttorneyDetails) {
          powerOfAttorneyDetails.userRealm = userRealm;
          powerOfAttorneyDetails.type = ActionItemType.General;
          objects.push(powerOfAttorneyDetails);
        }
        break;
      case DocumentType.WillOrTrust:
        const willTrustDetails = prepareDetailsForWillTrust(action);
        if (willTrustDetails) {
          willTrustDetails.userRealm = userRealm;
          willTrustDetails.type = ActionItemType.General;
          objects.push(willTrustDetails);
        }
        break;
      default:
        console.log("Unknown document type");
    }
  });

  if (objects.length > 0) {
    addMultipleActionItems(client, userRealm, OperationTargets.General, objects)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const constructMedicareActionItems = (benefit) => {
  let medicareDetails = [];

  if (benefit.type === GovernmentBenefitType.Medicare) {
    const medicareDetail = prepareDetailsForMedicare(benefit);
    medicareDetail.domainName = benefit.name;
    medicareDetails.push(medicareDetail);
  }
  return medicareDetails;
};

export const constructSocialSecurityActionItems = (benefit) => {
  let socialSecurityDetails = [];

  if (benefit.type === GovernmentBenefitType.SocialSecurity) {
    const socialSecurityDetail = prepareDetailsForSocialSecurity(benefit);
    // this can be null, must check
    if (socialSecurityDetail !== null) {
      socialSecurityDetail.domainName = benefit.name;
      socialSecurityDetails.push(socialSecurityDetail);
    }
  }
  return socialSecurityDetails;
};

// we have to add these as 'free floating' action items - since there are no domain objects associated with them
export const addMedicareActionItems = (client, userRealm, benefit) => {
  const medicareDetail = prepareDetailsForMedicare(benefit);
  medicareDetail.userRealm = userRealm;
  medicareDetail.type = ActionItemType.General;

  addOneActionItem(client, userRealm, OperationTargets.General, medicareDetail)
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
};

// we have to add these as 'free floating' action items - since there are no domain objects associated with them
export const addSocialSecurityActionItems = (client, userRealm, benefit) => {
  // there are no free floating social security action items
  return;
};

export const addDeathCertificateActionItem = (client, userRealm) => {
  const detail = {
    userRealm: userRealm,
    name: "Get a death certificate; make copies",
    dueDate: NonChoice,
    priority: PriorityType.Top,
    status: "Open",
    type: ActionItemType.General,
    notes:
      "Obtain from the mortuary/funeral home OR the state department of vital records",
  };

  addOneActionItem(client, userRealm, OperationTargets.General, detail)
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addAnythingElse = (client, planOwners, userRealm) => {
  // owner should be reminded to have cash of available for end of life
  // just assigning to the first plan owner in the list
  const detail = {
    userRealm: userRealm,
    name: "Make sure cash is available for end of life expenses.",
    dueDate: NonChoice,
    priority: PriorityType.High,
    status: "Open",
    owner: planOwners[0].firstName,
    type: ActionItemType.General,
    notes:
      "Create and fund a shared checking account with one or more of your beneficiaries",
  };

  addOneActionItem(client, userRealm, OperationTargets.General, detail)
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
};

// starting small with EOL action items - just creating one for the owner, one for the helper
export const constructEOLActionItems = (document) => {
  const nameOwner =
    "Fill out the details of the end of life arrangements for '" +
    document.user +
    "'";
    const nameHelper =
    "Look over the details of the end of life arrangements for '" +
    document.user +
    "'";

  // owner item is first
  const actionItemDetails = [
    {
      name: nameOwner,
      dueDate: NonChoice,
      priority: PriorityType.High,
      status: "Open",
      owner: document.user,
      type: ActionItemType.Documents,
      notes:
        "A document has been created for you to edit. It is named '" +
        document.name +
        "'"
    },
    // helper item
    {
      name: nameHelper,
      dueDate: NonChoice,
      priority: PriorityType.Top,
      status: "Open",
      type: ActionItemType.Documents,
      notes:
        "Find the document named '" +
        document.name +
        "' for details about end of life arrangements for and read over it carefully.",
    },
  ];

  return actionItemDetails;
};

// export const addOpenMeFirstActionItem = (client, userRealm) => {
//   const detail = {
//     name: "Prepare an 'Open Me First' packet",
//     dueDate: NonChoice,
//     priority: PriorityType.Top,
//     status: 'Open',
//     type: ActionItemType.General,
//     notes: "Include vital information OR where to find it including: location of password file"
//   }

//   addOneActionItem(client, userRealm, OperationTargets.General, detail)
//   .then(result => {
//     console.log(result)
//   })
//   .catch(error => {
//     console.log(error)
//   });
// }

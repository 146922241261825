import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import LTRCommonActionPanel from '../../shared/domains/LTRCommonActionPanel'

import QuickAddContact from './QuickAddContact';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import {ContactType} from '../../../Enums';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  }
}));

const ContactActionPanel = (props) => {
  const classes = useStyles();

  const [contactType, setContactType] = useState(ContactType.Other)
  const [quickAddOpen, setQuickAddOpen] = useState(false);

  const handleAddContact = (type) => {
    setContactType(type)
    setQuickAddOpen(true);

  }
  const handleCloseQuickAddOpen = () => {
    setQuickAddOpen(false);
  }

  const handleSave  = (contact) => {
    props.handleAddNotification(contact);
  }

  const handleReceiveData = (data) => {
    if (props.handleReceiveData) {
      props.handleReceiveData(data);
    }
  }

  return (
    <div className={classes.root}>
      <LTRCommonActionPanel
        actionComponents = {
          <ButtonGroup
            orientation="vertical"
            size = "small"
            color="primary"
            variant="text"
            aria-label="vertical outlined primary button group"
            >
            <Button className={classes.button} onClick={() => handleAddContact(ContactType.Family)} color="primary">
              Add a family member
            </Button>
            <Button className={classes.button} onClick={() => handleAddContact(ContactType.Friend)}   color="primary">
              Add a friend
            </Button>
            <Button className={classes.button} onClick={() => handleAddContact(ContactType.TrustedAdvisor)}   color="primary">
              Add a trusted advisor
            </Button>
            <Button className={classes.button} onClick={() => handleAddContact(ContactType.Business)}   color="primary">
              Add a business
            </Button>
            <Button className={classes.button} onClick={() => handleAddContact(ContactType.Medical)}   color="primary">
              Add a doctor or dentist
            </Button>
            <Button className={classes.button} onClick={() => handleAddContact(ContactType.Neighbor)}   color="primary">
              Add a neighbor
            </Button>
          </ButtonGroup>
        }
        />
        {quickAddOpen ? (
           <QuickAddContact 
              open = {quickAddOpen}
              contactType = {contactType}
              handleClose = {handleCloseQuickAddOpen}
              handleSave = {handleSave}
              handleReceiveData = {handleReceiveData}
           />) 
          : null
        }
    
    </div>
  )
}

ContactActionPanel.propTypes = {
  handleAddNotification: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired,
}

export default (ContactActionPanel)

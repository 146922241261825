import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Draggable from "react-draggable";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";

import { useDocumentAdd } from "../../../api/APIHooks";

import { useFamilyContext } from "../../../context/FamilyContext";
import { DocumentType } from "../../../Enums";

// our editor component
import Editor from "./Editor";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  flex: {
    flex: 1,
  },

  paper: {},

  button: {
    margin: theme.spacing(1),
  },

  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  modal: {
    overflow: "visible",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaperComponent = (props) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

export default function AddNoteDialog(props) {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editor, setEditor] = useState(null);

  const [addOneDocument] = useDocumentAdd(
    familyContext.familyRealm.id,
    (data) => {
      console.log(data);
      if (props.handleReceiveData) {
        props.handleReceiveData(data);
      }
    }
  );

  const handleEditorChanges = (content, delta, editor) => {
    setEditor(editor);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // get some stuff from the editor
    if (editor !== null) {
      const html = editor.getHTML();

      let output = {
        content: html,
        title: title,
      };

      handleSave(output);
    }
  };

  const handleSave = (output) => {
    // Save the document, with the note
    const details = {
      userRealm: familyContext.familyRealm.id,
      name: output.title,
      type: DocumentType.Note,
      description: description,
      // set all the other fields to blank or default values
      digital: false,
      location: "",
      notes: "",
    };

    // need to embed this note
    const documentNotes = [];
    documentNotes.push({
      content: output.content,
      name: title,
      description: description,
    });

    details.DocumentsNotes = {
      data: [...documentNotes],
    };

    addOneDocument({ variables: { object: details } })
      .then((result) => {
        props.handleSave(result.data.item);
      })
      .catch((error) => {
        console.error(error);
      });

    // close the dialog
    props.handleClose();
  };

  const editorHasNoContent = () => {
    if (editor === null) {
      return true;
    }
    const text = editor.getText();
    return text === "" ? true : false;
  };

  const { open, handleClose } = props;

  const dialogTitle = "Create a Note";

  let placeHolderText = "Create a note ...";

  const body = (
    <div>
      <Editor
        placeholder={placeHolderText}
        onChange={handleEditorChanges}
        initialContent={""}
      />
      <form className={classes.container}>
        <FormControl className={classes.formControl}>
          <TextField
            id="title"
            label="Title"
            required
            autoFocus
            className={classes.textField}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            margin="normal"
            helperText="Provide a descriptive title for this note"
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="description"
            label="Description"
            className={classes.textField}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            margin="normal"
            helperText="Describe the note (i.e. 'How to find our family photos')"
          />
        </FormControl>
      </form>
    </div>
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"lg"}
      PaperComponent={PaperComponent}
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="form-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={title === "" || editorHasNoContent()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddNoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {useApolloClient} from '@apollo/client';

import { 
  fetchOneDomainInstance,
  OperationTargets
} from '../../api/CommonCrudOperations'

import LTRDomainObjectInfo from '../shared/domains/LTRDomainObjectInfo';

// Our labels in the Info Table
const labels = {
  type: "TYPE",
  website: "WEBSITE",
  company: "COMPANY",
  phoneTreeLeader: "PHONE TREE CONTACT FOR",
  phoneNumber: "PHONE",
  email: "EMAIL",
  address: "ADDRESS",
  notes: "NOTES"
}

export const prepareTableRowInfo = (contact) => {
  // prepare table data for show
  let messageAction = '';
  let messageDoc = '';
  if (contact.actionItems) {
    messageAction = contact.actionItems.length === 0 ? "This contact has NO action items" :
    "This contact has " + contact.actionItems.length + " action items";
  }
  if (contact.attachments) {
    messageDoc = contact.attachments.length === 0 ? "This contact has NO attached files":
    "This contact has " + contact.attachments.length + " attached files"
  }
  const tableInfo = {
    name: contact.name,
    actionItemMessage: messageAction,
    documentsMessage: messageDoc,
    fieldData : [
      {
        field: labels.type,
        value:  contact.type
      },
      {
        field: labels.website,
        value: contact.website
      },
      {
        field: labels.company,
        value: contact.company
      },
      {
        field: labels.phoneTreeLeader,
        value: contact.phoneTreeLeader
      }
    ]
  }
  contact.ContactPhone.map (phone => {
    let phoneTypeLabel = phone.type.length > 1 ?  " (" + phone.type + ")" : "";
    const fieldData = {
      field: labels.phoneNumber + phoneTypeLabel,
      value: phone.phoneNumber
    }
    tableInfo.fieldData.push(fieldData);
  })
  contact.ContactEmail.map (email => {
    let emailTypeLabel = email.type.length > 1 ?  " (" + email.type + ")" : "";
    const fieldData = {
      field: labels.email +  emailTypeLabel,
      value: email.emailAddress
    }
    tableInfo.fieldData.push(fieldData);
  })
  contact.ContactAddress.map (address => {
    let addressTypeLabel = address.type.length > 1 ?  " (" + address.type + ")" : "";
    const fieldData = {
      field: labels.address +  addressTypeLabel,
      value: address.streetAddress1
    }
    if (address.city && address.city.length > 1) {
      fieldData.value = fieldData.value.concat(", " + address.city);
    }
    tableInfo.fieldData.push(fieldData);
  })

  // finally, notes
  const notesData = {
    field: labels.notes,
    value: contact.notes
  }
  tableInfo.fieldData.push(notesData);

  return tableInfo;  
}

const useContactFetch = (client, domainId, updateFn) => {
  const [contact, setContact] = useState(null);
  
  useEffect(() => {
    const fetchContact = () => { 
      fetchOneDomainInstance(client, OperationTargets.Contacts, domainId)
      .then(result => { 
        const contact = result.item;
        setContact(contact);
        updateFn(contact);
      })
      .catch(error => {
        console.log(error) 
      });
    }

    if (contact === null && domainId !== null) {
      fetchContact()
    }  
  }, [client, updateFn]);
};

const ContactInfo = (props) => {
  const client = useApolloClient();

  const [showInfoDialogOpen, setShowInfoDialogOpen] = useState(false);
  const [tableInfo, setTableInfo] = useState(null);
  const [contact, setContact] = useState(null);

  const handleShowInfoClose = () => {
    setShowInfoDialogOpen(false);
    // notify the client
    props.handleClose();
  }

  useContactFetch(client, props.domainId, (fetchedData) => {
    if (fetchedData !== null) {
      setContact(fetchedData);
      const info = prepareTableRowInfo(fetchedData);
      setTableInfo(info);
      setShowInfoDialogOpen(true);
    }
  });

  if (tableInfo === null) {
    return null;
  }

  return (
    <React.Fragment>  
      {showInfoDialogOpen ? (
        <LTRDomainObjectInfo
          open = {props.open}
          title = {contact.name}
          tableData = {tableInfo}
          handleClose = {handleShowInfoClose}
        />): null
      }
    </React.Fragment>
  )
}

ContactInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  domainId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default (ContactInfo);

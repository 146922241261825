const DOMAIN = process.env.AUTH0_DOMAIN ? process.env.AUTH0_DOMAIN: "noahshaf.auth0.com";
const CLIENTID = process.env.AUTH0_USER_CLIENTID ? process.env.AUTH0_USER_CLIENTID: "nYUMDX3kB53sDHhWumy4KDxF0IFUILce";

const HOST = window.location.host;
const SCHEME = process.env.NODE_ENV === 'production' ? "https" : "http";
const LOGIN_CALLBACK_PATH = "/";
const LOGOUT_CALLBACK_PATH="/";
const REDIRECT_LOGIN_URI = `${SCHEME}://${HOST}${LOGIN_CALLBACK_PATH}`;
const REDIRECT_LOGOUT_URI = `${SCHEME}://${HOST}${LOGOUT_CALLBACK_PATH}`;
const SCOPES = 'openid profile email';

const Auth0Config = {
  domain: DOMAIN,
  clientId: CLIENTID,
  loginRedirectUri: REDIRECT_LOGIN_URI,
  logoutRedirectUri: REDIRECT_LOGOUT_URI,
  scopes: SCOPES.split(/\s+/)
};

export default Auth0Config;
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
}));

const Settings = (props) => {
  const classes = useStyles();

  return <div className={classes.root}>Settings</div>;
};

Settings.propTypes = {};

export default Settings;


export const pcodeNotFound = "NOTFOUND";
export const pcodeExpired = "EXPIRED";
export const pcodeOK = "OK";

const passcodeKey = "p-code";

export default class PasscodeStorage {

  static getPasscode = () => { 
    const pcode = sessionStorage.getItem(passcodeKey);
   
    // see if we have anything - if not return null to indicate that
    // nothing has been saved
    if (pcode === null) {
      return pcodeNotFound;
    }
   
    return pcode;
  }

  static setPasscode = (passcode) => {
    // clear all storage to start with, then store
    sessionStorage.removeItem(passcodeKey);
    sessionStorage.setItem(passcodeKey, passcode); 
  }
  
  static clearPasscode = () => {
    sessionStorage.removeItem(passcodeKey);
  }

  static clearAll = () => {
    sessionStorage.removeItem(passcodeKey);
  }
}

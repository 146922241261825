import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Slide from "@material-ui/core/Slide";

import { FamilyContext, NULL_FAMILY_ID } from "../../../context/FamilyContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: "RED",
    width: "100%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChoosePlanDialog = (props) => {
  const classes = useStyles();
  const familyContext = useContext(FamilyContext);

  const [plan, setPlan] = useState(null);
  const [choices, setChoices]  = useState([]);
  
  useEffect(() => {
    if (props.realms && familyContext.familyRealm.id !== NULL_FAMILY_ID) {
      // excluse the current plan in use; we're only concerned with switching to other plans
      const values = props.realms.filter( value => value.realmId !== familyContext.familyRealm.id);
      setChoices(values);
      // see if have only 1 choice - if so, make it the new plan choice here since the user
      // will NOT have to select among multiple (more than 2) plans
      if (values.length === 1) {
        // the only choice
        setPlan(values[0]);
      }
    }
  }, [props.realms, familyContext]);
  
  const handleChange = (event) => {
    console.log(event.target.value)
    setPlan(event.target.value);
  };

  const handleSubmit = () => {
    props.handleSave(plan);
  };

  const handleCancel = () => {
    props.handleCancel();
  }

  if (!props.realms.length === 0) {
    return null;
  }

  if (familyContext.familyRealm.id === NULL_FAMILY_ID) {
    return null;
  }

  const title = "Work on a different plan";
  const numPlans = props.realms.length;
 
  const renderChoices = () => {
    let items = []; 
    for (let i = 0; i < choices.length; i++) {
      var value = choices[i].name;
      items.push(
        <FormControlLabel key={choices[i].realmId} value={choices[i].realmId} control={<Radio />} label={value} />
      );      
    }  
    return items;
  }

  const renderContentMoreThanTwo = () => {
    return (
      <DialogContent>
        <DialogContentText>You have {numPlans} plans.</DialogContentText>
        <DialogContentText gutterBottom>Choose a plan to work on.</DialogContentText>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Plan choices:</FormLabel>
          <RadioGroup aria-label="plans" name="plans1" value={plan} onChange={handleChange}>
            {renderChoices()}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      )
  }

  const renderContentJustTwo = () => {
    if (familyContext.familyRealm.id === NULL_FAMILY_ID) {
      return null;
    }

    const currentName = familyContext.familyRealm.name;
    const switchToName = choices.length ? choices[0].name : "NO CHOICES"
    return (
      <DialogContent>
        <DialogContentText variant="subtitle1" align="center" gutterBottom>Current plan: '{currentName}'.</DialogContentText>
        <DialogContentText align="center">Switch to '{switchToName}' ?</DialogContentText>
      </DialogContent>
      )
  }
  
  return (
    <div className={classes.root}>
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={props.open}
        onClose={handleCancel}
        aria-labelledby="choose-plan-dialog-title"
      >
        <DialogTitle>{title}</DialogTitle>
        <form id="choose-plan-id">
          {numPlans > 2 ? (
            renderContentMoreThanTwo()   
            ) : (
              renderContentJustTwo()
            )
          }
          
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={props.handleCancel}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={plan === null}
              onClick={handleSubmit}
            >
              Switch
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

ChoosePlanDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  realms: PropTypes.array.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
export default ChoosePlanDialog;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

//import bcrypt from '../../auth/custom-bcrypt';

import { signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { firebaseAuth } from "../../index";

import { useFormik } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

import Container from "@material-ui/core/Container";

import RealmInfoStorage, { LastPlanNotFound } from "../../api/RealmInfoStorage";
import Copyright from "../home/Copyright";
import { APPNAME } from "../../Enums";

import { USERFIELD_NOT_SET } from "../../context/UserContext";
import LetterRoutes from "../../Routes";

import { useApolloClient } from "@apollo/client";
import { fetchOneUserByEmail, fetchRealmsForUser } from "../../api/UsersApi";

import ForgotPasswordDialog from "./forms/ForgotPasswordDialog";
import { loginValidationSchema } from "../shared/validators/Validators";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  img: {
    opacity: 1,
    display: "block",
    maxWidth: "100%",
    height: "auto",
    verticalAlign: "middle",
    marginBottom: theme.spacing(1),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: "100%",
  },
  signin: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: "RED",
    width: "100%",
  },
}));

const SignIn = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const history = useHistory();

  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      save(values);
    },
  });

  const save = (values) => {
    // console.log('Username: ' + userName);
    // console.log('Password: ' + password);

    // console.log('encrypted password')
    // console.log(bcrypt.hash(password, {rounds: 20}));
    setLoading(true);
    setError(null);

    signInWithEmailAndPassword(firebaseAuth, values.email, values.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("Successfullys LOGGED in ");
        //console.log("User: " + user.uid);
        
        fetchOneUserByEmail(client, values.email)
          .then((dbuser) => {
            // need to fetch the realms for this user
            fetchRealmsForUser(client, dbuser.id)
              .then((result) => {
                const numRealms = result.data.UsersRealms.length;
                let userExt = {};
                if (numRealms > 1) {
                  // have the user choose
                  console.log("multiple realms");
                  let realmToUse = result.data.UsersRealms[0];
                  // this is copied from Landing - but here we just need to satisfy the needs of the 
                  // user context so just pick something
                  const lastRealm = RealmInfoStorage.getLastPlan();
                  if (lastRealm !== LastPlanNotFound ) {
                    realmToUse = lastRealm;
                  }
                  userExt.isOwner = realmToUse.isOwner;
                  userExt.userRealm = realmToUse.realmId;
                } else if (numRealms === 1) {
                  console.log("1 realm");
                  const realm = result.data.UsersRealms[0];
                  userExt.isOwner = realm.isOwner;
                  userExt.userRealm = realm.realmId;
                } else {
                  console.log("0 realms");
                  // new user, no realm set
                  userExt.isOwner = true;
                  userExt.userRealm = USERFIELD_NOT_SET;
                }

                // before we go, let's update the display name of this user IF 
                // he/she does not have one
                // see if we have a display name
                if (user.displayName) {
                  //console.log("Display name: " + user.displayName);
                  setLoading(false);
                  const res = {
                    status: "SUCCESS",
                    user: dbuser,
                    userExt: userExt,
                  };
                  props.handleSuccess(res);
                } else {
                  updateProfile(user, {
                    displayName: dbuser.firstName + " " + dbuser.lastName, 
                  })
                  .then((_) => {
                    setLoading(false);
                    const res = {
                      status: "SUCCESS",
                      user: dbuser,
                      userExt: userExt,
                    };
                    props.handleSuccess(res);
                  })
                }
              })
              .catch((error) => {
                setError(error);
                setLoading(false);
              });
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        // Firebase errors
        let reportedError = {
          code: error.code,
          message: 'Unspecified problem. Try again or contact the app developer for help.'
        }
        let errorCode = error.code;
        if (errorCode === 'auth/wrong-password') {
          reportedError.message = 'Wrong password or user name.';
        } else if (errorCode === 'auth/user-not-found'){
          reportedError.message ='Cannot find a user with these credentials. Check the user name and password and try again.'
        }
        else if (errorCode === 'auth/user-disabled'){
          reportedError.message = 'This user is disabled. Contact the app developer for help.'
        }
        else if (errorCode === 'auth/invalid-email') {
          reportedError.message = 'The email address provided is invalid. Try again.'
        }
        setError(reportedError);
      });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper elevation={3} className={classes.paper}>
        {/* <picture>
          <source type="image/webp" srcSet={process.env.PUBLIC_URL + 'FAMILY-resize.png'} />
          <img 
            src={process.env.PUBLIC_URL + 'FAMILY-resize.png'} 
            alt={'FamilyShare logo'}
            className = { classes.img} />
        </picture> */}
        <Typography component="h1" className={classes.signin} variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              autoFocus
              autoComplete="username"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            /> 
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              name="password"
              label="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword)
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          {error && (
            <Typography align="center" className={classes.error}>
              {error.message}
            </Typography>
          )}

          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formik.dirty && formik.isValid}
            type="submit"
          >
            Sign In
          </Button>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs>
              <Button
                size="small"
                onClick={() => setForgotPasswordDialogOpen(true)}
              >
                Forgot password?
              </Button>
            </Grid>
            <br />
            <Grid item xs>
              New to {APPNAME}?
              <Link
                component={RouterLink}
                to={LetterRoutes.SignupRoute}
                variant="body2"
              >
                {" Signup"}.
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <ForgotPasswordDialog
        open={forgotPasswordDialogOpen}
        handleClose={() => {
          setForgotPasswordDialogOpen(false);
          history.push(LetterRoutes.LandingRoute);
        }}
      />
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

SignIn.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
};

export default SignIn;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    margin: "4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  searchIcon: {
    marginTop: "10px",
    marginRight: "8px",
  },
  searchText: {
    flex: "0.8 0",
  },
  clearIcon: {
    "&:hover": {
      color: "#FF0000",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}));

export default function SearchInput(props) {
  const classes = useStyles();

  const [input, setInput] = useState("");

  const handleInput = (e) => {
    setInput(e.target.value);
    props.searchUpdate(e.target.value);
  };

  const onClear = () => {
    setInput("");
    props.searchUpdate(null);
  };

  return (
    <Paper component="form" className={classes.root}>
      <Input
        className={classes.input}
        value={input}
        autoFocus={true}
        disableUnderline={true}
        onChange={handleInput}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton className={classes.clearIcon} onClick={onClear}>
        <ClearIcon />
      </IconButton>
    </Paper>
  );
}

SearchInput.propTypes = {
  searchUpdate: PropTypes.func.isRequired,
};


import React, {useState, useEffect} from 'react';

import {makeStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {useInterviewContext, CheckListAnswerIds} from './InterviewContext';

export const TRUST_AND_WILL = "trust-and-will";
export const TRUST_ONLY = "trust-only";
export const WILL_ONLY = "will-only";
export const NO_WILL_OR_TRUST = "no-will-or-trust";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  answersSavedMsg: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold'
  },
}));

const DocCheckList = (props) => {
  const classes = useStyles();
  const interviewContext = useInterviewContext();

  const [trustWillValue, setTrustWillValue] = useState(NO_WILL_OR_TRUST);
  const [haveHealthDirective, setHaveHealthDirective] =  useState(false);
  const [spouseHaveHealthDirective, setSpouseHaveHealthDirective] =  useState(false);
  const [havePowerOfAttorney, setHavePowerOfAttorney] = useState(false);
  const [spouseHavePowerOfAttorney, setSpouseHavePowerOfAttorney] = useState(false);
  
  useEffect(() => {
    const answers = interviewContext.answers;
    
    // we have some answers - see if we need to adjust our defaults
    answers.forEach ((answer) => {
      if (answer.id === CheckListAnswerIds.tw) {
        setTrustWillValue(answer.value);
      }
      if (answer.id === CheckListAnswerIds.hd1) {
        setHaveHealthDirective(answer.value);
      }
      if (answer.id === CheckListAnswerIds.hd2) {
        setSpouseHaveHealthDirective(answer.value);
      }
      if (answer.id === CheckListAnswerIds.pa1) {
        setHavePowerOfAttorney(answer.value);
      }
      if (answer.id === CheckListAnswerIds.pa2) {
        setSpouseHavePowerOfAttorney(answer.value);
      }
    })  
  });

  const handleSetDirective = (event) => {
    setHaveHealthDirective(event.target.checked);

    // prepare an answer for the interview context
    const answer = {
      id: CheckListAnswerIds.hd1,
      value: event.target.checked
    }
    interviewContext.updateAnswer(answer);
  }

  const handleSetSpouseDirective = (event) => {
    setSpouseHaveHealthDirective(event.target.checked);

    // prepare an answer for the interview context
    const answer = {
      id: CheckListAnswerIds.hd2,
      value: event.target.checked
    }
    interviewContext.updateAnswer(answer);
  }

  const handleSetTrustAndWill = (event) => {
    setTrustWillValue(event.target.value)

    // prepare an answer for the interview context
    const answer = {
      id: CheckListAnswerIds.tw,
      value: event.target.value
    }
    interviewContext.updateAnswer(answer);
  }

  const handleSetPowerOfAttorney = (event) => {
    setHavePowerOfAttorney(event.target.checked)

    // prepare an answer for the interview context
    const answer = {
      id: CheckListAnswerIds.pa1,
      value: event.target.checked
    }
    interviewContext.updateAnswer(answer);
  }

  const handleSetSpousePowerOfAttorney = (event) => {
    setSpouseHavePowerOfAttorney(event.target.checked)

    // prepare an answer for the interview context
    const answer = {
      id: CheckListAnswerIds.pa2,
      value: event.target.checked
    }
    interviewContext.updateAnswer(answer);
  }

  const directiveLabel = "I have a heath directive document";
  const attorneyLabel = "I have a power of attorney document";
  const directiveSpouseLabel = "My spouse/partner has a health directive document";
  const attorneySpouseLabel = "My spouse/partner has a power of attorney document";

  const hasSpouse = interviewContext.planOwners.length > 1;

  return (
    <React.Fragment>
      <>
        <Typography align="left" variant="h6">
          Answer these questions from the perspective of the Plan Owner(s).
        </Typography>
        <Typography align="left" variant="h6" gutterBottom>
          Then press Next.
        </Typography>       
        <form>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Do you have a documented will and/or trust?</FormLabel>
            <RadioGroup 
              aria-label="trusts-wills" 
              name="trust1" 
              value={trustWillValue} 
              onChange={handleSetTrustAndWill}>
              <FormControlLabel value={TRUST_AND_WILL} control={<Radio />} label="Trust and Will" />
              <FormControlLabel value={TRUST_ONLY} control={<Radio />} label="Trust only" />
              <FormControlLabel value={WILL_ONLY} control={<Radio />} label="Will only" />
              <FormControlLabel value={NO_WILL_OR_TRUST}  control={<Radio />} label="No trust, no will" />
            </RadioGroup>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={haveHealthDirective}
                  onChange={handleSetDirective}
                  name="directive1"
                  color="primary"
                />
              }
              label={directiveLabel}
            />
          </FormControl>

          {hasSpouse ? (
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={spouseHaveHealthDirective}
                    onChange={handleSetSpouseDirective}
                    name="directive2"
                    color="primary"
                  />
                }
                label={directiveSpouseLabel}
              />
            </FormControl>
          ): null}
          
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={havePowerOfAttorney}
                  onChange={handleSetPowerOfAttorney}
                  name="attorney1"
                  color="primary"
                />
              }
              label={attorneyLabel}
            />
          </FormControl>

          {hasSpouse ? (
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={spouseHavePowerOfAttorney}
                    onChange={handleSetSpousePowerOfAttorney}
                    name="attorney1"
                    color="primary"
                  />
                }
                label={attorneySpouseLabel}
              />
            </FormControl>
          ) : null}
        </form>
      </>
    </React.Fragment>
  );
}

export default (DocCheckList);
import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { Route } from "react-router-dom";
import { useAuthHelper } from "./auth/AuthHook";

import { UserContext, USERFIELD_NOT_SET } from "./context/UserContext";
import { AuthContext } from "./context/AuthContext";

import LoginState from "./auth/LoginState";

const ProtectedRoute = ({ component: Component, path, ...rest }) => {
  const userContext = useContext(UserContext);
  const authContext = useContext(AuthContext);
  
  const {  loginUser, restoreEnvironment } = useAuthHelper();
  
  useEffect(() => { 
    // console.log('Protected route: ' + path);
    if (!authContext.isAuthenticated) {
      loginUser(path);
    } else {     
      // see if we have valid contexts - do we even have a user
      if (userContext.user && userContext.user.id === USERFIELD_NOT_SET) {
        // restore from session storage
        console.log('Restoring environment')
        const realmId = LoginState.getRealmData();
        // const user = authContext.authUser.uid;
        // // authContext may not be set up yet - protect against this
        // console.log("Protected route: user id is " + user.uid);
        // if (user) {
          // TODO: work out whether to pass the current uid OR get it from LoginState
          // temporarily using LoginState
          const userId = LoginState.getUserData()
          console.log(userId)
          console.log(realmId)

          restoreEnvironment(userId, realmId);
        //}
      }
    }
  }, [authContext, userContext, loginUser, restoreEnvironment, path]);

  const render = props =>
    authContext.isAuthenticated ? <Component {...props} /> : null;

  return <Route path={path} render={render} {...rest} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

export default ProtectedRoute;
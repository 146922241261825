import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import {useApolloClient} from '@apollo/client';
import {updateOneActionItem, fetchOneActionItem, actionItemTypeToOperationTarget} from '../../api/ActionItemApi';

import {FamilyContext} from '../../context/FamilyContext';

import {StatusType, PriorityType, NonChoice} from '../../Enums';

import AddEditActionItemFormCore from './AddEditActionItemFormCore';

const EditActionItemForm = (props) => {
  const client = useApolloClient();
  const familyContext = useContext(FamilyContext);

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [editedItem, setEditedItem] = useState(null);

  const fetchItem = () => { 
    const {selectedId} = props;

    fetchOneActionItem(client, selectedId)
    .then(result => { 
      let item = {...result.ActionItems_by_pk};
      // we likely have to nullify the due date
      if (item.dueDate.indexOf(NonChoice) === 0) {
        item.dueDate = null;
      }
      if (item.owner === null) {
        item.owner = NonChoice;
      }
      setEditedItem(item);
    })
    .catch(error => {
        console.log(error) 
    });
  }

  useEffect(() => {
    const {selectedId} = props;

    // if we're editing and we haven't yet fetched the item
    if (selectedId) {
      fetchItem();
    } 
  }, [props]);

  const save = (values) => {

    //alert(JSON.stringify(values, null, 2));

    // note: domainId and type come from the action item we fetched
    // these are NOT editable by the user
    const details = {
      userRealm: familyContext.familyRealm.id,
      name: values.name,
      owner: values.owner ? values.owner: NonChoice,
      priority: values.priority ? values.priority: PriorityType.Medium,
      dueDate: values.dueDate ? moment(values.dueDate).format('L'): NonChoice,
      status: values.status ? values.status: StatusType.Open,
      notes: values.notes ? values.notes: '',
      domainId: editedItem.domainId,
      domainName: editedItem.domainName,
      type: editedItem.type
    };
    
    updateOne(details);
  }
    
  const updateOne = (details) => {   
    const target = actionItemTypeToOperationTarget(details.type)
    updateOneActionItem(client, familyContext.familyRealm.id, target, props.selectedId, details)
    .then(result => { 
      console.log(result); 
      const item = result.update_ActionItems_by_pk;
      props.handleEdit(item);
    })
    .catch(
      error => { console.log(error) 
    });
    
    // hide the modal
    handleClose();
  }

   // normal close and cancel
   const handleClose = () => {
    props.handleClose();  
  }

  // we need an editedItem!
  if (editedItem === null) {
    return null;
  }
  
  const title =  editedItem.domainName ? editedItem.type + ": edit an action item for " + editedItem.domainName
            :  editedItem.type + ": edit an action item";
  
  return (   
    <>
    {props.open && editedItem ? ( 
      <AddEditActionItemFormCore 
        open = {props.open}
        title = {title}
        actionItem = {editedItem}
        domainType = {editedItem.type} 
        itemName = {editedItem.domainName}
        save = {save}
        handleClose = {handleClose}
      />
      ): null
    }
    </>
  )
}

EditActionItemForm.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired
};

export default (EditActionItemForm)


import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import * as PapaParse from 'papaparse'

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
}));

const fileEncoding = 'UTF-8';

const CSVReader = (props) => {
  const classes = useStyles();

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    complete: (results, file) => props.onFileLoaded(results, file),
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
  };

  const handleChangeFile = (e) => {
    let reader = new FileReader()
    const files = e.target.files;
    if (files.length > 0) {
      reader.onload = (event ) => {
        PapaParse.parse(reader.result, papaparseOptions);
      }
      reader.readAsText(files[0], fileEncoding)
    }
  }

  const inputProps = {
    accept: '.csv, text/csv',
  };
  
  return (
    <div className = {classes.root}>
        <Typography>{props.title}</Typography>
        <TextField
          id="csv file selection"
          type="file"
          inputProps={inputProps}
          onChange={e => handleChangeFile(e)}
        />
    </div>
  );
}

CSVReader.propTypes = {
  title: PropTypes.string.isRequired,
  onFileLoaded: PropTypes.func.isRequired,
};

export default CSVReader

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import LetterRoutes from "../../../Routes";
import { useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// steps
import PlanCreate from "./PlanCreate";
import PlanOwners from "./PlanOwners";
import FamilyMembers from "./FamilyMembers";
import DocCheckList from "./DocCheckList";
import GovCheckList from "./GovCheckList";
import LifeDeathCheckList from "./LifeDeathCheckList";
import Review from "./Review";

import { useInterviewContext } from "./InterviewContext";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(4))]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(6))]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(5)}px`,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = [
  "Create Plan",
  "Plan Owners",
  "Plan Helpers",
  "Checklist - Documents",
  "Checklist - Benefits",
 // "End of Life Wishes",
  "Review",
];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <PlanCreate />;
    case 1:
      return <PlanOwners />;
    case 2:
      return <FamilyMembers />;
    case 3:
      return <DocCheckList />;
    case 4:
      return <GovCheckList />;
    // case 5:
    //   return <LifeDeathCheckList />;
    case 5:
      return <Review />;
    default:
      throw new Error("Unknown step");
  }
}

const InterviewController = (props) => {
  const classes = useStyles();
  const interviewContext = useInterviewContext();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    const newStep = activeStep + 1;
    setActiveStep(newStep);
  };

  const handleBack = () => {
    const newStep = activeStep - 1;
    setActiveStep(newStep);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const commitInterview = () => {
    interviewContext
      .commitPlanNow()
      .then((result) => {
        // Navigate to the overview
        history.push(LetterRoutes.OverviewRoute);
      })
      .catch((error) => {
        console.log("Totally unexpected failure to commit a new plan");
      });
  };

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography align="center">Getting Started Interview</Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography gutterBottom>
                  Thank you for taking this interview.
                </Typography>
                <Typography>You're ready to build out your plan.</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={commitInterview}
                  className={classes.button}
                >
                  Get Started!
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Finish!" : "Next"}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default InterviewController;

import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Draggable from 'react-draggable';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { useApolloClient, gql, useQuery } from '@apollo/client';
import { listBillpayAccounts } from '../../graphql/queries';
import { updateOneBiller } from "../../api/BillerApi";

import { NonChoice } from '../../Enums';

import {useFamilyContext} from '../../context/FamilyContext';

const useStyles = makeStyles(theme => ({ 
  flex: {
    flex: 1,
  },
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
}));

const PaperComponent = (props) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuickAssignBillerAccount = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();
  const [account, setAccount] = React.useState('');

  const handleChange = (event) => {
    setAccount(event.target.value);
  };

  const handleSave = () => {
    // Mutation
    
    // we only have the name so let's use it
    const detailsBiller = {
      accountId: account
    }

     // update the biller
    updateOneBiller(client, props.biller.id, detailsBiller)
    .then((resultBiller) => {
      console.log(resultBiller);
      props.handleSave(props.biller)
    })
    .catch((error) => {
      console.error(error);
      props.handleClose()
    })
  }
  
  const createAccountSelectItems = () => {
    let items = []; 
    
    // allow NonChoice
    items.push(<MenuItem key={1000} value={NonChoice}>{NonChoice}</MenuItem>);
    
    for (let i = 0; i < data.Account.length; i++) {
      var value = data.Account[i].id;          
      items.push(<MenuItem key={value} value={value}>{data.Account[i].name}</MenuItem>);   
    }  
    return items;
  }  

  // we need to ensure that we're getting all accounts
  const { loading, data } = useQuery(gql(listBillpayAccounts), {
    fetchPolicy: 'network-only',
    variables: { userRealm: familyContext.familyRealm.id }
  });

  if (loading) {
    return null;
  }

  if (data.Account === null) {
    return null;
  }

  const { open, handleClose } = props;

  const title = "Assign a billpay account to: " + props.biller.payee;

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="form-dialog-title"
      >
      <DialogTitle style={{ cursor: 'move' }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <form>
          {data.Account && data.Account.length > 0 ? (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="billpayAccount-type-label">Billpay Account</InputLabel>
              <Select
                labelId="billpayAccount-type-label"
                id="billpayAccount-type"
                value={account}
                onChange={handleChange}
              >
                {
                  createAccountSelectItems()
                }
              </Select>
              <FormHelperText>Which account is used to pay this bill?</FormHelperText>
            </FormControl>
          ): null}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={account === ''} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>   
  )
}

QuickAssignBillerAccount.propTypes = {
  open: PropTypes.bool.isRequired,
  biller: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
}

export default (QuickAssignBillerAccount)
// app name
export const APPNAME = "Letter from Mom & Dad"

// used by all domain lists
export const NonChoice = "Not specified";

//low medium high
export const PriorityType = {
  Low: 'Low',
  Medium: 'Medium',
  High: 'High',
  Top: 'Top'
}

export const PriorityTypes = [
  PriorityType.Low,
  PriorityType.Medium,
  PriorityType.High,
  PriorityType.Top
]
export const StatusType = {
  Open: 'Open',
  Assigned: 'Assigned',
  Closed: 'Closed',
}

export const StatusTypes  = [
  StatusType.Open,
  StatusType.Closed,
]
  
export const ValueType = {
  NonChoice,
  Keep: 'Priceless, try to keep',
  YourChoice: 'Keep if you want it',
  Sell: 'Try to sell',
  Rid: 'Get rid of it unless someone else wants it'
}

export const ValueTypes = [
  NonChoice,
  ValueType.Keep,
  ValueType.YourChoice,
  ValueType.Sell,
  ValueType.Rid,
]

export const AccountType = {
  Checking: 'Checking',
  Savings: 'Savings',
  MoneyMarket: 'Money Market',
  Credit: 'Credit',
  Investment: 'Investment',
  Retirement: 'Retirement',
  Treasury: 'TreasuryDirect',
  Other: 'Other'
}

export const getAccountTypeEnumList = () => {
  const retList = [
    NonChoice,
    AccountType.Checking,
    AccountType.Savings,
    AccountType.MoneyMarket,
    AccountType.Credit,
    AccountType.Investment,
    AccountType.Retirement,
    AccountType.Treasury,
    AccountType.Other,
  ]
  return retList;
}

export const AssetType = {
  Vehicle: 'Car, truck or motorcycle',
  Housing: 'Primary or vacation residence',
  Building: 'Rental property',
  Annuity: 'Annuity',
  Pension: 'Pension',
  Digital: 'Digital',
  Cash: 'Cash',
  Other: 'Other',
}

export const AssetTypes = [
  NonChoice,
  AssetType.Vehicle,
  AssetType.Housing,
  AssetType.Building,
  AssetType.Annuity,
  AssetType.Pension,
  AssetType.Digital,
  AssetType.Cash,
  AssetType.Other,
]

export const SubscriptionType = {
  Software: 'Software',
  Magazine: 'Magazine',
  Newspaper: 'Newspaper',
  Music: 'Music',
  Video: 'Video',
  Other: 'Other'
}

export const SubscriptionTypeList = [
  NonChoice,
  SubscriptionType.Software,
  SubscriptionType.Magazine,
  SubscriptionType.Newspaper,
  SubscriptionType.Music,
  SubscriptionType.Video,
  SubscriptionType.Other, 
]

export const ServiceType = {
  Utility: "Utility",
  Telecommunications: "Cable/Internet/Phone",
  Housekeeping: 'Housekeeping',
  Gardening: 'Gardening',
  PestControl: 'Pest Control',
  Membership: 'Membership',
  Other: 'Other'
}

export const ServiceTypeList = [
  NonChoice,
  ServiceType.Utility,
  ServiceType.Telecommunications,
  ServiceType.Housekeeping,
  ServiceType.Gardening,
  ServiceType.PestControl,
  ServiceType.Membership,
  ServiceType.Other, 
]

export const OtherItemsType = {
  Furniture: 'Furniture',
  Artwork: 'Artwork',
  Collection: 'Collection',
  Other: 'Other'
}

export const OtherItemsTypeList = [
  NonChoice,
  OtherItemsType.Furniture,
  OtherItemsType.Artwork,
  OtherItemsType.Collection,
  OtherItemsType.Other, 
]

export const ContactType = {
  Family: 'Family',
  Friend: 'Friend',
  Neighbor: 'Neighbor',
  CoWorker: 'Co-worker',
  Medical: 'Medical',
  Business: 'Business',
  TrustedAdvisor: 'Trusted Advisor',
  Other: 'Other'
}

export const ContactTypeList = [
  NonChoice,
  ContactType.Family,
  ContactType.Friend,
  ContactType.Neighbor,
  ContactType.CoWorker,
  ContactType.Medical,
  ContactType.Business,
  ContactType.TrustedAdvisor,
  ContactType.Other
]

// lots of overlap here, but not identical to ContactType
export const PhoneTreeLeaderType = {
  Family: 'Family',
  Friends: 'Friends',
  Neighbors: 'Neighbors',
  CoWorkers: 'Co-workers',
  Other: 'Other'
}
export const PhoneTreeLeaderTypeList = [
  NonChoice,
  PhoneTreeLeaderType.Family,
  PhoneTreeLeaderType.Friends,
  PhoneTreeLeaderType.Neighbors,
  PhoneTreeLeaderType.CoWorkers,
  PhoneTreeLeaderType.Other
]

export const LiabilityType = {
  Housing: 'Mortgage',
  Rent: 'Rent',
  AutoLoan: 'Auto loan',
  StudentLoan: 'Student loan',
  PersonalLoan: 'Personal loan',
  OtherLoan: 'Other loan',
  Other: 'Other'
}

export const LiabilityTypes = [
  NonChoice,
  LiabilityType.Housing,
  LiabilityType.Rent,
  LiabilityType.AutoLoan,
  LiabilityType.StudentLoan,
  LiabilityType.PersonalLoan,
  LiabilityType.OtherLoan,
  LiabilityType.Other
]

export const InsuranceType = {
  HomeOwners: 'Home owners',
  Auto: 'Truck or Auto',
  Medical: 'Medical',
  Dental: 'Dental',
  LongTerm: 'Long term care',
  Life: 'Life',
  Other: 'Other'
}

export const InsuranceTypes = [
  NonChoice,
  InsuranceType.HomeOwners,
  InsuranceType.Auto,
  InsuranceType.Medical,
  InsuranceType.Dental,
  InsuranceType.LongTerm,
  InsuranceType.Life,
  InsuranceType.Other,
]

// DomainType and Action Item Types MUST overlap - since they refer to the 
// same database tables
export const DomainType = {
  Accounts: 'Accounts',
  Contacts: 'Contacts',
  Assets: 'Assets',
  Liabilities: 'Liabilities',
  Insurance: 'Insurance',
  Subscriptions: 'Subscriptions',
  Services: 'Services',
  OtherItems: 'Other Items',
  Documents: 'Documents'
}

export const ActionItemType = {
  All: 'All',
  Account: 'Account',
  Contacts: 'Contacts',
  Assets: 'Assets',
  Liabilities: 'Liabilities',
  Insurance: 'Insurance',
  Subscriptions: 'Subscriptions',
  Services: 'Services',
  OtherItems: 'Other Items',
  Documents: 'Documents',
  General: 'General'
}

export const ActionItemGroupType = {
  Medical: 'Medical',
  Government: 'Government',
  Home: 'Home',
  None: 'None',
}

// the UI occasionally needs a singular form
export const DomainTypeSingular = {
  Accounts: 'Account',
  Contacts: 'Contact',
  Assets: 'Asset',
  Liabilities: 'Liability',
  Insurance: 'Policy',
  Subscriptions: 'Subscription',
  Services: 'Service',
  OtherItems: 'Item',
  Documents: 'Document'
}

export const BillReceiptType = {
  Postal: 'Paper',
  Email: 'Email',
  Text: 'Text message',
  Electronic: 'Electronic (via bank)',
  None: 'No bill received'
}

export const BillReceipts = [
  NonChoice,
  BillReceiptType.Postal,
  BillReceiptType.Email,
  BillReceiptType.Text,
  BillReceiptType.Electronic,
  BillReceiptType.None
]

export const BillFrequencyType = {
  AsNeeded: 'As needed',
  Weekly: 'Weekly',
  BiWeekly: 'Bi-weekly',
  Monthly: 'Monthly',
  BiMonthly: 'Bi-monthly',
  Quarterly: 'Quarterly',
  Yearly: 'Yearly'
}

export const BillFrequencies = [
  NonChoice,
  BillFrequencyType.AsNeeded,
  BillFrequencyType.Weekly,
  BillFrequencyType.BiWeekly,
  BillFrequencyType.Monthly,
  BillFrequencyType.BiMonthly,
  BillFrequencyType.Quarterly,
  BillFrequencyType.Yearly
]

export const PaymentType = {
  Cash: 'Cash',
  Credit: 'Credit',
  Phone: 'Pay by phone',
  Service: 'Pay through payment service (i.e. Venmo, Paypal)',
  Bank: 'Electronic payment, made by me at my bank web site',
  RecurringBank: 'Recurring electronic payment, made by my bank',
  BillpayVendor:'Recurring electronic payment, initiated and managed by the vendor'
}

export const PaymentTypes = [
  NonChoice,
  PaymentType.Cash,
  PaymentType.Credit,
  PaymentType.Phone,
  PaymentType.Service,
  PaymentType.Bank,
  PaymentType.RecurringBank,
  PaymentType.BillpayVendor
]

export const DocumentType = {
  WillOrTrust: 'Will/Trust',
  HealthDirective: 'Health Directive',
  PowerOfAttorney: 'Power Of Attorney',
  Government: 'Government',
  EndOfLife: 'End of Life Arrangements/Wishes',
  Identity: 'Identity',
  Tax: 'Tax related',
  Other: 'Other',
  Note: 'Note' 
}

export const DocumentTypes = [
  NonChoice,
  DocumentType.WillOrTrust,
  DocumentType.HealthDirective,
  DocumentType.PowerOfAttorney,
  DocumentType.Government,
  DocumentType.EndOfLife,
  DocumentType.Identity,
  DocumentType.Tax,
  DocumentType.Other,
  DocumentType.Note
]

export const GovernmentBenefitType = {
  SocialSecurity: 'Social Security',
  Medicare: 'Medicare',
}

// supported actions on the domain toolbar 
export const ToolbarActions = {
  Import: 'Import',
  Upload: 'Upload'
}







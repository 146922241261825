import React, { useContext } from "react";

// import Typography from '@material-ui/core/Typography'

import OwnerView from "../user/OwnerView";
import HelperView from "../user/HelperView";

// import { useAuthHelper } from "../../auth/AuthHook";
// import { useFetchAppUser } from "../../api/UserHooks";

// import { APPNAME } from "../../Enums";

// import InfoModal from "../shared/modals/InfoModal";

import { FamilyContext, NULL_FAMILY_ID } from "../../context/FamilyContext";
import { UserContext } from "../../context/UserContext";

const Overview = (props) => {
  const familyContext = useContext(FamilyContext);
  const userContext = useContext(UserContext);

  //const [unverifiedEmail, setUnverifiedEmail] = useState(false);

  //const { logoutUser } = useAuthHelper();
  
  // const { appUser } = useFetchAppUser();

  // useEffect(() => {
  //   if (appUser && !appUser.emailVerified) {
  //     console.log('Not verified email');
  //     setUnverifiedEmail(true); 
  //   }
  // }, [appUser]);

  // const handleLogout = () => {
  //   setUnverifiedEmail(false);
  //   logoutUser();
  // }

  // NOTE SURE IF WE WANT TO HANDLE IT THIS WAY - DISRUPTIVE FLOW

  // const renderUnverfiedEmailContent  = (
  //   <>
  //     <Typography variant="body2" gutterBottom = {true}>You have successfully signed up to use '{APPNAME}' but you have not verified your email yet.</Typography>
  //     <br/>
  //     <Typography variant="body2" gutterBottom = {true}>Find the confirmation email you received and verify your email address before continuing.</Typography>
  //   </>
  // )
  
  // if (appUser && unverifiedEmail) {
  //   const title = "Email address (" + appUser.email + ") not verified";
  //   return (
  //     <InfoModal
  //       title={title} 
  //       open={unverifiedEmail}
  //       handleClose={handleLogout}
  //       body={renderUnverfiedEmailContent}
  //     /> 
  //   )
  // } 

  // we have to have a realm set up!
  if (familyContext.familyRealm.id === NULL_FAMILY_ID) {
    return null;
  }

  return userContext.user.isOwner ? <OwnerView /> : <HelperView />;
};

export default Overview;

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";

// wrappers for material ui components for formik
import { TextField, Select } from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { useApolloClient, useQuery, gql } from "@apollo/client";
import { useOtherItemsAdd, useOtherItemsUpdate } from "../../../api/APIHooks";

import { OtherItemsTypeList, ValueTypes, NonChoice } from "../../../Enums";

import { FormDialogBase } from "../../shared/formhelpers/FormDialogBase";

import { validationSchema } from "./OtherItemsFormMetaData";

import { getOtherItem } from "../../../graphql/queries";

import { OperationTargets } from "../../../api/CommonCrudOperations";

import { addMultipleActionItems } from "../../../api/ActionItemApi";

import { useFamilyContext } from "../../../context/FamilyContext";

import { constructCorrespondingActionItems } from "../OtherItemsHelper";

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },

  button: {
    margin: theme.spacing(1),
  },

  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const AddEditOtherItemsForm = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [open, setOpen] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [item, setItem] = useState({
    name: "",
    type: NonChoice,
    company: "",
    description: "",
    recommendation: NonChoice,
    notes: "",
    OtherItemsActionItems: [],
    OtherItemsAttachments: [],
  });

  const [skipQuery, setSkipQuery] = useState(true);

  let { loading, error, data } = useQuery(gql(getOtherItem), {
    variables: { id: props.selected ? props.selected.id : "" },
    skip: skipQuery,
  });

  const [addOneOtherItem] = useOtherItemsAdd(
    familyContext.familyRealm.id,
    (data) => {
      console.log(data);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data);
      // }
    }
  );
  const [updateOneOtherItem] = useOtherItemsUpdate(
    familyContext.familyRealm.id,
    (data) => {
      console.log(data.item);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data.item);
      // }
    }
  );

  useEffect(() => {
    if (!skipQuery) {
      const onCompleted = (data) => {
        setItem(data.item);
      };
      const onError = (error) => {
        return <div>{error}</div>;
      };

      if (onCompleted || onError) {
        if (onCompleted && !loading) {
          onCompleted(data);
          setSkipQuery(true);
        } else if (onError && !loading && error) {
          onError(error);
          setSkipQuery(true);
        }
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    const { selected } = props;

    // if we're editing and we haven't yet fetched the item
    if (selected && skipQuery) {
      setSkipQuery(false);
    }

    return () => {
      setSkipQuery(true);
    };
  }, [props.selected]);

  const save = (values) => {
    // Mutation - add or update one
    const details = {
      userRealm: familyContext.familyRealm.id,
      name: values.name,
      type: values.type ? values.type : "",
      description: values.description ? values.description : "",
      recommendation: values.recommendation ? values.recommendation : NonChoice,
      notes: values.notes ? values.notes : "",
    };

    // props.open means we're here for editing - otherwise we're adding
    props.open ? updateOne(details) : addOne(details);
  };

  const addOne = (details) => {
    const actionItems = constructCorrespondingActionItems(details);
    if (actionItems.length > 0) {
      details.OtherItemsActionItems = {
        data: [...actionItems],
      };
    }

    addOneOtherItem({ variables: { object: details}})
    .then((result) => {
      props.handleAdd(result.data.item);
    })
    .catch((error) => {
      console.error(error);
    });
   
    handleClose();
  };

  const updateOne = (details) => {
    if (item.actionItems.length === 0) {
      const actionItems = constructCorrespondingActionItems(details);
      if (actionItems.length > 0) {
        const items = actionItems.map((actionItem) => {
          actionItem.userRealm = familyContext.familyRealm.id;
          actionItem.domainId = item.id;
          return actionItem;
        });

        addMultipleActionItems(
          client,
          familyContext.familyRealm.id,
          OperationTargets.OtherItems,
          items
        )
          .then((result) => {
            console.log("Added %s action items", actionItems.length);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    // finallym update the item
    updateOneOtherItem({ variables: { id: item.id, changes: details } })
      .then((result) => {
        props.handleEdit(result.data.item);
      })
      .catch((error) => {
        console.error(error);
      });

    // hide the modal
    handleClose();
  };

  // Attach this to your <Formik>
  const formRef = useRef();

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleResetClick = () => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // normal close and cancel
  const handleClose = () => {
    // if we were opened by the client, let them close us, otherwise we close
    props.handleClose ? props.handleClose() : setOpen(false);
  };

  const body = (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={item}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        // actually save the form
        save(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="name"
              type="text"
              label="Name"
              autoFocus
              className={classes.textField}
              margin="normal"
              helperText={
                touched.name ? errors.name : "Provide the name of this item"
              }
              error={touched.name && Boolean(errors.name)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="otheritems-type">Type</InputLabel>
            <Field
              component={Select}
              name="type"
              inputProps={{
                id: "otheritems-type",
              }}
            >
              {OtherItemsTypeList.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText>Identify the type of this item</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="description"
              type="text"
              label="Description"
              className={classes.textField}
              margin="normal"
              helperText={"Provide a description of this item"}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="recommendation-type">
              Recommended Action
            </InputLabel>
            <Field
              component={Select}
              name="recommendation"
              inputProps={{
                id: "recommendation-type",
              }}
            >
              {ValueTypes.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText>
              Identify what you recommend doing with this item
            </FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="notes"
              label="Notes"
              multiline
              maxRows={4}
              className={classes.textField}
              margin="normal"
              helperText="Add any notes/hints/special instructions about this item"
            />
          </FormControl>
        </Form>
      )}
    </Formik>
  );

  const title = props.open ? "Edit an Item" : "Add an Item";

  return (
    <FormDialogBase
      open={props.open ? props.open : open}
      title={title}
      body={body}
      submitEnabled={submitEnabled}
      handleAddClickOpen={handleClickOpen}
      handleClose={handleClose}
      handleReset={handleResetClick}
      handleSubmit={handleSubmitClick}
    />
  );
};

// client can open if they choose to OR we will open
AddEditOtherItemsForm.defaultProps = {
  selected: null,
  open: false,
  handleClose: null,
};

AddEditOtherItemsForm.propTypes = {
  selected: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAdd: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired,
};

export default AddEditOtherItemsForm;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {useRecoilValue} from 'recoil';
import {rowsChosen} from './ImportState';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {DomainType} from '../../Enums';

import CSVReader from './CSVReader';
import VCardReader from './VCardReader';
import ContactsChooser from './ContactsChooser';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function Import (props) {
  const classes = useStyles();

  const [importResults, setImportResults] = useState(null);

  const chosenRows = useRecoilValue(rowsChosen);
  
  const handleLoaded = (results, fileInfo) => {
    setImportResults(results);
    console.log('Data')
    console.log(results.data, fileInfo);
    console.log ('Meta')
    console.log(results.meta);
    console.log ('Errors')
    console.log(results.errors);
  }

  const {open, domain, handleClose} = props;
  const title = 'Import ' + domain;

  const renderChooser = () => {
    switch (props.domain) {
      case DomainType.Contacts:
        return (<ContactsChooser data = {importResults}/>)
      case DomainType.Accounts:
      case DomainType.Assets:
      case DomainType.Liabilities:
      case DomainType.Insurance:
        console.log('Import not supported yet')
        break;
      default:
        console.log('Unsupported domain type')
        break;
    }
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'lg'}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {/* <CSVReader 
          title = {'Import from a CSV file'}
          onFileLoaded = {handleLoaded}
          /> */}
        <VCardReader 
          title = {'Import from a VCard file'}
          onFileLoaded = {handleLoaded}
        />
        {importResults ? renderChooser() : null} 
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button 
          disabled = {chosenRows.length === 0} 
          onClick={ () => props.handleSave(chosenRows)}  
          color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog> 
  )  
}

Import.propTypes = {
  open: PropTypes.bool.isRequired,
  domain: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};
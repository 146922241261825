import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Field } from 'formik';

// wrappers for material ui components for formik
import {Select} from 'formik-material-ui';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { gql, useQuery } from '@apollo/client';
import { listBillpayAccounts } from '../../../graphql/queries';

import {BillReceipts, BillFrequencies, PaymentTypes, NonChoice} from '../../../Enums';

import { FamilyContext } from '../../../context/FamilyContext';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
}));

const PaymentAndBilling = (props) => {
  const classes = useStyles();
  const familyContext = useContext(FamilyContext);

 
  // we need to ensure that we're getting all accounts
  const { loading, data } = useQuery(gql(listBillpayAccounts), {
    fetchPolicy: 'network-only',
    variables: { userRealm: familyContext.familyRealm.id }
  });

  if (loading) {
    return null;
  }

  if (data.Account === null) {
    return null;
  }

  const createAccountSelectItems = () => {
    let items = []; 
    
    // allow NonChoice
    items.push(<MenuItem key={1000} value={NonChoice}>{NonChoice}</MenuItem>);
    
    for (let i = 0; i < data.Account.length; i++) {
      var value = data.Account[i].id;          
      items.push(<MenuItem key={value} value={value}>{data.Account[i].name}</MenuItem>);   
    }  
    return items;
  }  

  return (
     <>
     <FormControl className={classes.formControl}>
        <InputLabel htmlFor="billtype-type">Bill Receipt</InputLabel>
        <Field
          component={Select}
          name={props.nameReceipt}
          inputProps={{
            id: 'billtype-type',
          }}
        >
          {BillReceipts.map ( (item, index) =>
            <MenuItem key={index} value={item}>{item}</MenuItem>
          )}
        </Field>
        <FormHelperText>How do you receive the bill?</FormHelperText>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="payment-types">How Paid</InputLabel>
        <Field
          component={Select}
          name={props.namePayment}
          inputProps={{
            id: 'payment-types',
          }}
        >
          {PaymentTypes.map ( (item, index) =>
            <MenuItem key={index} value={item}>{item}</MenuItem>
          )}
        </Field>
        <FormHelperText>How do you pay the bill?</FormHelperText>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="billfrequency-type">Bill Frequency</InputLabel>
        <Field
          component={Select}
          name={props.nameBilling}
          inputProps={{
            id: 'billfrequency-type',
          }}
        >
          {BillFrequencies.map ( (item, index) =>
            <MenuItem key={index} value={item}>{item}</MenuItem>
          )}
        </Field>
        <FormHelperText>How often do you pay the bill?</FormHelperText>
      </FormControl>
      {data.Account && data.Account.length > 0 ? (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="billpayAccount-type">Billpay Account</InputLabel>
          <Field
            component={Select}
            name={props.nameBillingAccount}
            inputProps={{
              id: 'billpayAccount-type',
            }}
          >
          {
            createAccountSelectItems()
          }
          </Field>
          <FormHelperText>Which account is used to pay this bill?</FormHelperText>
        </FormControl>
        ): null}
     </>
  );
}

PaymentAndBilling.defaultProps = {
  nameBillingAccount: ''
}

PaymentAndBilling.propTypes = {
  nameReceipt: PropTypes.string.isRequired,
  nameBilling: PropTypes.string.isRequired,
  namePayment: PropTypes.string.isRequired,
  nameBillingAccount: PropTypes.string
};

export default PaymentAndBilling;
import {ContactPhone, ContactEmail, ContactAddress, ActionItem} from './fragments';
import {Account, Asset, Contact, Insurance, Liability, OtherItem, Service, Subscription, Document} from './fragments';
import {User, UsersRealms, Realm, Biller, Settings, Attachment, Note} from './fragments';
import {LinkedContact, LinkedInsurance} from './fragments';

export const insertAccount = `mutation insert_Account ($object: Account_insert_input!) {
  item: insert_Account_one(object: $object) {
    ` + Account + `
    contact: Contact {` + LinkedContact + ` }
    actionItems: AccountActionItems {` + ActionItem + `} 
    attachments: AccountAttachments {` + Attachment + `}
  }
}
`;

export const insertAccounts = `mutation insert_Accounts($objects: [Accounts_insert_input!]!) {
  item: insert_Accounts(objects: $objects) {
    returning {
      ` + Account + `
      contact: Contact {` + LinkedContact + ` }
      actionItems: AccountActionItems {` + ActionItem + `} 
      attachments: AccountAttachments {` + Attachment + `}
    }
  }
}
`;

export const insertContact = `mutation insert_Contacts_one ($object: Contacts_insert_input!) {
  item: insert_Contacts_one(object: $object) {
    ` + Contact + `
    ContactPhone {` + ContactPhone + `}
    ContactEmail {` + ContactEmail + `}
    ContactAddress {` + ContactAddress + `}
    actionItems: ContactActionItems {` + ActionItem + `} 
    attachments: ContactAttachments {` + Attachment + `}
  }
}
`;

export const insertContacts = `mutation insert_Contacts($objects: [Contacts_insert_input!]!) {
  item: insert_Contacts(objects: $objects) {
    returning {
      ` + Contact + `
      ContactPhone {` + ContactPhone + `}
      ContactEmail {` + ContactEmail + `}
      ContactAddress {` + ContactAddress + `}
      actionItems: ContactActionItems {` + ActionItem + `} 
      attchments: ContactAttachments {` + Attachment + `}
    }
  }
}
`;

export const insertAsset = `mutation insert_Asset_one ($object: Asset_insert_input!) {
  item: insert_Asset_one(object: $object) {
    ` + Asset + `
    insurance: Insurance {` + LinkedInsurance + `}
    actionItems: AssetActionItems {` + ActionItem + `} 
    attachments: AssetAttachments {` + Attachment + `} 
  }
}
`;

export const insertAssets = `mutation insert_Asset($objects: [Asset_insert_input!]!) {
  item: insert_Asset(objects: $objects) {
    returning {
      ` + Asset + `
      insurance: Insurance {` + LinkedInsurance + `}
      actionItems: AssetActionItems {` + ActionItem + `} 
      attachments: AssetAttachments {` + Attachment + `} 
    }
  }
}
`;

export const insertInsurance = `mutation insert_Insurance_one ($object: Insurance_insert_input!) {
  item: insert_Insurance_one(object: $object) {
    ` + Insurance + `
    contact: Contact {` + LinkedContact + ` }
    billers: InsuranceBiller { ` + Biller + `}
    actionItems: InsuranceActionItems {` + ActionItem + `} 
    attachments: InsuranceAttachments {` + Attachment + `} 
  }
}
`;

export const insertInsurances = `mutation insert_Insurance ($objects: [Insurance_insert_input!]!) {
  item: insert_Insurance(objects: $objects) {
    returning {
      ` + Insurance + `
      contact: Contact {` + LinkedContact + ` }
      billers: InsuranceBiller { ` + Biller + `}
      actionItems: InsuranceActionItems {` + ActionItem + `} 
      attachments: InsuranceAttachments {` + Attachment + `} 
    } 
  }
}
`;

export const insertLiability = `mutation insert_Liability_one ($object: Liability_insert_input!) {
  item: insert_Liability_one(object: $object) {
    ` + Liability + `
    billers: LiabilityBiller { ` + Biller + `}
    actionItems: LiabilityActionItems {` + ActionItem + `} 
    attachments: LiabilityAttachments {` + Attachment + `} 
  }
}
`;

export const insertLiabilities = `mutation insert_Liability ($objects: [Liability_insert_input!]!) {
  item: insert_Liability(objects: $objects) {
    returning {
      ` + Liability + `
      billers: LiabilityBiller { ` + Biller + `}
      actionItems: LiabilityActionItems {` + ActionItem + `} 
      attachments: LiabilityAttachments {` + Attachment + `} 
    } 
  }
}
`;

export const insertSubscription = `mutation insert_Subscriptions_one ($object: Subscriptions_insert_input!) {
  item: insert_Subscriptions_one(object: $object) {
    ` + Subscription + `
    billers: SubscriptionBiller { ` + Biller + `}
    actionItems: SubscriptionsActionItems {` + ActionItem + `} 
    attachments: SubscriptionsAttachments {` + Attachment + `} 
  }
}
`;

export const insertSubscriptions = `mutation insert_Subscriptions ($objects: [Subscriptions_insert_input!]!) {
  item: insert_Subscriptions(objects: $objects) {
    returning {
      ` + Subscription + `
      billers: SubscriptionBiller { ` + Biller + `}
      actionItems: SubscriptionsActionItems {` + ActionItem + `} 
      attachments: SubscriptionsAttachments {` + Attachment + `} 
    } 
  }
}
`;

export const insertService = `mutation insert_Services_one ($object: Services_insert_input!) {
  item: insert_Services_one(object: $object) {
    ` + Service + `
    contact: Contact {` + LinkedContact + ` }
    billers: ServiceBiller {` + Biller + `}
    actionItems: ServicesActionItems {` + ActionItem + `}  
    attachments: ServicesAttachments {` + Attachment + `} 
  }
}
`;

export const insertServices = `mutation insert_Services ($objects: [Services_insert_input!]!) {
  item: insert_Services(objects: $objects) {
    returning {
      ` + Service + `
      billers: ServiceBiller {` + Biller + `}
      actionItems: ServicesActionItems {` + ActionItem + `}  
      attachments: ServicesAttachments {` + Attachment + `} 
    } 
  }
}
`;

export const insertOtherItem = `mutation insert_OtherItems_one ($object: OtherItems_insert_input!) {
  item: insert_OtherItems_one(object: $object) {
    ` + OtherItem + `
    actionItems: OtherItemsActionItems {` + ActionItem + `} 
    attachments: OtherItemsAttachments {` + Attachment + `} 
  }
}
`;

export const insertOtherItems = `mutation insert_OtherItems ($objects: [OtherItems_insert_input!]!) {
  items: insert_OtherItems(objects: $objects) {
    returning {
      ` + OtherItem + `
      actionItems: OtherItemsActionItems {` + ActionItem + `} 
      attachments: OtherItemsAttachments {` + Attachment + `} 
    } 
  }
}
`;

export const insertDocument = `mutation insert_Documents_one ($object: Documents_insert_input!) {
  item: insert_Documents_one(object: $object) {
    ` + Document + `
    actionItems: DocumentsActionItems {` + ActionItem + `} 
    attachments: DocumentsAttachments {` + Attachment + `} 
    note: DocumentsNotes { ` + Note + `}
  }
}
`;

export const insertDocuments = `mutation insert_Documents ($objects: [Documents_insert_input!]!) {
  item: insert_Documents(objects: $objects) {
    returning {
      ` + Document + `
      actionItems: DocumentsActionItems {` + ActionItem + `} 
      attachments: DocumentsAttachments {` + Attachment + `} 
      note: DocumentsNotes { ` + Note + `}
    } 
  }
}
`;

export const insertPhone = `mutation insert_Phone_one ($object: Phone_insert_input!) {
  insert_Phone_one(object: $object) {
    ` + ContactPhone + `
  }
}
`;

export const insertAddress = `mutation insert_Address_one ($object: Address_insert_input!) {
  insert_Address_one(object: $object) {
   ` + ContactAddress + `
  }
}
`;

export const insertEmail = `mutation insert_Email_one ($object: Email_insert_input!) {
  insert_Email_one(object: $object) {
   ` + ContactEmail + `
  }
}
`;

export const insertEmails = `mutation insert_Email($objects: [Email_insert_input!]!) {
  insert_Email(objects: $objects) {
    returning {
      ` + ContactEmail + `
    }
  }
}
`;

export const insertSettings = `mutation insert_Settings_one($object: Settings_insert_input!) {
  insert_Settings_one(object: $object) {
    ` + Settings + `
  }
}
`;

export const insertPhones = `mutation insert_Phone($objects: [Phone_insert_input!]!) {
  insert_Phone(objects: $objects) {
    returning {
      ` + ContactPhone + `
    }
  }
}
`;

export const insertActionItems = `mutation insert_ActionItems($objects: [ActionItems_insert_input!]!) {
  insert_ActionItems(objects: $objects) {
    returning {
      ` + ActionItem + `
    }
  }
}
`;

export const insertActionItem = `mutation insert_ActionItems_one($object: ActionItems_insert_input!) {
  insert_ActionItems_one(object: $object) {
    ` + ActionItem + `
  }
}
`;

export const insertAttachment = `mutation insert_Attachments_one($object: Attachments_insert_input!) {
  insert_Attachments_one(object: $object) {
    ` + Attachment + `
  }
}
`;

export const updateActionItem = `mutation update_ActionItems_by_pk($id: uuid!, $changes: ActionItems_set_input) {
  update_ActionItems_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + ActionItem + `
    }
  }
`;

export const updateActionItemsWhere = `mutation update_ActionItems($userRealm: String, $itemType: String, $changes: ActionItems_set_input) {
  update_ActionItems(
    where: {_and: [
      {userRealm: {_eq: $userRealm}}, 
      {type: {_eq: $itemType}}]},
    _set: $changes
    ) {
      affected_rows
      returning {
        ` + ActionItem + `
      }
    }
  }
`;

export const updateActionItemsWhereOwnersAreNull = `mutation update_ActionItems($userRealm: String, $itemType: String, $changes: ActionItems_set_input) {
  update_ActionItems(
    where: {_and: [{userRealm: {_eq: $userRealm}}, 
                   {type: {_eq: $itemType}},
                   {owner: {_eq: null}} ]},
    _set: $changes
    ) {
      affected_rows
      returning {
        ` + ActionItem + `
      }
    }
  }
`;

export const updateActionItemsGroupWhere = `mutation update_ActionItems($userRealm: String, $groupType: String, $changes: ActionItems_set_input) {
  update_ActionItems(
    where: {_and: [
      {userRealm: {_eq: $userRealm}}, 
      {group: {_eq: $groupType}}]},
    _set: $changes
    ) {
      affected_rows
      returning {
        ` + ActionItem + `
      }
    }
  }
`;

export const updateActionItemsGroupWhereOwnersAreNull = `mutation update_ActionItems($userRealm: String, $groupType: String, $changes: ActionItems_set_input) {
  update_ActionItems(
    where: {_and: [{userRealm: {_eq: $userRealm}}, 
                   {group: {_eq: $groupType}},
                   {owner: {_eq: null}} ]},
    _set: $changes
    ) {
      affected_rows
      returning {
        ` + ActionItem + `
      }
    }
  }
`;

export const updateAccount = `mutation update_Account_by_pk($id: uuid!, $changes: Account_set_input) {
  item: update_Account_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + Account + `
      contact: Contact {` + LinkedContact + ` }
      actionItems: AccountActionItems {` + ActionItem + `}
      attachments: AccountAttachments {` + Attachment + `}
    }
  }
`;

export const updateAsset = `mutation update_Asset_by_pk($id: uuid!, $changes: Asset_set_input) {
  item: update_Asset_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + Asset + `
      insurance: Insurance {` + LinkedInsurance + `}
      actionItems: AssetActionItems {` + ActionItem + `}  
      attachments: AssetAttachments {` + Attachment + `}
    }
  }
`;

export const updateContact = `mutation update_Contacts_by_pk($id: uuid!, $changes: Contacts_set_input) {
  item: update_Contacts_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + Contact + `
      ContactPhone {` + ContactPhone + `}
      ContactEmail {` + ContactEmail + `}
      ContactAddress {` + ContactAddress + `}
      actionItems: ContactActionItems {` + ActionItem + `} 
      attachments: ContactAttachments {` + Attachment + `}
    }
  }
`;

export const updateLiability = `mutation update_Liability_by_pk($id: uuid!, $changes: Liability_set_input) {
  item: update_Liability_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + Liability + `
      billers: LiabilityBiller { ` + Biller + `}
      actionItems: LiabilityActionItems {` + ActionItem + `}
      attachments: LiabilityAttachments {` + Attachment + `}    
    }
  }
`;

export const updateInsurance = `mutation update_Insurance_by_pk($id: uuid!, $changes: Insurance_set_input) {
  item: update_Insurance_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + Insurance + `
      contact: Contact {` + LinkedContact + ` }
      billers: InsuranceBiller { ` + Biller + `}
      actionItems: InsuranceActionItems {` + ActionItem + `}  
      attachments: InsuranceAttachments {` + Attachment + `}
    }
  }
`;

export const updateSubscription = `mutation update_Subscriptions_by_pk($id: uuid!, $changes: Subscriptions_set_input) {
  item: update_Subscriptions_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + Subscription + `
      billers: SubscriptionBiller { ` + Biller + `}
      actionItems: SubscriptionsActionItems {` + ActionItem + `}
      attachments: SubscriptionsAttachments {` + Attachment + `}
    }
  }
`;

export const updateService = `mutation update_Services_by_pk($id: uuid!, $changes: Services_set_input) {
  item: update_Services_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + Service + `
      contact: Contact {` + LinkedContact + ` }
      billers: ServiceBiller { ` + Biller + `}
      actionItems: ServicesActionItems {` + ActionItem + `}
      attachments: ServicesAttachments {` + Attachment + `}
    }
  }
`;

export const updateOtherItem = `mutation update_OtherItems_by_pk($id: uuid!, $changes: OtherItems_set_input) {
  item: update_OtherItems_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + OtherItem + `
      actionItems: OtherItemsActionItems {` + ActionItem + `}
      attachments: OtherItemsAttachments {` + Attachment + `}
    }
  }
`;

export const updateDocument = `mutation update_Documents_by_pk($id: uuid!, $changes: Documents_set_input) {
  item: update_Documents_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + Document + `
      actionItems: DocumentsActionItems {` + ActionItem + `}
      attachments: DocumentsAttachments {` + Attachment + `}
      note: DocumentsNotes { ` + Note + `}
    }
  }
`;

export const updateNote = `mutation update_Notes_by_pk($id: uuid!, $changes: Notes_set_input) {
  update_Notes_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + Note + `
    }
  }
`;

export const updatePhone = `mutation update_Phone_by_pk ($id: uuid!, $changes: Phone_set_input) {
  update_Phone_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + ContactPhone + `
    }
  }
`;

export const updateEmail = `mutation update_Email_by_pk ($id: uuid!, $changes: Email_set_input) {
  update_Email_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + ContactEmail + `
    }
  }
`;

export const updateAddress = `mutation update_Address_by_pk ($id: uuid!, $changes: Address_set_input) {
  update_Address_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + ContactAddress + `
    }
  }
`;

export const updateBiller = `mutation update_Biller_by_pk ($id: uuid!, $changes: Biller_set_input) {
  update_Biller_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + Biller + `
    }
  }
`;

export const updateServiceAndBiller = `mutation updateServiceAndBiller ($idService: uuid!, $idBiller: uuid!, $changesService: Services_set_input, $changesBiller: Biller_set_input) {
  update_Services_by_pk(
    _set: $changesService,
    pk_columns: {id: $idService}
    ), {
      ` + Service + `
    }
  }
  update_Biller_by_pk(
    _set: $changesBiller,
    pk_columns: {id: $idBiller}
    ), {
      ` + Biller + `
    }
  }
`;

export const updateSettings = `mutation update_Settings($userRealm: String!, $changes: Settings_set_input) {
  update_Settings(
    where: {userRealm: {_eq: $userRealm}} 
    _set: $changes
    ) {
      affected_rows
      returning {
        ` + Settings + `
      }
    }
  }
`;

export const deleteAccount = `mutation delete_Account_by_pk($id: uuid!) {
  item: delete_Account_by_pk(id: $id) {
    id
    name
  }
}
`;

export const deleteContact = `mutation delete_Contacts_by_pk($id: uuid!) {
  item: delete_Contacts_by_pk(id: $id) {
    id
    name
  }
}
`;

export const deleteAsset = `mutation delete_Asset_by_pk($id: uuid!) {
  item: delete_Asset_by_pk(id: $id) {
    id
    name
  }
}
`;

export const deleteLiability = `mutation delete_Liability_by_pk($id: uuid!) {
  item: delete_Liability_by_pk(id: $id) {
    id
    name
  }
}
`;

export const deleteInsurance = `mutation delete_Insurance_by_pk($id: uuid!) {
  item: delete_Insurance_by_pk(id: $id) {
    id
    name
  }
}
`;

export const deleteSubscription = `mutation delete_Subscriptions_by_pk($id: uuid!) {
  item: delete_Subscriptions_by_pk(id: $id) {
    id
    name
  }
}
`;

export const deleteService = `mutation delete_Services_by_pk($id: uuid!) {
  item: delete_Services_by_pk(id: $id) {
    id
    name
  }
}
`;

export const deleteOtherItem = `mutation delete_OtherItems_by_pk($id: uuid!) {
  item: delete_OtherItems_by_pk(id: $id) {
    id
    name
  }
}
`;

export const deleteDocument = `mutation delete_Documents_by_pk($id: uuid!) {
  item: delete_Documents_by_pk(id: $id) {
    id
    name
  }
}
`;

export const deleteBiller = `mutation delete_Biller_by_pk($id: uuid!) {
  delete_Biller_by_pk(id: $id) {
    id
    payee
  }
}
`;

export const deleteAttachment = `mutation delete_Attachments_by_pk($id: uuid!) {
  delete_Attachments_by_pk(id: $id) {
    id
    name
  }
}
`;

export const deleteActionItem = `mutation delete_ActionItems_by_pk($id: uuid!) {
  delete_ActionItems_by_pk(id: $id) {
    id
    name
  }
}
`;

export const deleteActionItems = `mutation delete_ActionItems($rows: [uuid!]!) {
  delete_ActionItems(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteActionItemsByDomainId = `mutation delete_ActionItems($domainId: uuid) {
  delete_ActionItems(
      where: {domainId: {_eq: $domainId}}
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteActionItemsByType = `mutation delete_ActionItems($itemType: String, $userRealm: String) {
  delete_ActionItems(
    where: {_and: {userRealm: {_eq: $userRealm}, type: {_eq: $itemType}}} 
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteAccounts = `mutation delete_Account($rows: [uuid!]!) {
  delete_Account(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteAssets = `mutation delete_Asset($rows: [uuid!]!) {
  delete_Asset(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteLiabilities = `mutation delete_Liability($rows: [uuid!]!) {
  delete_Liability(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteInsurances = `mutation delete_Insurance($rows: [uuid!]!) {
  delete_Insurance(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteOtherItems = `mutation delete_OtherItems($rows: [uuid!]!) {
  delete_OtherItems(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteDocuments = `mutation delete_Documents($rows: [uuid!]!) {
  delete_Documents(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteContacts = `mutation delete_Contacts($rows: [uuid!]!) {
  delete_Contacts(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteSubscriptions = `mutation delete_Subscriptions($rows: [uuid!]!) {
  delete_Subscriptions(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteServices = `mutation delete_Services($rows: [uuid!]!) {
  delete_Services(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        name
      }
  }
}
`;

export const deleteBillers = `mutation delete_Biller($rows: [uuid!]!) {
  delete_Biller(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        payee
      }
  }
}
`;

export const deleteBillerByDomainId = `mutation delete_Biller($domainId: uuid) {
  delete_Biller(
      where: {domainId: {_eq: $domainId}}
  ) {
      affected_rows
      returning {
        id
        payee
      }
  }
}
`;

export const deleteEmails = `mutation delete_Email($rows: [uuid!]!) {
  delete_Email(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        emailAddress
      }
  }
}
`;

export const deletePhones = `mutation delete_Phone($rows: [uuid!]!) {
  delete_Phone(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        phoneNumber
      }
  }
}
`;

export const deleteNotesByDomainId = `mutation delete_Notes($domainId: uuid) {
  delete_Notes(
      where: {domainId: {_eq: $domainId}}
  ) {
      affected_rows
      returning {
        id
      }
  }
}
`;

// ALL user and Realm related stuff
export const insertRealm = `mutation insert_Realms_one ($object: Realms_insert_input!) {
  insert_Realms_one(object: $object) {
    ` + Realm + `
  }
}
`;

export const insertUser = `mutation insert_Users_one ($object: Users_insert_input!) {
  insert_Users_one(object: $object) {
    ` + User + `
  }
}
`;

export const insertUsers = `mutation insert_Users($objects: [Users_insert_input!]!) {
  insert_Users(objects: $objects) {
    returning {
      ` + User + `
    }
  }
}
`;


export const updateUser = `mutation update_Users_by_pk ($id: uuid!, $changes: Users_set_input) {
  update_Users_by_pk(
    _set: $changes
    pk_columns: {id: $id}
    ) {
      ` + User + `
    }
  }
`;

export const deleteUser = `mutation delete_Users_by_pk($id: uuid!) {
  delete_Users_by_pk(id: $id) {
    id
    loginName
  }
}
`;

export const deleteUsers = `mutation delete_Users($rows: [uuid!]!) {
  delete_Users(
      where: {id: {_in: $rows}}
  ) {
      affected_rows
      returning {
        id
        loginName
      }
  }
}
`;

export const addUserToRealm = `mutation addUserToRealm ($object: UsersRealms_insert_input!) {
  insert_UsersRealms_one(object: $object) {
    ` + UsersRealms + `
  }
}`

export const updateUserRoleInRealm = `mutation updateUserRoleInRealm ($realmId: uuid!, $userId:uuid! $changes: UsersRealms_set_input) {
  update_UsersRealms_by_pk(
    _set: $changes
    pk_columns: {
      realmId: $realmId, 
      userId: $userId
    }), {
      ` + UsersRealms + `
    }
}`

export const removeUserFromRealm = `mutation removeUserFromRealm ($realmId: uuid!, $userId: uuid!) {
  delete_UsersRealms_by_pk(realmId: $realmId, userId: $userId) {  
    realmId
    userId  
  }
}`





// OLD STUFF

export const createIssue = `mutation CreateIssue($input: CreateIssueInput!) {
  createIssue(input: $input) {
    id
    title
    description
    issueType
    dateIssued
    status
    issuerId
    issuerName
    resolverId
    resolverName
  }
}
`;
export const updateIssue = `mutation UpdateIssue($input: UpdateIssueInput!) {
  updateIssue(input: $input) {
    id
    title
    description
    issueType
    dateIssued
    status
    issuerId
    issuerName
    resolverId
    resolverName
  }
}
`;
export const deleteIssue = `mutation DeleteIssue($input: DeleteIssueInput!) {
  deleteIssue(input: $input) {
    id
    title
    description
    issueType
    dateIssued
    status
    issuerId
    issuerName
    resolverId
    resolverName
  }
}
`;
export const createFamily = `mutation CreateFamily($input: CreateFamilyInput!) {
  createFamily(input: $input) {
    id
    realm
    members {
      items {
        id
        userRealm
        firstName
        lastName
        email
        phone
      }
      nextToken
    }
  }
}
`;
export const updateFamily = `mutation UpdateFamily($input: UpdateFamilyInput!) {
  updateFamily(input: $input) {
    id
    realm
    members {
      items {
        id
        userRealm
        firstName
        lastName
        email
        phone
      }
      nextToken
    }
  }
}
`;
export const deleteFamily = `mutation DeleteFamily($input: DeleteFamilyInput!) {
  deleteFamily(input: $input) {
    id
    realm
    members {
      items {
        id
        userRealm
        firstName
        lastName
        email
        phone
      }
      nextToken
    }
  }
}
`;


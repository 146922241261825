import {ActionItemType, ActionItemGroupType, PriorityType, NonChoice } from '../../Enums';

const prepareDetailsForServiceItems = (otherItems) => {
  let priority = PriorityType.Low;
  
  let name;
  if (otherItems.recommendation === NonChoice) {
    name = "Figure out what you want to with '" + otherItems.name + "'. Keep? Sell? Give away?."
  } else {
    name = "Figure out what you want to do with '" + otherItems.name + "'. RECOMMENDATION: " + otherItems.recommendation.toLowerCase() + ".";
  }
  
  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: otherItems.id,
    domainName: otherItems.name,
    type: ActionItemType.OtherItems,
    group: ActionItemGroupType.Home,
    notes:  ''
  };
  return actionItemDetails;
}

export const constructCorrespondingActionItems = (item) => {
  const actionItems = [];

  const otherItemsItemDetails = prepareDetailsForServiceItems(item);
  if (otherItemsItemDetails) {
    actionItems.push(otherItemsItemDetails);
  }
 
  return actionItems;
}
import React from 'react';
import PropTypes from 'prop-types';

import { Redirect, useLocation } from "react-router-dom";
import LetterRoutes from "../../Routes";

const LandingWithRealms = (props) => {
  const location = useLocation();
  
  if (!props.authenticated) {
    return null;
  }

  if (!props.appUser) {
    return null;
  }

  return (
    <Redirect
        to={{
          pathname: LetterRoutes.OverviewRoute,
          state: { from: location },
        }}
      />
  );
}

LandingWithRealms.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  appUser: PropTypes.object,
};

export default LandingWithRealms;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import axios from "axios";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import FormControl from '@material-ui/core/FormControl';

import {useApolloClient} from '@apollo/client';
import {addOneAttachment} from '../../api/AttachmentApi';

import {useFamilyContext} from '../../context/FamilyContext';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  container: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const UploadDialog = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext()

  const [uploadFile, setUploadFile] = useState({});
  const [attachmentName, setAttachmentName] = useState(null);
  const [attachmentDescription, setAttachmentDescription] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleChange= (e) => {
    if (e.target.files.length) {
      setUploadFile(e.target.files[0]);
    }
  }

  // formdata handler to retrieve data
  const handleUpload =  (f) => {
    
    var url = '/api/uploadFile';

    // construct a form data
    let data = new FormData();
    data.append('myfile', uploadFile);
    data.append('realm', familyContext.familyRealm.id);

    // set up the progress indicator
    setLoading(true);

    axios({
      method: 'POST',
      url: url,
      data: data,
      onUploadProgress: function (pe) {
        console.log('got a progress event')
        if(pe.lengthComputable) {
          //progressBar.max = pe.total
          //progressBar.value = pe.loaded
          console.log('Total %s:', pe.total);
          console.log('Loaded %s:', pe.loaded)
        }
      }
    })
    .then((response) => {
      setLoading(false);

      // now, we need to create an attachment
      console.log(response.data)
      const payload = {
        userRealm: familyContext.familyRealm.id,
        uri: response.data.results.data.Location,
        domainId: props.domainId,
        name: attachmentName,
        mimeType: response.data.results.mimetype,
        originalName: response.data.results.filename,
        description: attachmentDescription,
      }

      addOneAttachment(client, payload)
      .then(response => {
        console.log(response);
        props.handleSuccess(payload);
      })
      .catch(error => {
        console.log(error.toJSON());
        props.handleCancel() 
      });
    })
    .catch((error) => {
      setLoading(false);
      console.log(error.toJSON());
      props.handleCancel();
    })
  };

  const uploadHelperText = "Select a file to upload";

  return (
    <div className={classes.root}>
      <Dialog
        TransitionComponent={Transition}
        maxWidth = {'md'}
        open={props.open}
        onClose={props.handleCancel}
        aria-labelledby="upload-data-dialog-title">
        <DialogTitle>Upload a File Attachment</DialogTitle>
          {loading ? (
            <Container className={classes.container}>
              <CircularProgress />
            </Container>
          ): null}
          <form id = "uploadform-id" method="post" encType="multipart/form-data">
            <DialogContent>
              <FormControl className={classes.formControl}>
                <TextField 
                    autoFocus
                    type = "file"
                    margin="normal"
                    inputProps={{
                      name: 'uploadedFile',
                    }}
                    className = {classes.textField}
                    onChange = {handleChange}
                    helperText = {uploadHelperText}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField 
                    type = "text"
                    label = "Name"
                    className = {classes.textField}  
                    onChange = {(ev) => {setAttachmentName(ev.target.value)}}
                    helperText = {"Provide a name for this attachment"}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="description"
                    type="text"
                    label="Description"
                    multiline
                    maxRows={4}
                    className={classes.textField}
                    onChange = {(ev) => {setAttachmentDescription(ev.target.value)}}
                    helperText={"Describe this attachment"}
                  />  
                </FormControl>
                <input type="text" hidden id="realm" name="realm" />
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpload}  disabled = {uploadFile === null || attachmentName === null || attachmentDescription === null} color="primary">
                Upload
              </Button>
            </DialogActions>
          </form>
      </Dialog> 
    </div>
  );
}

UploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  domainId: PropTypes.string.isRequired,
  domainType: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};
export default (UploadDialog);
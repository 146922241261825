import {AccountType, ActionItemType, ActionItemGroupType, PriorityType } from '../../Enums';
import { NonChoice } from '../../Enums';

const prepareDetailsForAccountItems = (editItem, account) => {
  // figure out the priority based on the contact type
  let priority = PriorityType.Medium;
  if (account.type === AccountType.Checking || account.type === AccountType.Credit) {
    priority = PriorityType.High;
  }

  let actionItemName = "Close " + account.name;
  if (editItem.contact !== null) {
    actionItemName = "Contact " + editItem.contact.name + " to close the account for '" + account.name + "'";
  }

  if (account.type === AccountType.Investment || account.type === AccountType.Retirement) {
    if (account.institution) {
      actionItemName = "Contact " + account.institution + " to understand beneficiaries and discuss next steps for '" + account.name + "'.";
      if (editItem.contact !== null) {
        actionItemName = "Contact " + editItem.contact.name + " at " + account.institution + " to understand beneficiaries and discuss next steps for '" + account.name + "'.";
      }
    } else {
      actionItemName = "Contact the institution that manages '" + account.name + "' to understand beneficiaries and discuss next steps.";
      if (editItem.contact !== null) {
        actionItemName = "Contact " + editItem.contact.name + " at the institution that manages '" + account.name + "' to understand beneficiaries and discuss next steps.";
      }
    }
  }

  const actionItemDetails = {
    name: actionItemName,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: account.id,
    domainName: account.name,
    type: ActionItemType.Account,
    group: ActionItemGroupType.None,
    notes: ''
  };
  
  return actionItemDetails;
}

export const constructCorrespondingActionItems = (editedItem, item) => {
  const actionItems = [];

  const itemDetails = prepareDetailsForAccountItems(editedItem, item);
  if (itemDetails) {
    actionItems.push(itemDetails);
  }
 
  return actionItems;
}
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import cloneDeep from "lodash.clonedeep";

import AddEditAccountForm from "./forms/AddEditAccountForm";
import AccountActionPanel from "./forms/AccountActionPanel";

import LTRCommonLayout from "../shared/domains/LTRCommonLayout";
import LTRCommonNameList, {
  RefreshReason,
} from "../shared/domains/LTRCommonNameList";
import LTRCommonDataTable from "../shared/domains/LTRCommonDataTable";
import LTRDomainObjectInfo from "../shared/domains/LTRDomainObjectInfo";
import LTRSnackbar, {
  buildAddMessage,
  buildEditMessage,
  buildDeleteMessage,
} from "../utilties/LTRSnackbar";

import { useQuery, gql } from "@apollo/client";
import { listAccounts } from "../../graphql/queries";

import { useAccountDelete } from "../../api/APIHooks";

import { DomainType, DomainTypeSingular } from "../../Enums";

import { useFamilyContext } from "../../context/FamilyContext";

import ncrypter from "../utilties/ncrypter";
import PasscodeStorage, { pcodeNotFound } from "../../api/PasscodeStorage";

import { sortListByName } from "../utilties/SortFunctions";

import { prepareTableRowInfo } from "./AccountInfo";

import { useSnackbar } from "../utilties/snackbarUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const Accounts = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [accounts, setAccounts] = useState(null);

  const [editFormOpen, setEditFormOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [infoTableItem, setInfoTableItem] = useState(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const [visibleList, setVisibleList] = useState([]);

  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  const processReceivedData = (data) => {
    setAccounts(data);
  };

  const [deleteOneAccount] = useAccountDelete(familyContext.familyRealm.id, (data) => {
      console.log(data);
    }
  );

  const { loading, error, data, refetch } = useQuery(gql(listAccounts), {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  useEffect(() => {
    const onCompleted = (data) => {
      processReceivedData(data.item);
    };

    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    // just one reason we're here: if accounts change we need to adjust
    if (accounts) {
      const sortedList = sortListByName(accounts);
      setVisibleList(sortedList);
    }
  }, [accounts]);

  const handleSearch = (searchTerm) => {
    if (accounts.length === 0) {
      return [];
    }

    let displayList;
    if (searchTerm === null) {
      displayList = [...accounts];
    } else {
      displayList = accounts.filter((item) => {
        const testName = item.name ? item.name.toLowerCase() : "";
        const testType = item.type ? item.type.toLowerCase() : "";
        const testInstitution = item.institution
          ? item.institution.toLowerCase()
          : "";
        const testAccountNumber = item.accountNumber
          ? item.accountNumber.toLowerCase()
          : "";
        const testNotes = item.usage ? item.usage.toLowerCase() : "";
        const againstSearchTerms = searchTerm ? searchTerm.toLowerCase() : "";

        // test the relevant fields
        return (
          testName.includes(againstSearchTerms) ||
          testType.includes(againstSearchTerms) ||
          testInstitution.includes(againstSearchTerms) ||
          testAccountNumber.includes(againstSearchTerms) ||
          testNotes.includes(againstSearchTerms)
        );
      });
    }
    return setVisibleList(displayList);
  };

  const handleAddNotification = (item) => {
    if (item) {
      openSnackbar(buildAddMessage(item.name));
    }
  };

  const handleEditNotification = (item) => {
    if (item) {
      openSnackbar(buildEditMessage(item.name));
    }
  };

  const handleEditOpen = (selectedItem) => {
    setSelectedItem(selectedItem);
    setEditFormOpen(true);
  };

  const handleEditClose = () => {
    setSelectedItem(null);
    setEditFormOpen(false);
  };

  const handleDelete = (selected) => {
    deleteOneAccount({ variables: { id: selected.id } })
      .then((data) => {
        openSnackbar(buildDeleteMessage(selected.name));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMultiDeleteNotification = (selected) => {
    // id is the id of the deleted object

    const aList = accounts.filter((item) => {
      return selected.includes(item.id) ? false : true;
    });

    let userMessage = "";
    const numItemsDeleted = selected.length;
    if (numItemsDeleted > 1) {
      userMessage = numItemsDeleted + " accounts deleted successfully";
      openSnackbar(userMessage);
    } else {
      userMessage = "Account deleted successfully";
      openSnackbar(userMessage);
    }
    setAccounts(aList);
  };

  const handleEditFromId = (selectedId) => {
    const aList = accounts.filter((item) => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!

    // call the handleEdit open handler
    handleEditOpen(aList[0]);
  };

  const handleRefresh = (selected, refreshReason) => {
    console.log("Selected:");
    console.log(selected);

    switch (refreshReason) {
      case RefreshReason.UploadAttachment:
        console.log("Refresh - upload");
        refetch();
        break;
      case RefreshReason.DeleteAttachment:
        console.log("Refresh - delete");

        const list = accounts.filter((i) => i.id !== selected.id);
        selected.attachments = [];
        list.push(selected);
        processReceivedData(list);
        break;
      default:
        console.log("NOT A VALID PATH");
        break;
    }
  };

  const handleShowInfo = (selectedId) => {
    const aList = accounts.filter((item) => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!

    let account = cloneDeep(aList[0]);

    if (account.accountNumber && account.accountNumber !== "") {
      // see if we have a passcode
      const pcode = PasscodeStorage.getPasscode();
      if (pcode !== pcodeNotFound) {
        // we've got a passcode - go ahead and decrypt
        const tempNumber = ncrypter.decryptData(pcode, account.accountNumber);
        account.accountNumber = tempNumber;
      } else {
        account.accountNumber = "########################";
      }
    }

    const tableInfo = prepareTableRowInfo(account);

    setInfoTableItem(tableInfo);

    setSelectedItem(aList[0]);
    setShowInfoDialog(true);
  };

  const handleShowInfoClose = () => {
    setSelectedItem(false);
    setShowInfoDialog(false);
  };

  const checkForAttachments = (selected) => {
    return selected.attachments.length > 0;
  };

  const handleViewChange = (oldView, newView) => {
    // whenever we get a view change, we need to cancel all filtering, searching and restore the display list
    handleSearch(null);
  };

  // are we ready?
  if (accounts === null) {
    return <div>Loading ...</div>;
  }

  return (
    <React.Fragment>
      <LTRCommonLayout
        domain={DomainType.Accounts}
        domainSingular={DomainTypeSingular.Accounts}
        handleViewChange={handleViewChange}
        commonNameList={
          <div className={classes.root}>
            <LTRCommonNameList
              list={visibleList}
              domain={DomainType.Accounts}
              domainSingular={DomainTypeSingular.Accounts}
              hasAttachments={checkForAttachments}
              addComponent={
                <AddEditAccountForm
                  handleAdd={handleAddNotification}
                  handleEdit={handleEditNotification}
                  handleReceiveData={processReceivedData}
                />
              }
              handleDelete={handleDelete}
              handleEdit={handleEditOpen}
              handleInfo={handleShowInfo}
              handleSearch={handleSearch}
              handleRefresh={handleRefresh}
            />
          </div>
        }
        commonNameTable={
          <LTRCommonDataTable
            domain={DomainType.Accounts}
            domainColumns={[
              { name: "name", label: "Name" },
              { name: "type", label: "Type" },
              { name: "institution", label: "Institution" },
              { name: "taxable", label: "Taxable" },
              {
                name: "billpayAccount",
                label: "Billpay Account",
                options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                    if (value === true) {
                      return "yes";
                    } else {
                      return "no";
                    }
                  },
                },
              },
              { name: "usage", label: "Notes" },
            ]}
            dataItems={visibleList}
            handleMultiDeleteNotification={handleMultiDeleteNotification}
            handleEdit={handleEditFromId}
          />
        }
        actionPanel={
          <AccountActionPanel 
            handleAddNotification={handleAddNotification} 
            handleReceiveData={processReceivedData}
          />
        }
      />
      {editFormOpen ? (
        <AddEditAccountForm
          open={editFormOpen}
          selected={selectedItem}
          handleAdd={handleAddNotification}
          handleEdit={handleEditNotification}
          handleClose={handleEditClose}
          handleReceiveData={processReceivedData}
        />
      ) : null}
      {showInfoDialog ? (
        <LTRDomainObjectInfo
          open={showInfoDialog}
          title={selectedItem.name}
          tableData={infoTableItem}
          handleClose={handleShowInfoClose}
        />
      ) : null}
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    </React.Fragment>
  );
};

export default Accounts;

import {gql} from '@apollo/client';

import {
  updatePhone,
  insertPhones,
  deletePhones,
} from '../graphql/mutations';

export async function deleteMultiplePhones(client, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(deletePhones), 
      variables: {rows: payload}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function insertMultiplePhones(client, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(insertPhones), 
      variables: {objects: payload}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function updateOnePhone(client, id, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(updatePhone), 
      variables: {id: id, changes: payload}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
}
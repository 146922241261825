import { gql } from '@apollo/client';

import {
  insertAttachment,
} from '../graphql/mutations';

// Attachments are stored in AWS S3

// they are accessed through end points on our server, which in turn 
// uses the AWS S3 SDK to manage our S3 data

// endpoints:

export const uploadUrl = '/api/uploadFile';
export const deleteEndpoint = '/api/deleteFile';
export const signedUrlEndpoint = '/api/getFileUrl';
export const getFileEndpoint = '/api/getFile';

export async function addOneAttachment(client, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(insertAttachment), 
      variables: {object: payload}
    })
    .then(result => { 
      resolve(result.data.insert_Attachments_one);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};


// delete
//const deleteOneAttachment =  (attachment) => {
 
  // const options = {
  //   method: 'POST',
  //   body: attachment.name,
  // };

  // fetch(deleteUrl, options)
  // .then((response) => {
  //   return (response.json());
  // })
  // .then((json) => {
  //   setLoading(false);

  //   // now, we need to create an attachment
  //   console.log(json)
  //   const payload = {
  //     userRealm: familyContext.familyRealm.id,
  //     uri: json.data.Location,
  //     domainId: props.domainId,
  //     name: attachmentName,
  //     mimeType: json.mimetype,
  //     originalName: json.filename
  //   }

  //   deleteAttachment(client, payload)
  //   .then(response => {
  //     console.log(response);
  //   })
  //   .catch(error => {
  //     console.log(error) 
  //   });
  // })
  // .catch((error) => {
  //   setLoading(false);
  //   console.log(error);
  // })
  // .finally(() => {
  //   props.handleClose()
  // }) 
//};


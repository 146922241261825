import {
  updateOneEmail,
  insertMultipleEmails,
  deleteMultipleEmails,
} from '../../api/EmailApi';

import {
  updateOnePhone,
  insertMultiplePhones,
  deleteMultiplePhones,
} from '../../api/PhoneApi';

const determineEmailChanges = (original, edited) => {
  // emails first
  let emailsToDelete = [];
  let emailsToAdd = [];
  let emailsToUpdate = [];
    
  if (original) {
    const numOriginal = original.length;
    const numEdited = edited.length;
    if (numOriginal > numEdited) {
      // removing an email

      // special case - if there are NONE left in edited then all
      // emails have been deleted
      if (numEdited === 0) {
        emailsToDelete = [...original];
      } else {
        // have to see which have been deleted
        emailsToDelete = original.filter( item => {
          const bList = edited.filter(email => {
            return (item.id.indexOf(email.id) === -1) ? true : false;
          })
          return bList.length > 0  ? true : false;
        });
      }
    } else if (numOriginal < numEdited) {
      // adding an email - just look at the edit list
      // for items that are incomplete

      emailsToAdd = edited.filter( item => {
        // item will have an email address and a type, but nothing else
        return (item.id === undefined) ? true : false;
      })
    } else {
      emailsToUpdate = edited.filter (item => {
        const bList = original.filter (email => {
          if (email.id.indexOf(item.id) === 0) {
            // same item, did anything change
            if (email.type.indexOf(item.type) !== 0) {
              // type is different
              return true;
            } 
            if (email.emailAddress.indexOf(item.emailAddress) !== 0) {
              // email address is different
              return true;
            }
            
            return false;
          }
        });
        return bList.length > 0 ? true : false;
      })
    }
  }
  return ({emailsToDelete, emailsToAdd, emailsToUpdate});
}

export const handleContactEmailChanges = (contact, client, userRealm, original, edited) => {
  
  const {emailsToDelete, emailsToAdd, emailsToUpdate} = determineEmailChanges(original, edited);

  // deletes
  let deletedIds = [];
  emailsToDelete.map(email => {
    deletedIds.push(email.id);
    return true;
  })
  if (deletedIds.length > 0) {
    deleteMultipleEmails(client, deletedIds)
    .then(result => { 
      console.log(result);
    })
    .catch(error => {
       console.log(error) 
    });
  }

  // adds
  let addedEmails = [];
  emailsToAdd.map(email => {
    let newEmail = {
      emailAddress: email.emailAddress,
      type: email.type,
      contactId: contact.id,
      userRealm:userRealm
    }
    addedEmails.push(newEmail)
    return true; 
  })
  
  if (addedEmails.length > 0) {
    insertMultipleEmails(client, addedEmails)
    .then(result => { 
      console.log(result);
    })
    .catch(error => {
       console.log(error) 
    });
  }

  // modify - have to do this one at a time
  emailsToUpdate.map(email => {
    let changedEmail = {
      // only two fields we want to change
      emailAddress: email.emailAddress,
      type: email.type,
    }
    updateOneEmail(client, email.id, changedEmail)
    .then(result => { 
      console.log(result);
    })
    .catch(error => {
       console.log(error) 
    });
    return true;
  })
}

const determinePhoneChanges = (original, edited) => {
  let phonesToDelete = [];
  let phonesToAdd = [];
  let phonesToUpdate = [];
    
  if (original) {
    const numOriginal = original.length;
    const numEdited = edited.length;
    if (numOriginal > numEdited) {
      // removing an email

      // special case - if there are NONE left in edited then all
      // emails have been deleted
      if (numEdited === 0) {
        phonesToDelete = [...original];
      } else {
        // have to see which have been deleted
        phonesToDelete = original.filter( item => {
          const bList = edited.filter(phone => {
            return (item.id.indexOf(phone.id) === -1) ? true : false;
          })
          return bList.length > 0  ? true : false;
        });
      }
    } else if (numOriginal < numEdited) {
      // additions appear in the edit list as items that are incomplete

      phonesToAdd = edited.filter( item => {
        // item will have a phone number and a type, but nothing else
        return (item.id === undefined) ? true : false;
      })
    } else {
      phonesToUpdate = edited.filter (item => {
        const bList = original.filter (phone => {
          if (phone.id.indexOf(item.id) === 0) {
            // same item, did anything change
            if (phone.type.indexOf(item.type) !== 0) {
              // type is different
              return true;
            } 
            if (phone.phoneNumber.indexOf(item.phoneNumber) !== 0) {
              // phone number is different
              return true;
            }
            
            return false;
          }
        });
        return bList.length > 0 ? true : false;
      })
    }
  }
  return ({phonesToDelete, phonesToAdd, phonesToUpdate});
}

export const handleContactPhoneChanges = (contact, client, userRealm, original, edited) => {
  
  const {phonesToDelete, phonesToAdd, phonesToUpdate} = determinePhoneChanges(original, edited);

  // deletes
  let deletedIds = [];
  phonesToDelete.map(email => {
    deletedIds.push(email.id)
  })
  if (deletedIds.length > 0) {
    deleteMultiplePhones(client, deletedIds)
    .then(result => { 
      console.log(result);
    })
    .catch(error => {
       console.log(error) 
    });
  }

  // adds
  let addedPhones = [];
  phonesToAdd.map(phone => {
    let newPhone = {
      phoneNumber: phone.phoneNumber,
      type: phone.type,
      contactId: contact.id,
      userRealm: userRealm,
    };
    
    addedPhones.push(newPhone)
  })
  
  if (addedPhones.length > 0) {
    insertMultiplePhones(client, addedPhones)
    .then(result => { 
      console.log(result);
    })
    .catch(error => {
       console.log(error) 
    });
  }

  // modify - have to do this one at a time
  phonesToUpdate.map(phone => {
    let changedPhone = {
      // only two fields we want to change
      phoneNumber: phone.phoneNumber,
      type: phone.type,
    }
    updateOnePhone(client, phone.id, changedPhone)
    .then(result => { 
      console.log(result);
    })
    .catch(error => {
       console.log(error) 
    });
  })
}
import React from 'react';
import PropTypes from 'prop-types';

import InfoModal from '../modals/InfoModal'

import AccountSnip from '../../help/domainHelpSnippets/AccountSnip';
import ContactsSnip from '../../help/domainHelpSnippets/ContactsSnip';
import AssetSnip from '../../help/domainHelpSnippets/AssetSnip';
import DocumentsSnip from '../../help/domainHelpSnippets/DocumentsSnip';
import LiabilitySnip from '../../help/domainHelpSnippets/LiabilitySnip';
import InsuranceSnip from '../../help/domainHelpSnippets/InsuranceSnip';
import SubscriptionsSnip from '../../help/domainHelpSnippets/SubscriptionsSnip';
import ServicesSnip from '../../help/domainHelpSnippets/ServicesSnip';
import OtherItemsSnip from '../../help/domainHelpSnippets/OtherItemsSnip';

import { DomainType } from '../../../Enums';

const DomainHelp = (props) => {
  
  const getSnip = () => {
    const def = 'Answer the question: what is a ' + props.domainSingular
    switch (props.domain) {
      case DomainType.Accounts:
        return (<AccountSnip />)
      case DomainType.Contacts:
        return (<ContactsSnip />)
      case DomainType.Assets:
        return (<AssetSnip />)
      case DomainType.Documents:
        return (<DocumentsSnip />)
      case DomainType.Liabilities:
        return (<LiabilitySnip />)
      case DomainType.Insurance:
        return (<InsuranceSnip />)
      case DomainType.Services:
        return (<ServicesSnip />)
      case DomainType.Subscriptions:
        return (<SubscriptionsSnip />)
      case DomainType.OtherItems:
        return (<OtherItemsSnip />)
      default:
        console.log(props.domain)
        return def;
    }
  }

  return (
     <InfoModal 
      open = {props.open}
      title = {props.domain + ' help'}
      handleClose = {props.handleClose}   
      body = {getSnip()}
     />
  );
}

DomainHelp.propTypes = {
  open: PropTypes.bool.isRequired,
  domain: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default DomainHelp;
import { gql } from '@apollo/client';

import { OperationTargets } from './CommonCrudOperations';
import { ActionItemType } from '../Enums';

import {
  insertActionItem,
  insertActionItems,
  updateActionItem,
  updateActionItemsWhere,
  updateActionItemsWhereOwnersAreNull,
  updateActionItemsGroupWhere,
  updateActionItemsGroupWhereOwnersAreNull,
  deleteActionItem,
  deleteActionItemsByDomainId,
  deleteActionItemsByType
} from '../graphql/mutations';

import {
  getActionItem,
  listAllActionItems,
  listAccountActionItems,
  listContactActionItems,
  listAssetActionItems,
  listLiabilityActionItems,
  listInsuranceActionItems,
  listServiceActionItems,
  listSubscriptionActionItems,
  listOtherItemsActionItems,
  listDocumentsActionItems,
  listGeneralActionItems,
} from '../graphql/queries';

export const actionItemTypeToOperationTarget = (actionItem) => {
  let retVal;
  switch (actionItem) {
    case ActionItemType.Account: {
      retVal = OperationTargets.Accounts;
      break;
    }
    case ActionItemType.Contacts: {
      retVal = OperationTargets.Contacts;
      break;
    }
    case ActionItemType.Assets: {
      retVal = OperationTargets.Assets;
      break;
    }
    case ActionItemType.Liabilities: {
      retVal = OperationTargets.Liabilities;
      break;
    }
    case ActionItemType.Insurance: {
      retVal = OperationTargets.Insurance;
      break;
    }
    case ActionItemType.Subscriptions: {
      retVal = OperationTargets.Subscriptions;
      break;
    }
    case ActionItemType.Services: {
      retVal = OperationTargets.Services;
      break;
    }
    case ActionItemType.OtherItems: {
      retVal = OperationTargets.OtherItems;
      break;
    }
    case ActionItemType.Documents: {
      retVal = OperationTargets.Documents;
      break;
    }
    case ActionItemType.General: {
      retVal = OperationTargets.General;
      break;
    }
    default: 
      console.log('Unexpected domain type')
  }
  return retVal;
}

export async function fetchOneActionItem(client, id) { 
  let promise = new Promise((resolve, reject) => {

    client.query( {
      query: gql(getActionItem), 
      variables: {id: id}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function addOneActionItem(client, userRealm, target, payload) { 
  let promise = new Promise((resolve, reject) => {

    let refetchQuery;
    switch (target) {
      case OperationTargets.Accounts:
        refetchQuery = gql(listAccountActionItems);
        break;
      case OperationTargets.Contacts:
        refetchQuery = gql(listContactActionItems);
        break;
      case OperationTargets.Assets:
        refetchQuery = gql(listAssetActionItems);
        break;
      case OperationTargets.Liabilities:
        refetchQuery = gql(listLiabilityActionItems);
        break;
      case OperationTargets.Insurance:
        refetchQuery = gql(listInsuranceActionItems);
        break; 
      case OperationTargets.Subscriptions:
        refetchQuery = gql(listSubscriptionActionItems);
        break;
      case OperationTargets.Services:
        refetchQuery = gql(listServiceActionItems);
        break;
      case OperationTargets.OtherItems:
        refetchQuery = gql(listOtherItemsActionItems);
        break;
      case OperationTargets.Documents:
        refetchQuery = gql(listDocumentsActionItems);
        break; 
      case OperationTargets.General:
        refetchQuery = gql(listGeneralActionItems);
        break;                     
      default:
        console.log('Unknown operation')
    }

    client.mutate( {
      mutation: gql(insertActionItem),
      refetchQueries: [{
        query: refetchQuery,
        variables: { userRealm: userRealm},
      }], 
      variables: {object: payload}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function addMultipleActionItems(client, userRealm, target, payload) { 
  let promise = new Promise((resolve, reject) => {

    let refetchQuery;
    switch (target) {
      case OperationTargets.Accounts:
        refetchQuery = gql(listAccountActionItems);
        break;
      case OperationTargets.Contacts:
        refetchQuery = gql(listContactActionItems);
        break;
      case OperationTargets.Assets:
        refetchQuery = gql(listAssetActionItems);
        break;
      case OperationTargets.Liabilities:
        refetchQuery = gql(listLiabilityActionItems);
        break;
      case OperationTargets.Insurance:
        refetchQuery = gql(listInsuranceActionItems);
        break; 
      case OperationTargets.Subscriptions:
        refetchQuery = gql(listSubscriptionActionItems);
        break;
      case OperationTargets.Services:
        refetchQuery = gql(listServiceActionItems);
        break;
      case OperationTargets.OtherItems:
        refetchQuery = gql(listOtherItemsActionItems);
        break; 
      case OperationTargets.Documents:
        refetchQuery = gql(listDocumentsActionItems);
        break; 
      case OperationTargets.General:
        refetchQuery = gql(listGeneralActionItems);  
        break;              
      default:
        console.log('Unknown operation')
    }

    client.mutate( {
      mutation: gql(insertActionItems),
      refetchQueries: [{
        query: refetchQuery,
        variables: { userRealm: userRealm},
      }],   
      variables: {objects: payload}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function updateOneActionItem(client, userRealm, target, id, payload) { 
  let promise = new Promise((resolve, reject) => {

    let refetchQuery;
    switch (target) {
      case OperationTargets.Accounts:
        refetchQuery = gql(listAccountActionItems);
        break;
      case OperationTargets.Contacts:
        refetchQuery = gql(listContactActionItems);
        break;
      case OperationTargets.Assets:
        refetchQuery = gql(listAssetActionItems);
        break;
      case OperationTargets.Liabilities:
        refetchQuery = gql(listLiabilityActionItems);
        break;
      case OperationTargets.Insurance:
        refetchQuery = gql(listInsuranceActionItems);
        break; 
      case OperationTargets.Subscriptions:
        refetchQuery = gql(listSubscriptionActionItems);
        break;
      case OperationTargets.Services:
        refetchQuery = gql(listServiceActionItems);
        break;
      case OperationTargets.OtherItems:
        refetchQuery = gql(listOtherItemsActionItems);
        break; 
      case OperationTargets.Documents:
        refetchQuery = gql(listDocumentsActionItems);
        break; 
      case OperationTargets.General:
        refetchQuery = gql(listGeneralActionItems);  
        break;                  
      default:
        console.log('Unknown operation')
    }

    client.mutate( {
      mutation: gql(updateActionItem),
      refetchQueries: [{
        query: refetchQuery,
        variables: { userRealm: userRealm},
      }],  
      variables: {id: id, changes: payload}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

// special purpose = update owners of an action item, either all or some 
export async function updateActionItemOwners(client, userRealm, itemType, applyToAll, payload) { 
  let promise = new Promise((resolve, reject) => {

    const updateMutation = applyToAll ? gql(updateActionItemsWhere) : gql(updateActionItemsWhereOwnersAreNull);

    client.mutate( {
      mutation: updateMutation,
      variables: {
        userRealm: userRealm,
        itemType: itemType, 
        changes: payload
      }
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function updateActionItemGroupOwners(client, userRealm, groupType, applyToAll, payload) { 
  let promise = new Promise((resolve, reject) => {

    const updateMutation = applyToAll ? gql(updateActionItemsGroupWhere) : gql(updateActionItemsGroupWhereOwnersAreNull);

    client.mutate( {
      mutation: updateMutation,
      variables: {
        userRealm: userRealm,
        groupType: groupType, 
        changes: payload
      }
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function deleteActionItemsByDomainOwner(client, userRealm, target, domainId) { 
  let promise = new Promise((resolve, reject) => {

    let refetchQuery;
    switch (target) {
      case OperationTargets.Accounts:
        refetchQuery = gql(listAccountActionItems);
        break;
      case OperationTargets.Contacts:
        refetchQuery = gql(listContactActionItems);
        break;
      case OperationTargets.Assets:
        refetchQuery = gql(listAssetActionItems);
        break;
      case OperationTargets.Liabilities:
        refetchQuery = gql(listLiabilityActionItems);
        break;
      case OperationTargets.Insurance:
        refetchQuery = gql(listInsuranceActionItems);
        break; 
      case OperationTargets.Subscriptions:
        refetchQuery = gql(listSubscriptionActionItems);
        break;
      case OperationTargets.Services:
        refetchQuery = gql(listServiceActionItems);
        break;
      case OperationTargets.OtherItems:
        refetchQuery = gql(listOtherItemsActionItems);
        break;  
      case OperationTargets.Documents:
        refetchQuery = gql(listDocumentsActionItems);
        break; 
      case OperationTargets.General:
        refetchQuery = gql(listGeneralActionItems);
        break;                  
      default:
        console.log('Unknown target')
    }

    client.mutate( {
      mutation: gql(deleteActionItemsByDomainId),
      refetchQueries: [{
        query: refetchQuery,
        variables: { userRealm: userRealm},
      }],   
      variables: {domainId: domainId}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function fetchActionItems(client, userRealm, itemType) { 
  let promise = new Promise((resolve, reject) => {

    let selectedQuery;
    let ourFetchPolicy = 'network-only';
    switch (itemType) {
      case ActionItemType.All:
        selectedQuery = gql(listAllActionItems);
        break;
      case ActionItemType.Account:
        selectedQuery = gql(listAccountActionItems);
        break;
      case ActionItemType.Contacts:
        selectedQuery = gql(listContactActionItems);
        break;
      case ActionItemType.Assets:
        selectedQuery = gql(listAssetActionItems);
        break;
      case ActionItemType.Liabilities:
        selectedQuery = gql(listLiabilityActionItems);
        break;
      case ActionItemType.Insurance:
        selectedQuery = gql(listInsuranceActionItems);
        break;  
      case ActionItemType.Subscriptions:
        selectedQuery = gql(listSubscriptionActionItems); 
        break;
      case ActionItemType.Services:
        selectedQuery = gql(listServiceActionItems); 
        break;
      case ActionItemType.OtherItems:
        selectedQuery = gql(listOtherItemsActionItems); 
        break;
      case OperationTargets.Documents:
        selectedQuery = gql(listDocumentsActionItems);  
        break; 
      case OperationTargets.General:
        selectedQuery = gql(listGeneralActionItems);  
        break;  
      default:
        selectedQuery = gql(listAllActionItems);
    }
    client.query( {
      query: selectedQuery,
      fetchPolicy: ourFetchPolicy,
      variables: {userRealm: userRealm}
    })
    .then(result => { 
      resolve(result);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};


export async function deleteActionItemsByItemType(client, realm, type) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(deleteActionItemsByType),
      variables: {itemType: type, userRealm: realm}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function deleteOneActionItem(client, id) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(deleteActionItem),  
      variables: {id: id}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

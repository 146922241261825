import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import {useInterviewContext, CheckListAnswerIds} from './InterviewContext';
import { 
  TRUST_AND_WILL, 
  TRUST_ONLY, 
  WILL_ONLY, 
  NO_WILL_OR_TRUST 
} from './DocCheckList';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: `${theme.spacing(1)}px 0`,
  },
  headings: {
    fontWeight: '700',
  },
  title: {
    fontWeight: '700',
    marginTop: theme.spacing(2),
  },
  flaggedItem: {
    fontStyle: 'italic'
  }
}));

const Review = (props) => {
  const classes = useStyles();
  const interviewContext = useInterviewContext();

  const owners =   interviewContext.planOwners;
  const workers = interviewContext.planWorkers;
  const realm = interviewContext.realm.name;
  const answers = interviewContext.answers;

  const planOwnerName = owners[0].firstName;
  const planOwnerSpouseName = owners.length > 1 ? owners[1].firstName : null;
 
  const renderSocialSecurityAnswers = () => {
    const items = answers.map (answer => {
      if (answer.id === CheckListAnswerIds.ss1) {
        if (answer.value) {
          return (
            <Typography gutterBottom>{planOwnerName} is receiving Social Security.</Typography>
          )
        } else {
          return (
            <Typography className={classes.flaggedItem} gutterBottom>{planOwnerName} is NOT receiving Social Security.</Typography>
          )
        }
      }
    })
    return items;
  }

  const renderSocialSecuritySpouseAnswers = () => {
    const items = answers.map (answer => {
      if (answer.id === CheckListAnswerIds.ss2) {
        if (answer.value) {
          return (
            <Typography gutterBottom>{planOwnerSpouseName} is receiving Social Security.</Typography>
          )
        } else {
          return (
            <Typography className={classes.flaggedItem} gutterBottom>{planOwnerSpouseName} is NOT receiving Social Security.</Typography>
          )
        }
      }
    })
    return items;
  }

  const renderMedicareAnswers = () => {
    const items = answers.map (answer => {
      if (answer.id === CheckListAnswerIds.mc1) {
        if (answer.value) {
          return (
            <Typography gutterBottom>{planOwnerName} is signed up for Medicare.</Typography>
          )
        } else {
          return (
            <Typography className={classes.flaggedItem} gutterBottom>{planOwnerName} is NOT signed up for Medicare.</Typography>
          )
        }
      }
    })
    return items;
  }

  const renderMedicareSpouseAnswers = () => {
    const items = answers.map (answer => {
      if (answer.id === CheckListAnswerIds.mc2) {
        if (answer.value) {
          return (
            <Typography gutterBottom>{planOwnerSpouseName} is signed up for Medicare.</Typography>
          )
        } else {
          return (
            <Typography className={classes.flaggedItem} gutterBottom>{planOwnerSpouseName} is NOT signed up for Medicare.</Typography>
          )
        }
      }
    })
    return items;
  }

  const renderPowerOfAttorneyAnswers = () => {
    const items = answers.map (answer => {
      if (answer.id === CheckListAnswerIds.pa1) {
        if (answer.value) {
          return (
            <Typography gutterBottom>{planOwnerName} has prepared a Power of Attorney.</Typography>
          )
        } else {
          return (
            <Typography className={classes.flaggedItem} gutterBottom>{planOwnerName} has NOT prepared a Power of Attorney.</Typography>
          )
        }
      }
    })
    return items;
  }

  const renderPowerOfAttorneySpouseAnswers = () => {
    const items = answers.map (answer => {
      if (answer.id === CheckListAnswerIds.pa2) {
        if (answer.value) {
          return (
            <Typography gutterBottom>{planOwnerSpouseName} has prepared a Power of Attorney.</Typography>
          )
        } else {
          return (
            <Typography className={classes.flaggedItem} gutterBottom>{planOwnerSpouseName} has NOT prepared a Power of Attorney..</Typography>
          )
        }
      }
    })
    return items;
  }

  const renderHealthDirectiveAnswers = () => {
    const items = answers.map (answer => {
      if (answer.id === CheckListAnswerIds.hd1) {
        if (answer.value) {
          return (
            <Typography gutterBottom>{planOwnerName} has prepared an Advanced Health Directive.</Typography>
          )
        } else {
          return (
            <Typography className={classes.flaggedItem} gutterBottom>{planOwnerName} has NOT prepared an Advanced Health Directive.</Typography>
          )
        }
      }
    })
    return items;
  }

  const renderHealthDirectiveSpouseAnswers = () => {
    const items = answers.map (answer => {
      if (answer.id === CheckListAnswerIds.hd2) {
        if (answer.value) {
          return (
            <Typography gutterBottom>{planOwnerSpouseName} has prepared an Advanced Health Directive.</Typography>
          )
        } else {
          return (
            <Typography className={classes.flaggedItem} gutterBottom>{planOwnerSpouseName} has NOT prepared an Advanced Health Directive.</Typography>
          )
        }
      }
    })
    return items;
  }

  const renderTrustOrWill = () => {
    const items = answers.map (answer => {
      if (answer.id === CheckListAnswerIds.tw) {
        switch (answer.value) {
          case TRUST_AND_WILL:
            return (
              <Typography gutterBottom>You have both a trust and a will.</Typography>
            )
          case TRUST_ONLY:
            return (
              <Typography gutterBottom>You have a trust BUT no will.</Typography>
            )
          case WILL_ONLY:
            return (
              <Typography gutterBottom>You have a will BUT no trust. </Typography>
            )
          case NO_WILL_OR_TRUST:
            return (
              <Typography className={classes.flaggedItem} gutterBottom>You have neither a will nor a trust.</Typography>
            )  
          default:
            console.log('Unnown trust-will value');
        }
      }
    })
    return items;
  }

  // const renderEndOfLifeArrangements = () => {
  //   const items = answers.map (answer => {
  //     if (answer.id === CheckListAnswerIds.el1) {
  //       if (answer.value) {
  //         return (
  //           <Typography gutterBottom>{planOwnerName} has made end of life arrangements.</Typography>
  //         )
  //       } else {
  //         return (
  //           <Typography className={classes.flaggedItem} gutterBottom>{planOwnerName} has NOT made end of life arrangements.</Typography>
  //         )
  //       }
  //     }
  //     if (answer.id === CheckListAnswerIds.el2) {
  //       if (answer.value) {
  //         return (
  //           <Typography gutterBottom>{planOwnerSpouseName} has made end of life arrangements.</Typography>
  //         )
  //       } else {
  //         return (
  //           <Typography className={classes.flaggedItem} gutterBottom>{planOwnerSpouseName} has NOT made end of life arrangements.</Typography>
  //         )
  //       }
  //     }
  //   })
  //   return items;
  // }

  // const renderEndOfLifeArrangementsSpouse = () => {
  //   const items = answers.map (answer => {
  //     if (answer.id === CheckListAnswerIds.el2) {
  //       if (answer.value) {
  //         return (
  //           <Typography gutterBottom>{planOwnerSpouseName} has made end of life arrangements.</Typography>
  //         )
  //       } else {
  //         return (
  //           <Typography className={classes.flaggedItem} gutterBottom>{planOwnerSpouseName} has NOT made end of life arrangements.</Typography>
  //         )
  //       }
  //     }
  //   })
  //   return items;
  // }

  return (
    <>
      <Typography className={classes.headings} gutterBottom>
         Summary for: {realm}
      </Typography>
      <Divider />
      <Grid container spacing={8}>
        <Grid item xs={12} sm={6}>
          <List disablePadding>
            <Typography className={classes.headings}>
              Plan Owners
            </Typography>
            {owners.map((owner, index) => (
              <ListItem className={classes.listItem} key={index}>
                <ListItemText primary={owner.firstName + ' ' + owner.lastName} secondary={owner.email}/>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={6}>
          {workers.length > 0 ? (
            <List disablePadding>
              <Typography className={classes.headings}>
                Plan Helpers
              </Typography>
              {workers.map((worker, index) => (
                <ListItem className={classes.listItem} key={index}>
                  <ListItemText primary={worker.firstName + ' ' + worker.lastName} secondary={worker.email}/>
                </ListItem>
              ))}
            </List>
          ): (<Typography>There are no helpers set up</Typography>)}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={8}>
        <Grid item xs={12} sm={6}>
          <Typography  gutterBottom className={classes.title}>
            Will and/or Trust
          </Typography>
          {renderTrustOrWill()}         
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Typography  gutterBottom className={classes.title}>
          {planOwnerName}: End of Life Arrangements
          </Typography>
          {renderEndOfLifeArrangements()}
        </Grid>   
        {planOwnerSpouseName ? (
          <Grid item xs={12} sm={6}>
            <Typography  gutterBottom className={classes.title}>
            {planOwnerSpouseName}: End of Life Arrangements
            </Typography>
            {renderEndOfLifeArrangementsSpouse()}
          </Grid>
        ): null}      */}
        <Grid item xs={12} sm={6}>
          <Typography  gutterBottom className={classes.title}>
            {planOwnerName}: Important Documents and Benefits
          </Typography>
          {renderPowerOfAttorneyAnswers()}
          {renderHealthDirectiveAnswers()}
          {renderSocialSecurityAnswers()}
          {renderMedicareAnswers()}         
        </Grid>
        {planOwnerSpouseName ? (
          <Grid item xs={12} sm={6}>
            <Typography  gutterBottom className={classes.title}>
            {planOwnerSpouseName}: Important Documents and Benefits
            </Typography>
            {renderPowerOfAttorneySpouseAnswers()}
            {renderHealthDirectiveSpouseAnswers()}
            {renderSocialSecuritySpouseAnswers()}
            {renderMedicareSpouseAnswers()}
          </Grid>
        ): null}
      </Grid>
    </>
  )
}

export default Review;
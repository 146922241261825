import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemText from "@material-ui/core/ListItemText";

import Typography from "@material-ui/core/Typography";

import LTRSnackbar, {
  buildEditMessage,
  buildDeleteMessage,
} from "../utilties/LTRSnackbar";
import { useSnackbar } from "../utilties/snackbarUtil";
import ConfirmModal from "../shared/modals/ConfirmModal";

import { getIncompleteAccounts } from "../../graphql/queries";
import { deleteAccount } from "../../graphql/mutations";

import { FamilyContext } from "../../context/FamilyContext";

import { gql, useQuery, useMutation } from "@apollo/client";

import AddEditAccountForm from "../accounts/forms/AddEditAccountForm";

const GET_ACCOUNTS = gql(
  `query listAccounts ($userRealm: String) {
  ` +
    getIncompleteAccounts +
    `
}
`
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: "bold",
    marginRight: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const IncompleteAccountView = (props) => {
  const classes = useStyles();
  const familyContext = useContext(FamilyContext);

  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState({});
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [accountDelete] = useMutation(gql(deleteAccount), {
    refetchQueries: [
      {
        query: GET_ACCOUNTS,
        variables: { userRealm: familyContext.familyRealm.id },
      },
    ],
  });

  const { loading, error, data, refetch } = useQuery(GET_ACCOUNTS, {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  useEffect(() => {
    const onCompleted = (data) => {
      setItems(data.Account);
    };
    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  if (error) return `Error! ${error.message}`;

  const handleEdit = (item) => {
    setSelected(item);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditNotification = (item) => {
    setEditDialogOpen(false);

    // refetch our incomplete items
    refetch()
      .then((_) => {
        // notify the user and refetch the counts
        openSnackbar(buildEditMessage(item.name));
        props.refetchFunc();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (item) => {
    setSelected(item);
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteAccount = () => {
    handleDeleteClose();

    accountDelete({ variables: { id: selected.id } })
      .then((data) => {
        openSnackbar(buildDeleteMessage(selected.name));
        props.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // simply return if we have no data
  if (items.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography
        variant="subtitle1"
        gutterBottom
        className={classes.titleText}
      >
        {"Account items"}
      </Typography>

      <List>
        {items.map((account) => (
          <ListItem
            key={account.id}
            role={undefined}
            alignItems="flex-start"
            divider
            className={classes.listItem}
            onClick={() => setSelected(account)}
          >
            <ListItemText
              disableTypography
              primary={
                <React.Fragment>
                  <Typography
                    // component="div"
                    variant="body1"
                    color="textPrimary"
                  >
                    {account.name}
                  </Typography>
                </React.Fragment>
              }
              secondary={
                "To fix: edit the account and provide the 'institution name'"
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title={"Edit"}>
                <IconButton
                  aria-label={"Edit"}
                  onClick={() => {
                    handleEdit(account);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete"}>
                <IconButton
                  aria-label={"Delete"}
                  onClick={() => {
                    handleDelete(account);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <ConfirmModal
        title={"Confirm account deletion"}
        open={deleteDialogOpen}
        handleConfirm={handleDeleteAccount}
        handleClose={handleDeleteClose}
        body={"Are you sure you want to delete this account?"}
      />
      {editDialogOpen ? (
        <AddEditAccountForm
          selected={selected}
          open={editDialogOpen}
          handleClose={handleEditClose}
          handleAdd={() => {}}
          handleEdit={handleEditNotification}
        />
      ) : null}
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    </div>
  );
};

IncompleteAccountView.propTypes = {
  refetchFunc: PropTypes.func.isRequired,
};

export default IncompleteAccountView;



export const ActionItem = `
  id
  name
  type
  dueDate
  priority
  status
  owner
  domainId
  domainName
  group
  notes
`;

export const ContactPhone = `
  id
  phoneNumber
  type
  contactId
`;

export const ContactEmail = `
  id
  emailAddress
  type
  contactId
`;

export const ContactAddress = `
  id
  streetAddress1
  streetAddress2
  city
  state
  postalCode
  poBox
  country
  type
  contactId
`;

export const LinkedContact = `
  id
  name
`;

export const LinkedInsurance = `
  id
  name
  company
`;

// domain objects
export const Account = `
  id
  userRealm
  name
  type
  institution
  contactId
  accountNumber
  usage
  taxable
  billpayAccount
`;

export const Asset = `
  id
  userRealm
  name
  description
  type
  insurerId
  notes  
`;

export const Contact = `
  id
  userRealm
  name
  type
  company
  website
  phoneTreeLeader
  notes
`;

export const Insurance = `
  id
  userRealm
  name
  type
  company
  contactId
  policyNumber
  usage   
`;

export const Liability = `
  id
  userRealm
  name
  type
  institution
  accountNumber
  usage   
`;

export const OtherItem = `
  id
  userRealm
  name
  type
  description
  recommendation
  notes
`;

export const Service = `
  id
  userRealm
  name
  type
  contactId
  description
  notes
`;

export const Subscription = `
  id
  userRealm
  name
  type
  company
  notes
`;

export const User = `
  id
  authId
  loginName
  firstName
  lastName
  email
  emailVerified
`;

// this is an item in a table that connects users to realms
export const UsersRealms = `
  userId
  realmId
  isOwner
  realms {
    id
    name
  }
`

export const Realm = `
  id
  name
`;

export const Settings = `
  encryptedData
  encryptionHint
  encryptedBase
  userRealm
`

export const Biller = `
  id
  userRealm
  payee
  billType
  howPaid
  billingFrequency
  domainId
  accountId
  domainType
  description
`

export const Document = `
  id
  userRealm
  name
  type
  description
  digital
  location
  notes
`

export const Attachment = `
  id
  userRealm
  uri
  domainId
  name
  mimeType
  originalName
  description
`;

export const Note = `
  id
  userRealm
  domainId
  content
  name
  description
`


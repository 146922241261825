import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

import { listBillers } from "../../graphql/queries";
import { useQuery, gql } from "@apollo/client";
import { PaymentType }  from "../../Enums";

import SearchInput from "../utilties/SearchInput";

import { sortListByPayee } from "../utilties/SortFunctions";

import { useFamilyContext } from "../../context/FamilyContext";

import { renderDomainInfo } from "./BillerInfo";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  actionButtons: {
    margin: theme.spacing(1),
  },
}));

const Billers = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [billers, setBillers] = useState(null);
  const [selectedBiller, setSelectedBiller] = useState(null);
  const [showRelatedInfoDialog, setShowRelatedInfoDialog] = useState(null);

  const [visibleList, setVisibleList] = useState([]);

  const { loading, error, data } = useQuery(gql(listBillers), {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  useEffect(() => {
    const onCompleted = (data) => {
      setBillers(data.Biller);
    };
    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    // just one reason we're here: if billers change we need to adjust
    if (billers) {
      const sortedList = sortListByPayee(billers);
      setVisibleList(sortedList);

      // billers.map((biller) => {
      //   if (biller.BillerAccount.length) {
      //     console.log(biller.payee + " has a biller account named " + biller.BillerAccount[0].name)
      //   } else {
      //     console.log(biller.payee + " NO biller account")
      //   }
      // })
      //console.log(billers)
    }
  }, [billers]);

  const handleInfoRequestRelated = (biller) => {
    setSelectedBiller(biller);
    setShowRelatedInfoDialog(true);
  };

  const handleShowRelatedInfoDialogClose = () => {
    setSelectedBiller(null);
    setShowRelatedInfoDialog(false);
  };

  const handleSearch = (searchTerm) => {
    let displayList;

    if (searchTerm === null) {
      displayList = [...billers];
    } else {
      displayList = billers.filter((item) => {
        const testPayee = item.payee ? item.payee.toLowerCase() : "";
        const testDomainType = item.domainType
          ? item.domainType.toLowerCase()
          : "";
        const testDescription = item.description
          ? item.description.toLowerCase()
          : "";
        const againstSearchTerms = searchTerm ? searchTerm.toLowerCase() : "";

        // test the relevant fields
        return (
          testPayee.includes(againstSearchTerms) ||
          testDomainType.includes(againstSearchTerms) ||
          testDescription.includes(againstSearchTerms)
        );
      });
    }
    setVisibleList(displayList);
  };

  // just return if we have no billers or can't complete the query
  if (billers === null) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <SearchInput searchUpdate={handleSearch} />
        </Grid>
        <Grid item xs={12}>
          <List>
            {visibleList.map((biller) => (
              <ListItem key={biller.id}>
                <ListItemText
                  primary={biller.payee}
                  secondary={
                    <>
                    <Typography component="div">
                      {biller.description ? biller.description : biller.domainType}
                    </Typography>
                    
                    { biller.BillerAccount.length ? (
                      <Typography component="div">
                        {"Paid '" + biller.billingFrequency + "', from " +  biller.BillerAccount[0].name}
                      </Typography>
                      ): (
                      <Typography component="div">
                        {"Paid '" + biller.billingFrequency + "'"}
                      </Typography>
                      )
                    }
                    { biller.howPaid === PaymentType.RecurringBank || biller.howPaid === PaymentType.BillpayVendor ? (
                      <Typography component="div">
                        {"NOTE: this is an automated, recurring payment"}
                      </Typography>
                      ): null
                    }
                    </>
                  }
                />
                <ListItemSecondaryAction className={classes.actionButtons}>
                  <IconButton
                    edge="end"
                    aria-label="info"
                    onClick={() => handleInfoRequestRelated(biller)}
                  >
                    <InfoIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      {showRelatedInfoDialog && selectedBiller !== {}
        ? renderDomainInfo(
            selectedBiller.domainType,
            selectedBiller.domainId,
            handleShowRelatedInfoDialogClose
          )
        : null}
    </div>
  );
};

Billers.propTypes = {};

export default Billers;

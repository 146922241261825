import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  border: {
    borderStyle: "solid",
    borderColor: theme.palette.grey[500],
    borderRadius: "8px",
  },
  pdfBody: {
    display: 'block',
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(1),
    width: '100%'
  },
}));

const PDFViewer = (props) => {
  const classes = useStyles();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.border} fixed maxWidth="md">
        <div className={classes.pdfBody}>
          <Typography variant="body2" color="initial">
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </Typography>

          <Button
            size={"small"}
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previous
          </Button>
          <Button
            size={"small"}
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </Button>
        </div>
        <Document
          file={props.url}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </Container>
    </div>
  );
};

PDFViewer.propTypes = {
  attachment: PropTypes.object.isRequired,
};
export default PDFViewer;

import React from "react";

import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconButton: {},
}));

const CustomToolbar = (props) => {
  const classes = useStyles();

  const handleClick = () => {
    if (props.handleAddClick) {
      props.handleAddClick();
    } else {
      console.log("clicked on toobar icon!");
    }
  };

  // NOTE: we aren't adding a custom toolbar since there is no use for it at this time
  return (
    <React.Fragment>
      {props.includeAddAction ? (
        <Tooltip title={props.addTitle}>
          <IconButton className={classes.iconButton} onClick={handleClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </React.Fragment>
  );
};

CustomToolbar.defaultProps = {
  addTitle: "Create action item",
  includeAddAction: false,
};

CustomToolbar.propTypes = {
  addTitle: PropTypes.string,
  handleAddClick: PropTypes.func,
  includeAddAction: PropTypes.bool,
};

export default CustomToolbar;

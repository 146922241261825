import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LTRDomainLayout from './LTRDomainLayout';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



const LTRCommonLayout = (props) => {
  const classes = useStyles();

  const handleViewChange = (oldView, newView) => {
    if (props.handleViewChange) {
      props.handleViewChange(oldView, newView);
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs>
          <LTRDomainLayout 
            domain = {props.domain}
            domainSingular = {props.domainSingular}
            handleViewChange = {handleViewChange}
            commonNameList = {props.commonNameList}
            commonDataTable = {props.commonNameTable}
            toolbarActionHandler = {props.toolbarActionHandler}
            toolbarActionsNeeded = {props.toolbarActionsNeeded}
          />
         
        </Grid>
        {props.actionPanel ? (
          <Grid item xs={3}>
            {props.actionPanel}
           </Grid>
        ) : null}
        
      </Grid>
    </div>
  )
}

LTRCommonLayout.defaultProps = {
  toolbarActionHandler : null,
  actionPanel: null,
  toolbarActionsNeeded: []
}

LTRCommonLayout.propTypes = {
  domain: PropTypes.string.isRequired,
  domainSingular: PropTypes.string,
  handleViewChange: PropTypes.func,
  commonNameList: PropTypes.object.isRequired,
  commonNameTable: PropTypes.object.isRequired,
  actionPanel: PropTypes.object,
  toolbarActionHandler: PropTypes.func,
  toolbarActionsNeeded: PropTypes.array
}

export default (LTRCommonLayout)
export const LastPlanNotFound = "NOTFOUND";

const lastPlanKey = "ltr:lastPlan";

export default class RealmInfoStorage {
  static getLastPlan = () => {
    const plan = localStorage.getItem(lastPlanKey);

    // see if we have anything - if not return null to indicate that
    // nothing has been saved
    if (plan === null) {
      return LastPlanNotFound;
    }

    return JSON.parse(plan);
  };

  static setLastPlan = (plan) => {
    // clear all storage to start with, then store
    localStorage.removeItem(lastPlanKey);
    localStorage.setItem(lastPlanKey, JSON.stringify(plan));
  };

  static clearPlan = () => {
    localStorage.removeItem(lastPlanKey);
  };
}

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

import {useRecoilValue} from 'recoil';
import { 
  actionItemList
} from '../actionitems/ActionItemState';

import AssignGroupOwnerDialog from '../actionitems/AssignGroupOwnerDialog';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  centerItem: {
   textAlign: 'center'

  }
}));

const ActionItemGroups = (props) => {
  const classes = useStyles();

  const actionItems = useRecoilValue(actionItemList);

  const [assignOwnerGroupDialogOpen, setAssignOwnerGroupDialogOpen] = useState(false);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    if (actionItems.length !== 0) {  
      const items = actionItems.filter(item => {
        console.log(item.group)
        return item.group === props.groupTypeToFilter;
      });
      setFilteredList(items);
    }
  }, [actionItems]);


  const handleAssignOwnerGroupDialogOpen = () => {
    setAssignOwnerGroupDialogOpen(true);
  }
  
  const handleAssignOwnerGroupDialogClose = () => {
    setAssignOwnerGroupDialogOpen(false);
  }

  return (
    <div className={classes.root}>  
      { filteredList.length === 0 ? (
        <Typography align="center">
          No action items found
        </Typography>
      ): (
        <>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid 
            item
          >
            <List>
              {filteredList.map(item => (
                <ListItem
                  key={item.id}
                  role={undefined}
                  alignItems="flex-start"
                  divider
                  dense
                  className={classes.listItem}
                  // onClick={() => setSelected(item)}
                >
                  <ListItemText primary={item.name} secondary={item.type}/>
                  {/* <ListItemSecondaryAction>
                    <Button size="small" variant="outlined" color="primary" onClick={() => showCellInfo(rowIndex, dataIndex)}>
                      More
                    </Button>  
                  </ListItemSecondaryAction> */}
                  
                </ListItem>             
              ))}
            </List> 
          </Grid>
          <Grid 
            item
            className={classes.centerItem}
          >
          <Button className={classes.assignButton} size="small" variant="outlined" color="primary" onClick={() => handleAssignOwnerGroupDialogOpen()}>
                  Assign this group to one handler
          </Button>  
          </Grid>
        </Grid>  
        </>
      )}
      {assignOwnerGroupDialogOpen ? (
        <AssignGroupOwnerDialog 
          open = {assignOwnerGroupDialogOpen}
          actionItemGroup= {props.groupTypeToFilter}
          handleClose = {handleAssignOwnerGroupDialogClose}
        />
      ) : null}
    </div>
  );
}

ActionItemGroups.propTypes = {
  groupTypeToFilter: PropTypes.string.isRequired,  
}

export default ActionItemGroups;
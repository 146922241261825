import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <Grid 
      container 
      className={classes.root} 
      direction="column"
      justifyContent="center" 
      alignItems="center"
      spacing={2}>
      <Grid item xs={12}>
        <CircularProgress size={100} />
      </Grid>
    </Grid>
  );
};

export default Loading;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const PageNotFound = (props) => {

  const loc = props.location.pathname;
  const message = "Page ('" + loc + "') not found";
  return (
    <div>
      <h2>{message}</h2>
      <p>The requested page could not be found. <Link to="/">Click here to return to FamilyShare.</Link></p>
    </div>
  );
}

PageNotFound.propTypes = {
  location: PropTypes.object.isRequired
 };

export default PageNotFound;
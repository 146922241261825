import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container'
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  boxMargins: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}));

const PasswordRequirments = (props) => {
  const classes = useStyles();

  return ( 
    <Container className={classes.boxMargins} maxWidth="xs">
      <Typography variant="body1" gutterBottom>A password is considered valid if</Typography>
      <Typography variant="subtitle2">- it contains at least 8 characters.</Typography>
      <Typography variant="subtitle2">- it contains at least one 1 digit</Typography>
      <Typography variant="subtitle2">- it contains at least one upper-case alpha character</Typography>
      <Typography variant="subtitle2">- it contains at least one lower-case alpha charater</Typography>
      <Typography variant="subtitle2">- it contains at least one special character, i.e. !@#?$%*()-+~=^</Typography>
      <Typography variant="subtitle2">- it does NOT contain any white space (i.e. spaces, tabs)</Typography>
    </Container>
  )
}

export default PasswordRequirments;

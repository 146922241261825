import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { useInsuranceAdd } from '../../../api/APIHooks';

import Draggable from 'react-draggable';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import {InsuranceType, NonChoice, DomainType} from '../../../Enums';

import {useFamilyContext} from '../../../context/FamilyContext';

const useStyles = makeStyles(theme => ({ 
  flex: {
    flex: 1,
  },
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: 200,
  },

  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
}));

const PaperComponent = (props) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuickAddInsurance = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [name, setName] = useState('');

  const [addOneInsurance] = useInsuranceAdd(
    familyContext.familyRealm.id,
    (data) => {
      console.log(data);
      if (props.handleReceiveData) {
        props.handleReceiveData(data);
      }
    }
  );

  const save = (dismiss) => {
    // Mutation
    const details = {
      userRealm: familyContext.familyRealm.id,
      name: name,
      type: props.insuranceType,
      // set all the other fields to blank or default values
      company: '',
      policyNumber: '',
      usage: '',
    };

     // we only have the name so let's use it
     const detailsBiller = {
      payee: name,
      billType: NonChoice,
      howPaid: NonChoice,
      billingFrequency: NonChoice, 
      domainType: DomainType.Insurance,
    }

    // prepare the biller detail - just add it to the payload
    details.InsuranceBiller = {
      data: [{...detailsBiller}]
    }

    addOneInsurance({ variables: { object: details } })
    .then((result) => {
      handleSave(result.data.item);
      if (dismiss) {
        handleClose();
      } else {
        setName('');
        setFocus();
      } 
    })
    .catch((error) => {
      console.error(error);
    });
  }
  const handleSaveAndStay = () => {
    save(false);
  }

  const handleSaveAndGo = () => {
    save (true);
  }

  // need a ref to re-focus the form element
  const nameRef = useRef();
  const setFocus = () => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }

  const {open, handleClose, handleSave, insuranceType} = props;

  const title = "Quick Add Insurance: " + insuranceType;

  let helpText;
  switch (insuranceType) {
    case InsuranceType.HomeOwners: {
      helpText = "Provide a descriptive name for this home owner's insurance";
      break;
    }
    case InsuranceType.Auto: {
      helpText = "Provide a descriptive name for this auto, truck or motorcycle insurance";
      break;
    }
    case InsuranceType.Medical: {
      helpText = "Provide a descriptive name for this medical insurance";
      break;
    }
    case InsuranceType.Dental: {
      helpText = "Provide a descriptive name for this dental insurance')";
      break;
    }
    case InsuranceType.LongTerm: {
      helpText = "Provide a descriptive name for this long term care insurance')";
      break;
    }
    case InsuranceType.Life: {
      helpText = "Provide a descriptive name for this life insurance')";
      break;
    }
    default: {
      helpText = "Provide a descriptive name for this insurance (i.e. 'Home warranty' or 'Pet insurance')";
    }
  }
 
  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="form-dialog-title"
      >
      <DialogTitle style={{ cursor: 'move' }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <form>
          <FormControl
            className = {classes.formControl}  
          >
            <TextField
              inputRef = {nameRef}
              autoFocus
              name= "name"
              label = "Insurance or business name"
              value = {name}
              onChange = { (ev) => setName(ev.target.value)}
              helperText={helpText}
            />
          </FormControl>
          <FormControl
            className = {classes.formControl}
          >
            <TextField
              disabled
              name = "insuranceType"
              label = "Insurance type"
              value = {insuranceType}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveAndStay} disabled={name === ''} color="primary">
          Save and continue
        </Button>
        <Button onClick={handleSaveAndGo} disabled={name === ''} color="primary">
          Save and finish
        </Button>
      </DialogActions>
    </Dialog>   
  )
}

QuickAddInsurance.propTypes = {
  open: PropTypes.bool.isRequired,
  insuranceType: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired,
}

export default (QuickAddInsurance)
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import cloneDeep from "lodash.clonedeep";

import { Formik, Form, Field } from "formik";

// wrappers for material ui components for formik
import { Select, TextField } from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { FormDialogBase } from "../../shared/formhelpers/FormDialogBase";
import PaymentAndBilling from "../../shared/formhelpers/PaymentAndBilling";

import { validationSchema } from "./InsuranceFormMetaData";

import { InsuranceTypes, NonChoice, DomainType } from "../../../Enums";

import { useApolloClient, useQuery, gql } from "@apollo/client";
import { useInsuranceAdd, useInsuranceUpdate } from "../../../api/APIHooks";

import { OperationTargets } from "../../../api/CommonCrudOperations";

import { getInsurance } from "../../../graphql/queries";
import { addMultipleActionItems } from "../../../api/ActionItemApi";
import { updateOneBiller } from "../../../api/BillerApi";

import { useFamilyContext } from "../../../context/FamilyContext";

import ContactSelect from "../../shared/formhelpers/ContactSelect";

import { constructCorrespondingActionItems } from "../InsuranceHelper";

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const AddEditInsuranceForm = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [open, setOpen] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [insurance, setInsurance] = useState({
    name: "",
    type: NonChoice,
    company: "",
    contact: null,
    contactId: null,
    policyNumber: "",
    howPaid: NonChoice,
    billType: NonChoice,
    billingFrequency: NonChoice,
    usage: "",
    accountId: NonChoice,
    InsuranceActionItems: [],
    InsuranceAttachments: [],
  });

  const [skipQuery, setSkipQuery] = useState(true);

  let { loading, error, data } = useQuery(gql(getInsurance), {
    variables: { id: props.selected ? props.selected.id : "" },
    skip: skipQuery,
  });

  const [addOneInsurance] = useInsuranceAdd(familyContext.familyRealm.id,(data) => {
    console.log(data);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data);
      // }
    }
  );

  const [updateOneInsurance] = useInsuranceUpdate(familyContext.familyRealm.id, (data) => {
    console.log(data);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data);
      // }
    }
  );

  useEffect(() => {
    if (!skipQuery) {
      const onCompleted = (data) => {
        const insurance = data.item;
        const editInsurance = cloneDeep(insurance);

        // flatten the Insurance Biller fields
        if (insurance.billers && insurance.billers.length > 0) {
          const biller = insurance.billers[0];
          editInsurance.billingFrequency = biller.billingFrequency
            ? biller.billingFrequency
            : "";
          editInsurance.howPaid = biller.howPaid ? biller.howPaid : "";
          editInsurance.billType = biller.billType ? biller.billType : "";
          editInsurance.billerId = biller.id;
          editInsurance.accountId = biller.accountId
            ? biller.accountId
            : NonChoice;
        }
        setInsurance(editInsurance);
      };
      const onError = (error) => {
        return <div>{error}</div>;
      };

      if (onCompleted || onError) {
        if (onCompleted && !loading) {
          onCompleted(data);
          setSkipQuery(true);
        } else if (onError && !loading && error) {
          onError(error);
          setSkipQuery(true);
        }
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    const { selected } = props;

    // if we're editing and we haven't yet fetched the item
    if (selected && skipQuery) {
      setSkipQuery(false);
    }

    return () => {
      setSkipQuery(true);
    };
  }, [props.selected]);

  const save = (values) => {
    // alert(JSON.stringify(values, null, 2));
    // return;

    // Mutation - add one, edit one
    const detailsMain = {
      userRealm: familyContext.familyRealm.id,
      name: values.name,
      type: values.type ? values.type : "",
      company: values.company ? values.company : "",
      policyNumber: values.policyNumber ? values.policyNumber : "",
      usage: values.usage ? values.usage : "",
    };


    // if we have a contact, add it here
    if (values.contact) {
      detailsMain.contactId = values.contact.id
    }

    // use the company name, if we have one, as the payee
    // use the name itself as the description
    const detailsBiller = {
      payee: values.company ? values.company : values.name,
      howPaid: values.howPaid ? values.howPaid : "",
      billType: values.billType ? values.billType : "",
      billingFrequency: values.billingFrequency ? values.billingFrequency : "",
      domainType: DomainType.Insurance,
      description: values.name,
      accountId: values.accountId !== NonChoice ? values.accountId : null,
    };

    // props.open means we're here for editing - otherwise we're adding
    props.open
      ? updateOne(values, detailsMain, detailsBiller)
      : addOne(values, detailsMain, detailsBiller);
  };

  const addOne = (values, detailsMain, detailsBiller) => {
    // prepare the biller detail - just add it to the payload
    detailsMain.InsuranceBiller = {
      data: [{ ...detailsBiller }],
    };

    // add any action items
    const actionItems = constructCorrespondingActionItems(values, detailsMain);
    if (actionItems.length > 0) {
      detailsMain.InsuranceActionItems = {
        data: [...actionItems],
      };
    }

    addOneInsurance({ variables: { object: detailsMain } })
    .then((result) => {
      props.handleAdd(result.data.item);
    })
    .catch((error) => {
      console.error(error);
    });

    // hide the modal
    handleClose();
  };

  const updateOne = (values, detailsMain, detailsBiller) => {
    // add action items IF we don't have any already
    if (insurance.actionItems.length === 0) {
      const actionItems = constructCorrespondingActionItems(values, detailsMain);
      if (actionItems.length > 0) {
        const items = actionItems.map((item) => {
          item.userRealm = familyContext.familyRealm.id;
          item.domainId = insurance.id;
          return item;
        });

        addMultipleActionItems(
          client,
          familyContext.familyRealm.id,
          OperationTargets.Insurance,
          items
        )
          .then((result) => {
            console.log("Added %s action items", actionItems.length);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    // update the biller
    updateOneBiller(client, insurance.billerId, detailsBiller)
      .then((resultBiller) => {
        console.log(resultBiller);
      })
      .catch((error) => {
        console.error(error);
      });

    // finally, update the policy
    updateOneInsurance({
      variables: { id: insurance.id, changes: detailsMain },
    })
      .then((result) => {
        props.handleEdit(result.data.item);
      })
      .catch((error) => {
        console.error(error);
      });

    // hide the modal
    handleClose();
  };

  // Attach this to your <Formik>
  const formRef = useRef();

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleResetClick = () => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // normal close and cancel
  const handleClose = () => {
    // if we were opened by the client, let them close us, otherwise we close
    props.handleClose ? props.handleClose() : setOpen(false);
  };

  const body = (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={insurance}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        // actually save the form
        save(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="name"
              type="text"
              label="Name"
              autoFocus
              className={classes.textField}
              margin="normal"
              helperText={
                touched.name ? errors.name : "Provide a name for this policy"
              }
              error={touched.name && Boolean(errors.name)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="insurancetype-type">Insurance Type</InputLabel>
            <Field
              component={Select}
              name="type"
              inputProps={{
                id: "insurancetype-type",
              }}
            >
              {InsuranceTypes.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText>What type of insurance is this?</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="company"
              type="text"
              label="Company"
              className={classes.textField}
              margin="normal"
              helperText="Provide the name of the company that provides the insurance"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <ContactSelect
              existingContact={insurance.contactId === null ? undefined : insurance.contact} 
              onChange={(contact) => {
                setFieldValue('contact', contact ? contact: null);
              }}
            />  
            <FormHelperText>Identify a contact person for this institution</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="policyNumber"
              type="text"
              label="Policy"
              className={classes.textField}
              margin="normal"
              helperText="Provide the policy number for this insurance contract"
            />
          </FormControl>
          <PaymentAndBilling
            nameReceipt="billType"
            namePayment="howPaid"
            nameBilling="billingFrequency"
            nameBillingAccount="accountId"
          />
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="usage"
              label="Notes"
              multiline
              rows="4"
              helperText="Add any other useful information about this insurance (i.e 'This is our home warranty insurance')."
            />
          </FormControl>
        </Form>
      )}
    </Formik>
  );

  const title = props.open ? "Edit a policy" : "Add a policy";

  return (
    <FormDialogBase
      open={props.open ? props.open : open}
      title={title}
      body={body}
      submitEnabled={submitEnabled}
      handleAddClickOpen={handleClickOpen}
      handleClose={handleClose}
      handleReset={handleResetClick}
      handleSubmit={handleSubmitClick}
    />
  );
};

// client can open if they choose to OR we will open
AddEditInsuranceForm.defaultProps = {
  selected: null,
  open: false,
  handleClose: null,
};

AddEditInsuranceForm.propTypes = {
  selected: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAdd: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleReceivedData: PropTypes.func,
};

export default AddEditInsuranceForm;

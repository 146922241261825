import {useMutation, gql} from '@apollo/client';

import {
  listAccounts,
  listAssets,
  listContacts,
  listDocuments,
  listLiabilities,
  listInsurance,
  listOtherItems,
  listServices,
  listSubscriptions,
} from '../graphql/queries';

import {
  insertAccount, 
  insertAsset, 
  insertContact, 
  insertDocument,
  insertLiability, 
  insertInsurance, 
  insertOtherItem,
  insertService,
  insertSubscription, 
} from '../graphql/mutations';

import {
  updateAccount,
  updateAsset, 
  updateContact, 
  updateDocument, 
  updateLiability, 
  updateInsurance, 
  updateOtherItem,
  updateService,
  updateSubscription,
} from '../graphql/mutations';

import {
  deleteAccount, 
  deleteAsset, 
  deleteContact, 
  deleteDocument,
  deleteLiability, 
  deleteInsurance, 
  deleteOtherItem,
  deleteService,
  deleteSubscription,
} from '../graphql/mutations';
 
// common



// THIS HANDLER JUST READS FROM THE CACHE AND RETURNS WHAT THE CACHE HAS
// const TEST_1_commonAddUpdateHandler = (cache, result, userRealm, refetchQuery) => {
  
//   const resultData = result.data.item;
//   console.log('In common add update handler for: ' + resultData.__typename);
//   console.log('Here is the new item')
//   console.log(resultData);  

//   // update the cache
//   const data = cache.readQuery({
//     query: refetchQuery,
//     variables: { userRealm: userRealm},
//   });

//   console.log('Just read from the cache for existing data')
//   console.log(data)

//   return data;
// }

const commonAddUpdateHandler = (cache, result, userRealm, refetchQuery) => {
  
  const resultData = result.data.item;
  // console.log('In common add update handler for: ' + resultData.__typename);
  // console.log('Here is the new item')
  // console.log(resultData);  

  // update the cache
  const existingData = cache.readQuery({
    query: refetchQuery,
    variables: { userRealm: userRealm},
  });

  // console.log('Just read from the cache for existing data')
  // console.log(existingData)

  const newData = [...existingData.item, resultData]

  // console.log('Trying to write this')
  // console.log(newData)

  cache.writeQuery({
    query: refetchQuery,
    data: {item: newData},
    variables: { userRealm: userRealm},
  });

   // DEBUG ONLY
  // console.log('Return value from write: ')
  // console.log (ret)

  // console.log('Reading cache a second time. Here is the new data');
  // const mergedData = cache.readQuery({
  //   query: refetchQuery,
  //   variables: { userRealm: userRealm},
  // });

  //console.log(mergedData)

  return newData;
}


const commonUpdateUpdateHandler = (cache, result, userRealm, refetchQuery) => {
  const resultData = result.data.item;
  // console.log(resultData);  

  // update the cache
  const existingData = cache.readQuery({
    query: refetchQuery,
    variables: { userRealm: userRealm},
  });

  if (existingData) {
    // remove the edited item from the list, then add it back
    const list = existingData.item.filter(i => i.id !== resultData.id)
    list.push(resultData);
  
    const newList = [...list];

    cache.writeQuery({
      query: refetchQuery,
      data: {item: newList},
      variables: { userRealm: userRealm},
    });
  
     // DEBUG ONLY
    // console.log('Return value from write: ')
    // console.log (ret)
  
    // console.log('Reading cache a second time. Here is the new data');
    const mergedData = cache.readQuery({
      query: refetchQuery,
      variables: { userRealm: userRealm},
    });
  
    //console.log(mergedData)
  
    return mergedData;
  } else {
    // cache entries not returned for some reason (probably no items in the cache), just return a list of one, i.e. the updated object
    const  shortList = [];
    shortList.push(resultData);
    return shortList;
  }
}

// NOTE: this could probably (a) not WriteQuery (b) not evict. Neither seems to do anything
const commonDeleteUpdateHandler = (cache, result, userRealm, refetchQuery) => {
 
  // this has to be 'OtherItems' or 'Liability' or 'Asset'
  const cacheName = result.data.item.__typename;
  cache.modify( {
    fields: {
      [cacheName]: (existingData) => {
        const deletedItemId = cache.identify(result.data?.item);

        return existingData.filter(ref => {
          return cache.identify(ref) !== deletedItemId;
        });
      }
    }
    
  })    
  // now, read the updated cache and return the data
  const newData = cache.readQuery({
    query: refetchQuery,
    variables: { userRealm: userRealm},
  });  

  return newData;
}

// Accounts
export const useAccountAdd = (userRealm, cb) => {

  const insertOneQuery = gql(insertAccount);
  const listAllQuery = gql(listAccounts);

  const [addOneAccount] = useMutation(insertOneQuery, {
    update(cache, result) {
      const mergeData = commonAddUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [addOneAccount];
}

export const useAccountUpdate = (userRealm, cb) => {
  const updateOneQuery = gql(updateAccount);
  const listAllQuery = gql(listAccounts);

  const [updateOneAccount] = useMutation(updateOneQuery, {
    update(cache, result) {
      const mergeData = commonUpdateUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [updateOneAccount];
}

export const useAccountDelete = (userRealm, cb) => {

  const deleteOneQuery = gql(deleteAccount);
  const listAllQuery = gql(listAccounts);

  const [deleteOneAccount] = useMutation(deleteOneQuery, {
    update(cache, result) {
      const mergeData = commonDeleteUpdateHandler(cache, result, userRealm, listAllQuery);
      
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [deleteOneAccount];
}

// Assets
export const useAssetAdd = (userRealm, cb) => {

  const insertOneQuery = gql(insertAsset);
  const listAllQuery = gql(listAssets);

  const [addOneAsset] = useMutation(insertOneQuery, {
    update(cache, result) {
      const mergeData = commonAddUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [addOneAsset];
}

export const useAssetUpdate = (userRealm, cb) => {
  const updateOneQuery = gql(updateAsset);
  const listAllQuery = gql(listAssets);

  const [updateOneAsset] = useMutation(updateOneQuery, {
    update(cache, result) {
      const mergeData = commonUpdateUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [updateOneAsset];
}

export const useAssetDelete = (userRealm, cb) => {

  const deleteOneQuery = gql(deleteAsset);
  const listAllQuery = gql(listAssets);

  const [deleteOneAsset] = useMutation(deleteOneQuery, {
    update(cache, result) {
      const mergeData = commonDeleteUpdateHandler(cache, result, userRealm, listAllQuery);
      
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [deleteOneAsset];
}

// Contacts
export const useContactAdd = (userRealm, cb) => {

  const insertOneQuery = gql(insertContact);
  const listAllQuery = gql(listContacts);

  const [addOneContact] = useMutation(insertOneQuery, {
    update(cache, result) {
      const mergeData = commonAddUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [addOneContact];
}

export const useContactUpdate = (userRealm, cb) => {
  const updateOneQuery = gql(updateContact);
  const listAllQuery = gql(listContacts);

  const [updateOneContact] = useMutation(updateOneQuery, {
    update(cache, result) {
      const mergeData = commonUpdateUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [updateOneContact];
}

export const useContactDelete = (userRealm, cb) => {

  const deleteOneQuery = gql(deleteContact);
  const listAllQuery = gql(listContacts);

  const [deleteOneContact] = useMutation(deleteOneQuery, {
    update(cache, result) {
      const mergeData = commonDeleteUpdateHandler(cache, result, userRealm, listAllQuery);
      
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [deleteOneContact];
}

// Documents
export const useDocumentAdd = (userRealm, cb) => {

  const insertOneQuery = gql(insertDocument);
  const listAllQuery = gql(listDocuments);

  const [addOneDocument] = useMutation(insertOneQuery, {
    update(cache, result) {
      const mergeData = commonAddUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [addOneDocument];
}

export const useDocumentUpdate = (userRealm, cb) => {
  const updateOneQuery = gql(updateDocument);
  const listAllQuery = gql(listDocuments);

  const [updateOneDocument] = useMutation(updateOneQuery, {
    update(cache, result) {
      const mergeData = commonUpdateUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [updateOneDocument];
}

export const useDocumentDelete = (userRealm, cb) => {

  const deleteOneQuery = gql(deleteDocument);
  const listAllQuery = gql(listDocuments);

  const [deleteOneDocument] = useMutation(deleteOneQuery, {
    update(cache, result) {
      const mergeData = commonDeleteUpdateHandler(cache, result, userRealm, listAllQuery);
      
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [deleteOneDocument];
}

// Insurance
export const useInsuranceAdd = (userRealm, cb) => {

  const insertOneQuery = gql(insertInsurance);
  const listAllQuery = gql(listInsurance);

  const [addOneInsurance] = useMutation(insertOneQuery, {
    update(cache, result) {
      const mergeData = commonAddUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [addOneInsurance];
}

export const useInsuranceUpdate = (userRealm, cb) => {
  const updateOneQuery = gql(updateInsurance);
  const listAllQuery = gql(listInsurance);

  const [updateOneInsurance] = useMutation(updateOneQuery, {
    update(cache, result) {
      const mergeData = commonUpdateUpdateHandler(cache, result, userRealm, listAllQuery); 

      // callback with the data we just merged
      cb(mergeData);
    }
  });
  return [updateOneInsurance];
}

export const useInsuranceDelete = (userRealm, cb) => {

  const deleteOneQuery = gql(deleteInsurance);
  const listAllQuery = gql(listInsurance);

  const [deleteOneInsurance] = useMutation(deleteOneQuery, {
    update(cache, result) {
      const mergeData = commonDeleteUpdateHandler(cache, result, userRealm, listAllQuery);
      
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [deleteOneInsurance];
}

// Liabilities
export const useLiabilityAdd = (userRealm, cb) => {

  const insertOneQuery = gql(insertLiability);
  const listAllQuery = gql(listLiabilities);

  const [addOneLiability] = useMutation(insertOneQuery, {
    refetchQueries : [ {
      query: listAllQuery,
      variables: { userRealm: userRealm },
    }],
    awaitRefetchQueries: true,
    update(cache, result) {
      const mergeData = commonAddUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [addOneLiability];
}

export const useLiabilityUpdate = (userRealm, cb) => {
  const updateOneQuery = gql(updateLiability);
  const listAllQuery = gql(listLiabilities);

  const [updateOneLiability] = useMutation(updateOneQuery, {
    update(cache, result) {;
      const mergeData = commonUpdateUpdateHandler(cache, result, userRealm, listAllQuery); 

      // callback with the data we just merged
      cb(mergeData);
    }
  });
  return [updateOneLiability];
}


export const useLiabilityDelete = (userRealm, cb) => {

  const deleteOneQuery = gql(deleteLiability);
  const listAllQuery = gql(listLiabilities);

  const [deleteOneLiability] = useMutation(deleteOneQuery, {
    update(cache, result) {
      const mergeData = commonDeleteUpdateHandler(cache, result, userRealm, listAllQuery);
        
      // callback with the data we just merged
      cb(mergeData);
    },
  });
  return [deleteOneLiability];
}

// Other Items
export const useOtherItemsAdd = (userRealm, cb) => {
  const insertOneQuery = gql(insertOtherItem);
  const listAllQuery = gql(listOtherItems);

  const [addOneOtherItem] = useMutation(insertOneQuery, {
    update(cache, result) {
      const mergeData = commonAddUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [addOneOtherItem];
}

export const useOtherItemsUpdate = (userRealm, cb) => {
  const updateOneQuery = gql(updateOtherItem);
  const listAllQuery = gql(listOtherItems);

  const [updateOneOtherItems] = useMutation(updateOneQuery, {
    update(cache, result) {
      const mergeData = commonUpdateUpdateHandler(cache, result, userRealm, listAllQuery); 

      // callback with the data we just merged
      cb(mergeData);
    }
  });
  return [updateOneOtherItems];
}

export const useOtherItemsDelete = (userRealm, cb) => {
  const deleteOneQuery = gql(deleteOtherItem);
  const listAllQuery = gql(listOtherItems);

  const [deleteOneOtherItem] = useMutation(deleteOneQuery, {
    update(cache, result) {
      const mergeData = commonDeleteUpdateHandler(cache, result, userRealm, listAllQuery);
      
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  
  return [deleteOneOtherItem];
}

// Services
export const useServiceAdd = (userRealm, cb) => {

  const insertOneQuery = gql(insertService);
  const listAllQuery = gql(listServices);

  const [addOneService] = useMutation(insertOneQuery, {
    update(cache, result) {
      const mergeData = commonAddUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [addOneService];
}

export const useServiceUpdate = (userRealm, cb) => {
  const updateOneQuery = gql(updateService);
  const listAllQuery = gql(listServices);

  const [updateOneService] = useMutation(updateOneQuery, {
    update(cache, result) {
      const mergeData = commonUpdateUpdateHandler(cache, result, userRealm, listAllQuery); 

      // callback with the data we just merged
      cb(mergeData);
    }
  });
  return [updateOneService];
}

export const useServiceDelete = (userRealm, cb) => {

  const deleteOneQuery = gql(deleteService);
  const listAllQuery = gql(listServices);

  const [deleteOneService] = useMutation(deleteOneQuery, {
   
    update(cache, result) {
      const mergeData = commonDeleteUpdateHandler(cache, result, userRealm, listAllQuery);
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [deleteOneService];
} 

// Subscription
export const useSubscriptionAdd = (userRealm, cb) => {

  const insertOneQuery = gql(insertSubscription);
  const listAllQuery = gql(listSubscriptions);

  const [addOneSubscription] = useMutation(insertOneQuery, {
    update(cache, result) {
      const mergeData = commonAddUpdateHandler(cache, result, userRealm, listAllQuery); 
    
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [addOneSubscription];
}

export const useSubscriptionUpdate = (userRealm, cb) => {
  const updateOneQuery = gql(updateSubscription);
  const listAllQuery = gql(listSubscriptions);

  const [updateOneSubscription] = useMutation(updateOneQuery, {
    update(cache, result) {
      const mergeData = commonUpdateUpdateHandler(cache, result, userRealm, listAllQuery); 

      // callback with the data we just merged
      cb(mergeData);
    }
  });
  return [updateOneSubscription];
}

export const useSubscriptionDelete = (userRealm, cb) => {

  const deleteOneQuery = gql(deleteSubscription);
  const listAllQuery = gql(listSubscriptions);

  const [deleteOneSubscription] = useMutation(deleteOneQuery, {
    update(cache, result) {
      const mergeData = commonDeleteUpdateHandler(cache, result, userRealm, listAllQuery);
      
      // callback with the data we just merged
      cb(mergeData)
    }
  });
  return [deleteOneSubscription];
}


/* eslint-disable react/prop-types */

import React, { useState, useContext } from "react";

import cloneDeep from "lodash.clonedeep";

import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { gql } from '@apollo/client';
import { getSettings } from '../graphql/queries';

import {  currentUserState } from "../context/UserState";

import {  fetchOneUserByEmail } from "../api/UsersApi";

import { encryptionSettings } from '../pages/settings/SettingsState';
import LoginState from "../auth/LoginState";

export const UserContext = React.createContext();

export const useUserContext= () => useContext(UserContext);

export const USERFIELD_NOT_SET = "ufield-notset";

export const UserValidityStates = {
  Valid: 'Valid',
  ValidNoPlan: 'ValidNoPlan',
  Unknown: 'Unknown',
}

export const UserProvider = ({
  children,
  ...props
}) => {

  const [user, setUser] = useState(
    {
      id: USERFIELD_NOT_SET,
      authId: USERFIELD_NOT_SET,
      loginName: USERFIELD_NOT_SET,
      firstName: USERFIELD_NOT_SET,
      lastName: USERFIELD_NOT_SET,
      email: USERFIELD_NOT_SET,
      isOwner: USERFIELD_NOT_SET,
      userRealm: USERFIELD_NOT_SET
    }
  );

  const setSettings = useSetRecoilState(encryptionSettings);
  const setAppUser = useSetRecoilState(currentUserState);

  const resetAppUser = useResetRecoilState(currentUserState);

  const establishUserByAuthEmail = (email) => {
    // we don't have the ENTIRE user, fetch now
    fetchOneUserByEmail(props.client, email)
    .then((user) => {
      const numRealms = user.UsersRealms.length;
      let realmList = [];

      // simplify the realms
      user.UsersRealms.forEach((topLevelRealm) => {
        const realmEntry = {
          isOwner: topLevelRealm.isOwner,
          realmId: topLevelRealm.realmId,
          name: topLevelRealm.realms.name,
        };
        realmList.push(realmEntry);
      });

      let tempUser = cloneDeep(user);
      if (numRealms > 0) {
        tempUser.realms = [...realmList];
      } else {
        console.log("0 realms");
        // new user, no realm set
        tempUser.realms = [];
      }
      // TODO: see whether we really care about this email verification before doing anything
      tempUser.emailVerified = false;
      setAppUser(tempUser);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  const onLogin = (user) => {
    //console.log('OnLogin called')
    handleLogin(user);

    // console.log(user)
    // fetch the settings for this realm
    if (user) {
      props.client.query( {
        query: gql(getSettings), 
        fetchPolicy: "network-only",
        variables: {userRealm: user.userRealm}
      })
      .then(result => { 
        setSettings(result.data.Settings[0]);

        // we don't have the ENTIRE user, fetch now
        fetchOneUserByEmail(props.client, user.email)
        .then((user) => {
          const numRealms = user.UsersRealms.length;
          let realmList = [];

          // simplify the realms
          user.UsersRealms.forEach((topLevelRealm) => {
            const realmEntry = {
              isOwner: topLevelRealm.isOwner,
              realmId: topLevelRealm.realmId,
              name: topLevelRealm.realms.name,
            };
            realmList.push(realmEntry);
          });

          let tempUser = cloneDeep(user);
          if (numRealms > 0) {
            tempUser.realms = [...realmList];
          } else {
            console.log("0 realms");
            // new user, no realm set
            tempUser.realms = [];
          }
          // TODO: see whether we really care about this email verification before doing anything
          tempUser.emailVerified = false;
          setAppUser(tempUser);
        })
        .catch((error) => {
          console.log(error);
        })
      })
      .catch(error => {
        console.log(error) 
      });
    }
  }

  const handleLogin = user => {
    setUser(user);

    // need to persist this for long lived sessions
    LoginState.setUserData(user.id);
  }

  const onLogout = () => {
    handleLogout();
  }

  const handleLogout = () => {
    setUser(null);

    // clear the global user state
    resetAppUser();
  }

  const profile = () => {
    return {user};
  }

  const setOwner = (isOwner) => {
    user.isOwner = isOwner;
  }

  const setRealm = (realm) => {
    user.userRealm = realm;
  }

  const clearRealm = (realm) => {
    user.userRealm = USERFIELD_NOT_SET;
  }

  // temp! approve all
  const validUser = (email) => {
    return UserValidityStates.Valid;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        onLogin,
        onLogout,
        establishUserByAuthEmail,
        profile,
        validUser,
        setOwner,
        setRealm,
        clearRealm,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  bodyMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const ServicesSnip = (props) => {
  const classes = useStyles();

  return (
     <div className={classes.root}>
      <Typography variant="body2" gutterBottom color="initial">
        'Services' consist of people/business who do work for you.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        This could be a gardening or cleaning service. It could be a pest or roof cleaning service.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        This can also include memberships, such as membership in a gym or a wine club.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        Identify services that you hire for recurring work that you pay for. It is not necessary
        for you to identify one-off services unless you want the owner to be notified if something happens to you,
        (like a hair salon that you use periodically).
      </Typography>
     </div>
  );
}

export default ServicesSnip;
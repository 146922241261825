const moment = require('moment');

const ONE_WEEK = 60 * 60 * 24 * 7;

const tokenKey = "token";

const createDateTimeKey ="createDateTime";
const expirationDateTimeKey ="expirationDateTime";

const userIdKey = "ltr:uid";
const realmIdKey = "ltr:realm";

class LoginState {

  static isLoggedIn() {
    return LoginState.isTokenValid();
  }

  static isAuthenticated() {
    return LoginState.isTokenValid();
  }

  static isTokenValid() {
    const token = LoginState.getToken();
    if (typeof token === 'undefined') {
      return false;
    }
    if ( (token === null) || (token === "undefined")) {
      return false;
    }

    // appears that we have a stored token, see if it has expired
    if (LoginState.hasTokenExpired()) {
      // clear state
      LoginState.clearTokenData();
      return false;
    }

    // still valid
    return true;
  }

  static getToken() {
    return sessionStorage.getItem(tokenKey);
  }

  static getTokenData() {
    var data = {
      token: sessionStorage.getItem(tokenKey),
      userId: sessionStorage.getItem(userIdKey),
      createDateTime: sessionStorage.getItem(createDateTimeKey)
    };
    return data;
  }

  static setTokenData(token, userId, createDateTime) {
    sessionStorage.setItem(tokenKey, token);
    sessionStorage.setItem(userIdKey, userId);
    sessionStorage.setItem(createDateTimeKey, createDateTime.format());

    // calculate and store the date/time that our token will expire
    const expirationDate = moment(createDateTime).add(ONE_WEEK, 'seconds');
    sessionStorage.setItem(expirationDateTimeKey, expirationDate.format());;
  }

  static clearTokenData() {
    sessionStorage.clear();
  }

  static hasTokenExpired() {
    var retVal = false;

    // compare the current time to the expiration date/time
    // if it is a positive value, we still have time to live
    // zero (or negative) means we have run out of time
    const nowDate = moment();
 
    // if the token has expired return true
    const expirationDateTime = sessionStorage.getItem(expirationDateTimeKey);
    const expirationCompareMoment = moment(expirationDateTime);

    const diffValue = expirationCompareMoment.diff(nowDate, 'seconds');
    if (diffValue < 1) {
      console.log("Time has expired - the auth token is no longer valid");
      retVal = true;
    } else {
      const diffInHoursValue = expirationCompareMoment.diff(nowDate, 'hours');
      if (diffInHoursValue < 2) {
        console.log('Still alive but expiring in this many hours: ', diffInHoursValue);
      }
    }
    return retVal;
  }

  // we're defining soon as 1 hour left
  static isLoginExpiringSoon() {
    var retVal = false;

    // compare the current time to the expiration date/time
    // if it is a positive value, we still have time to live
    // if we have dropped below 2 hours, give a warning
    const nowDate = moment();
 
    // if the token has expired return true
    const expirationDateTime = sessionStorage.getItem(expirationDateTimeKey);
    const expirationCompareMoment = moment(expirationDateTime);

    const diffValue = expirationCompareMoment.diff(nowDate, 'hours');
    if (diffValue < 2) {
      console.log("Time is about to expire");
      retVal = true;
    } else {
      console.log('Still alive, expiring in this hours: ', diffValue);
    }
    return retVal;
  }

  // user data
  static setUserData(userId) {
    localStorage.setItem(userIdKey, userId);
  }

  static getUserData() {
    const userId = localStorage.getItem(userIdKey); 
    return userId;
  }

  static setRealmData(realmId) {
    localStorage.setItem(realmIdKey, realmId);
  }

  static getRealmData() {
    const realmId = localStorage.getItem(realmIdKey); 
    return realmId;
  }

  static clearUserData() {
    localStorage.removeItem(realmIdKey);
    localStorage.removeItem(userIdKey);
  }
}
export default LoginState;
import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import cloneDeep from "lodash.clonedeep";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";

import { useApolloClient, useMutation, gql } from "@apollo/client";

import { addOneUserWithRealm } from "../../../api/UsersApi";

import { addUserToRealm, insertSettings, insertRealm } from "../../../graphql/mutations";

import {
  USERFIELD_NOT_SET,
  UserContext
} from "../../../context/UserContext";

import { InterviewContext } from "./InterviewContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  gridItem: {
    topMargin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  planCreateMsg: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
  },
  planHeadline: {
    paddingBottom: theme.spacing(3),
  },
  editSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  bigBottomMargin: {
    marginBottom: theme.spacing(2),
  },
}));

const MyPlan = "MyPlan";
const NotMyPlan = "NotMyPlan";

const PlanCreate = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const interviewContext = useContext(InterviewContext);
  const userContext = useContext(UserContext);

  const [friendlyName, setFriendlyName] = useState("");
  const [planCreated, setPlanCreated] = useState(false);
  const [userIntent, setUserIntent] = useState(MyPlan);
  // if we have a proxy user we need to know WHO they are creating the plan for so the interview makes sense
  const [realOwnerFirstName, setRealOwnerFirstName] = useState("");
  const [realOwnerLastName, setRealOwnerLastName] = useState("");
  const [realOwnerEmail, setRealOwnerEmail] = useState("");
  const [emailInputError, setEmailInputError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState(
    "Enter a valid email address"
  );

  const [addOneRealm]  = useMutation(gql(insertRealm));
  const [addUserRealm] = useMutation(gql(addUserToRealm));
  const [addSettings] = useMutation(gql(insertSettings));

  const handleEmailValidation = (event) => {
    
    if (event.target.value.length > 1) {   
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)) {
        setEmailInputError(true);
      } else {
        setEmailInputError(false);
      }
    } else {
      setEmailInputError(false);
    }
    setRealOwnerEmail(event.target.value);
  };

  const createPlan = () => {
    // lots going on here
    // 1) save the plan
    // 2) update the interview context
    // 3) create a settings row for this realm
    // 4) update the logged in user with the realm

    // 1) save the plan,
    const details = {
      name: friendlyName,
    };
    addOneRealm({ variables: {object: details} } )
      .then((result) => {
        const realm = result.data.insert_Realms_one;
        // 2) add it to the interview context
        interviewContext.addRealm(realm);
        setPlanCreated(true);

        // 3) create settings for this realm
        const settings = {
          userRealm: realm.id,
        };
        addSettings( {variables: { object: settings } })
          .then((_) => {
            // 4) now that we have a realm AND setting, update this user to the plan owner with a realm
            const { user } = userContext;
            if (user.userRealm === USERFIELD_NOT_SET) {
              const realmDetails = {
                userId: user.id,
                realmId: realm.id,
                isOwner: true,
              };
              addUserRealm({ variables: { object: realmDetails } })
                .then((result) => {
                  // need to update the user context for the logged in user
                  userContext.setOwner(true);
                  userContext.setRealm(realm);

                  // and the interview context - add these same fields just to ensure they are set
                  let tempUser = cloneDeep(user);
                  tempUser.isOwner = true;
                  tempUser.realm = realm;

                  const proxyOwner = userIntent.indexOf(NotMyPlan) === 0;
                  if (proxyOwner) {
                    // for the interview we have to add both the proxy user and the real plan user
                    // start with the proxy owner
                    interviewContext.addProxyOwner(tempUser);

                    // now, add the proxy owner
                    const saveLoginName =
                      realOwnerFirstName + "-" + realOwnerLastName;
                    const details = {
                      loginName: saveLoginName.toLowerCase(),
                      firstName: realOwnerFirstName,
                      lastName: realOwnerLastName,
                      email: realOwnerEmail,
                    };
                    addOneUserWithRealm(client, realm, true, details)
                      .then((result) => {
                        const user = result;
                        let tempUser = cloneDeep(user);
                        tempUser.isOwner = true;
                        tempUser.realm = realm;
                        // need to add this user (since it is not the logged in user) and add the realm relationship
                        interviewContext.addOwner(tempUser);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    // much simpler case - the logged in user IS the owner
                    interviewContext.addOwner(tempUser);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            props.handleClose();
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeUserIntent = (event) => {
    setUserIntent(event.target.value);
  };

  const planMsg = "Your plan has been created!";
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid 
          container
          className={classes.gridItem}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <Typography variant="h5" gutterBottom>
              New Plan
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.planHeadline} variant="body1" gutterBottom>
              Let's get started by creating a new plan. A name is all that is needed
              to create a plan.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.gridItem}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          {planCreated ? (
            // <Grid item xs={12} sm={6}>
            <Typography
              className={classes.planCreateMsg}
              align="center"
              variant="h6"
              gutterBottom
            >
              {planMsg}
            </Typography>
          ) : (
            //</Grid>
            <>
              <Grid item xs={12} sm={6}>
                <form noValidate autoComplete="off">
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="name"
                      name="name"
                      value={friendlyName}
                      onChange={(ev) => setFriendlyName(ev.target.value)}
                      label="Plan name"
                      helperText="Enter a name, i.e 'Smith Plan', 'Plan for Mom'"
                      fullWidth
                    />
                  </FormControl>
                  <Divider className={classes.bigBottomMargin} />
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel component="legend">Whose plan is It?</FormLabel>
                    <RadioGroup
                      aria-label="planintent"
                      name="intent"
                      value={userIntent}
                      onChange={handleChangeUserIntent}
                    >
                      <FormControlLabel
                        value={MyPlan}
                        control={<Radio />}
                        label="This plan is for me"
                      />
                      <FormControlLabel
                        value={NotMyPlan}
                        control={<Radio />}
                        label="This plan is for someone else (I'm creating it on their behalf)"
                      />
                    </RadioGroup>
                  </FormControl>
                  {userIntent.indexOf(NotMyPlan) === 0 ? (
                    <>
                      <FormControl>
                        <TextField
                          name="firstName"
                          type="text"
                          label="First name"
                          onChange={(ev) =>
                            setRealOwnerFirstName(ev.target.value)
                          }
                          helperText="Enter the first name of the plan owner"
                          className={classes.textField}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          name="lastName"
                          type="text"
                          label="Last name"
                          onChange={(ev) => setRealOwnerLastName(ev.target.value)}
                          helperText="Enter the last name of the plan owner"
                          className={classes.textField}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          error={emailInputError}
                          name="email"
                          type="email"
                          label="Email"
                          onChange={handleEmailValidation}
                          helperText={
                            emailInputError
                              ? emailHelperText
                              : "Enter the email of the plan owner (note: this is optional and can be added later)"
                          }
                          className={classes.textField}
                        />
                      </FormControl>
                    </>
                  ) : null}
                </form>

                {userIntent.indexOf(MyPlan) === 0 ||
                (userIntent.indexOf(NotMyPlan) === 0 &&
                  realOwnerFirstName &&
                  realOwnerLastName) ? (
                  <Button
                    variant="contained"
                    className={classes.button}
                    color="secondary"
                    onClick={createPlan}
                    disabled={friendlyName === ""}
                  >
                    Create Plan
                  </Button>
                ) : null}
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default PlanCreate;

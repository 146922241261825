import {gql} from '@apollo/client';

import {
  getUser,
  getUserByAuthId,
  getUserByUserName,
  getUserByEmail,
  getUserByAuthIdOrEmail,
  getRealmsForUser,
  listUsers
} from '../graphql/queries';

import {
  insertRealm,
  insertUser,
  updateUser,
  addUserToRealm
} from '../graphql/mutations';

export async function addOneRealm(client, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(insertRealm), 
      variables: {object: payload}
    })
    .then(result => { 
      resolve(result.data.insert_Realms_one);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function fetchOneRealm(client, realmId) { 
  let promise = new Promise((resolve, reject) => {

    client.query( {
      query: gql(listUsers), 
      variables: {userRealm: realmId}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function fetchRealmsForUser(client, userId) { 
  let promise = new Promise((resolve, reject) => {

    client.query( {
      query: gql(getRealmsForUser), 
      variables: {userId: userId}
    })
    .then(result => { 
      resolve(result);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function fetchOneUser(client, id) { 
  let promise = new Promise((resolve, reject) => {

    client.query( {
      query: gql(getUser), 
      variables: {id: id}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function fetchOneUserByAuthId(client, authId) { 
  let promise = new Promise((resolve, reject) => {

    client.query( {
      query: gql(getUserByAuthId), 
      variables: {authId: authId}
    })
    .then(result => { 
      if (result.data.Users.length === 0) {
        const error = {
          message: "Unknown user."
        }
        reject (error)
      } else {
        resolve(result.data.Users[0]);
      }
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function fetchOneUserByUserName(client, username) { 
  let promise = new Promise((resolve, reject) => {

    client.query( {
      query: gql(getUserByUserName), 
      fetchPolicy: 'network-only',
      variables: {loginName: username}
    })
    .then(result => { 
      if (result.data.Users.length === 0) {
        const error = {
          message: "Unknown user."
        }
        reject (error)
      } else {
        resolve(result.data.Users[0]);
      }
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function fetchOneUserByEmail(client, emailAddress) { 
  let promise = new Promise((resolve, reject) => {

    client.query( {
      query: gql(getUserByEmail), 
      fetchPolicy: 'network-only',
      variables: {email: emailAddress}
    })
    .then(result => { 
      if (result.data.Users.length === 0) {
        const error = {
          message: "Unknown user."
        }
        reject (error)
      } else {
        resolve(result.data.Users[0]);
      }
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function fetchOneUserByAuthIdOrEmail(client, authId, emailAddress) { 
  let promise = new Promise((resolve, reject) => {

    client.query( {
      query: gql(getUserByAuthIdOrEmail), 
      fetchPolicy: 'network-only',
      variables: {authId: authId, email: emailAddress}
    })
    .then(result => { 
      if (result.data.Users.length === 0) {
        const error = {
          message: "Unknown user."
        }
        reject (error)
      } else {
        resolve(result.data.Users[0]);
      }
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function addOneUser(client, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(insertUser), 
      variables: {object: payload}
    })
    .then(result => { 
      if (result.data === []) {
        reject("Unable to add user")
      } else {
        resolve(result.data.insert_Users_one);
      } 
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function updateOneUser(client, id, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(updateUser),
      variables: {id: id, changes: payload}
    })
    .then(result => { 
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

/*
*/
export async function addOneUserWithRealm(client, realmId, isOwner, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(insertUser), 
      variables: {object: payload}
    })
    .then(result => { 
      const user = result.data.insert_Users_one;
      const realmDetails = {
        userId: user.id,
        realmId: realmId,
        isOwner: isOwner,
      };
      client.mutate( {
        mutation: gql(addUserToRealm),
        variables: { object: realmDetails }
      })
      .then((_) => {
        resolve(user);
      })
      .catch(error => {
        console.log(error) 
        reject(error);
      });
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};


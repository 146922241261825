import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { useContactAdd } from '../../../api/APIHooks';

import Draggable from 'react-draggable';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import {ContactType, NonChoice} from '../../../Enums';

import {useFamilyContext} from '../../../context/FamilyContext';

const useStyles = makeStyles(theme => ({ 
  flex: {
    flex: 1,
  },
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: 200,
  },

  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
}));

const PaperComponent = (props) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuickAddContact = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [name, setName] = useState('');

  const [addOneContact] = useContactAdd(familyContext.familyRealm.id, (data) => {
    console.log(data)
    if (props.handleReceiveData) {
      props.handleReceiveData(data)
    }    
  });

  const save = (dismiss) => {
    // Mutation
    const details = {
      userRealm: familyContext.familyRealm.id,
      name: name,
      type: props.contactType,
      // set all the other fields to blank values
      phoneTreeLeader: NonChoice,
      website: '',
      company: '',
      notes: '',
    };

    addOneContact({ variables: { object: details } })
    .then((result) => {
      handleSave(result.data.item);
      if (dismiss) {
        handleClose();
      } else {
        setName('');
        setFocus();
      } 
    })
    .catch((error) => {
      console.error(error);
    });
  }

  const handleSaveAndStay = () => {
    save(false);
  }

  const handleSaveAndGo = () => {
    save (true);
  }

  // need a ref to re-focus the form element
  const nameRef = useRef();
  const setFocus = () => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }

  const {open, handleClose, handleSave, contactType} = props;

  const title = "Quick Add Contact: " + contactType;

  let helpText;
  switch (contactType) {
    case ContactType.Medical: {
      helpText = "Provide a descriptive name for this doctor, dentist or medical facility";
      break;
    }
    case ContactType.Friend: {
      helpText = "Provide a descriptive name for this friend";
      break;
    }
    case ContactType.TrustedAdvisor: {
      helpText = "Provide a descriptive name for this legal or financial advisor";
      break;
    }
    case ContactType.Family: {
      helpText = "Provide a descriptive name for this family member";
      break;
    }
    case ContactType.Neighbor: {
      helpText = "Provide a descriptive name for this neighbor (i.e. 'Karen from across the street')";
      break;
    }
    default: {
      helpText = "Provide a descriptive name for this contact (i.e. 'JF Gardening' or 'Smith Pest Control')";
    }
  }
 
  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="form-dialog-title"
      >
      <DialogTitle style={{ cursor: 'move' }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <form>
          <FormControl
            className = {classes.formControl}  
          >
            <TextField
              inputRef = {nameRef}
              autoFocus
              name= "name"
              label = "Contact or business name"
              value = {name}
              onChange = { (ev) => setName(ev.target.value)}
              helperText={helpText}
            />
          </FormControl>
          <FormControl
            className = {classes.formControl}
          >
            <TextField
              disabled
              name = "contactType"
              label = "Contact type"
              value = {contactType}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveAndStay} disabled={name === ''} color="primary">
          Save and continue
        </Button>
        <Button onClick={handleSaveAndGo} disabled={name === ''} color="primary">
          Save and finish
        </Button>
      </DialogActions>
    </Dialog>   
  )
}

QuickAddContact.propTypes = {
  open: PropTypes.bool.isRequired,
  contactType: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired
}

export default (QuickAddContact)
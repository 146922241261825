import React from 'react';

// domain type
import {DomainType} from '../../Enums';

// helpers, for showing info
import AccountInfo from '../accounts/AccountInfo';
import AssetInfo from '../assets/AssetInfo';
import ContactInfo from '../contacts/ContactInfo';
import InsuranceInfo from '../insurance/InsuranceInfo';
import LiabilityInfo from '../liabilities/LiabilityInfo';
import OtherItemsInfo from '../otheritems/OtherItemsInfo';
import ServiceInfo from '../services/ServiceInfo';
import SubscriptionInfo from '../subscriptions/SubscriptionInfo';

// Our labels in the Info Table
const labels = {
  payee: "PAYEE",
  description: "DESCRIPTION",
  billType: "TYPE OF BILL",
  billingFrequency: "BILL FREQUENCY",
  howPaid: "HOW PAID"
}

export const prepareTableRowInfo = (biller) => {
  // prepare table data for show
  
  const tableInfo = {
    name: biller.payee,
    nameLabel: labels.payee,
    fieldData : [
      {
        field: labels.description,
        value: biller.description
      },
      {
        field: labels.billType,
        value: biller.billType
      },
      {
        field: labels.howPaid,
        value: biller.howPaid
      },
      {
        field: labels.billingFrequency,
        value: biller.billingFrequency
      },  
    ] 
  }
  return tableInfo;  
}

export const renderDomainInfo = (domainType, domainId, handleCloseShowInfo) => {
  
  switch (domainType) {
    case DomainType.Accounts: 
      return (
        <AccountInfo
          open = {true}
          domainId = {domainId}
          handleClose = {handleCloseShowInfo}
        />
        )
    case DomainType.Assets:
      return (
        <AssetInfo
          open = {true}
          domainId = {domainId}
          handleClose = {handleCloseShowInfo}
        />
        )
    case DomainType.Contacts:
      return (
        <ContactInfo
          open = {true}
          domainId = {domainId}
          handleClose = {handleCloseShowInfo}
        />
      )
    case DomainType.Insurance:
      return (
        <InsuranceInfo
          open = {true}
          domainId = {domainId}
          handleClose = {handleCloseShowInfo}
        />
      )
    case DomainType.Liabilities:
      return (
        <LiabilityInfo
          open = {true}
          domainId = {domainId}
          handleClose = {handleCloseShowInfo}
        />
      )
    case DomainType.OtherItems:
      return (
        <OtherItemsInfo
          open = {true}
          domainId = {domainId}
          handleClose = {handleCloseShowInfo}
        />
      )
    case DomainType.Services:
      return (
        <ServiceInfo
          open = {true}
          domainId = {domainId}
          handleClose = {handleCloseShowInfo}
        />
      )
    case DomainType.Subscriptions:
      return (
        <SubscriptionInfo
          open = {true}
          domainId = {domainId}
          handleClose = {handleCloseShowInfo}
        />
      )
    default:
      console.log ('Domain show info not supported') 
      break;
  }
}


import { ActionItemType, PriorityType, PaymentType } from '../../Enums';
import { NonChoice } from '../../Enums';

// Logic here about action item creation
// If you create a subscription, there will be two actions
// items created. One for cancelling the subscription, the other for cancelling payment
//
// Exceptions: if you have a recurring bank payment OR payment through a vendor
// then a special message will be created and only ONE action item will be created.

const prepareDetailsForCancelledPaymentsItems = (subscription) => {
  let priority = PriorityType.Medium;

  // Special case: we are only going to create one action item, 
  // combining Cancel Service and Cancel Payment - here we 
  // will create an action item for RecurringBank with a special message
  // and different priority
  if (subscription.howPaid === PaymentType.BillpayVendor) {
    return null;
  }

  let name = "Cancel upcoming payments to the " + subscription.type.toLowerCase() + " service: '" + subscription.name + "'.";
  if (subscription.howPaid === PaymentType.RecurringBank) {
    priority = PriorityType.High;
    name = "Log into the bank website where the payments are set up. Cancel future payments to '" + subscription.name + "'.";
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: subscription.id,
    domainName: subscription.name,
    type: ActionItemType.Subscriptions,
    notes: ""
  };
  return actionItemDetails;
}

const prepareDetailsForCancelledServiceItems = (subscription) => {
  let priority = PriorityType.Medium;
  
  // Special case: we are only going to create one action item, 
  // combining Cancel Service and Cancel Payment - here we 
  // will create an action item for BillpayVendorwith a special message
  // and different priority
  if (subscription.howPaid === PaymentType.RecurringBank) {
    return null;
  }

  let name = "Cancel the " + subscription.type.toLowerCase() + " subscription for '" + subscription.name + "'"; 
  
  if (subscription.howPaid === PaymentType.BillpayVendor) {
    priority = PriorityType.High;
      name = "Contact the company that provides the '" + subscription.name + "' subscription and cancel it, making sure future payments are cancelled.";
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: subscription.id,
    domainName: subscription.name,
    type: ActionItemType.Subscriptions,
    notes: ""
  };
  return actionItemDetails;
}

export const constructCorrespondingActionItems = (item) => {
  const actionItems = [];

  const paymentItemDetails = prepareDetailsForCancelledPaymentsItems(item);
  const serviceItemDetails = prepareDetailsForCancelledServiceItems(item);

  if (paymentItemDetails) {
    actionItems.push(paymentItemDetails);
  }
  if (serviceItemDetails) {
    actionItems.push(serviceItemDetails);
  }

  return actionItems;
}
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { useFormik } from "formik";

import axios from "axios";

import { getIdToken } from "firebase/auth";
import { firebaseAuth } from "../../../index";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Slide from "@material-ui/core/Slide";

import LTRSnackbar from "../../utilties/LTRSnackbar";
import { useSnackbar } from "../../utilties/snackbarUtil";

import { emailValidationSchema } from "../../shared/validators/Validators";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: "RED",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeEmailDialog = (props) => {
  const classes = useStyles();
  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  const [error, setError] = useState(false);
 
  //const {getAccessTokenSilently} = useAuth0();
  
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailValidationSchema,
    onSubmit: (values) => {
      save(values);
    },
  });

  // formdata handler to retrieve data
  const save = async (values) => {
    var url = "/api/users/updateEmail";
    const user = firebaseAuth.currentUser;

    try {
      const accessToken = await getIdToken(user);

      axios({
        method: "PATCH",
        url: url,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          email: values.email,
        },
      })
      .then((response) => {
        // nothing to do EXCEPT CONFIRM TO USER
        console.log(response.data);
        openSnackbar("Successfully changed your email");
        
        // reset the form
        formik.resetForm();

        // return the new email to the caller
        props.handleSave(response.data.results.data);
      })
      .catch((error) => {
        console.log(error.toJSON());
        setError(error);
      });
    } catch (e) {
      setError(e.error)
      console.log('Error in try block')
      console.error(e.error);
    }
  };

  const handleCancel = () => {
    // reset the form
    formik.resetForm();
    
    // return control to the caller
    props.handleClose()
  }

  const title = "Change email";
  const labelText = props.authUser ? "Existing email: '" + props.authUser.email + "'" : "";

  return (
    <div className={classes.root}>
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={props.open}
        onClose={handleCancel}
        aria-labelledby="update-user-data-dialog-title"
      >
      <DialogTitle>{title}</DialogTitle> 
        <DialogContent>
          <DialogContentText>{labelText}</DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                name="email"
                label="New email"
                value={formik.values.email}
                onChange={formik.handleChange}
                autoFocus
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>

            {error && (
              <Typography align="center" className={classes.error}>
                {error.message}
              </Typography>
            )}
          
            <DialogActions>
              <Button
                fullwidth
                variant="contained"
                className={classes.submit}
                onClick={() => {
                  handleCancel()}
                }
                >
                Cancel
              </Button>
              <Button
                fullWidth
                variant="contained"
                type = {"submit"}
                color="primary"
                className={classes.submit}
                disabled={!formik.dirty && formik.isValid}
              >
                Change
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    </div>
  );
};

ChangeEmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  authUser: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};
export default ChangeEmailDialog;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import LTRCommonActionPanel from '../../shared/domains/LTRCommonActionPanel'

import QuickAddOtherItem from './QuickAddOtherItem';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import {OtherItemsType} from '../../../Enums';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  }
}));

const OtherItemsActionPanel = (props) => {
  const classes = useStyles();

  const [otherItemsType, setOtherItemsType] = useState(OtherItemsType.Other)
  const [quickAddOpen, setQuickAddOpen] = useState(false);

  const handleAddOtherItem= (type) => {
    setOtherItemsType(type)
    setQuickAddOpen(true);
  }

  const handleCloseQuickAddOpen = () => {
    setQuickAddOpen(false);
  }

  const handleSave  = (savedItem) => {
    props.handleAddNotification(savedItem);
  }

  const handleReceiveData = (data) => {
    if (props.handleReceiveData) {
      props.handleReceiveData(data);
    }
  }

  return (
    <div className={classes.root}>
      <LTRCommonActionPanel
        actionComponents = {
          <ButtonGroup
            orientation="vertical"
            size = "small"
            color="primary"
            variant="text"
            aria-label="vertical outlined primary button group"
            >
            <Button className={classes.button} onClick={() => handleAddOtherItem(OtherItemsType.Furniture)} color="primary">
              Add furniture
            </Button>
            <Button className={classes.button} onClick={() => handleAddOtherItem(OtherItemsType.Artwork)}   color="primary">
              Add artwork
            </Button>
            <Button className={classes.button} onClick={() => handleAddOtherItem(OtherItemsType.Collection)}   color="primary">
              Add a collection
            </Button>
          </ButtonGroup>
        }
        />
        {quickAddOpen ? (
           <QuickAddOtherItem 
              open = {quickAddOpen}
              otherItemsType = {otherItemsType}
              handleClose = {handleCloseQuickAddOpen}
              handleSave = {handleSave}
              handleReceiveData = {handleReceiveData}
           />) 
          : null
        }    
    </div>
  )
}

OtherItemsActionPanel.propTypes = {
  handleAddNotification: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired,
}

export default (OtherItemsActionPanel)

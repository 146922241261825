import React from "react";

import Grid from "@material-ui/core/Grid";

import { useRecoilValue } from "recoil";
import { actionItemList } from "./ActionItemState";

import ActionItemTable from "./ActionItemTable";
import { ActionItemType } from "../../Enums";

import ActionItemStatus from "../overview/ActionItemStatus";

const GlobalActionItemView = (props) => {
  const actionItems = useRecoilValue(actionItemList);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={10}>
        <ActionItemTable itemType={ActionItemType.All} allowAddNew={true} />
      </Grid>
      <Grid item xs={2}>
        {actionItems && actionItems.length ? <ActionItemStatus /> : null}
      </Grid>
    </Grid>
  );
};

export default GlobalActionItemView;

import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, FieldArray } from 'formik';

// wrappers for material ui components for formik
import { TextField, Select } from 'formik-material-ui';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import {ContactType, ContactTypeList, PhoneTreeLeaderTypeList, NonChoice} from '../../../Enums';

import {FormDialogBase} from '../../shared/formhelpers/FormDialogBase';

import {validationSchema} from './ContactFormMetaData';

import {handleContactEmailChanges, handleContactPhoneChanges} from '../ContactDetails';

import {
  OperationTargets, 
} from '../../../api/CommonCrudOperations';

import {addMultipleActionItems} from '../../../api/ActionItemApi';

import {getContact} from '../../../graphql/queries';

import {useFamilyContext} from '../../../context/FamilyContext';
import { useApolloClient, useQuery, gql} from '@apollo/client';
import { useContactAdd, useContactUpdate } from '../../../api/APIHooks';

import {constructCorrespondingActionItems} from '../ContactHelper';

const useStyles = makeStyles(theme => ({
  flex: {
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },

  formControlNot100: {
    margin: theme.spacing(1),
    width: '40%'
  },
  rowButtons: {
    marginTop: theme.spacing(4),
    fontWeight: 'bold',
    fontSize: '14px'
  }
}));

const AddEditContactForm = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();
        
  const [open, setOpen] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [phones, setPhones] = useState([]);
  const [emails, setEmail] = useState([]);
  const [addresses, setAddresses] = useState([]);
  // opening dialogs for each of phone, email and address
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [contact, setContact] = useState(
    { 
      name: '', 
      company: '',
      website: '', 
      notes: '', 
      type: NonChoice,
      phoneTreeLeader: NonChoice,
      phones: [],
      emails: [],
      ContactActionItems: [],
      ContactDocumentItems: []  
    }
  )
  const [skipQuery, setSkipQuery] = useState(true);

  let { loading, error, data } = useQuery(gql(getContact), {
      variables: {id: props.selected ? props.selected.id : ''},
      skip: skipQuery,
    } 
  );

  const [addOneContact] = useContactAdd(familyContext.familyRealm.id, (data) => {
    console.log(data)
    // if (props.handleReceiveData) {
    //   props.handleReceiveData(data)
    // }    
  });

  const [updateOneContact] = useContactUpdate(familyContext.familyRealm.id, (data) => {
    console.log(data)
    // if (props.handleReceiveData) {
    //   props.handleReceiveData(data)
    // }   
  });

  useEffect(() => {
    if (!skipQuery) {
      const onCompleted = data => {
        const contact = data.item;
        let editedContact = {...contact};

        // handle any null website values
        if (contact.website === null) {
          editedContact.website = '';
        }
        // need to massage the contact a bit for editing purposes
        const emails = contact.ContactEmail.map((email) => {
          const addedEmail = {
            id: email.id,
            contactId: email.contactId,
            emailAddress: email.emailAddress,
            type: email.type
          }
          return addedEmail;
        })
        editedContact.emails = [...emails];

        const phones = contact.ContactPhone.map((phone) => {
          const addedPhone = {
            id: phone.id,
            contactId: phone.contactId,
            phoneNumber: phone.phoneNumber,
            type: phone.type
          }
          return addedPhone;
        })
        editedContact.phones = [...phones];

        contact.ContactAddress.map((address) => {
          console.log(address);
        })
        setContact(editedContact);
      }
      const onError = error => {
        return (
          <div>{error}</div>
        )
      }
      
      if (onCompleted || onError) {
        if (onCompleted && !loading) {
          onCompleted(data);
          setSkipQuery(true);
        } else if (onError && !loading && error) {
          onError(error);
          setSkipQuery(true);
        }
      }
    }
  }, [loading, data, error]);
    
  useEffect(() => {
    const {selected} = props;

    // if we're editing and we haven't yet fetched the item
    if (selected && skipQuery) {
      setSkipQuery(false);
    } 

    return () => {
      setSkipQuery(true);
    }
  }, [props.selected]);

  
  const save = (values) => {
    const editing = props.open;
   
     // Mutation - add or update one
    const details = {
      userRealm: familyContext.familyRealm.id,
      name: values.name,
      type: values.type ? values.type: NonChoice,
      company: values.company ? values.company : '',
      website: values.website ? values.website : '',
      notes: values.notes ? values.notes: '',
      phoneTreeLeader: values.phoneTreeLeader ? values.phoneTreeLeader: NonChoice
    }
   
    // props.open means we're here for editing - otherwise we're adding
    editing ? updateOne(details, values) : addOne(details, values);
  }

  const addOne = (details, values) => {
    // add emails
    if (values.emails.length > 0) {
      const notEmpty = values.emails.filter(email => email.emailAddress !== ''); 
      if (notEmpty.length > 0) {
        details.ContactEmail = {
          data: [...notEmpty]
        }
      }
    }

    // add phonenumbers
    if (values.phones.length > 0) {
      const notEmpty = values.phones.filter(phone => phone.phoneNumber !== ''); 
      if (notEmpty.length > 0) {
        details.ContactPhone = {
          data: [...notEmpty]
        }
      }
    }

    // add any action items
    const actionItems = constructCorrespondingActionItems(details);
    if (actionItems.length > 0) {
      details.ContactActionItems = {
        data: [...actionItems]
      }
    }

    addOneContact({variables: {object: details}})
    .then((result) => {
      props.handleAdd(result.data.item);
    })
    .catch((error) => {
      console.error(error);
    })

    // hide the modal
    handleClose();
  
  }

  const updateOne = (details, values) => {

    // Deal first with the separate components of the updated Contact: emails, phone numbers and addresses
    // Note: ContactPhone and ContactEmail have the original details, emails and phonenumbers have the edits
  
    // are we starting with an email? was it added, was one deleted or updated?
    if (values.ContactEmail || values.emails.length > 0) {
      // NOTE: we may not have an emails when editing starts, but we must pass at least an empty array
      let originalEmails = [];
      if (values.ContactEmail) {
        originalEmails = [...values.ContactEmail];
      }
      handleContactEmailChanges(contact, client, familyContext.familyRealm.id, originalEmails, values.emails);
    }

    if (values.ContactPhone || values.phones.length > 0) {
      // NOTE: we may not have a phone when editing starts, but we must pass at least an empty array
      let originalPhones = [];
      if (values.ContactPhone) {
        originalPhones = [...values.ContactPhone];
      }
      handleContactPhoneChanges(contact, client, familyContext.familyRealm.id, originalPhones, values.phones);
    }

    // add action items IF we don't have any already
    if (contact.actionItems.length === 0) {
     
      const actionItems = constructCorrespondingActionItems(details);
      if (actionItems.length > 0) {
        const items = actionItems.map(item => {
          item.userRealm = familyContext.familyRealm.id;
          item.domainId = contact.id;
          return item;
        });
        
        addMultipleActionItems(client, familyContext.familyRealm.id, OperationTargets.Contacts, items)
        .then(result => { 
          console.log(result)
          console.log('Added %s action items', actionItems.length);
        })
        .catch(error => { 
          console.log(error) 
        });
      } 
    }

    // finally, update the contact
    updateOneContact({variables: {id: contact.id, changes: details}})
    .then((result) => {
      props.handleEdit(result.data.item);
    })
    .catch((error) => {
      console.error(error);
    })
    
    // hide the modal
    handleClose();
  }

  // Attach this to your <Formik>
  const formRef = useRef();
  
  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const handleResetClick = () => {
    if (formRef.current) {
      formRef.current.handleReset()
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  }

  // normal close and cancel
  const handleClose = () => {
    // if we were opened by the client, let them close us, otherwise we close
    props.handleClose ?  props.handleClose() : setOpen(false);  
  }

  // dialog control for extras: phone, address, email
  const handleSetEmailDialogClose = (data) => {
    setEmailDialogOpen(false);
  }

  const handleSetEmailDialogSave = (data) => {
    let newContact = contact;
    newContact.emails.push(data)
    setContact(newContact)
    setEmailDialogOpen(false);
  }

  const body = (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={contact}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
       setSubmitting(false);
    
        // actually save the form
        save(values);
      }}
    >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      handleReset,
      setFieldValue,
      isSubmitting,
      isValid
      /* and other goodies */
    }) => (
      <>
      <Form onReset={handleReset} onSubmit={handleSubmit}>
        <FormControl className={classes.formControl}>
          <Field
            component={TextField}
            name="name"
            type="text"
            label="Full Name"
            autoFocus
            className={classes.textField}
            margin="normal"
            helperText={touched.name ? errors.name : "Provide the full name of this contact"}
            error={touched.name && Boolean(errors.name)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="contact-type">Contact Type</InputLabel>
          <Field
            component={Select}
            name="type"
            inputProps={{
              id: 'contact-type',
            }}
          >
            {ContactTypeList.map ( (item, index) =>
              <MenuItem key={index} value={item}>{item}</MenuItem>
            )}
          </Field>
          <FormHelperText>What type of contact is this?</FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Field
            component={TextField}
            name="company"
            label="Company"
            className={classes.textField}
            margin="normal"
            helperText="If this contact is with a company, provide the name of the company"
          />  
        </FormControl>
        <FormControl className={classes.formControl}>
          <Field
            component={TextField}
            name="website"
            label="Website"
            className={classes.textField}
            margin="normal"
            helperText="Provide the website of this contact, if there is one"
          />  
        </FormControl>
        {values.type === ContactType.Family 
          || values.type === ContactType.Friend 
          || values.type === ContactType.Neighbor 
          || values.type === ContactType.CoWorker? (
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="contact-phonetree-type">Phone Tree Contact</InputLabel>
            <Field
              component={Select}
              name="phoneTreeLeader"
              inputProps={{
                id: 'contact-phonetree-type',
              }}
            >
              {PhoneTreeLeaderTypeList.map ( (item, index) =>
                <MenuItem key={index} value={item}>{item}</MenuItem>
              )}
            </Field>
            <FormHelperText>Designate which group of contacts THIS contact should reach out to (hint: you may want to add Notes to clarify).</FormHelperText>
          </FormControl>): null
        } 
        <FormControl className={classes.formControl}>
          <Field
            component={TextField}
            name="notes"
            label="Notes"
            multiline
            maxRows={4}
            className={classes.textField}
            margin="normal"
            helperText="Add any notes/hints/special instructions to help with this contact"
          />  
        </FormControl>

        {/* TODO: provide one empty form to load - as soon as you get input, add the + sign and the x*/}
        {/* TODO: so, this way you ALWAYS have space allocated */}
        <FieldArray
            name="phones"
            render={arrayHelpers => (
              <div>
                {values.phones && values.phones.length > 0 ? (
                  values.phones.map((phone, index) => (
                    <div key={index}>
                      <FormControl className={classes.formControlNot100}>
                        <Field 
                          component={TextField}
                          label={' Phone'}
                          type = "text"
                          autoFocus = {props.selected ? false : true}
                          className={classes.textField}
                          name={`phones.${index}.phoneNumber`}
                          margin="normal"
                          error={touched.phone && Boolean(errors.phone)}
                          helperText={touched.phone ? errors.phone : "Provide a phone number for this contact"}
                        />
                      </FormControl>
                      <FormControl className={classes.formControlNot100}>
                        <Field 
                          component={TextField}
                          label={' Type'}
                          className={classes.textField}
                          name={`phones.${index}.type`} 
                          placeholder={'Primary or Secondary'}
                          margin="normal"
                          />
                      </FormControl>
                      <Button
                        className={classes.rowButtons}
                        onClick={() => arrayHelpers.remove(index)} // remove a phone from the list
                      >
                        -
                      </Button>
                      <Button
                        className={classes.rowButtons}
                        onClick={() => arrayHelpers.insert(index, { phoneNumber: '', type: '' })} // insert an empty string at a position
                      >
                        +
                      </Button>
                    </div>
                  ))
                ) : (
                  <Button onClick={() => arrayHelpers.push({ phoneNumber: '', type: '' })}>
                   
                    Add a phone
                  </Button>
                )}
              </div>
            )}
          />

        <FieldArray
            name="emails"
            render={arrayHelpers => (
              <div>
                {values.emails && values.emails.length > 0 ? (
                  values.emails.map((email, index) => (
                    <div key={index}>
                      <FormControl className={classes.formControlNot100}>
                        <Field 
                          component={TextField}
                          label={' Email'}
                          type = "email"
                          autoFocus = {props.selected ? false : true}
                          className={classes.textField}
                          name={`emails.${index}.emailAddress`}
                          margin="normal"
                          helperText={touched.phone ? errors.phone : "Provide an email for this contact"}
                          error={touched.email && Boolean(errors.email)}
                        />
                      </FormControl>
                      <FormControl className={classes.formControlNot100}>
                        <Field 
                          component={TextField}
                          label={' Type'}
                          className={classes.textField}
                          name={`emails.${index}.type`} 
                          placeholder={'Primary or Secondary'}
                          margin="normal"
                          />
                      </FormControl>
                      <Button
                        className={classes.rowButtons}
                        onClick={() => arrayHelpers.remove(index)} // remove an email from the list
                      >
                        -
                      </Button>
                      <Button
                        className={classes.rowButtons}
                        onClick={() => arrayHelpers.insert(index, { emailAddress: '', type: '' })} // insert an empty string at a position
                      >
                        +
                      </Button>
                    </div>
                  ))
                ) : (
                  <Button onClick={() => arrayHelpers.push({ emailAddress: '', type: '' })}>
                   
                    Add an email
                  </Button>
                )}
              </div>
            )}
          />
         
       
        {/* 
        <FormControl className={classes.formControl}>
          <Field
            component={TextField}
            name="phone"
            type="text"
            label="Phone"
            className={classes.textField}
            margin="normal"
            helperText={touched.phone ? errors.phone : "Provide a phone number for this contact"}
            error={touched.phone && Boolean(errors.phone)}
          />
        </FormControl> */}
      </Form>
       {/* <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button>Add Phone</Button>
          <Button  onClick = {() => setEmailDialogOpen(true)}>Add Email</Button>
          <Button>Add Address</Button>
      </ButtonGroup> */}
      </>
    )}
  </Formik>
  )
  
  const title =  props.open ? "Edit a contact" : "Add a contact";

  return (
    <>
     <FormDialogBase 
        open = {props.open ? props.open: open}
        title = {title}
        body = {body}
        submitEnabled = {submitEnabled}
        handleAddClickOpen = {handleClickOpen}
        handleClose = {handleClose}
        handleReset = {handleResetClick}
        handleSubmit = {handleSubmitClick} 
      />
      {/* {emailDialogOpen ? (
        <ContactEmailDialog 
          open = {emailDialogOpen}
          handleClose = {handleSetEmailDialogClose}
          handleSave = {handleSetEmailDialogSave}
        />
        ): null
      } */}
    </>
  );
}

// client can open if they choose to OR we will open
AddEditContactForm.defaultProps = {
  selected: null,
  open: false,
  handleClose: null
}

AddEditContactForm.propTypes = {
  selected: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAdd: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func,
}

export default (AddEditContactForm);


import {LiabilityType, ActionItemType, ActionItemGroupType, PriorityType, PaymentType } from '../../Enums';
import {NonChoice } from '../../Enums';

const prepareDetailsForCancelledPaymentsItems = (liability) => {
  let priority = PriorityType.High;
  let groupType = ActionItemGroupType.None;

  // Special case: we are only going to create one action item, combining Cancel Service and Cancel Payment
  if (liability.howPaid === PaymentType.BillpayVendor 
    || liability.type === LiabilityType.Rent 
    || liability.type === LiabilityType.Housing) {
    return null;
  }

  let name = "Cancel upcoming payments for '" + liability.name + "'.";
  if (liability.howPaid === PaymentType.RecurringBank) {
    priority = PriorityType.High;
    if (liability.institution !== '') {
      name = "Log into the bank website where the payments are set up. Cancel future payments to " + liability.institution + ".";
    } else {
      name = "Log into the bank website where the payments are set up. Cancel future payments for '" + liability.name + "'.";
    }
  }

  if (liability.type === LiabilityType.Rent || liability.type === LiabilityType.Housing) {
    groupType = ActionItemGroupType.Home;
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: liability.id,
    type: ActionItemType.Liabilities,
    group: groupType,
    notes: ""
  };
  return actionItemDetails;
}

const prepareDetailsForCancelledServiceItems = (liability) => {
  let priority = PriorityType.Medium;

  // none of these action items make sense for a housing liability, handle it specially
  if (liability.type === LiabilityType.Housing) {
    return null;
  }

  // Special case: we are only handle rent separately
  let name = null;
  if (liability.type === LiabilityType.Rent) {
    name = "Pay off any cleaning deposit and other fees for the rental of '" + liability.name + "', and ensure that no future rent payments are made."
  } else { 
    name = "Pay off the " + liability.type.toLowerCase() + " liability for '" + liability.name + "'.";
    if (liability.howPaid === PaymentType.BillpayVendor) {
      priority = PriorityType.High;
      if (liability.institution !== '') {
        name = "Contact " + liability.institution + " and find out the procedure to pay off the liabilty.";
      } else {
        name = "Contact the company for '" + liability.name + "' and find out the procedure to pay off the liabilty.";;
      }
    }
  }
  // if we haven't set up anything, return null
  if (name === null) {
    return null;
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: liability.id,
    type: ActionItemType.Liabilities,
    group: ActionItemGroupType.None,
    notes: ""
  };
  return actionItemDetails;
}

const prepareDetailsForSpecialItems = (liability) => {
  let priority = PriorityType.Medium;
  
  let name;
  if (liability.type === LiabilityType.Rent) {
    priority = PriorityType.High;
    name = "Contact the landlord and give notice for the rental of '" + liability.name +"'. Find out how much is owed for rent and fees and pay them off."
  } else if (liability.type === LiabilityType.Housing) {
    name = "The liability '" + liability.name + "' depends on an asset which you may decide to keep or sell. Figure out what to do with the asset first, then add action items to deal with the liability."
  } else {
    // no other special cases
    return null;
  }
  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: liability.id,
    type: ActionItemType.Liabilities,
    notes: ""
  };
  return actionItemDetails;
}

export const constructCorrespondingActionItems = (liability) => {
  const actionItems = [];

  const serviceItemDetails = prepareDetailsForCancelledServiceItems(liability);
  const specialItemDetails = prepareDetailsForSpecialItems(liability);
  const paymentItemDetails = prepareDetailsForCancelledPaymentsItems(liability);
  
  if (serviceItemDetails) {
    actionItems.push(serviceItemDetails);
  }
  if (specialItemDetails) {
    actionItems.push(specialItemDetails)
  }
  if (paymentItemDetails) {
    actionItems.push(paymentItemDetails);
  }

  return actionItems;
}
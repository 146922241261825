import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

//import { useAuth0 } from "@auth0/auth0-react";
import { useAuthHelper } from "../../auth/AuthHook";

import { useRecoilValue } from "recoil";
import { currentUserState } from "../../context/UserState";

import clsx from 'clsx';

import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import AlarmIcon from '@material-ui/icons/Alarm';
import PolicyIcon from '@material-ui/icons/Policy';
import PaymentIcon from '@material-ui/icons/Payment';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HelpIcon from '@material-ui/icons/Help';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from "@material-ui/core/Tooltip";

import {useLocation, useHistory, Link as RouterLink} from 'react-router-dom'
import Link from '@material-ui/core/Link';

import {useUserContext, USERFIELD_NOT_SET} from '../../context/UserContext'
import {FamilyContext, NULL_FAMILY_NAME} from "../../context/FamilyContext";

import LetterRoutes from '../../Routes';
import ProfileCard from '../user/ProfileCard';

import {APPNAME} from '../../Enums';

//import { useFetchAppUser } from "../../api/UserHooks";
import PlanSwitcher from "../user/PlanSwitcher";

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  plans: {
    fontStyle: 'italic',
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const userContext = useUserContext();
  const familyContext = useContext(FamilyContext);

  const [open, setOpen] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [userRealms, setUserRealms] = useState([]);
  const [switchPlansOpen, setSwitchPlansOpen] = useState(false);

  //const { appUser } = useFetchAppUser();

  //const { isLoading } = useAuth0();
  const { isAuthenticated } = useAuthHelper();

  const [title, setTitle] = useState("");

  const appUser = useRecoilValue(currentUserState);
  
  useEffect(() => {
    if (isAuthenticated()) {  
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (appUser) {
      setUserRealms(appUser.realms)
    }
  }, [authenticated, appUser]);

  useEffect(() => {
    if (familyContext.familyRealm.name !== NULL_FAMILY_NAME) {
      const dashTitle =  APPNAME + " - " + familyContext.familyRealm.name;
      setTitle(dashTitle);
    }
    
  }, [familyContext]);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const {children} = props;

  // if (isLoading) {
  //   return <div>Loading ...</div>;
  // }
  
  const drawer = (
    <div>
      <MenuList> 
        <MenuItem component={RouterLink} to={LetterRoutes.OverviewRoute} selected={LetterRoutes.OverviewRoute === location.pathname}>
          <ListItemIcon className={classes.icon}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Overview" />
        </MenuItem>        
        <Divider />
        {userContext.user && userContext.user.isOwner ? (
          <MenuItem component={RouterLink} to={LetterRoutes.GlobalActionItemsRoute} selected={'/' === location.pathname}>
            <ListItemIcon className={classes.icon}>
              <AlarmIcon/>
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.primary }} primary="Action Items" />
          </MenuItem>
        ): null}
        <MenuItem component={RouterLink} to={LetterRoutes.AccountsRoute} selected={LetterRoutes.AccountsRoute === location.pathname}>
          <ListItemIcon className={classes.icon}>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Accounts" />
        </MenuItem>
        <MenuItem component={RouterLink} to={LetterRoutes.ContactsRoute} selected={LetterRoutes.ContactsRoute === location.pathname}>
          <ListItemIcon className={classes.icon}>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Contacts" />
        </MenuItem>
        <MenuItem component={RouterLink} to={LetterRoutes.DocumentsRoute} selected={LetterRoutes.DocumentsRoute === location.pathname}>
          <ListItemIcon className={classes.icon}>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Documents" />
        </MenuItem>
        <MenuItem component={RouterLink} to={LetterRoutes.AssetsRoute} selected={LetterRoutes.AssetsRoute === location.pathname}>
          <ListItemIcon className={classes.icon}>
            <EmojiTransportationIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Assets" />
        </MenuItem>
        <MenuItem component={RouterLink} to={LetterRoutes.LiabilitiesRoute} selected={LetterRoutes.LiabilitiesRoute === location.pathname}>
          <ListItemIcon className={classes.icon}>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Liabilities" />
        </MenuItem>
        <MenuItem component={RouterLink} to={LetterRoutes.InsuranceRoute} selected={LetterRoutes.InsuranceRoute === location.pathname}>
          <ListItemIcon className={classes.icon}>
            <PolicyIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Insurance" />
        </MenuItem>
        <MenuItem component={RouterLink} to={LetterRoutes.SubscriptionsRoute} selected={LetterRoutes.SubscriptionsRoute === location.pathname}>
          <ListItemIcon className={classes.icon}>
            <SubscriptionsIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Subscriptions" />
        </MenuItem>
        <MenuItem component={RouterLink} to={LetterRoutes.ServicesRoute} selected={LetterRoutes.ServicesRoute === location.pathname}>
          <ListItemIcon className={classes.icon}>
            <RoomServiceIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Services" />
        </MenuItem>
        <MenuItem component={RouterLink} to={LetterRoutes.OtherItemsRoute} selected={LetterRoutes.OtherItemsRoute === location.pathname}>
          <ListItemIcon className={classes.icon}>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Household Valuables" />
        </MenuItem>
        <Divider />
        <MenuItem component={RouterLink} to={LetterRoutes.HelpRoute} selected={LetterRoutes.HelpRoute === location.pathname}>
          <ListItemIcon className={classes.icon}>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Help" />
        </MenuItem>
      </MenuList>
    </div>
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && authenticated && userContext.user && userContext.user.userRealm !== USERFIELD_NOT_SET && classes.appBarShift)}
        >
          <Toolbar disableGutters={!open} className={classes.toolbar}>
            {authenticated && userContext.user && userContext.user.userRealm !== USERFIELD_NOT_SET && (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden,
                )}
            >
              <MenuIcon />
            </IconButton>
            )}
            {userRealms.length > 1 ? (
              <Tooltip title={"Switch plans"}>
                <IconButton className = {classes.switchPlans} color="inherit" onClick = { () => {
                      setSwitchPlansOpen(true)
                    }} >
                  <SupervisorAccountIcon />
                </IconButton>
              </Tooltip>
            ) : null}
             
            <Typography
              component="h1"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {title}
            </Typography>
           
            {authenticated  && (
              <>
                <Link className={classes.title} variant="body1" color="inherit" component={RouterLink} to={LetterRoutes.GettingStartedRoute}>
                    Got Questions?
                </Link>
                {/* <Link className={classes.title} align="right" variant="caption" display="block" color="inherit" component={RouterLink} to={FamilyShareRoutes.PrivacyTermsRoute}>
                    Terms and Privacy
                </Link> */}
                <Tooltip title={"Settings"}>
                  <IconButton color="inherit" onClick = { () => {
                      const path = LetterRoutes.SettingsRoute;
                      history.push(path);
                    }} >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
                <ProfileCard />   
              </>
            )}
          </Toolbar>
        </AppBar>
        {authenticated && userContext.user && userContext.user.userRealm !== USERFIELD_NOT_SET && (
          <nav className={classes.drawer}>
            <Hidden xsDown>
              <Drawer
                container = {props.container}
                variant="persistent"
                open={open}
                onClose={handleDrawerClose}
                classes={{
                  paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
              >
                <div className={classes.toolbarIcon}>
                  <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
                <Divider />
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden smUp>
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        )}
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.toolbar} />
            {children}
          {userRealms.length > 1 && switchPlansOpen && (
            <PlanSwitcher 
              open = {switchPlansOpen}
              userRealms = {userRealms}
              handleClose = {() => setSwitchPlansOpen(false)}
            />
          )}
        </main>
      </div>
    </React.Fragment>
  );
}

Dashboard.propTypes = {
  children: PropTypes.object.isRequired,
};

export default (Dashboard);

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import LTRCommonLayout from "../shared/domains/LTRCommonLayout";
import LTRCommonNameList, {
  RefreshReason,
} from "../shared/domains/LTRCommonNameList";
import LTRCommonDataTable from "../shared/domains/LTRCommonDataTable";
import LTRDomainObjectInfo from "../shared/domains/LTRDomainObjectInfo";
import LTRSnackbar, {
  buildAddMessage,
  buildEditMessage,
  buildDeleteMessage,
} from "../utilties/LTRSnackbar";

import { DomainType, DomainTypeSingular } from "../../Enums";

import { useQuery, gql } from "@apollo/client";
import { listLiabilities } from "../../graphql/queries";
import { useLiabilityDelete } from "../../api/APIHooks";

import AddEditLiabilityForm from "./forms/AddEditLiabilityForm";
import LiabilityActionPanel from "./forms/LiabilityActionPanel";

import { prepareTableRowInfo } from "./LiabilityInfo";

import { sortListByName } from "../utilties/SortFunctions";

import { useFamilyContext } from "../../context/FamilyContext";

import { useSnackbar } from "../utilties/snackbarUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const Liabilities = () => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [liabilities, setLiabilities] = useState(null);

  const [editFormOpen, setEditFormOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [infoTableItem, setInfoTableItem] = useState(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const [visibleList, setVisibleList] = useState([]);

  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  const { loading, error, data, refetch } = useQuery(gql(listLiabilities), {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  const processReceivedData = (data) => {
    const items = data.map((liability) => {
      let modified = { ...liability };
      if (liability.billers && liability.billers.length > 0) {
        const biller = liability.billers[0];
        modified.billingFrequency = biller.billingFrequency
          ? biller.billingFrequency
          : "";
        modified.howPaid = biller.howPaid ? biller.howPaid : "";
        modified.billType = biller.billType ? biller.billType : "";
        modified.billerId = biller.id;
      }
      return modified;
    });

    setLiabilities(items);
  };

  const [deleteOneLiability] = useLiabilityDelete(
    familyContext.familyRealm.id,
    (data) => {
      console.log(data);
      // processReceivedData(data);
    }
  );

  useEffect(() => {
    const onCompleted = (data) => {
      processReceivedData(data.item);
    };
    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    // just one reason we're here: if liabilities change we need to adjust
    if (liabilities) {
      const sortedList = sortListByName(liabilities);
      setVisibleList(sortedList);
    }
  }, [liabilities]);

  const handleSearch = (searchTerm) => {
    if (liabilities.length === 0) {
      return [];
    }

    let displayList;
    if (searchTerm === null) {
      displayList = [...liabilities];
    } else {
      displayList = liabilities.filter((item) => {
        const testName = item.name ? item.name.toLowerCase() : "";
        const testType = item.type ? item.type.toLowerCase() : "";
        const testCompany = item.institution
          ? item.institution.toLowerCase()
          : "";
        const testAccountNumber = item.accountNumber
          ? item.accountNumber.toLowerCase()
          : "";
        const testBillType = item.billType ? item.billType.toLowerCase() : "";
        const testBillingFrequency = item.billingFrequency
          ? item.billingFrequency.toLowerCase()
          : "";
        const testHowPaid = item.howPaid ? item.howPaid.toLowerCase() : "";
        const testNotes = item.usage ? item.usage.toLowerCase() : "";
        const againstSearchTerms = searchTerm ? searchTerm.toLowerCase() : "";

        // test the relevant fields
        return (
          testName.includes(againstSearchTerms) ||
          testType.includes(againstSearchTerms) ||
          testCompany.includes(againstSearchTerms) ||
          testAccountNumber.includes(againstSearchTerms) ||
          testBillType.includes(againstSearchTerms) ||
          testBillingFrequency.includes(againstSearchTerms) ||
          testHowPaid.includes(againstSearchTerms) ||
          testNotes.includes(againstSearchTerms)
        );
      });
    }
    return setVisibleList(displayList);
  };

  const handleAddNotification = (item) => {
    if (item) {
      openSnackbar(buildAddMessage(item.name));
    }
  };

  const handleEditNotification = (item) => {
    if (item) {
      openSnackbar(buildEditMessage(item.name));
    }
  };

  const handleEditOpen = (selectedItem) => {
    setSelectedItem(selectedItem);
    setEditFormOpen(true);
  };

  const handleEditClose = () => {
    setSelectedItem(null);
    setEditFormOpen(false);
  };

  const handleDelete = (selected) => {
    deleteOneLiability({ variables: { id: selected.id } })
      .then((result) => {
        openSnackbar(buildDeleteMessage(result.data.item.name));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMultiDeleteNotification = (selected) => {
    const aList = liabilities.filter((item) => {
      return selected.includes(item.id) ? false : true;
    });

    let userMessage = "";
    const numItemsDeleted = selected.length;
    if (numItemsDeleted > 1) {
      userMessage = numItemsDeleted + " liabilities deleted successfully";
      openSnackbar(userMessage);
    } else {
      userMessage = "Liability deleted successfully";
      openSnackbar(userMessage);
    }
    setLiabilities(aList);
  };

  const handleEditFromId = (selectedId) => {
    const aList = liabilities.filter((item) => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!

    // call the handleEdit open handler
    handleEditOpen(aList[0]);
  };

  const handleShowInfo = (selectedId) => {
    const aList = liabilities.filter((item) => {
      return selectedId.indexOf(item.id) === 0 ? true : false;
    });
    // should only have 1 result!
    const liability = aList[0];
    const tableInfo = prepareTableRowInfo(liability);

    setInfoTableItem(tableInfo);
    setSelectedItem(aList[0]);
    setShowInfoDialog(true);
  };

  const handleShowInfoClose = () => {
    setSelectedItem(false);
    setShowInfoDialog(false);
  };

  const checkForAttachments = (selected) => {
    return selected.attachments.length > 0;
  };

  const handleRefresh = (selected, refreshReason) => {
    console.log("Selected:");
    console.log(selected);

    switch (refreshReason) {
      case RefreshReason.UploadAttachment:
        console.log("Refresh - upload");
        refetch();
        break;
      case RefreshReason.DeleteAttachment:
        console.log("Refresh - delete");

        const list = liabilities.filter((i) => i.id !== selected.id);
        selected.attachments = [];
        list.push(selected);
        processReceivedData(list);
        break;
      default:
        console.log("NOT A VALID PATH");
        break;
    }
  };

  const handleViewChange = (oldView, newView) => {
    // whenever we get a view change, we need to cancel all filtering, searching and restore the display list
    handleSearch(null);
  };

  // are we ready?
  if (liabilities === null) {
    return <div>Loading ...</div>;
  }

  return (
    <React.Fragment>
      <LTRCommonLayout
        domain={DomainType.Liabilities}
        domainSingular={DomainTypeSingular.Liabilities}
        handleViewChange={handleViewChange}
        commonNameList={
          <div className={classes.root}>
            <LTRCommonNameList
              list={visibleList}
              domain={DomainType.Liabilities}
              domainSingular={DomainTypeSingular.Liabilities}
              hasAttachments={checkForAttachments}
              addComponent={
                <AddEditLiabilityForm
                  handleAdd={handleAddNotification}
                  handleEdit={handleEditNotification}
                  handleReceiveData={processReceivedData}
                />
              }
              handleDelete={handleDelete}
              handleEdit={handleEditOpen}
              handleInfo={handleShowInfo}
              handleSearch={handleSearch}
              handleRefresh={handleRefresh}
            />
          </div>
        }
        commonNameTable={
          <LTRCommonDataTable
            domain={DomainType.Liabilities}
            domainColumns={[
              { name: "name", label: "Name" },
              { name: "type", label: "Type" },
              { name: "institution", label: "Company" },
              { name: "accountNumber", label: "Account Number" },
              { name: "howPaid", label: "How Paid" },
              { name: "billType", label: "Bill Type" },
              { name: "billingFrequency", label: "Bill Frequency" },
              { name: "usage", label: "Notes" },
            ]}
            dataItems={visibleList}
            handleMultiDeleteNotification={handleMultiDeleteNotification}
            handleEdit={handleEditFromId}
          />
        }
        actionPanel={
          <LiabilityActionPanel 
            handleAddNotification={handleAddNotification} 
            handleReceiveData={processReceivedData}
          />
        }
      />
      {editFormOpen ? (
        <AddEditLiabilityForm
          open={editFormOpen}
          selected={selectedItem}
          handleAdd={handleAddNotification}
          handleEdit={handleEditNotification}
          handleClose={handleEditClose}
          handleReceiveData={processReceivedData}
        />
      ) : null}
      {showInfoDialog ? (
        <LTRDomainObjectInfo
          open={showInfoDialog}
          title={"Info: " + selectedItem.name}
          tableData={infoTableItem}
          handleClose={handleShowInfoClose}
        />
      ) : null}
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    </React.Fragment>
  );
};

export default Liabilities;

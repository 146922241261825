import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {useApolloClient} from '@apollo/client';

import { 
  fetchOneDomainInstance,
  OperationTargets
} from '../../api/CommonCrudOperations'

import LTRDomainObjectInfo from '../shared/domains/LTRDomainObjectInfo';

// Our labels in the Info Table
const labels = {
  type: "TYPE",
  description: "DESCRIPTION",
  insurance: "INSURED BY",
  notes: "NOTES"
}

export const prepareTableRowInfo = (asset) => {
  // prepare table data for show
  let messageAction = '';
  let messageDoc = '';
  if (asset.actionItems) {
    messageAction = asset.actionItems.length === 0 ? "This asset has NO action items" :
    "This asset has " + asset.actionItems.length + " action items";
  }
  if (asset.attachments) {
    messageDoc = asset.attachments.length === 0 ? "This asset has NO attached files":
    "This asset has " + asset.attachments.length + " attached files"
  }
  const tableInfo = {
    name: asset.name,
    actionItemMessage: messageAction,
    documentsMessage: messageDoc,
    fieldData : [
      {
        field: labels.type,
        value: asset.type
      },
      {
        field: labels.description,
        value: asset.description
      },
      {
        field: labels.insurance,
        value: asset.insurance !== null ? asset.insurance.name : ''
      },
      {
        field: labels.notes,
        value: asset.notes
      },
    ]
  }

  return tableInfo;  
}

const useAssetFetch = (client, domainId, updateFn) => {
  const [asset, setAsset] = useState(null);
  
  useEffect(() => {
    const fetchAsset = () => { 
      fetchOneDomainInstance(client, OperationTargets.Assets, domainId)
      .then(result => { 
        const asset = result.item;
        setAsset(asset);
        updateFn(asset);
      })
      .catch(error => {
        console.log(error) 
      });
    }

    if (asset === null && domainId !== null) {
      fetchAsset()
    }  
  }, [client, asset, domainId, updateFn]);
};

const AssetInfo = (props) => {
  const client = useApolloClient();

  const [showInfoDialogOpen, setShowInfoDialogOpen] = useState(false);
  const [tableInfo, setTableInfo] = useState(null);
  const [asset, setAsset] = useState(null);

  const handleShowInfoClose = () => {
    setShowInfoDialogOpen(false);
    // notify the client
    props.handleClose();
  }

  useAssetFetch(client, props.domainId, (fetchedData) => {
    if (fetchedData !== null) {
      setAsset(fetchedData);
      const info = prepareTableRowInfo(fetchedData);
      setTableInfo(info);
      setShowInfoDialogOpen(true);
    }
  });

  if (tableInfo === null) {
    return null;
  }

  return (
    <React.Fragment>  
      {showInfoDialogOpen ? (
        <LTRDomainObjectInfo
          open = {props.open}
          title = {asset.name}
          tableData = {tableInfo}
          handleClose = {handleShowInfoClose}
        />): null
      }
    </React.Fragment>
  )
}

AssetInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  domainId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default (AssetInfo);

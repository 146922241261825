import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemText from "@material-ui/core/ListItemText";

import Typography from "@material-ui/core/Typography";

import LTRSnackbar, {
  buildEditMessage,
  buildDeleteMessage,
} from "../utilties/LTRSnackbar";
import { useSnackbar } from "../utilties/snackbarUtil";
import ConfirmModal from "../shared/modals/ConfirmModal";

import { deleteLiability } from "../../graphql/mutations";

import { FamilyContext } from "../../context/FamilyContext";

import { getIncompleteLiabilities } from "../../graphql/queries";

import { gql, useQuery, useMutation } from "@apollo/client";

import AddEditLiabilityForm from "../liabilities/forms/AddEditLiabilityForm";

const GET_LIABILITY = gql(
  `query listLiability ($userRealm: String) {
  ` +
    getIncompleteLiabilities +
    `
}
`
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: "bold",
    marginRight: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const IncompleteLiabilityView = (props) => {
  const classes = useStyles();
  const familyContext = useContext(FamilyContext);

  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState({});
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [liabilityDelete] = useMutation(gql(deleteLiability), {
    refetchQueries: [
      {
        query: GET_LIABILITY,
        variables: { userRealm: familyContext.familyRealm.id },
      },
    ],
  });

  const { loading, error, data, refetch } = useQuery(GET_LIABILITY, {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  useEffect(() => {
    const onCompleted = (data) => {
      setItems(data.Liability);
    };
    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error]);

  if (error) return `Error! ${error.message}`;

  const handleEdit = (item) => {
    setSelected(item);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditNotification = (item) => {
    setEditDialogOpen(false);

    // refetch our incomplete items
    refetch()
      .then((_) => {
        // notify the user and refetch the counts
        openSnackbar(buildEditMessage(item.name));
        props.refetchFunc();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (item) => {
    setSelected(item);
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteItem = () => {
    handleDeleteClose();

    liabilityDelete({ variables: { id: selected.id } })
      .then((data) => {
        openSnackbar(buildDeleteMessage(selected.name));
        props.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // simply return if we have no data
  if (items.length === 0) {
    return null;
  }

  // common messages
  const fixMessage =
    "To fix: edit the liability and provide the 'company name'";
  const deleteConfirmTitle = "Confirm liability deletion";
  const deleteConfirmBody = "Are you sure you want to delete this liability?";
  const itemTitle = "Liability items";

  return (
    <div className={classes.root}>
      <Typography
        variant="subtitle1"
        gutterBottom
        className={classes.titleText}
      >
        {itemTitle}
      </Typography>

      <List>
        {items.map((item) => (
          <ListItem
            key={item.id}
            role={undefined}
            alignItems="flex-start"
            divider
            className={classes.listItem}
            onClick={() => setSelected(item)}
          >
            <ListItemText 
              disableTypography
              primary={
                <React.Fragment>
                  <Typography
                    // component="div"
                    variant="body1"
                    color="textPrimary"
                  >
                    {item.name}
                  </Typography>
                </React.Fragment>
              } 
              secondary={fixMessage} />
            <ListItemSecondaryAction>
              <Tooltip title={"Edit"}>
                <IconButton
                  aria-label={"Edit"}
                  onClick={() => {
                    handleEdit(item);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete"}>
                <IconButton
                  aria-label={"Delete"}
                  onClick={() => {
                    handleDelete(item);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <ConfirmModal
        title={deleteConfirmTitle}
        open={deleteDialogOpen}
        handleConfirm={handleDeleteItem}
        handleClose={handleDeleteClose}
        body={deleteConfirmBody}
      />
      {editDialogOpen ? (
        <AddEditLiabilityForm
          selected={selected}
          open={editDialogOpen}
          handleClose={handleEditClose}
          handleAdd={() => {}}
          handleEdit={handleEditNotification}
        />
      ) : null}
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    </div>
  );
};

IncompleteLiabilityView.propTypes = {
  refetchFunc: PropTypes.func.isRequired,
};

export default IncompleteLiabilityView;

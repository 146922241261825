import {ActionItemType, ActionItemGroupType, PriorityType, PaymentType, ServiceType } from '../../Enums';
import {NonChoice } from '../../Enums';

// Logic here about action item creation
// If you create a service, there will be two actions
// items created. One for cancelling the service, the other for cancelling payment
//
// Exceptions: if you have a recurring bank payment OR payment through a vendor
// then a special message will be created and only ONE action item will be created.

const prepareDetailsForCancelledPaymentsItems = (service) => {
  let priority = PriorityType.Medium;

  // Special case: we are only going to create one action item, 
  // combining Cancel Service and Cancel Payment - here we 
  // will create an action item for RecurringBank with a special message
  // and different priority

  if (service.howPaid === PaymentType.BillpayVendor) {
    return null;
  }

  let name = "Cancel upcoming payments to the " + service.type.toLowerCase() + " service: '" + service.name + "'.";
  if (service.howPaid === PaymentType.RecurringBank) {
    priority = PriorityType.High;
    name = "Log into the bank website where the payments are set up. Cancel future payments to '" + service.name + "'.";  
  }

  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: service.id,
    domainName: service.name,
    type: ActionItemType.Services,
    group: ActionItemGroupType.None,
    notes: ""
  };
  return actionItemDetails;
}

const prepareDetailsForCancelledServiceItems = (editItem, serviceDetails) => {
  let priority = PriorityType.Medium;

  // Special case: we are only going to create one action item, 
  // combining Cancel Service and Cancel Payment - here we 
  // will create an action item for BillpayVendor with a special message
  // and different priority
  if (serviceDetails.howPaid === PaymentType.RecurringBank) {
    return null;
  }
  
  let name = "Cancel the " + serviceDetails.type.toLowerCase() + " service for '" + serviceDetails.name + "'";
  if (editItem.contact !== null) {
    name = "Contact " + editItem.contact.name + " to cancel the " + serviceDetails.type.toLowerCase() + " service for '" + serviceDetails.name + "'";
  }
  if (serviceDetails.howPaid === PaymentType.BillpayVendor) {
    priority = PriorityType.High;
    if (serviceDetails.type === ServiceType.Membership) {
      if (editItem.contact !== null) {
        name = "Contact " + editItem.contact.name + " at the company that provides the '" + serviceDetails.name + "' and cancel it, making sure future payments are cancelled.";
      } else {
        name = "Contact the individual/company that provides the '" + serviceDetails.name + "' and cancel it, making sure future payments are cancelled.";
      }
    } else {
      if (editItem.contact !== null) {
        name = "Contact " + editItem.contact.name + " at the company that provides the '" + serviceDetails.name + "' service and cancel it, making sure future payments are cancelled.";
      } else {
        name = "Contact the individual/company that provides the '" + serviceDetails.name + "' service and cancel it, making sure future payments are cancelled."; 
      }
    }
  }
  const actionItemDetails = {
    name: name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: serviceDetails.id,
    domainName: serviceDetails.name,
    type: ActionItemType.Services,
    group: ActionItemGroupType.None,
    notes: ''
  };
  return actionItemDetails;
}

export const constructCorrespondingActionItems = (editItem, serviceDetails) => {
  const actionItems = [];

  const paymentItemDetails = prepareDetailsForCancelledPaymentsItems(serviceDetails);
  const serviceItemDetails = prepareDetailsForCancelledServiceItems(editItem, serviceDetails);

  if (paymentItemDetails) {
    actionItems.push(paymentItemDetails);
  }
  if (serviceItemDetails) {
    actionItems.push(serviceItemDetails);
  }

  return actionItems;
}
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import ListIcon from '@material-ui/icons/List';
import TableChartIcon from '@material-ui/icons/TableChart';
import AlarmIcon from '@material-ui/icons/Alarm';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HelpIcon from '@material-ui/icons/Help';

import ActionItemTable from '../../actionitems/ActionItemTable';
import {ActionItemType, DomainType, ToolbarActions} from '../../../Enums';
import DomainHelp from '../help/DomainHelp';

const ViewType = {
  List: 'List',
  Table: 'Table',
  Action: 'Action'
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  toggleContainer: {
    textAlign: 'center',
    display:'block',
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  buttons: {
    textAlign: 'right',
    display:'block',
    width: '100%',
    margin: theme.spacing(2, 0),
    paddingRight: theme.spacing(5),
  }
}));

const LTRDomainLayout = (props) => {
  const classes = useStyles();

  const [viewSelected, setViewSelected] = useState(ViewType.List)
  const [helpOpen, setHelpOpen] = useState(false);
  
  const handleAlignment = (event, newView) => {
    const oldView = viewSelected;
    // notify the client that the view is changing
    props.handleViewChange(oldView, newView);
    setViewSelected(newView)
  };

  const actionTypeFromDomain = () => {
    switch (props.domain) {
      case DomainType.Accounts:
        return ActionItemType.Account;
      case DomainType.Assets:
        return ActionItemType.Assets;
      case DomainType.Contacts:
        return ActionItemType.Contacts;
      case DomainType.Liabilities:
        return ActionItemType.Liabilities;
      case DomainType.Insurance:
        return ActionItemType.Insurance;
      case DomainType.Subscriptions:
        return ActionItemType.Subscriptions;
      case DomainType.Services:
        return ActionItemType.Services;
      case DomainType.OtherItems:
        return ActionItemType.OtherItems;
      case DomainType.Documents: 
        return ActionItemType.Documents;
      default: 
        console.log('Domain type not found. Is this intended?')
        return ActionItemType.All
    }
  }

  const handleImportClick = () => {
    if (props.toolbarActionHandler) {
      const action = {
        type: ToolbarActions.Import
      }
      props.toolbarActionHandler(action);
    }
  }

  const handleUploadClick = () => {
    if (props.toolbarActionHandler) {
      const action = {
        type: ToolbarActions.Upload
      }
      props.toolbarActionHandler(action);
    }
  }

  const handleHelpOpen = () => {
    setHelpOpen(true);
  }

  const handleHelpClose = () => {
    setHelpOpen(false);
  }

  const toolbarActionImport = props.toolbarActionsNeeded.length && props.toolbarActionsNeeded.includes(ToolbarActions.Import);
  const toolbarActionUpload = props.toolbarActionsNeeded.length && props.toolbarActionsNeeded.includes(ToolbarActions.Upload);

  const renderToolbar = (
    <Paper variant="outlined">
      <Grid  
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}>
        <Grid item xs={8}>
          <div className = {classes.toggleContainer}>
            <ToggleButtonGroup
              value={viewSelected}
              exclusive
              size="small" 
              onChange={(event, newView) => handleAlignment(event, newView)}
              aria-label="view alignment"
            >
              <Tooltip title="List" arrow>
                <ToggleButton value={ViewType.List}  aria-label="list view">
                  <ListIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Table" arrow>
                <ToggleButton value={ViewType.Table} aria-label="table view">
                  <TableChartIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Action Items" arrow>
                <ToggleButton value={ViewType.Action} aria-label="action items view">
                  <AlarmIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className = {classes.buttons}>
              {props.toolbarActionHandler && toolbarActionImport ? (
                <Tooltip title="Import" arrow>
                  <IconButton onClick = {handleImportClick} aria-label="import export">
                    <ImportExportIcon />
                  </IconButton>
                </Tooltip>
                ) : null  
              }  
              {props.toolbarActionHandler && toolbarActionUpload ? (
                <Tooltip title="Upload" arrow>
                  <IconButton onClick = {handleUploadClick} aria-label="upload file">
                    <CloudUploadIcon />
                  </IconButton>
                </Tooltip>
                ) : null  
              }      
              <Tooltip title="Help" arrow>
                <IconButton onClick = {handleHelpOpen} aria-label="help">
                  <HelpIcon />
                </IconButton>
              </Tooltip> 
            </div>
          </Grid>
      </Grid>
    </Paper>
  );

  const renderMainContent = (
    <Grid item xs={12}>
      {renderToolbar}

      {viewSelected === ViewType.List && 
        <>    
          <div>
            {props.commonNameList}
          </div>
        </>
      }
      {viewSelected === ViewType.Action && 
    
        <div>       
          <ActionItemTable 
            itemType = {actionTypeFromDomain()} /> 
        </div>
      
      }
      {viewSelected === ViewType.Table &&
        <div>
          {props.commonDataTable}
        </div>
      }
    </Grid>
  )

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        spacing={1}
      >
        {renderMainContent}
        <Grid item xs={3}>
          {props.actionPanel}
        </Grid>
      </Grid>
      {helpOpen && 
        <DomainHelp 
          open = {helpOpen}
          domain = {props.domain}
          domainSingular = {props.domainSingular}
          handleClose = {handleHelpClose}
        />
      }
    </div>
  )
}

LTRDomainLayout.defaultProps = {
  toolbarActionHandler: null,
  toolbarActionsNeeded: []
}

LTRDomainLayout.propTypes = {
  domain: PropTypes.string.isRequired,
  domainSingular: PropTypes.string.isRequired,
  handleViewChange: PropTypes.func.isRequired,
  commonNameList: PropTypes.object.isRequired,
  commonDataTable: PropTypes.object.isRequired,
  toolbarActionHandler: PropTypes.func,
  toolbarActionsNeeded: PropTypes.array
}

export default (LTRDomainLayout)
import React, { useState, useEffect, Suspense } from "react";
import PropTypes from "prop-types";

import MUIDataTable from "mui-datatables";

import { useRecoilState } from "recoil";
import { actionItemList } from "./ActionItemState";

import Button from "@material-ui/core/Button";

import { useApolloClient, useMutation, gql } from "@apollo/client";

import { fetchActionItems } from "../../api/ActionItemApi";
import { listAllActionItems } from "../../graphql/queries";
import { deleteActionItems } from "../../graphql/mutations";
import { ActionItemType } from "../../Enums";

import CustomToolbar from "./CustomToolbar";
import CustomToolbarSelect from "./CustomToolbarSelect";
import LTRSnackbar, {
  buildAddMessage,
  buildEditMessage,
} from "../utilties/LTRSnackbar";

import EditActionItemForm from "./EditActionItemForm";

import { PriorityType } from "../../Enums";

import { useFamilyContext } from "../../context/FamilyContext";

import { useSnackbar } from "../utilties/snackbarUtil";

// helpers, for showing info
import AccountInfo from "../accounts/AccountInfo";
import AssetInfo from "../assets/AssetInfo";
import ContactInfo from "../contacts/ContactInfo";
import InsuranceInfo from "../insurance/InsuranceInfo";
import LiabilityInfo from "../liabilities/LiabilityInfo";
import OtherItemsInfo from "../otheritems/OtherItemsInfo";
import ServiceInfo from "../services/ServiceInfo";
import SubscriptionInfo from "../subscriptions/SubscriptionInfo";
import DocumentInfo from "../documents/DocumentInfo";
import GeneralInfo from "../general/GeneralInfo";

import AddActionItemForm from "./AddActionItemForm";

const ActionItemTable = (props) => {
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [actionItems, setActionItems] = useRecoilState(actionItemList);

  const [selected, setSelected] = useState({});
  const [editActionItemOpen, setEditActionItemOpen] = useState(false);

  const [showDomainInfo, setShowDomainInfo] = useState(false);
  const [addActionItemOpen, setAddActionItemOpen] = useState(false);

  // all derived data from actionItems
  const [dataRows, setDataRows] = useState([]);

  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  // Handling delete
  const [actionItemDelete] = useMutation(gql(deleteActionItems), {
    refetchQueries: [
      {
        query: gql(listAllActionItems),
        fetchPolicy: "network-only",
        variables: { userRealm: familyContext.familyRealm.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    let isMounted = true;

    const fetchItems = () => {
      fetchActionItems(client, familyContext.familyRealm.id, props.itemType)
        .then((result) => {
          const items = result.data.ActionItems;
          if (isMounted) {
            setActionItems(items);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchItems();

    return () => {
      isMounted = false;
    }; // use effect cleanup to set flag false, if unmounted
  }, [props.itemType]);

  useEffect(() => {
    if (actionItems && actionItems.length !== 0) {
      let data = [];
      actionItems.map((item) => {
        const dataRow = [
          item.id,
          item.dueDate,
          item.type,
          item.name,
          item.owner,
          item.priority,
          item.status,
          item.notes,
        ];
        data.push(dataRow);
        return dataRow;
      });
      setDataRows(data);
    } else {
      setDataRows([]);
    }
  }, [actionItems]);

  //onRowClick = (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => void
  const handleRowClick = (rowData, rowMeta) => {
    console.log("Rowdata: ", rowData);
    console.log("RowMeta dataindex: ", rowMeta.dataIndex);
    console.log("RowMeta rowindex: ", rowMeta.rowIndex);
  };

  const removeDeletedRows = (deletedItems) => {
    const idsToRemove = deletedItems.map((row) => {
      return row.id;
    });

    const remainingRows = actionItems.filter((row) => {
      return idsToRemove.includes(row.id) ? false : true;
    });

    setActionItems(remainingRows);
  };

  const onRowsDelete = (rowsDeleted) => {
    actionItemDelete({ variables: { rows: rowsDeleted } })
      .then((result) => {
        if (result.data && result.data.delete_ActionItems) {
          const deletedItems = [...result.data.delete_ActionItems.returning];
          const numItemsDeleted = result.data.delete_ActionItems.affected_rows;
          removeDeletedRows(deletedItems);
          // notify the user
          let userMessage = "";
          if (numItemsDeleted > 1) {
            userMessage =
              numItemsDeleted + " action items deleted successfully";
            openSnackbar(userMessage);
          } else {
            userMessage = "Action item deleted successfully";
            openSnackbar(userMessage);
          }
        }
      })
      .catch((error) => {
        console.log("Error deleting action item: " + error);
        openSnackbar("Oh no! The action item was NOT deleted");
      });
    return true;
  };

  const handleEdit = (selectedId) => {
    // need to identify which action item is selected
    const item = actionItems.filter((actionItem) => {
      return actionItem.id.indexOf(selectedId) === 0;
    });
    setSelected(item[0]);
    setEditActionItemOpen(true);
  };

  const handleEditActionItemNotification = (actionItem) => {
    const existingRows = actionItems.filter((item) => {
      return item.id.indexOf(actionItem.id) !== 0;
    });

    const newRows = [...existingRows, actionItem];
    setActionItems(newRows);
    openSnackbar(buildEditMessage(actionItem.name));
  };

  const handleAddActionItemNotification = (actionItem) => {
    const newRows = [actionItem, ...actionItems];
    setActionItems(newRows);
    openSnackbar(buildAddMessage(actionItem.name));
  };

  const showCellInfo = (rowIndex, dataIndex) => {
    const itemRow = dataRows[dataIndex];
    const matchingItems = actionItems.filter((item) => {
      return item.id === itemRow[0];
    });

    if (matchingItems.length > 1) {
      console.error("Serious data issue: should only match one item");
    }
    setSelected(matchingItems[0]);
    setShowDomainInfo(true);
  };

  const handleCloseShowInfo = () => {
    setShowDomainInfo(false);
    setSelected({});
  };

  const handleAddActionItem = () => {
    setAddActionItemOpen(true);
  };

  // are we ready?
  if (actionItems === null) {
    return <div>Loading ...</div>;
  }

  const renderDomainInfo = () => {
    if (selected === {}) {
      return null;
    }
    switch (selected.type) {
      case ActionItemType.Account:
        return (
          <AccountInfo
            open={true}
            domainId={selected.domainId}
            handleClose={handleCloseShowInfo}
          />
        );
      case ActionItemType.Assets:
        return (
          <AssetInfo
            open={true}
            domainId={selected.domainId}
            handleClose={handleCloseShowInfo}
          />
        );
      case ActionItemType.Contacts:
        return (
          <ContactInfo
            open={true}
            domainId={selected.domainId}
            handleClose={handleCloseShowInfo}
          />
        );
      case ActionItemType.Insurance:
        return (
          <InsuranceInfo
            open={true}
            domainId={selected.domainId}
            handleClose={handleCloseShowInfo}
          />
        );
      case ActionItemType.Liabilities:
        return (
          <LiabilityInfo
            open={true}
            domainId={selected.domainId}
            handleClose={handleCloseShowInfo}
          />
        );
      case ActionItemType.OtherItems:
        return (
          <OtherItemsInfo
            open={true}
            domainId={selected.domainId}
            handleClose={handleCloseShowInfo}
          />
        );
      case ActionItemType.Services:
        return (
          <ServiceInfo
            open={true}
            domainId={selected.domainId}
            handleClose={handleCloseShowInfo}
          />
        );
      case ActionItemType.Subscriptions:
        return (
          <SubscriptionInfo
            open={true}
            domainId={selected.domainId}
            handleClose={handleCloseShowInfo}
          />
        );
      case ActionItemType.Documents:
        return (
          <DocumentInfo
            open={true}
            domainId={selected.domainId}
            handleClose={handleCloseShowInfo}
          />
        );
      case ActionItemType.General:
        return <GeneralInfo open={true} handleClose={handleCloseShowInfo} />;
      default:
        console.log("Domain show info not supported");
        break;
    }
  };

  // Note: this is to support sorting priorities - see sortCompare below
  const priorities = {
    low: 1,
    medium: 2,
    high: 3,
    top: 4,
  };

  // NOTE: the first column MUST be the id - so we can find it and use
  // it for operations
  let columns = [
    {
      name: "Id",
      options: {
        display: "excluded",
        download: false,
        print: false,
        filter: false,
      },
    },
    {
      name: "Due Date",
    },
    {
      name: "Type",
      options: {
        display: props.itemType === ActionItemType.All ? "true" : "excluded",
        filter: props.itemType === ActionItemType.All,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            var a = obj1.data.toUpperCase(); // ignore upper and lowercase
            var b = obj2.data.toUpperCase(); // ignore upper and lowercase
            if (a < b) {
              return -1 * (order === "asc" ? 1 : -1);
            }
            if (a > b) {
              return 1 * (order === "asc" ? 1 : -1);
            }
            // domains must be equal
            return 0;
          };
        },
      },
    },
    {
      name: "Description",
      options: {
        sort: false,
      },
    },
    { name: "Assigned to" },
    {
      name: "Priority",
      options: {
        /*
          Semantic sort order is: Low, Medium, High, Top but
          alpha sort order is High, Low, Medium, Top, 
          SO we need a special sort routine
        */
        sortCompare: (order) => {
          return (obj1, obj2) => {
            // same
            if (
              obj1.data === PriorityType.Low &&
              obj2.data === PriorityType.Low
            ) {
              return 0;
            }
            if (
              obj1.data === PriorityType.Medium &&
              obj2.data === PriorityType.Medium
            ) {
              return 0;
            }
            if (
              obj1.data === PriorityType.High &&
              obj2.data === PriorityType.High
            ) {
              return 0;
            }
            if (
              obj1.data === PriorityType.Top &&
              obj2.data === PriorityType.Top
            ) {
              return 0;
            }

            // first compare item is 'low'
            if (
              obj1.data === PriorityType.Low &&
              obj2.data === PriorityType.Medium
            ) {
              return (
                (priorities.low - priorities.medium) *
                (order === "asc" ? 1 : -1)
              );
            }
            if (
              obj1.data === PriorityType.Low &&
              obj2.data === PriorityType.High
            ) {
              return (
                (priorities.low - priorities.high) * (order === "asc" ? 1 : -1)
              );
            }
            if (
              obj1.data === PriorityType.Low &&
              obj2.data === PriorityType.Top
            ) {
              return (
                (priorities.low - priorities.top) * (order === "asc" ? 1 : -1)
              );
            }

            // first compare item is 'medium'
            if (
              obj1.data === PriorityType.Medium &&
              obj2.data === PriorityType.Low
            ) {
              return (
                (priorities.medium - priorities.low) *
                (order === "asc" ? 1 : -1)
              );
            }
            if (
              obj1.data === PriorityType.Medium &&
              obj2.data === PriorityType.High
            ) {
              return (
                (priorities.medium - priorities.high) *
                (order === "asc" ? 1 : -1)
              );
            }
            if (
              obj1.data === PriorityType.Medium &&
              obj2.data === PriorityType.Top
            ) {
              return (
                (priorities.medium - priorities.top) *
                (order === "asc" ? 1 : -1)
              );
            }

            // first compare is 'high'
            if (
              obj1.data === PriorityType.High &&
              obj2.data === PriorityType.Low
            ) {
              return (
                (priorities.high - priorities.low) * (order === "asc" ? 1 : -1)
              );
            }
            if (
              obj1.data === PriorityType.High &&
              obj2.data === PriorityType.Medium
            ) {
              return (
                (priorities.high - priorities.medium) *
                (order === "asc" ? 1 : -1)
              );
            }
            if (
              obj1.data === PriorityType.High &&
              obj2.data === PriorityType.Top
            ) {
              return (
                (priorities.high - priorities.top) * (order === "asc" ? 1 : -1)
              );
            }
            // first compare is 'top'
            if (
              obj1.data === PriorityType.Top &&
              obj2.data === PriorityType.Low
            ) {
              return (
                (priorities.top - priorities.low) * (order === "asc" ? 1 : -1)
              );
            }
            if (
              obj1.data === PriorityType.Top &&
              obj2.data === PriorityType.Medium
            ) {
              return (
                (priorities.top - priorities.medium) *
                (order === "asc" ? 1 : -1)
              );
            }
            if (
              obj1.data === PriorityType.Top &&
              obj2.data === PriorityType.High
            ) {
              return (
                (priorities.top - priorities.high) * (order === "asc" ? 1 : -1)
              );
            }
          };
        },
      },
    },
    { name: "Status" },
    {
      name: "Notes",
      options: {
        sort: false,
      },
    },
    {
      name: "Info",
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        print: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const itemRow = dataRows[dataIndex];
          const type = itemRow[2];
          if (type === ActionItemType.General) {
            return null;
          } else {
            return (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => showCellInfo(rowIndex, dataIndex)}
              >
                More
              </Button>
            );
          }
        },
      },
    },
  ];

  const options = {
    filterType: "multiselect",
    responsive: "vertical",
    fixedHeader: true,
    tableBodyHeight: "600px",
    tableBodyMaxHeight: "auto",
    downloadOptions: { filename: "actionitems-download.csv", separator: "," },
    sortOrder: {
      name: props.itemType === ActionItemType.All ? "Type" : "Description",
      direction: "asc",
    },
    onRowClick: (rowData, rowState) => {
      handleRowClick(rowData, rowState);
    },
    customToolbar: () => {
      return (
        <CustomToolbar
          includeAddAction={props.allowAddNew}
          handleAddClick={props.allowAddNew ? handleAddActionItem : null}
        />
      );
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        onRowsDelete={onRowsDelete}
        handleEdit={handleEdit}
      />
    ),
    textLabels: {
      body: {
        noMatch: "No action items found",
      },
    },
  };

  let title = props.itemType + ": action items";
  if (props.itemType === ActionItemType.All) {
    title = "Action items";
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <MUIDataTable
        title={title}
        data={dataRows}
        columns={columns}
        options={options}
      />
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
      {editActionItemOpen && selected !== {} ? (
        <EditActionItemForm
          open={editActionItemOpen}
          selectedId={selected.id}
          handleClose={() => setEditActionItemOpen(false)}
          handleEdit={handleEditActionItemNotification}
        />
      ) : null}
      {addActionItemOpen && selected !== {} ? (
        <AddActionItemForm
          open={addActionItemOpen}
          handleClose={() => setAddActionItemOpen(false)}
          handleAddNotification={handleAddActionItemNotification}
        />
      ) : null}
      {showDomainInfo && selected !== {} ? renderDomainInfo() : null}
    </Suspense>
  );
};

ActionItemTable.defaultProps = {
  allowAddNew: false,
};

ActionItemTable.propTypes = {
  itemType: PropTypes.string.isRequired,
  allowAddNew: PropTypes.bool,
};

export default ActionItemTable;

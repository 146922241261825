import {
  OperationTargets,
  addMultipleDomainInstances,
} from "../../../api/CommonCrudOperations";

import {
  AssetType,
  InsuranceType,
  DomainType,
  GovernmentBenefitType,
} from "../../../Enums";

import {
  constructDocActionItems,
  addDocActionItems,
  constructMedicareActionItems,
  constructSocialSecurityActionItems,
  constructEOLActionItems,
  addMedicareActionItems,
  addSocialSecurityActionItems,
  addDeathCertificateActionItem,
  addAnythingElse
} from "./ActionItemHelper";

export const addDeathCerticateItem = (client, userRealm) => {
  // this is a simple action item, just add it
  addDeathCertificateActionItem(client, userRealm);
};

export const addAnythingElseItems = (client, planOwners, userRealm) => {
  // catch all
  addAnythingElse(client, planOwners, userRealm);
}

export const addEOLItems = (client, documents, userRealm) => {
  const details = [];

  documents.map((document) => {
    const docDetail = {
      userRealm: userRealm,
      name: document.name,
      type: document.type,
      description: document.description,
      digital: false,
      location: "",
      notes: document.notes,
    };

    const actionItems = constructEOLActionItems(document);
    if (actionItems.length > 0) {
      docDetail.DocumentsActionItems = {
        data: [...actionItems],
      };
    }
    details.push(docDetail);
  });

  if (details.length) {
    // we may not have any documents to add
    addMultipleDomainInstances(
      client,
      userRealm,
      OperationTargets.Documents,
      details
    )
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const addDocuments = (client, userRealm, documents, actions) => {
  const details = [];
  documents.map((document) => {
    const docDetail = {
      userRealm: userRealm,
      name: document.name,
      type: document.type,
      description: document.description,
      digital: false,
      location: "",
      notes: "",
    };

    const actionItems = constructDocActionItems(document);
    if (actionItems.length > 0) {
      docDetail.DocumentsActionItems = {
        data: [...actionItems],
      };
    }
    details.push(docDetail);
  });

  // we may not have any documents to add
  if (documents.length > 0) {
    addMultipleDomainInstances(
      client,
      userRealm,
      OperationTargets.Documents,
      details
    )
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  if (actions.length > 0) {
    // we still need to add some action items: get a will, get a power of attorney, get a health directive
    addDocActionItems(client, userRealm, actions);
  }
};

export const addGovernmentBenefits = (client, userRealm, benefits) => {
  // if this is social security it is an Asset: Pension
  // if this is medicare it is Insurance: Medical
  // NOTE: logic will need to be more sophisicated if we have any other type of benefit

  const assetDetails = [];
  const insuranceDetails = [];
  benefits.map((benefit) => {
    const benefitDetail = {
      userRealm: userRealm,
      name: benefit.name,
      type:
        benefit.domainType === DomainType.Assets
          ? AssetType.Pension
          : InsuranceType.Medical,
    };

    // if this is a medicare item AND we are getting medicare, then grab any
    // action items and stuff them into the insurance item we're adding
    if (benefit.type === GovernmentBenefitType.Medicare) {
      if (benefit.value) {
        const actionItems = constructMedicareActionItems(benefit);
        if (actionItems.length > 0) {
          benefitDetail.InsuranceActionItems = {
            data: [...actionItems],
          };
        }
        // insurance does not have a description field
        benefitDetail.usage =
          "benefit is provided by Centers for Medicare & Medicaid";
        benefitDetail.company = "CMS";
        insuranceDetails.push(benefitDetail);
      } else {
        addMedicareActionItems(client, userRealm, benefit);
      }
    }

    // if this is a social securuty item AND we are getting getting, then grab any
    // action items and stuff them into the asset item we're adding
    if (benefit.type === GovernmentBenefitType.SocialSecurity) {
      if (benefit.value) {
        const actionItems = constructSocialSecurityActionItems(benefit);
        if (actionItems.length > 0) {
          benefitDetail.AssetActionItems = {
            data: [...actionItems],
          };
        }
        // asset does have a description field
        benefitDetail.description =
          "Provided by the Social Security Administration (SSA)";
        assetDetails.push(benefitDetail);
      } else {
        addSocialSecurityActionItems(client, userRealm, benefit);
      }
    }
  });

  // we may not have any benefits at all to add
  if (assetDetails.length || insuranceDetails.length) {
    // add the assets first, if we have any
    if (assetDetails.length > 0) {
      addMultipleDomainInstances(
        client,
        userRealm,
        OperationTargets.Assets,
        assetDetails
      )
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (insuranceDetails.length > 0) {
      addMultipleDomainInstances(
        client,
        userRealm,
        OperationTargets.Insurance,
        insuranceDetails
      )
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};

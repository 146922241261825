import {ContactType, ActionItemType, ActionItemGroupType, PriorityType } from '../../Enums';
import { NonChoice } from '../../Enums';

export const prepareDetailsForContactItems = (contact) => {
  // figure out the priority based on the contact type
  let priority = PriorityType.Medium;
  if (contact.type === ContactType.Family) {
    priority = PriorityType.High;
  }

  let groupType = ActionItemGroupType.None;
  // if this is a medical contact we will group this with other medical action items
  if (contact.type === ContactType.Medical) {
    groupType = ActionItemGroupType.Medical;
  }

  const actionItemDetails = {
    name: 'Contact ' + contact.name,
    dueDate: NonChoice,
    priority: priority,
    status: 'Open',
    domainId: contact.id,
    domainName: contact.name,
    type: ActionItemType.Contacts,
    group: groupType,
    notes: ''
  };
  
  return actionItemDetails;
}

export const constructCorrespondingActionItems = (item) => {
  const actionItems = [];

  const itemDetails = prepareDetailsForContactItems(item);
  if (itemDetails) {
    actionItems.push(itemDetails);
  }
 
  return actionItems;
}
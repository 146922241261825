import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import cloneDeep from "lodash.clonedeep";

import { Formik, Form, Field } from "formik";

// wrappers for material ui components for formik
import { TextField, Select } from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { SubscriptionTypeList, NonChoice, DomainType } from "../../../Enums";

import { FormDialogBase } from "../../shared/formhelpers/FormDialogBase";
import PaymentAndBilling from "../../shared/formhelpers/PaymentAndBilling";

import { validationSchema } from "./SubscriptionFormMetaData";

import { OperationTargets } from "../../../api/CommonCrudOperations";

import { getSubscription } from "../../../graphql/queries";
import { updateOneBiller } from "../../../api/BillerApi";
import { addMultipleActionItems } from "../../../api/ActionItemApi";

import { useFamilyContext } from "../../../context/FamilyContext";
import { useApolloClient, useQuery, gql } from "@apollo/client";
import { useSubscriptionAdd, useSubscriptionUpdate } from "../../../api/APIHooks";

import { constructCorrespondingActionItems } from "../SubscriptionHelper";

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },

  button: {
    margin: theme.spacing(1),
  },

  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const AddEditSubscriptionForm = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [open, setOpen] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [subscription, setSubscription] = useState({
    name: "",
    type: NonChoice,
    company: "",
    billingFrequency: NonChoice,
    howPaid: NonChoice,
    billType: NonChoice,
    notes: "",
    accountId: NonChoice,
    SubscriptionsActionItems: [],
    SubscriptionsDocumentItems: [],
  });

  const [skipQuery, setSkipQuery] = useState(true);

  let { loading, error, data } = useQuery(gql(getSubscription), {
    variables: { id: props.selected ? props.selected.id : "" },
    skip: skipQuery,
  });

  const [addOneSubscription] = useSubscriptionAdd(
    familyContext.familyRealm.id,
    (data) => {
      console.log(data);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data);
      // }
    }
  );

  const [updateOneSubscription] = useSubscriptionUpdate(
    familyContext.familyRealm.id,
    (data) => {
      console.log(data);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data);
      // }
    }
  );

  useEffect(() => {
    if (!skipQuery) {
      const onCompleted = (data) => {
        const subscription = data.item;
        const editSubscription = cloneDeep(subscription);

        // flatten the Subscription Biller fields
        if (subscription.billers && subscription.billers.length > 0) {
          const biller = subscription.billers[0];
          editSubscription.billingFrequency = biller.billingFrequency
            ? biller.billingFrequency
            : "";
          editSubscription.howPaid = biller.howPaid ? biller.howPaid : "";
          editSubscription.billType = biller.billType ? biller.billType : "";
          editSubscription.billerId = biller.id;
          editSubscription.accountId = biller.accountId
            ? biller.accountId
            : NonChoice;
        }
        setSubscription(editSubscription);
      };
      const onError = (error) => {
        return <div>{error}</div>;
      };

      if (onCompleted || onError) {
        if (onCompleted && !loading) {
          onCompleted(data);
          setSkipQuery(true);
        } else if (onError && !loading && error) {
          onError(error);
          setSkipQuery(true);
        }
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    const { selected } = props;

    // if we're editing and we haven't yet fetched the item
    if (selected && skipQuery) {
      setSkipQuery(false);
    }

    return () => {
      setSkipQuery(true);
    };
  }, [props.selected]);

  const save = (values) => {
    // Mutation - add or update one
    const detailsMain = {
      userRealm: familyContext.familyRealm.id,
      name: values.name,
      type: values.type ? values.type : "",
      company: values.company ? values.company : "",
      notes: values.notes ? values.notes : "",
    };

    // use the company name, if we have one, as the payee
    // use the name itself as the description
    const detailsBiller = {
      payee: values.company ? values.company : values.name,
      howPaid: values.howPaid ? values.howPaid : "",
      billType: values.billType ? values.billType : "",
      billingFrequency: values.billingFrequency ? values.billingFrequency : "",
      domainType: DomainType.Subscriptions,
      description: values.name,
      accountId: values.accountId !== NonChoice ? values.accountId : null,
    };

    // props.open means we're here for editing - otherwise we're adding
    props.open
      ? updateOne(detailsMain, detailsBiller)
      : addOne(detailsMain, detailsBiller);
  };

  const addOne = (detailsMain, detailsBiller) => {
    // prepare the biller detail - just add it to the payload
    detailsMain.SubscriptionBiller = {
      data: [{ ...detailsBiller }],
    };

    // add any default action items based on the subscription
    const actionItems = constructCorrespondingActionItems(detailsMain);
    if (actionItems.length > 0) {
      detailsMain.SubscriptionsActionItems = {
        data: [...actionItems],
      };
    }

    addOneSubscription({ variables: { object: detailsMain } })
    .then((result) => {
      props.handleAdd(result.data.item);
    })
    .catch((error) => {
      console.error(error);
    });

    // hide the modal
    handleClose();
  };

  const updateOne = (detailsMain, detailsBiller) => {
    // add default subscription items if they haven't been added already
    if (subscription.actionItems.length === 0) {
      const actionItems = constructCorrespondingActionItems(detailsMain);
      if (actionItems.length > 0) {
        const items = actionItems.map((item) => {
          item.userRealm = familyContext.familyRealm.id;
          item.domainId = subscription.id;
          return item;
        });

        addMultipleActionItems(
          client,
          familyContext.familyRealm.id,
          OperationTargets.Subscriptions,
          items
        )
          .then((_) => {
            console.log("Added %s action items", actionItems.length);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    // update the biller
    updateOneBiller(client, subscription.billerId, detailsBiller)
      .then((resultBiller) => {
        console.log(resultBiller);
      })
      .catch((error) => {
        console.log(error);
      });

    // finally the subscription
    updateOneSubscription({
      variables: { id: subscription.id, changes: detailsMain },
    })
      .then((result) => {
        props.handleEdit(result.data.item);
      })
      .catch((error) => {
        console.error(error);
      });

    // hide the modal
    handleClose();
  };

  // Attach this to your <Formik>
  const formRef = useRef();

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleResetClick = () => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // normal close and cancel
  const handleClose = () => {
    // if we were opened by the client, let them close us, otherwise we close
    props.handleClose ? props.handleClose() : setOpen(false);
  };

  const body = (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={subscription}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        // actually save the form
        save(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="name"
              type="text"
              label="Name"
              autoFocus
              className={classes.textField}
              margin="normal"
              helperText={
                touched.name
                  ? errors.name
                  : "Provide the name of this subscription"
              }
              error={touched.name && Boolean(errors.name)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="subscription-type">
              Subscription Type
            </InputLabel>
            <Field
              component={Select}
              name="type"
              inputProps={{
                id: "subscription-type",
              }}
            >
              {SubscriptionTypeList.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText>What type of subscription is this?</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="company"
              label="Company"
              className={classes.textField}
              margin="normal"
              helperText="Provide the name of the company that manages the subscription"
            />
          </FormControl>
          <PaymentAndBilling
            nameReceipt="billType"
            namePayment="howPaid"
            nameBilling="billingFrequency"
            nameBillingAccount="accountId"
          />
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="notes"
              label="Notes"
              multiline
              maxRows={4}
              className={classes.textField}
              margin="normal"
              helperText="Add any notes/hints/special instructions to help with this subscription"
            />
          </FormControl>
        </Form>
      )}
    </Formik>
  );

  const title = props.open ? "Edit a Subscription" : "Add a Subscription";

  return (
    <div>
      <FormDialogBase
        open={props.open ? props.open : open}
        title={title}
        body={body}
        submitEnabled={submitEnabled}
        handleAddClickOpen={handleClickOpen}
        handleClose={handleClose}
        handleReset={handleResetClick}
        handleSubmit={handleSubmitClick}
      />
    </div>
  );
};

// client can open if they choose to OR we will open
AddEditSubscriptionForm.defaultProps = {
  selected: null,
  open: false,
  handleClose: null,
  handleReceiveData: null,
};

AddEditSubscriptionForm.propTypes = {
  selected: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAdd: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleReceivedData: PropTypes.func,
};

export default AddEditSubscriptionForm;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { sendPasswordResetEmail } from "firebase/auth";
import { firebaseAuth } from "../../../index";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import LTRSnackbar from "../../utilties/LTRSnackbar";
import { useSnackbar } from "../../utilties/snackbarUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: "RED",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ForgotPasswordDialog = (props) => {
  const classes = useStyles();
  const { openSnackbar, closeSnackbar, snackbarOpen, snackbarMessage } =
    useSnackbar();

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  // formdata handler to retrieve data
  const handleSubmit = () => {
    sendPasswordResetEmail(firebaseAuth, email)
      .then((_) => {
        openSnackbar("Successfully sent password reset email");
        props.handleClose();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const title = "Forgot Password?";
  const labelText = "Enter your email address.";
  const bodyText =
    "If your email address is recognized, a link to reset your password will be sent to your inbox.";

  return (
    <div className={classes.root}>
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="forgot-password-dialog-title"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{labelText}</DialogContentText>

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            label="Email"
            type="email"
            autoFocus
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <DialogContentText>{bodyText}</DialogContentText>
          {error && (
            <Typography align="center" className={classes.error}>
              {error.message}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={email === ""}
            onClick={handleSubmit}
          >
            Send Reset Email
          </Button>
        </DialogActions>
      </Dialog>
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    </div>
  );
};

ForgotPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ForgotPasswordDialog;

import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { useApolloClient } from "@apollo/client";

import { useSetRecoilState } from "recoil";
import { currentUserState } from "../../context/UserState";

import { useFormik } from "formik";

import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { firebaseAuth } from "../../index";

import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

import { fetchOneUserByEmail, addOneUser } from "../../api/UsersApi";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import Container from "@material-ui/core/Container";

import InfoModal from "../shared/modals/InfoModal";

import Copyright from "../home/Copyright";

import LetterRoutes from "../../Routes";

import { signupValidationSchema } from "../shared/validators/Validators";
import PasswordRequirements from "../shared/security/PasswordRequirements";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  img: {
    opacity: 1,
    display: "block",
    maxWidth: "100%",
    height: "auto",
    verticalAlign: "middle",
    marginBottom: theme.spacing(1),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: "100%",
  },
  signin: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: "RED",
    width: "100%",
  },
}));

const SignUp = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const history = useHistory();

  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const setAppUser = useSetRecoilState(currentUserState);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: signupValidationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      save(values);
    },
  });
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const updateUserCache = async () => {
    await firebaseAuth.currentUser.reload()
  }

  const save = async (values) => {
    createUserWithEmailAndPassword(firebaseAuth, values.email, values.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("Successfullys signed up AND logged in to Firebase");
        console.log("User: " + user.uid);
        // just updating the user's profile
        updateProfile(user, {
          displayName: values.firstName + " " + values.lastName, 
        })
        .then((_) => {
          // make sure our cache gets updated for downstream clients
          updateUserCache();

          // OK, we have created an auth user
          // Now, see if this user already exists in our system; if he or she
          // doesn't add the user to our system

          // now see if this user is a new user or not!
          fetchOneUserByEmail(client, values.email)
          .then((result) => {
            // TODO: should we tolerate this? Could be a user migrating to the new auth system,
            // we will just continue quietly but perhaps we shouldn't
            // congratulate and provide a way out of this page
            setInfoModalOpen(true);
          })
          .catch((error) => {
            // OK, we're in the clear - we have an auth user but NOT a dbuser
            let tempUser = {
              authId: user.uid,
              loginName: values.firstName + " " + values.lastName,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              emailVerified: user.emailVerified,
            };
  
            console.log("Plan to add new user");
            console.log(tempUser);
  
            addOneUser(client, tempUser)
              .then((result) => {
                const newUser = result;
                console.log("Successfully added new user")
                tempUser.id = newUser.id;
                tempUser.realms = [];
                setAppUser(tempUser);
                setInfoModalOpen(true);
              })
              .catch((error) => {
                setError(error);
              });
          });  
        })
        .catch((error) => {
          setError(error);
        })
      })
      .catch((error) => {
        setError(error);
      });
  };

  const hideInfoModal = () => {
    setInfoModalOpen(false);
    const path = LetterRoutes.LandingRoute;
    history.push(path);
  };

  const successBody = (
    <React.Fragment>
      <Typography
        align="center"
        variant="body1"
        className={classes.titleText}
        gutterBottom
      >
        {"Congratulations " +
          formik.values.firstName +
          ". You have successfully signed up!"}
      </Typography>
      <br />
      <Typography
        align="center"
        variant="body1"
        className={classes.titleText}
        gutterBottom
      >
        {"Press OK to get started on your plan."}
      </Typography>
    </React.Fragment>
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper elevation={3} className={classes.paper}>
        {/* <picture>
          <source type="image/webp" srcSet={process.env.PUBLIC_URL + 'FAMILY-resize.png'} />
          <img 
            src={process.env.PUBLIC_URL + 'FAMILY-resize.png'} 
            alt={'FamilyShare logo'}
            className = { classes.img} />
        </picture> */}
        <Typography component="h1" className={classes.signin} variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <FormControl className={classes.formControl} >
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="First name"
              value={formik.values.firstName}
              autoFocus
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="lastName"
              name="lastName"
              label="Last name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </FormControl>
          <FormControl className={classes.formControl} >
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </FormControl>
          <FormControl className={classes.formControl} >
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
            
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword)
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          {formik.touched.password && Boolean(formik.errors.password) ? <PasswordRequirements /> : null}

          {error && (
            <Typography align="center" className={classes.error}>
              {error.message}
            </Typography>
          )}
        
          <Button 
            fullWidth
            className={classes.submit}
            color="primary" 
            variant="contained" 
            disabled={!formik.dirty && formik.isValid}
            type="submit"
          >
            Signup
          </Button>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs>
              Already signed up?
              <Link
                component={RouterLink}
                to={LetterRoutes.LoginRoute}
                variant="body2"
              >
                {" Login"}.
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>
      <InfoModal
        title="Success"
        open={infoModalOpen}
        handleClose={hideInfoModal}
        body={successBody}
      />
    </Container>
  );
};

export default SignUp;

import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { useDocumentAdd } from '../../../api/APIHooks';

import Draggable from 'react-draggable';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import {DocumentType} from '../../../Enums';

import {useFamilyContext} from '../../../context/FamilyContext';

const useStyles = makeStyles(theme => ({ 
  flex: {
    flex: 1,
  },
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: 200,
  },

  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
}));

const PaperComponent = (props) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuickAddDocument = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [name, setName] = useState('');

  const [addOneDocument] = useDocumentAdd(familyContext.familyRealm.id, (data) => {
    console.log(data)
    if (props.handleReceiveData) {
      props.handleReceiveData(data)
    } 
  });

  const save = (dismiss) => {
    // Mutation
    const details = {
      userRealm: familyContext.familyRealm.id,
      name: name,
      type: props.documentType,
      // set all the other fields to blank or default values
      description: '',
      digital: false,
      location: "",
      notes: ''
    };

    addOneDocument({ variables: { object: details } })
    .then((result) => {
      handleSave(result.data.item);
      if (dismiss) {
        handleClose();
      } else {
        setName('');
        setFocus();
      } 
    })
    .catch((error) => {
      console.error(error);
    });
  }
  const handleSaveAndStay = () => {
    save(false);
  }

  const handleSaveAndGo = () => {
    save (true);
  }

  // need a ref to re-focus the form element
  const nameRef = useRef();
  const setFocus = () => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }

  const {open, handleClose, handleSave, documentType} = props;

  const title = "Quick Add Document: " + documentType;

  let helpText;
  switch (documentType) {
    case DocumentType.WillOrTrust: {
      helpText = "Provide a descriptive name for this Will/Trust document (i.e. '2003 Smith Family Trust').";
      break;
    }
    case DocumentType.HealthDirective: {
      helpText = 'Provide a descriptive name for this Health Directive document (i.e. "Dad\'s Health Directive").';
      break;
    }
    case DocumentType.PowerOfAttorney: {
      helpText = 'Provide a descriptive name for this Power of Attorney document (i.e. "Mom\'s Power of Attorney").';
      break;
    }
    case DocumentType.Government: {
      helpText = "Provide a descriptive name for this government document (i.e. 'Social Security statement' or 'Passport page').";
      break;
    }
    case DocumentType.Identity: {
      helpText = "Provide a descriptve name for this identity document (i.e. 'Birth certificate').";
      break;
    }
    case DocumentType.Tax: {
      helpText = 'Provide a descriptive name for this tax document (i.e. "Copy of last year\'s tax filing").';
      break;
    }
    default: {
      helpText = "Provide a descriptive name for this document (i.e. 'Photo of cabinet where our will is' or 'Notes about valuables').";
    }
  }
 
  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="form-dialog-title"
      >
      <DialogTitle style={{ cursor: 'move' }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <form>
          <FormControl
            className = {classes.formControl}  
          >
            <TextField
              inputRef = {nameRef}
              autoFocus
              name= "name"
              label = "Document name"
              value = {name}
              onChange = { (ev) => setName(ev.target.value)}
              helperText={helpText}
            />
          </FormControl>
          <FormControl
            className = {classes.formControl}
          >
            <TextField
              disabled
              name = "documentType"
              label = "Document type"
              value = {documentType}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveAndStay} disabled={name === ''} color="primary">
          Save and continue
        </Button>
        <Button onClick={handleSaveAndGo} disabled={name === ''} color="primary">
          Save and finish
        </Button>
      </DialogActions>
    </Dialog>   
  )
}

QuickAddDocument.propTypes = {
  open: PropTypes.bool.isRequired,
  documentType: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired,
}

export default (QuickAddDocument)
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Draggable from 'react-draggable'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';

import {useApolloClient} from '@apollo/client';
import {updateActionItemGroupOwners} from '../../api/ActionItemApi'

import { useFamilyContext } from '../../context/FamilyContext';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
}));

const PaperComponent = (props) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AssignGroupOwnerDialog = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [owner, setOwner] = useState('')
  const [scope, setScope] = useState('All');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createOwnerSelectItems = () => {
    let items = []; 
    const { members } = familyContext;
    
    for (let i = 0; i < members.length; i++) {
      var value = members[i].firstName;           
      items.push(<MenuItem key={i} value={value}>{value}</MenuItem>);   
    }  
    return items;
  }  

  const handleSubmit = (values) => {
    setIsSubmitting(true);
    console.log('Submit owner: ' + owner);
    console.log('Submit choice: ' + scope)

    // save
    const applyToAll = scope.indexOf ('All') === 0;
    const payload = {
      owner: owner
    };

    updateActionItemGroupOwners(client, familyContext.familyRealm.id, props.actionItemGroup, applyToAll, payload)
    .then(result => { 
      console.log(result)
    })
    .catch(error => { 
        console.log(error) 
    });

    // close
    props.handleClose();
  }

  const handleChangeScope = (event) => {
    setScope(event.target.value);
  }
  const handleChangeOwner = (event) => {
    setOwner(event.target.value);
  }

  const body = (
      <form>
        <FormControl className={classes.formControl}>
          <InputLabel id="owner-label">Assign to</InputLabel>
          <Select
            labelId="owner-label"
            id="owner-select"
            value={owner}
            onChange={handleChangeOwner}
          >
             {createOwnerSelectItems()}
          </Select>
          <FormHelperText>Who will handle this group?</FormHelperText>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Assigned Scope</FormLabel>
          <RadioGroup aria-label="scope" name="scope" value={scope} onChange={handleChangeScope}>
            <FormControlLabel 
              value="All" 
              control={<Radio />} 
              label="Assign to all items in this group" 
              disabled = {isSubmitting}
              />
            <FormControlLabel 
              value="Some" 
              control={<Radio />} 
              label="Only assign to items that have no handler" 
              disabled={isSubmitting}/>
          </RadioGroup>
        </FormControl>
      </form>  
  )

  const title = "Assign a group handler";

  return (
     <div className={classes.root}>
        <Dialog
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={'md'}
          open={props.open}
          onClose={props.handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="form-dialog-title">
          <DialogTitle style={{ cursor: 'move' }} id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Assign a handler for ALL action items that are part of the {props.actionItemGroup} group.
            </DialogContentText>
            {body}
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled = {owner === ''} color="primary">
              Assign
            </Button>
          </DialogActions>
        </Dialog>  
     </div>
  );
}

AssignGroupOwnerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  actionItemGroup: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};
export default (AssignGroupOwnerDialog);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import IsolatedContainer from './IsolatedContainer';

const useStyles = makeStyles((theme) => ({
  subheading: {
    fontSize: 18,
    display: 'block',
    fontFamily: 'inherit',
    fontWeight: '500',
    lineHeight: '1.1',
    marginTop: '10px',
    marginBottom: '10px',
  }
}));

const sizes = {
  small: {zoom: 0.7},
  medium: {zoom: 1},
  large: {zoom: 2},
}

const Loading = ({size = 'medium', className = '', ...props}) => {
  return (
    <div style={sizes[size]} className={`${className} lds-ellipsis`} {...props}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

const LoadingMessagePage = ({children, ...props}) => {
  const classes = useStyles();
  return (
    <IsolatedContainer>   
      <Typography align="center" className={classes.subheading}>{children}</Typography>
      <Loading />
    </IsolatedContainer>
  )
}

export default LoadingMessagePage

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Formik, Form, Field } from "formik";

// wrappers for material ui components for formik
import { TextField } from "formik-material-ui";

import axios from "axios";

import { getIdToken } from "firebase/auth";
import { firebaseAuth } from "../../../index";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Slide from "@material-ui/core/Slide";

import LTRSnackbar from "../../utilties/LTRSnackbar";
import { useSnackbar } from "../../utilties/snackbarUtil";

import { validPassword } from "../../shared/validators/Validators";
import PasswordRequirements from "../../shared/security/PasswordRequirements";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: "RED",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangePasswordDialog = (props) => {
  const classes = useStyles();
  const {
    openSnackbar,
    closeSnackbar,
    snackbarOpen,
    snackbarMessage,
  } = useSnackbar();

  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  
  // formdata handler to retrieve data
  const save = async (values) => { 
    var url = "/api/users/updatePassword";

    const user = firebaseAuth.currentUser;

    try {
      const accessToken = await getIdToken(user);
     
      axios({
        method: "PATCH",
        url: url,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          password: values.password,
        },
      })
      .then((response) => {
        // nothing to do EXCEPT CONFIRM TO USER
        console.log(response.data);
        openSnackbar("Successfully changed password");
        handleCancel();
      })
      .catch((error) => {
        console.log(error.toJSON());
        setError(error);
      });
    } catch (e) {
      setError(e.error)
      console.log('Error in try block')
      console.error(e.error);
    } 
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCancel = () => {
    // reset our state
    setShowPassword(false);
  
    // return control to the caller
    props.handleClose()
  }

  const validatePassword = (value) => {
    let error;
    if (value && !validPassword(value)) {
      //console.log('validatePassord detects an validation error')
      error = 'Review password';
    }
    return error;
  }

  const title = "Change password";

  return (
    <div className={classes.root}>
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={props.open}
        onClose={handleCancel}
        aria-labelledby="update-user-data-dialog-title"
      >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize={true}
          initialValues={{
            password: ''
          }}
          //validationSchema = {passwordValidationSchema}
          onSubmit = {(values) => {
            save(values);
          }}
        >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          isSubmitting,
          isValid,
          /* and other goodies */
        }) => (
          <Form>
            <FormControl className={classes.formControl}>
              <Field
                component={TextField}
                name="password"
                label="New password"
                onChange={handleChange}
                validate = {validatePassword}
                autoFocus
                type={showPassword ? "text" : "password"}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword)
                        }}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            
            </FormControl>
          {errors.password ? <PasswordRequirements /> : null}
          {/* {formik.touched.password 
            && Boolean(!validPassword(formik.values.password)) ? <PasswordRequirements /> : null}
         */}
          {error && (
            <Typography align="center" className={classes.error}>
              {error.message}
            </Typography>
          )}
         
          <DialogActions>
            <Button
              fullwidth="true"
              variant="contained"
              className={classes.submit}
              onClick={() => {
                handleCancel()}
              }
              >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              type = {"submit"}
              color="primary"
              className={classes.submit}
              disabled={!validPassword(values.password)}
            >
              Change
            </Button>
          </DialogActions>
  
          </Form>
          )}
          </Formik>
        </DialogContent>
      </Dialog>
      <LTRSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    </div>
  );
};

ChangePasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ChangePasswordDialog;

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 50,
    marginLeft: 50,
    marginRight: 50,
    display: 'flex',
    justifyContent: 'center',
  }
}));

function IsolatedContainer({children, ...props}) {
  const classes  = useStyles();
  return (
    <div className={classes.container}>
      <div>{children}</div>
    </div>
  )
}

export default (IsolatedContainer)
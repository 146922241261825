/* eslint-disable no-useless-escape */

import * as yup from 'yup';

export const emailValidationSchema = () => {
  const schema = yup.object({
    email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  })
  return schema;
}

// regular expression literal - key to special characters: !@#?$%*()-~+=^
const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\#\?\$%\^&\*\(\)\=\-\~\+])(?=.{8,})/gm
export const validPassword = (password) => {
  const retVal = re.test(password);
  // console.log('testing password:' +  password)
  // if (retVal) {
  //   console.log('found a match')
  // } else {
  //   console.log('NO match')
  // }
  return retVal;
};

export const passwordValidationSchema = () => {
  const regex = new RegExp(re);
  const schema = yup.object({
    password: yup
    .string().matches(regex, 'Password is not valid')
    .min(8, 'Password should be of minimum 8 characters length') 
    .required('Password is required')
  })
  return schema;
}

export const usernameValidationSchema = () => {
  const schema = yup.object({
    username: yup
      .string()
      .min(5, 'Must be at least 5 characters')
      .required('User name is required')
  })
  return schema;
}

export const signupValidationSchema = () => {
 
  const rePassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\?#\$%\^&\*\=])(?=.{8,})");
  const schema = yup.object({
    firstName: yup
      .string()
      .required('First name is required'),
    lastName: yup
      .string()
      .required('Last name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string().matches(rePassword, 'Password is not valid')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
  })
  return schema;
}

export const loginValidationSchema = () => {
   const schema = yup.object({
    email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
    password: yup
    .string()
    .required('Password is required')
  })
  return schema;
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  bodyMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const LiabilitySnip = (props) => {
  const classes = useStyles();

  return (
     <div className={classes.root}>
      <Typography variant="body2" gutterBottom color="initial">
        'Liabilities' represent 'big' items that you OWE money on.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        This could be a mortgage or rent, a car loan or a student loan. Any type of loan is a liability.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        Many liabilities are tied to 'Assets' that are expensive and are paid off over time. If an asset is sold,
        the liability associated with it must be paid off.
      </Typography>
     </div>
  );
}

export default LiabilitySnip;
import {gql} from '@apollo/client';

import {
  updateNote,
  deleteNotesByDomainId,
} from '../graphql/mutations';

import {DomainType} from '../Enums';

export function domainSupportsNotes (domainType) {
  let retVal = false;
  switch (domainType) {
    case DomainType.Documents:
      retVal = true;
      break;
    default: 
      break;
  }
  return retVal;
}

export async function deleteNotesByDomainOwner(client, userRealm, domainId) { 
  let promise = new Promise((resolve, reject) => {

   // const refetchQuery = gql(listNotes);
    client.mutate( {
      mutation: gql(deleteNotesByDomainId),  
      variables: {domainId: domainId}
    })
    .then(result => { 
       // update the cache
      //  const data = client.readQuery({
      //   query: refetchQuery,
      //   variables: { userRealm: userRealm },
      // });
      
      // const updateData = result.data.delete_Notes;
      // // remove the edited item from the list
      // const list = data.filter(i => i.id !== updateData.id)
      // const newData = {Notes: [...list]};

      // client.writeQuery({
      //   query: refetchQuery, 
      //   variables: { userRealm: userRealm },
      //   data: newData
      // })
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

export async function updateOneNote(client, userRealm, id, payload) { 
  let promise = new Promise((resolve, reject) => {

    client.mutate( {
      mutation: gql(updateNote),
      variables: {id: id, changes: payload}
    })
    .then(result => { 
      // update the cache
      // const data = client.readQuery({
      //   query: gql(listDocuments),
      //   variables: { userRealm: userRealm },
      // });
      

      // const updatedNote = result.data.update_Notes_by_pk;

      // // remove the edited item from the list, then add it back
      // list = existingData.Documents.filter(i => i.id !== updatedNote.domainId)
      // list.push(updateData);
      // mergedData = {Liability: [...list]};    


      // const newData = ''// = mergeUpdateData(target, data, result);

      // client.writeQuery({
      //   query: gql(getDocument), 
      //   variables: { userRealm: userRealm },
      //   data: newData
      // })
      resolve(result.data);
    })
    .catch(error => {
      console.log(error) 
      reject(error);
    });
  });

  let result = await promise; // wait till the promise resolves (*)
  return result;  
};

import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {useRecoilValue} from 'recoil';
import {encryptionSettings} from '../../settings/SettingsState';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Slide from '@material-ui/core/Slide';

import ncrypter from '../../utilties/ncrypter';
import PasscodeStorage from '../../../api/PasscodeStorage';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  boldText: {
    fontWeight: 'bold'
  },
  error: {
    color: 'RED',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  hint: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));

const MIN_PASSCODE_LENGTH = 8;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const PasswordEntry = (props) => {
  const classes = useStyles();

  const settings = useRecoilValue(encryptionSettings);
  
  const [passcode, setPasscode] = useState(null);
  const [savePasscodeForSession, setSavePasscodeForSession] = useState(true);
  const [error, setError] = useState(null);

  const handleProtect= (ev) => {

    // test to see if we can decrypt
    const testValue = props.current;
    const tempNumber = ncrypter.decryptData(passcode, testValue);
    if (tempNumber.length > 0) {
      // store the passcode so we don't need to keep fetching
      if (savePasscodeForSession) {
        PasscodeStorage.setPasscode(passcode);
      }
      props.handleSave()
    } else {
      setError("Sorry, the passphrase you provided is incorrect");
      setPasscode(null);
      resetField();
    }
  }

  // need a ref to clear the field and re-focus 
  const phraseRef = useRef();
  const resetField = () => {
    if (phraseRef.current) {
      phraseRef.current.value = null;
      phraseRef.current.focus();
    }
  }

  const updateField = (ev) => {
    setPasscode(ev.target.value)
    setError(null);
  }

  const {open} = props;

  const passwordHelperTxt = "Enter your passphrase"; 
  let yourPasswordHint = '';
  if (settings.encryptionHint) {
    yourPasswordHint = "Hint: ('" + settings.encryptionHint + "')";
  }

  const bodyText1 = "This field IS protected by a passphrase.";
  const bodyText2 = "Enter your passphrase to make this field visible.";
  return (
    <div classes = {classes.root}>
      <Dialog
        TransitionComponent={Transition}
        maxWidth = {'md'}
        open={open}
        onClose={props.handleClose}
        aria-labelledby="protect-data-dialog-title">
        <DialogTitle>Enter your passphrase</DialogTitle>
        <DialogContent>
          <DialogContentText>  
              {bodyText1}  
          </DialogContentText>  
          <DialogContentText>
            {bodyText2}
          </DialogContentText> 
          <TextField 
            label="Passphrase" 
            inputRef = {phraseRef}
            autoFocus
            type = "password"
            margin="normal"
            className = {classes.textField}
            onChange = {updateField}
            helperText = {passwordHelperTxt}
          />
            {error && 
              <Typography align="center" className={classes.error}>{error}</Typography>
            }
            <DialogContentText className = {classes.hint}>{yourPasswordHint}</DialogContentText>
          <br/>
          <br/>
          <FormControlLabel
            control={
              <Checkbox
                checked={savePasscodeForSession}
                onChange={(event) => setSavePasscodeForSession(event.target.checked)}
                name="savePasscode"
                color="primary"
              />
            }
            label="Remember passphrase until you quit?"
          />  
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleProtect} disabled={passcode === null || passcode.length < MIN_PASSCODE_LENGTH} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog> 
    </div> 
  );
}

PasswordEntry.propTypes = {
  open: PropTypes.bool.isRequired,
  current: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default (PasswordEntry)
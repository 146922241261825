import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import cloneDeep from "lodash.clonedeep";

import { useQuery, useMutation, gql } from "@apollo/client";

import { getUser } from "../../../graphql/queries";
import { updateUser, updateUserRoleInRealm } from "../../../graphql/mutations";

import UserAddEditFormCore from "./UserAddEditFormCore";

import InfoModal from "../../shared/modals/InfoModal";
import UserEditIssues from "../UserEditIssues";

import { FamilyContext } from "../../../context/FamilyContext";

const UserEdit = (props) => {
  const familyContext = useContext(FamilyContext);

  const [user, setUser] = useState(null);
  const [userUpdate] = useMutation(gql(updateUser));
  const [userUpdateRole] = useMutation(gql(updateUserRoleInRealm));

  const [infoModalOpen, setInfoModalOpen] = useState(false);

  // NOTE: just want to capture this approach - it allows you
  // to postpone a query until you are ready - just set
  // the skipQuery variable and it will fire
  // BUT .. it doesn't appy in this case since we are ALWAYS
  // going to fetch based on props.id

  // const [skipQuery, setSkipQuery] = useState(true);

  // let { loading, error, data } = useQuery(qql(getUser),
  //   {
  //       variables: {id: props.id},
  //       skip: skipQuery,
  //   }
  // );

  // useEffect(() => {
  //   if (!skipQuery) {
  //       if (!loading && !error) {
  //           setResults(data?.symbolLookup);
  //           setSkipQuery(true);
  //       }
  //       else if (!loading && error) {
  //           console.log(error);
  //           setSkipQuery(true);
  //       }
  //   }
  // }, [loading, data, error]);

  let { loading, error, data } = useQuery(gql(getUser), {
    variables: { id: props.selectedId },
  });

  useEffect(() => {
    const onCompleted = (data) => {
      const user = data.Users_by_pk;
      // Note: need to add the role that this user has with the current realm
      let tempUser = cloneDeep(user);
      tempUser.isOwner = props.isOwner;
      setUser(tempUser);
    };
    const onError = (error) => {
      return <div>{error}</div>;
    };
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, props.isOwner]);

  const handleError = (values, user) => {};

  const handleAddToRealm = (values, user) => {};

  const handleSave = (values) => {
    //Test before save
    //alert(JSON.stringify(values, null, 2));
    //return
    if (values.email) {
      // need to check for email uniqueness
    }

    const roleChanged = values.isOwner !== user.isOwner;

    // NOTE: not updating the user's login name
    const details = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };

    userUpdate({ variables: { id: user.id, changes: details } })
      .then((result) => {
        // before we hand off the updated user, we need to update the role in the current realm

        // see if we can detect a change
        if (roleChanged) {
          const details = { isOwner: values.isOwner };
          userUpdateRole({
            variables: {
              userId: user.id,
              realmId: familyContext.familyRealm.id,
              changes: details,
            },
          })
            .then((result1) => {
              const user = result.data.update_Users_by_pk;
              user.isOwner = values.isOwner;
              props.handleEdit(user);
            })
            .catch((error) => {
              console.log(error);
              props.handleClose();
            });
        } else {
          const user = result.data.update_Users_by_pk;
          props.handleEdit(user);
        }
      })
      .catch((error) => {
        console.log(error);
        props.handleClose();
      });
  };

  const handleInfoModalClose = () => {
    setInfoModalOpen(false);
    props.handleClose();
  };

  const title = "Edit user";

  return (
    <>
      {props.open && user ? (
        <UserAddEditFormCore
          open={props.open}
          title={title}
          allowOwnerControl={props.allowOwnerControl}
          user={user}
          handleClose={props.handleClose}
          handleSave={handleSave}
        />
      ) : null}
      <InfoModal
        title="User already exists"
        open={infoModalOpen}
        handleClose={handleInfoModalClose}
        body={
          <UserEditIssues
            messageLead="Nothing saved - this user is ALREADY part of your plan"
            editUser={user}
            existingUser={user}
          />
        }
      />
    </>
  );
};

UserEdit.defaultProps = {
  allowOwnerControl: true,
};

UserEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedId: PropTypes.string.isRequired,
  isOwner: PropTypes.bool,
  allowOwnerControl: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default UserEdit;

import React from 'react';
import PropTypes from 'prop-types';

import Draggable from 'react-draggable'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';

const PaperComponent = (props) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FormDialogBase = (props) => {
  
  const {
    open,
    width, 
    body, 
    title, 
    submitEnabled,
    handleAddClickOpen, 
    handleClose, 
    handleReset, 
    handleSubmit
  } = props;

  return (
    <>
      <Tooltip title={title}>
        <IconButton
          aria-label={title}
          onClick={handleAddClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={width}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="form-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {body}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleReset} color="primary">
            Reset
          </Button>
          <Button onClick={handleSubmit} disabled={submitEnabled} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>   
    </>
  )
}

FormDialogBase.defaultProps = {
  width: "md",
  submitEnabled: false
}

FormDialogBase.propTypes = {
  open: PropTypes.bool.isRequired,
  width: PropTypes.string,
  body: PropTypes.object.isRequired,
  submitEnabled: PropTypes.bool,
  handleAddClickOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

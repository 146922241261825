import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import Interweave from 'interweave';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  
  media: {
    width: '50%',
    height: '50%',
    borderRadius: '8px', 
  },

  photosContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },

  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    width: '100%',
    color: theme.palette.text.secondary,
  },
}));

export default function NoteContentContainer (props) {
  const classes = useStyles();

  const transform = (node, children) => {
    if (node.tagName === 'IMG') {
      return <div className={classes.photosContainer}>
                <img src={node.getAttribute('src')} alt="thumbnail" className = {classes.media} />
              </div>;
    }
  }

  return (
   
    <Paper className = {classes.paper}>
      <Interweave
        transform = {transform}
        content= {props.children}
        /> 
    </Paper>

  )
}


NoteContentContainer.propTypes = {
 children: PropTypes.string.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },  
  border: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[500],
    borderRadius: '8px',
  },
  image: {
    display: 'block',
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(1),
    width: '100%'
  }
}));


const ImageViewer = (props) => {
  const classes = useStyles();


   /*
   id
  userRealm
  uri
  type
  domainId
  name
  mimeType
  originalName
  */

  if (props.attachment === null) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Container className={classes.border} fixed maxWidth="md">
        <picture>
            <img alt="attachment item" className={classes.image} src={props.url} />
          </picture>
      </Container>
    </div>
  );
}

ImageViewer.propTypes = {
  attachment: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
};

export default ImageViewer;


import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import LTRCommonActionPanel from '../../shared/domains/LTRCommonActionPanel'

import QuickAddLiability from './QuickAddLiability';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import {LiabilityType} from '../../../Enums';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  }
}));

const LiabilityActionPanel = (props) => {
  const classes = useStyles();

  const [liabilityType, setLiabilityType] = useState(LiabilityType.Other)
  const [quickAddOpen, setQuickAddOpen] = useState(false);

  const handleAddLiability = (type) => {
    setLiabilityType(type)
    setQuickAddOpen(true);

  }
  const handleCloseQuickAddOpen = () => {
    setQuickAddOpen(false);
  }

  const handleSave  = (liability) => {
    props.handleAddNotification(liability);
  }

  const handleReceiveData = (data) => {
    if (props.handleReceiveData) {
      props.handleReceiveData(data);
    }
  }

  return (
    <div className={classes.root}>
      <LTRCommonActionPanel
        actionComponents = {
          <ButtonGroup
            orientation="vertical"
            size = "small"
            color="primary"
            variant="text"
            aria-label="vertical outlined primary button group"
            >
            <Button className={classes.button} onClick={() => handleAddLiability(LiabilityType.Housing)} color="primary">
              Add a mortgage
            </Button>
            <Button className={classes.button} onClick={() => handleAddLiability(LiabilityType.Rent)} color="primary">
              Add rent
            </Button>
            <Button className={classes.button} onClick={() => handleAddLiability(LiabilityType.AutoLoan)}   color="primary">
              Add an auto loan
            </Button> 
            <Button className={classes.button} onClick={() => handleAddLiability(LiabilityType.StudentLoan)}   color="primary">
              Add a student loan
            </Button>
            <Button className={classes.button} onClick={() => handleAddLiability(LiabilityType.PersonalLoan)}   color="primary">
              Add a personal loan
            </Button>
            <Button className={classes.button} onClick={() => handleAddLiability(LiabilityType.OtherLoan)}   color="primary">
              Add other type of loan
            </Button>
            <Button className={classes.button} onClick={() => handleAddLiability(LiabilityType.Other)}   color="primary">
              Add other liability
            </Button>
          </ButtonGroup>
        }
        />
        {quickAddOpen ? (
           <QuickAddLiability
              open = {quickAddOpen}
              liabilityType = {liabilityType}
              handleClose = {handleCloseQuickAddOpen}
              handleSave = {handleSave}
              handleReceiveData = {handleReceiveData}
           />) 
          : null
        }
    </div>
  )
}

LiabilityActionPanel.propTypes = {
  handleAddNotification: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired,
}

export default (LiabilityActionPanel)

import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { useLiabilityAdd } from '../../../api/APIHooks';

import Draggable from 'react-draggable';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import {LiabilityType, NonChoice, DomainType} from '../../../Enums';

import {useFamilyContext} from '../../../context/FamilyContext';

const useStyles = makeStyles(theme => ({ 
  flex: {
    flex: 1,
  },
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: 200,
  },

  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
}));

const PaperComponent = (props) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuickAddLiability = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [name, setName] = useState('');

  const [addOneLiability] = useLiabilityAdd(
    familyContext.familyRealm.id,
    (data) => {
      console.log(data);
      if (props.handleReceiveData) {
        props.handleReceiveData(data);
      }
    }
  );
  
  const save = (dismiss) => {
    // Mutation
    const details = {
      userRealm: familyContext.familyRealm.id,
      name: name,
      type: props.liabilityType,
      // set everything else to ''
      institution: '',
      usage: '',
      accountNumber: ''
    };

    // we only have the name so let's use it
    const detailsBiller = {
      payee: name,
      billType: NonChoice,
      howPaid: NonChoice,
      billingFrequency: NonChoice, 
      domainType: DomainType.Liabilities,
    }

     // prepare the biller detail - just add it to the payload
     details.LiabilityBiller = {
      data: [{...detailsBiller}]
    }

    addOneLiability({ variables: { object: details } })
    .then((result) => {
      handleSave(result.data.item);
      if (dismiss) {
        handleClose();
      } else {
        setName('');
        setFocus();
      } 
    })
    .catch((error) => {
      console.error(error);
    });
  }

  const handleSaveAndStay = () => {
    save(false);
  }

  const handleSaveAndGo = () => {
    save (true);
  }

  // need a ref to re-focus the form element
  const nameRef = useRef();
  const setFocus = () => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }

  const {open, handleClose, handleSave, liabilityType} = props;

  const title = "Quick Add Liability: " + liabilityType;

  let helpText;
  switch (liabilityType) {
    case LiabilityType.Housing: {
      helpText = "Provide a descriptive name for this mortgage you pay (i.e. 'Loan on 1st Street house')";
      break;
    }
    case LiabilityType.Rent: {
      helpText = "Provide a descriptive name for the rent you pay (i.e. 'Storage Rental')";
      break;
    }
    case LiabilityType.AutoLoan: {
      helpText = "Provide a descriptive name for this auto loan (i.e. 'Loan on Acura')";
      break;
    }
    case LiabilityType.StudentLoan: {
      helpText = "Provide a descriptive name for student loan (i.e. 'Old college loan')";
      break;
    }
    case LiabilityType.PersonalLoan: {
      helpText = "Provide a descriptive name for this personal loan (i.e. 'Loan from my sister')";
      break;
    }
    case LiabilityType.OtherLoan: {
      helpText = "Provide a descriptive name for this loan (i.e. 'Loan on new windows')";
      break;
    }
    default: {
      helpText = "Provide a descriptive name for this liability";
    }
  }
 
  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="form-dialog-title"
      >
      <DialogTitle style={{ cursor: 'move' }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <form>
          <FormControl
            className = {classes.formControl}  
          >
            <TextField
              inputRef = {nameRef}
              autoFocus
              name= "name"
              label = "Liability name"
              value = {name}
              onChange = { (ev) => setName(ev.target.value)}
              helperText={helpText}
            />
          </FormControl>
          <FormControl
            className = {classes.formControl}
          >
            <TextField
              disabled
              name = "liabilityType"
              label = "Liability type"
              value = {liabilityType}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveAndStay} disabled={name === ''} color="primary">
          Save and continue
        </Button>
        <Button onClick={handleSaveAndGo} disabled={name === ''} color="primary">
          Save and finish
        </Button>
      </DialogActions>
    </Dialog>   
  )
}

QuickAddLiability.propTypes = {
  open: PropTypes.bool.isRequired,
  liabilityType: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func.isRequired
}

export default (QuickAddLiability)
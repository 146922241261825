import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import {
  isFileTypeImage,
  isFileTypeVideo,
  isFileTypeAudio,
  isFileTypeDoc,
} from "../../utilties/MimeTypeHelper";

import ImageViewer from "./ImageViewer";
import PDFViewer from "./PDFViewer";
import AudioViewer from "./AudioViewer";
import VideoViewer from "./VideoViewer";
import UnsupportedViewer from "./UnsupportedViewer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  }
}));

const ViewWrapper = (props) => {
  const classes = useStyles();

  /*
   id
  userRealm
  uri
  type
  domainId
  name
  mimeType
  originalName
  */

  const renderContentViewer = () => {
    const { attachment, contentUrl } = props;

    if (isFileTypeImage(attachment.mimeType)) {
      return <ImageViewer attachment={attachment} url={contentUrl} />;
    }
    if (isFileTypeDoc(attachment.mimeType)) {
      return <PDFViewer attachment={attachment} url={contentUrl} />;
    }
    if (isFileTypeAudio(attachment.mimeType)) {
      return <AudioViewer attachment={attachment} url={contentUrl}/>
    }
    if (isFileTypeVideo(attachment.mimeType)) {
      return <VideoViewer attachment={attachment} url={contentUrl}/>
    }
    console.log ('Unknown, unhandled mimetype')
    return <UnsupportedViewer attachment={attachment} url={contentUrl}/>
  };

  if (props.attachment === null) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          {renderContentViewer()}
        </Grid>
      </Grid>
    </div>
  );
};

ViewWrapper.propTypes = {
  attachment: PropTypes.object.isRequired,
  contentUrl: PropTypes.string.isRequired,
};

export default ViewWrapper;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  bodyMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const AssetSnip = (props) => {
  const classes = useStyles();

  return (
     <div className={classes.root}>
      <Typography variant="body2" gutterBottom color="initial">
        'Assets' represent 'big' items that you OWN.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        That may be a house, car or truck, or a boat. It might also be a pension or annuity - a steady source of income for you. 
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        Assets may be digital as well - cryto currentcy, digital tokens, domain names, online storage. 
        Digital assets may include content, like images and videos stored at Google or Amazon Photos. 
        Include here any digital asset that needs to be transferred, downloaded or deleted.
      </Typography>
      <Typography variant="body2" className = {classes.bodyMargin} color="initial">
        Many assets are not owned outright - they are frequently tied to a loan ('Liability') that you are paying off.
        Do not include smallish items here, such as 'toaster', 'sewing machine' or 'mountain bike'.
      </Typography>
     </div>
  );
}

export default AssetSnip;
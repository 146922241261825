import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";

import cloneDeep from "lodash.clonedeep";

// wrappers for material ui components for formik
import { TextField, Select } from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { useQuery, gql } from "@apollo/client";
import { useServiceAdd, useServiceUpdate } from "../../../api/APIHooks";

import { ServiceTypeList, NonChoice, DomainType } from "../../../Enums";

import { FormDialogBase } from "../../shared/formhelpers/FormDialogBase";
import PaymentAndBilling from "../../shared/formhelpers/PaymentAndBilling";

import { validationSchema } from "./ServiceFormMetaData";

import { constructCorrespondingActionItems } from "../ServiceHelper";

import { OperationTargets } from "../../../api/CommonCrudOperations";

import { getService } from "../../../graphql/queries";
import { updateOneBiller } from "../../../api/BillerApi";
import { addMultipleActionItems } from "../../../api/ActionItemApi";

import { useFamilyContext } from "../../../context/FamilyContext";
import { useApolloClient } from "@apollo/client";

import ContactSelect from "../../shared/formhelpers/ContactSelect";

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },

  button: {
    margin: theme.spacing(1),
  },

  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const AddEditServiceForm = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const familyContext = useFamilyContext();

  const [open, setOpen] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [service, setService] = useState({
    name: "",
    type: NonChoice,
    description: "",
    contactId: null,
    contact: null,
    billingFrequency: NonChoice,
    howPaid: NonChoice,
    billType: NonChoice,
    notes: "",
    accountId: NonChoice,
    ServicesActionItems: [],
    ServicesDocumentItems: [],
  });

  const [skipQuery, setSkipQuery] = useState(true);

  let { loading, error, data } = useQuery(gql(getService), {
    variables: { id: props.selected ? props.selected.id : "" },
    skip: skipQuery,
  });

  const [addOneService] = useServiceAdd(
    familyContext.familyRealm.id,
    (data) => {
      console.log(data);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data);
      // }
    }
  );

  const [updateOneService] = useServiceUpdate(
    familyContext.familyRealm.id,
    (data) => {
      console.log(data);
      // if (props.handleReceiveData) {
      //   props.handleReceiveData(data);
      // }
    }
  );

  useEffect(() => {
    if (!skipQuery) {
      const onCompleted = (data) => {
        const service = data.item;
        const editService = cloneDeep(service);

        // flatten the Service Biller fields
        if (service.billers && service.billers.length > 0) {
          const biller = service.billers[0];
          editService.billingFrequency = biller.billingFrequency
            ? biller.billingFrequency
            : "";
          editService.howPaid = biller.howPaid ? biller.howPaid : "";
          editService.billType = biller.billType ? biller.billType : "";
          editService.billerId = biller.id;
          editService.accountId = biller.accountId
            ? biller.accountId
            : NonChoice;
        }
        setService(editService);
      };
      const onError = (error) => {
        return <div>{error}</div>;
      };

      if (onCompleted || onError) {
        if (onCompleted && !loading) {
          onCompleted(data);
          setSkipQuery(true);
        } else if (onError && !loading && error) {
          onError(error);
          setSkipQuery(true);
        }
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    const { selected } = props;

    // if we're editing and we haven't yet fetched the item
    if (selected && skipQuery) {
      setSkipQuery(false);
    }

    return () => {
      setSkipQuery(true);
    };
  }, [props.selected]);

  const save = (values) => {
    // Mutation - add or update one

    const detailsMain = {
      userRealm: familyContext.familyRealm.id,
      name: values.name,
      type: values.type ? values.type : "",
      description: values.description ? values.description : "",
      notes: values.notes ? values.notes : "",
    };

     // if we have a contact, add it here
     if (values.contact) {
      detailsMain.contactId = values.contact.id
    }

    // use the name as the payee
    // use the description as the description
    const detailsBiller = {
      payee: values.name,
      howPaid: values.howPaid ? values.howPaid : "",
      billType: values.billType ? values.billType : "",
      billingFrequency: values.billingFrequency ? values.billingFrequency : "",
      domainType: DomainType.Services,
      description: values.description ? values.description : "",
      accountId: values.accountId !== NonChoice ? values.accountId : null,
    };

    // props.open means we're here for editing - otherwise we're adding
    props.open
      ? updateOne(values, detailsMain, detailsBiller)
      : addOne(values, detailsMain, detailsBiller);
  };

  const addOne = (values, detailsMain, detailsBiller) => {
    // prepare the biller detail - just add it to the payload
    detailsMain.ServiceBiller = {
      data: [{ ...detailsBiller }],
    };

    // add any default action items based on the service
    const actionItems = constructCorrespondingActionItems(values, detailsMain);
    if (actionItems.length > 0) {
      detailsMain.ServicesActionItems = {
        data: [...actionItems],
      };
    }

    addOneService({ variables: { object: detailsMain } })
    .then((result) => {
      props.handleAdd(result.data.item);
    })
    .catch((error) => {
      console.error(error);
    });

    // hide the modal
    handleClose();
  };

  const updateOne = (values, detailsMain, detailsBiller) => {
    // add default service items if they haven't been added already
    if (service.actionItems.length === 0) {
      const actionItems = constructCorrespondingActionItems(values, detailsMain);
      if (actionItems.length > 0) {
        const items = actionItems.map((item) => {
          item.userRealm = familyContext.familyRealm.id;
          item.domainId = service.id;
          return item;
        });

        addMultipleActionItems(
          client,
          familyContext.familyRealm.id,
          OperationTargets.Services,
          items
        )
          .then((_) => {
            console.log("Added %s action items", actionItems.length);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    // update the biller
    updateOneBiller(client, service.billerId, detailsBiller)
      .then((resultBiller) => {
        console.log(resultBiller);
      })
      .catch((error) => {
        console.log(error);
      });

    // finally, update the subscription
    updateOneService({ variables: { id: service.id, changes: detailsMain } })
    .then((result) => {
      props.handleEdit(result.data.item);
    })
    .catch((error) => {
      console.error(error);
    });

    // hide the modal
    handleClose();
  };

  // Attach this to your <Formik>
  const formRef = useRef();

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleResetClick = () => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // normal close and cancel
  const handleClose = () => {
    // if we were opened by the client, let them close us, otherwise we close
    props.handleClose ? props.handleClose() : setOpen(false);
  };

  const body = (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={service}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        // actually save the form
        save(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="name"
              label="Name"
              autoFocus
              className={classes.textField}
              margin="normal"
              helperText={
                touched.name ? errors.name : "Provide the name of this service"
              }
              error={touched.name && Boolean(errors.name)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="service-type">Service Type</InputLabel>
            <Field
              component={Select}
              name="type"
              inputProps={{
                id: "service-type",
              }}
            >
              {ServiceTypeList.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText>What type of service is this?</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="description"
              label="Description"
              className={classes.textField}
              margin="normal"
              helperText={"Provide a description of this service"}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <ContactSelect
              existingContact={service.contactId === null ? undefined : service.contact} 
              onChange={(contact) => {
                setFieldValue('contact', contact ? contact: null);
              }}
            />  
          <FormHelperText>Identify a contact person for this service</FormHelperText>
          </FormControl>
          <PaymentAndBilling
            nameReceipt="billType"
            namePayment="howPaid"
            nameBilling="billingFrequency"
            nameBillingAccount="accountId"
          />
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="notes"
              label="Notes"
              multiline
              maxRows={4}
              className={classes.textField}
              margin="normal"
              helperText="Add any notes/hints/special instructions to help with this service"
            />
          </FormControl>
        </Form>
      )}
    </Formik>
  );

  const title = props.open ? "Edit a Service" : "Add a Service";

  return (
    <FormDialogBase
      open={props.open ? props.open : open}
      title={title}
      body={body}
      submitEnabled={submitEnabled}
      handleAddClickOpen={handleClickOpen}
      handleClose={handleClose}
      handleReset={handleResetClick}
      handleSubmit={handleSubmitClick}
    />
  );
};

// client can open if they choose to OR we will open
AddEditServiceForm.defaultProps = {
  selected: null,
  open: false,
  handleClose: null,
};

AddEditServiceForm.propTypes = {
  selected: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAdd: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleReceiveData: PropTypes.func,
};

export default AddEditServiceForm;

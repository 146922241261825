import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import MUIDataTable from "mui-datatables";

import CustomUserTableFooter from './CusterUserTableFooter';

// TODO: this import should come from shared
import CustomToolbarSelect from "../actionitems/CustomToolbarSelect";
import LTRSnackbar, {
  buildAddMessage,
  buildEditMessage,
} from "../utilties/LTRSnackbar";

import { useQuery, useMutation, gql } from "@apollo/client";
import { listUsers } from "../../graphql/queries";
import { deleteUsers, removeUserFromRealm } from "../../graphql/mutations";

import { useFamilyContext } from "../../context/FamilyContext";
import ConfirmModal from "../shared/modals/ConfirmModal";

import UserEdit from "./forms/UserEdit";
import UserCreate from "./forms/UserCreate";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    // flexWrap: 'wrap',
    width: "100%",
  },
  heading: {
    margin: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const CustomToolbar = (props) => {
  const classes = useStyles();

  const handleClick = () => {
    if (props.handleAddClick) {
      props.handleAddClick();
    } else {
      console.log("clicked on toobar icon!");
    }
  };

  // NOTE: we aren't adding a custom toolbar since there is no use for it at this time
  return (
    <React.Fragment>
      <Tooltip title={props.addTitle}>
        <IconButton className={classes.iconButton} onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
};

CustomToolbar.propTypes = {
  addTitle: PropTypes.string.isRequired,
  handleAddClick: PropTypes.func.isRequired,
};

const UserTableView = (props) => {
  const classes = useStyles();
  const familyContext = useFamilyContext();

  const [users, setUsers] = useState(null);
  const [selected, setSelected] = useState({});
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [rowsDeleted, setRowsDeleted] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // all derived data from users
  const [dataRows, setDataRows] = useState([]);

  const [removeUsers] = useMutation(gql(deleteUsers), {
    refetchQueries: [
      {
        query: gql(listUsers),
        fetchPolicy: "network-only",
        variables: { userRealm: familyContext.familyRealm.id },
      },
    ],
  });

  const [removeFromRealm] = useMutation(gql(removeUserFromRealm));

  const { loading, error, data, refetch } = useQuery(gql(listUsers), {
    variables: { userRealm: familyContext.familyRealm.id },
  });

  const processReceivedData = (data) => {
    const userList = [];
    data.Users.map((user) => {
      const userToAdd = { ...user };

      // // need to find THIS realm to find out the user role
      const thisRealm = user.UsersRealms.filter((item) => {
        return item.realms.id === familyContext.familyRealm.id;
      });

      userToAdd.isOwner = thisRealm[0].isOwner;
      userList.push(userToAdd);
    })
    setUsers(userList);
  }

  useEffect(
    () => {
      const onCompleted = data => {
        processReceivedData(data);
      }
      const onError = error => {
        return (
          <div>{error}</div>
        )
      }
      if (onCompleted || onError) {
        if (onCompleted && !loading && !error) {
          onCompleted(data)
        } else if (onError && !loading && error) {
          onError(error)
        }
      }
    },
    [loading, data, error]
  )

  useEffect(() => {
    if (users && users.length !== 0) {
      let data = [];
      users.map((item) => {
        const dataRow = [
          item.id,
          item.isOwner,
          item.firstName,
          item.lastName,
          item.email,
        ];
        data.push(dataRow);
        return dataRow;
      });
      setDataRows(data);
    }
  }, [users]);

  if (users === null) {
    return null;
  }

  // NOTE: the first column MUST be the id - so we can find it and use
  // it for operations
  let columns = [
    {
      name: "Id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Role",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return "Owner";
          } else {
            return "Helper";
          }
        },
      },
    },
    { name: "First name", options: { sort: false } },
    { name: "Last name", options: { sort: false } },
    { name: "Email", options: { sort: false } },
  ];

  const options = {
    responsive: "vertical",
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    fixedHeader: true,
    tableBodyHeight: "auto",
    tableBodyMaxHeight: "auto",
    sortOrder: {
      name: "Role",
      direction: "asc",
    },
    customToolbar: () => {
      return (
        <CustomToolbar addTitle="Add user" handleAddClick={handleAddUserOpen} />
      );
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        onRowsDelete={onRowsDelete}
        handleEdit={handleEdit}
        allowMultipleDelete={false}
      />
    ),
    textLabels: {
      body: {
        noMatch: "No users found",
      },
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
      return (
        <CustomUserTableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      );
    },
  };

  const openSnackbar = (message) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const removeDeletedRows = (deletedItems) => {
    const idsToRemove = deletedItems.map((row) => {
      return row.id;
    });

    const remainingRows = users.filter((row) => {
      return idsToRemove.includes(row.id) ? false : true;
    });

    setUsers(remainingRows);
  };

  const handleDeleteUser = () => {
    // close the confirmation dialog
    setDeleteConfirmOpen(false);

    // Note: even though most of this code can handle multiple delete we are simplifying by
    // only allowing 1 user to be deleted at a time
    const userId = rowsDeleted[0];
    removeUsers({ variables: { rows: rowsDeleted } })
      .then((result) => {
        // before we process the removed user in the table
        removeFromRealm({
          variables: { userId: userId, realmId: familyContext.familyRealm.id },
        })
          .then((data) => {
            // don't care
          })
          .catch((error) => {
            console.log(error);
          });

        // remove from the table and notify the user
        if (result.data && result.data.delete_Users) {
          const deletedItems = [...result.data.delete_Users.returning];
          const numItemsDeleted = result.data.delete_Users.affected_rows;
          removeDeletedRows(deletedItems);

          // clear the rowsDeleted list so it doesn't linger
          setRowsDeleted([]);

          // notify the user
          let userMessage = "";
          if (numItemsDeleted > 1) {
            userMessage = numItemsDeleted + " users deleted successfully";
            openSnackbar(userMessage);
          } else {
            userMessage = "User deleted successfully";
            openSnackbar(userMessage);
          }
        }
      })
      .catch((error) => {
        console.log("Error deleting user: " + error);
        openSnackbar("Oh no! The user was NOT deleted");
      });
    return true;
  };

  const onRowsDelete = (rowsDeleted) => {
    setRowsDeleted(rowsDeleted);
    setDeleteConfirmOpen(true);
  };

  const handleEdit = (selectedId) => {
    // need to identify which action item is selected
    const item = users.filter((user) => {
      return user.id.indexOf(selectedId) === 0;
    });
    setSelected(item[0]);
    setEditUserOpen(true);
  };

  const handleEditUserSave = (user) => {
    const existingRows = users.filter((item) => {
      return item.id.indexOf(user.id) !== 0;
    });

    const newRows = [...existingRows, user];
    setUsers(newRows);
    openSnackbar(buildEditMessage(user.firstName + " " + user.lastName));
    setEditUserOpen(false);
  };

  const handleAddUserOpen = () => {
    setAddUserOpen(true);
  };

  const handleAddUserSave = (user) => {
    // just refetch
    refetch()
    // const list = [user, ...users];
    openSnackbar(buildAddMessage(user.firstName + " " + user.lastName));
   // setUsers(list);
    setAddUserOpen(false);
  };

  const title = "Users";

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        direction="column"
        style={{ minHeight: "100vh" }}
      >
        <Grid item>
          <MUIDataTable
            title={title}
            data={dataRows}
            columns={columns}
            options={options}
          />
          <LTRSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            handleClose={handleSnackbarClose}
          />
          {editUserOpen && selected !== {} ? (
            <UserEdit
              open={editUserOpen}
              selectedId={selected.id}
              isOwner={selected.isOwner}
              handleClose={() => {
                setEditUserOpen(false);
              }}
              handleEdit={handleEditUserSave}
            />
          ) : null}
          {addUserOpen ? (
            <UserCreate
              open={addUserOpen}
              realm={familyContext.familyRealm.id}
              allowOwnerControl={true}
              handleClose={() => {
                setAddUserOpen(false);
              }}
              handleSave={handleAddUserSave}
            />
          ) : null}
          <ConfirmModal
            title={"Confirm user deletion"}
            open={deleteConfirmOpen}
            handleConfirm={handleDeleteUser}
            handleClose={() => {
              setDeleteConfirmOpen(false);
            }}
            body={"Are you sure you want to delete this user?"}
          />
        </Grid>
      </Grid>
    </div>
  );
};

UserTableView.propTypes = {};

export default UserTableView;
